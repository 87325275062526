import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecruiterListComponent } from './recruiter-list/recruiter-list.component';
import { CoreModule } from '../core/core.module';
import { MaterialComponentsModule } from '../material-components.module';
import { RouterModule } from '@angular/router';
import { AddNewRecruiterComponent } from './add-new-recruiter/add-new-recruiter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditRecruiterComponent } from './edit-recruiter/edit-recruiter.component';


@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    MaterialComponentsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [RecruiterListComponent, AddNewRecruiterComponent, EditRecruiterComponent],
  exports: [AddNewRecruiterComponent, EditRecruiterComponent],
  entryComponents: [AddNewRecruiterComponent, EditRecruiterComponent]

})
export class RecruiterModule { }
