import { Component, OnInit } from '@angular/core';
import { SubscriberService } from '../service/subscriber.service';
import { CommonService } from '../../core/services/common.service';
import { AuthService } from '../../core/services/auth.service';
import { StatusMaster } from '../../core/constants/status';


import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AddSubscribeComponent } from '../../subscribe/add-subscribe/add-subscribe.component';
import { EditSubscribeComponent } from '../../subscribe/edit-subscribe/edit-subscribe.component';
import { ConfirmDeleteComponent } from '../../shared/confirm-delete/confirm-delete.component';
import { BlockModalComponent } from '../../shared/block-modal/block-modal.component';
import { AppSettings } from '../../config/app-settings';

export interface PeriodicElement {
  user_id: string
  name: string;
  email: string;
  phone: string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  // { user_id: 'VL-111', name: 'Floyd J. Felker', email: 'Mitchell@jourrapide.com', phone: '+1-740-957-2600', },
  // { user_id: 'VL-112', name: 'Steven G. Ashurst', email: 'Mitchell@jourrapide.com', phone: '+1-863-603-6221', },
  // { user_id: 'VL-113', name: 'Wanda R. Harmon', email: 'Mitchell@jourrapide.com', phone: '+1-904-923-2279', },
  // { user_id: 'VL-114', name: 'Mildred C. Conner', email: 'Mitchell@jourrapide.com', phone: '+1-559-674-8543', },
  // { user_id: 'VL-115', name: 'Floyd J. Felker', email: 'Mitchell@jourrapide.com', phone: '+1-740-957-2600', },
  // { user_id: 'VL-116', name: 'Steven G. Ashurst', email: 'Mitchell@jourrapide.com', phone: '+1-863-603-6221', },
  // { user_id: 'VL-117', name: 'Wanda R. Harmon', email: 'Mitchell@jourrapide.com', phone: '+1-904-923-2279', },
  // { user_id: 'VL-118', name: 'Mildred C. Conner', email: 'Mitchell@jourrapide.com', phone: '+1-559-674-8543', },
  // { user_id: 'VL-119', name: 'Wanda R. Harmon', email: 'Mitchell@jourrapide.com', phone: '+1-904-923-2279', },
];

@Component({
  selector: 'app-subscribe-list',
  templateUrl: './subscribe-list.component.html',
  styleUrls: ['./subscribe-list.component.scss']
})
export class SubscribeListComponent implements OnInit {

  displayedColumns: string[] = ['userId', 'name', 'email', 'phone','permissionId', 'Status', 'status'];
  dataSource = [];
  spanOption: boolean = true;
  spanOptionToggle() {
    this.spanOption = !this.spanOption;
  }

  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  isLoadMore: boolean = false;

  statusMaster: any = StatusMaster

  //NoSubFound: boolean = false;
  public manualPaging: boolean = false

  constructor(
    private subscriberService: SubscriberService,
    private commonService: CommonService,
    private authService: AuthService,
    public dialog: MatDialog,
    private appSettings: AppSettings
  ) { }


  ngOnInit() {
    this.subscriberListing(false);
  }

  subscriberListing(isPaging: boolean) {
    if (this.authService.isCompanyAdmin()) {
      try {
        let params
        if (this.manualPaging == true && isPaging != true) {
          this.pageNumber = 1
          params = {
            PageNumber: this.pageNumber,
            PageSize: this.pageSize
          }
        }
        else {
          params = {
            PageNumber: this.pageNumber,
            PageSize: this.pageSize
          }
        }
        this.subscriberService.getSubscriberList(params).subscribe(response => {
          if (response.success) {
            if (response.data.length < this.pageSize) {
              this.isLoadMore = false;
            }
            else {
              this.isLoadMore = true;
            }

            if (isPaging) {
              this.appendsubscriberListing(response.data);
            }
            else {
              this.dataSource = response.data;
            }
          }
          else {
            this.commonService.showSnakeBar(response.message);
          }
        }, (err) => {
          this.commonService.checkTokenValidity(err);
        })
      } catch{
        this.commonService.commonSnakeBar();
      }

    }
    else {
      this.commonService.showSnakeBar("Unauthorized");
    }
  }

  loadMore() {
    this.pageNumber = this.pageNumber + 1
    this.pageSize = this.pageSize
    this.subscriberListing(true);
  }


  appendsubscriberListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.dataSource.length > 0) {
        newState = this.dataSource.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.dataSource = newState;
    }
  }

  addSubscribeModal(): void {
    const dialogRef = this.dialog.open(AddSubscribeComponent, {
      disableClose: true,
      panelClass: "full-width-popup"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == undefined) {
        this.manualPaging = true
        this.subscriberListing(false);
      }
    })
  }
  editSubscribeModal(userDetail): void {
    const dialogRef = this.dialog.open(EditSubscribeComponent, {
      disableClose: true,
      panelClass: "full-width-popup",
      data: {
        userDetail
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      var index = this.dataSource.findIndex(ds => ds.userId == result.UserId);
      if (index != -1) {
        this.dataSource[index] = {
          'userId': result.UserId,
          'name': result.Name,
          'phone': result.Phone,
          'email': result.Email,
          'permissionId': result.PermissionId,
          'status': result.Status,
          'statusId': result.StatusId
        };
        this.dataSource = this.dataSource.slice()
      }
    })
  }
  deleteConfirmModal(userId): void {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      width: '600px',
      height: 'auto',
      data: {
        userId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == undefined) {
        this.manualPaging = true
        this.subscriberListing(false);
      }
    })
  }
  blockModal(userDetail): void {
    const dialogRef = this.dialog.open(BlockModalComponent, {
      width: '600px',
      height: 'auto',
      data: {
        userDetail
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == undefined) {
        this.manualPaging = true
        this.subscriberListing(false);
      }
    })
  }

  onChange(enable: boolean, userDetail) {
    const dialogRef = this.dialog.open(BlockModalComponent, {
      width: '600px',
      height: 'auto',
      data: {
        userDetail
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == undefined) {
        this.manualPaging = true
        this.subscriberListing(false);
      }
    })
  }
}
