import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';

import { MaterialComponentsModule } from '../material-components.module';

import { LoginComponent } from './user-auth/login/login.component';
import { ForgotPasswordComponent } from './user-auth/forgot-password/forgot-password.component';
import { RouterModule } from '@angular/router';
import { CookieService } from 'angular2-cookie';


@NgModule({
  imports: [
    CommonModule,
    MaterialComponentsModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers:[CookieService],
  declarations: [LoginComponent, ForgotPasswordComponent]
})
export class UsersModule { }
