import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CommonService } from '../../core/services/common.service';
import { AppSettings } from '../../config/app-settings';
import { SharedService } from '../../shared/service/shared.service';
import { JwtService } from '../../core/services/jwt.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { DirectCallingServicesService } from '../services/direct-calling-services.service';
import { TwilioService, TwilioTokenType } from '../../core/services/twilio.service';
import { SignalRService } from '../../core/services/signalr.service';

declare const Twilio: any;
@Component({
  selector: 'app-direct-dial-list',
  templateUrl: './direct-dial-list.component.html',
  styleUrls: ['./direct-dial-list.component.scss']
})
export class DirectDialListComponent implements OnInit {

  displayedColumns: string[] = ['Id', 'Name', 'Phone', 'StatusId', 'Status'];
  ListingData = []
  userdata: any
  id: any
  candidateName: any
  private token: any;
  device: any;
  userId: any
  CandidateEndMutedataSource = []
  selected: any;
  public candidateDialListSpForm: FormGroup;
  showhide: boolean = false;
  userName: any
  callInProgress: boolean = false;
  CandidatedataSource = []
  consentDiv: boolean = false;
  candidateId: any
  muteUnmuteData = []
  IsShowRecordingOption: boolean = false;
  IsRecording: boolean = true;
  stopTimers: boolean = true;
  durationStart: boolean = true;
  callingFeatureAvailability: boolean = false;
  conferenceSid: any
  frontEndNum: any
  frontEndName: any
  candidateAutoGenerateId: any
  showCallDuration: boolean = false
  mergeEmpNumbers = []
  empDropdownButtonMerge: boolean = false;
  dropDownName: any
  dropDownNumber: any
  dropDownId: any
  updatedId: any
  conferenceName: any
  timerIntervalId: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DirectDialListComponent,
    private directCallingServicesService: DirectCallingServicesService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private jwtService: JwtService,
    private readonly twilioService: TwilioService,
    private readonly signalrService: SignalRService) {
    this.userdata = data;
    this.userId = this.jwtService.getCurrentUserId();
    this.id = this.userdata.candidateDetail.Id;
    this.candidateName = this.userdata.candidateDetail.Name;

  }

  ngOnInit() {
    this.initSignalR();
    this.initForm();
    this.ListingData = this.userdata.candidateDetail;
    let count = [];
    count.push({
      id: this.userdata.candidateDetail.Id,
      candidateName: this.userdata.candidateDetail.Name,
      phone: this.userdata.candidateDetail.Phone,
      statusId: this.userdata.candidateDetail.StatusId,
      callStatus: 0,
      status: this.userdata.candidateDetail.Status

    })
    this.CandidatedataSource = count;
    this.ListingData = count;
    console.log(this.ListingData);
    //this.generateTokenForCall();
    this.getEmployeeDropDownForCallMerge();
    this.getCallingAvailability();
    //setInterval(() => { this.checkedValueForCallDuration(); }, 1000);
  }

  initForm() {
    this.candidateDialListSpForm = this.formBuilder.group({
      'Employee': [[], [Validators.required]],
      'Description': [null],
      'dialerNum': [{ value: '', disabled: true }]
    })
  }

  /**
   * init singnalr
   */
  initSignalR() {
    this.signalrService.startConnection((type, data) => {
      console.log(type, data);
      if (type === 'respConf') {
        if (data.sequenceNumber === "1") {
          this.addNumbers();
        }
        if (data.statusCallbackEvent === "conference-end") {
          if (data.reasonConferenceEnded === "last-participant-left" || data.reasonConferenceEnded ===  "participant-with-end-conference-on-exit-left") {
            this.hangUpCall('event');
          }
        }
      }
      if (type === 'callConf') {
        if (data.to && (data.status === "no-answer" || data.status === 'busy' || data.status === 'canceled' || data.status === 'failed' || data.status === 'completed')) {
          let phone = data.to.substr(4);
          this.CandidateEndMutedataSource = this.CandidateEndMutedataSource.filter(item => !item.phone.includes(phone));
          if (this.CandidateEndMutedataSource.length === 0) {
            this.hangUpCall();
          }
        }
        if (data.status === "in-progress") {
          console.log('in-progress');
          if (data.to) {
            let phone = data.to.substr(4);
            if (this.frontEndNum.includes(phone)) {
              if (!this.timerIntervalId) {
                this.showCallDuration = true
                this.startTimer()
              }
            }
          }
        }
      }
    });
  }


  getEmployeeDropDownForCallMerge() {
    this.directCallingServicesService.getEmployeeDropDownForCallMerge().subscribe(response => {
      if (response.success) {
        this.mergeEmpNumbers = response.data;
      }
      else {
        this.commonService.commonSnakeBar();
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  // This method is used to generate the token for the creating the conference
  generateTokenForCall() {
    this.directCallingServicesService.generateTokenForCall().subscribe(response => {
      if (response.success) {
        let data = response.data;
        this.token = data.content;
        localStorage.setItem('localtoken', this.token);

        if (this.token) {
          try {
            // Setup Twilio.Device
            this.device = new Twilio.Device(this.token, {
              codecPreferences: ['opus', 'pcmu'],
              fakeLocalDTMF: true,
              enableRingingState: true,
            });

            this.device.on('ready', function (device) {
              //  console.log('On ready-----');
            });

            this.device.on('error', function (error) {
              // console.log('Twilio.Device Error: ' + error.message);
            });

            this.device.on('connect', function (conn) {
              // console.log('Successfully established call!------');

            });
            this.device.on('disconnect', function (conn) {
              // console.log('Call ended.');
            });
          } catch (error) {
            console.log(error);
          }
        }
      }
      else {
        this.commonService.commonSnakeBar();
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  public async addNumbersSleep() {   
    // For Checking the user has data for calling or not
    if (this.callingFeatureAvailability) {
      if (this.userdata.candidateDetail.SpAction == "Edit") {
        this.editCandidate();
      }
      else {
        this.candidateId = this.id;
        this.twilioGenerateConference();
      }
      //await this.delay(10000);
      //this.addNumbers();
    }
    else {
      this.commonService.callingDataNotAvailable();
    }

    // if (this.userdata.candidateDetail.SpAction == "Edit") {
    //   this.editCandidate();
    // }
    // else {
    //   this.candidateId = this.id;
    //   this.twilioGenerateConference();
    // }
    // await this.delay(10000);
    // this.addNumbers();

  }

  // This method is used to start the call with the conference
  addNumbers() {
    // this.CandidatedataSource.find(item => item.id == this.updatedId).statusId = 3;
    let newdata = this.ListingData.filter(item => item.phone == this.frontEndNum)

    if (newdata.length > 0) {
      this.candidateAutoGenerateId = newdata[0].id;
    }

    let params = {
      To: this.frontEndNum,
      ConferenceSid: this.conferenceName,
      Description: this.candidateDialListSpForm.controls.Description.value,
      CandidateAutoGenerateId: this.candidateId
    }
    this.directCallingServicesService.addNumbers(params).subscribe(response => {
      if (response.success) {
        this.conferenceSid = response.data;
        this.IsShowRecordingOption = true;
        this.CandidatedataSource.find(item => item.id == this.updatedId).statusId = 3;
        this.callInProgress = true;
        this.CandidateEndMutedataSource.push({
          candidateName: this.frontEndName,
          phone: this.frontEndNum
        })
        this.stopTimers = true;
      }
      else {
        this.commonService.commonSnakeBar();
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  public delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  dialertoggle() {
    this.showhide = !this.showhide;
  }

  // This method is used to dial the number through dropdown with the conference call
  mergeDropDownNumbers() {

    let params = {
      To: this.dropDownNumber,
      ConferenceSid: this.conferenceName
    }
    this.directCallingServicesService.addNumbers(params).subscribe(response => {
      if (response.success) {
        this.conferenceSid = response.data;

        this.CandidateEndMutedataSource.push({
          candidateName: this.dropDownName,
          phone: this.dropDownNumber
        })
      }
      else {
        this.commonService.commonSnakeBar();
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  // This method is used to merge the call of dialer from the dialer with the conference call
  mergeDialerNumbers() {
    this.dialertoggle();
    if (this.conferenceName != undefined) {
      let params = {
        To: this.candidateDialListSpForm.controls.dialerNum.value,
        ConferenceSid: this.conferenceName
      }
      this.directCallingServicesService.addNumbers(params).subscribe(response => {
        if (response.success) {
          this.conferenceSid = response.data;

          this.CandidateEndMutedataSource.push({
            candidateName: this.candidateDialListSpForm.controls.dialerNum.value,
            phone: this.candidateDialListSpForm.controls.dialerNum.value
          })
        }
        else {
          this.commonService.commonSnakeBar();
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    }
    else {
      this.commonService.conferenceNotCreatedClientSideYet();
    }
  }

  employeeselect(event) {
    if (this.conferenceName != undefined) {
      this.empDropdownButtonMerge = true;
    }
    this.dropDownName = event.value.name;
    this.dropDownNumber = event.value.phone;
    this.dropDownId = event.value.id;
  }

  // This method is used to start the conference 
  twilioGenerateConference() {
    this.CandidatedataSource = this.ListingData
    let server = this.CandidatedataSource.find(x => x.statusId === 13);
    this.updatedId = server.id
    this.conferenceName = 'ConferenceDirectCall'.concat(this.id)
    this.conferenceName = this.conferenceName.concat('_').concat(this.userId).concat('-').concat(this.candidateId)
    let conId = this.signalrService.connectionId;
    this.frontEndNum = server.phone
    this.frontEndName = server.candidateName
    var params = {
      token: '',
      confName: this.conferenceName,
      conId
    };
    this.twilioService.connect(params, TwilioTokenType.Conference, () => {});
  }

  // This method checks whether the user have calling seconds or not
  getCallingAvailability() {
    let params = {
      ComponentId: 5
    }
    this.directCallingServicesService.getCallingAvailability(params).subscribe(response => {

      if (response.success && response.data.length > 0) {
        this.callingFeatureAvailability = true;
      }
      else {
        this.callingFeatureAvailability = false;
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  // This method is used to check that the call is initiated or not
  checkedValueForCallDuration() {
    if (this.durationStart == true) {
      let params = {
        ConferenceSid: this.conferenceName,
        UserId: this.userId
      }
      this.directCallingServicesService.checkedValueForCallDuration(params).subscribe(response => {
        if (response.success) {
          this.showCallDuration = true
          this.durationStart = false;
          this.startTimer()
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    }
  }

  // This method is used for start the calling duration
  startTimer() {
    var _this = this
    let totalSeconds = 0;
    //if (this.stopTimers == true) {
      this.timerIntervalId = setInterval(function () {
      var newVal = _this.stopTimers
      if (newVal == true) {
        let minutesLabel = document.getElementById("minutes");
        let secondsLabel = document.getElementById("seconds");
        let hoursLabel = document.getElementById("hours");
        // console.log(newVal);
        ++totalSeconds;
        let valStringSec = Number(totalSeconds % 60).toFixed() + "";
        if (valStringSec.length < 2) {
          valStringSec = "0" + valStringSec;
        }
        secondsLabel.innerHTML = valStringSec;
        let valStringMin = totalSeconds / 60 + "";
        if (valStringMin.length < 2) {
          valStringMin = "0" + valStringMin;
        }
        minutesLabel.innerHTML = String(Math.floor(Number(valStringMin)));
        let valStringHour = totalSeconds / 3600 + "";
        if (valStringHour.length < 2) {
          valStringHour = "0" + valStringHour;
        }
        hoursLabel.innerHTML = String(Math.floor(Number(valStringHour)));
      }
      else {
        totalSeconds = 0
      }
    }, 1000);
  }

  // This method is used for start the recording of the call
  createRecording() {
    let params = {
      ConferenceSid: this.conferenceName,
      To: this.frontEndNum
    }
    this.directCallingServicesService.createRecording(params).subscribe(response => {
      if (response.success) {
        this.conferenceSid = response.data;
        this.IsRecording = false;
        this.consentDiv = true;
      }
      else {
        this.commonService.commonSnakeBar();
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  // This method is used to dial the number through dialer by dialing dialer pad
  DialerNumbers(num) {
    let dummyNum
    if (this.candidateDialListSpForm.controls.dialerNum.value != '') {
      dummyNum = this.candidateDialListSpForm.controls.dialerNum.value
      let concatedValue = dummyNum.concat(num)

      this.candidateDialListSpForm.controls.dialerNum.setValue(concatedValue)
    }
    else {
      this.candidateDialListSpForm.controls.dialerNum.setValue(num)
    }
  }

  // This method is used to remove the numbers from the dialer after dialing the numbers from the diler 
  backSpaceNumbers() {
    let dummyNum
    dummyNum = this.candidateDialListSpForm.controls.dialerNum.value;
    if (dummyNum != '') {
      var sliced = dummyNum.slice(0, -1);
      this.candidateDialListSpForm.controls.dialerNum.setValue(sliced)
    }
  }

  // This method is used to mute/unmute to the candidates,dialed numbers the call during calling
  muteAndUnmute(el, phone) {
    let muteUnmuteValue: boolean = true

    if (this.muteUnmuteData.length == 0) {

      let muteUnmuteData = this.muteUnmuteData;
      muteUnmuteValue = true;
      el.innerHTML = '<i class="material-icons">mic_off</i>';

      this.muteUnmuteData.push({
        phone: phone,
        muteUnmute: true
      })
    }
    else {
      this.muteUnmuteData = this.muteUnmuteData.filter(item => item.phone = phone);

      if (this.muteUnmuteData.length == 0) {
        let muteUnmuteData = this.muteUnmuteData;
        muteUnmuteValue = true;
        el.innerHTML = '<i class="material-icons">mic_off</i>';

        this.muteUnmuteData.push({
          phone: phone,
          muteUnmute: true
        })
      }
      else {
        if (this.muteUnmuteData[0].muteUnmute == true) {
          this.muteUnmuteData.find(item => item.phone == phone).muteUnmute = false;
          muteUnmuteValue = false;
          el.innerHTML = '<i class="material-icons">mic</i>';
        }
        else {
          this.muteUnmuteData.find(item => item.phone == phone).muteUnmute = true;
          muteUnmuteValue = true;
          el.innerHTML = '<i class="material-icons">mic_off</i>';
        }
      }
    }

    let params = {
      Mute: muteUnmuteValue,
      ConferenceSid: this.conferenceName,
      To: phone
    }
    this.directCallingServicesService.muteAndUnmute(params).subscribe(response => {
      if (response.success) {
        this.conferenceSid = response.data;
      }
      else {
        this.commonService.commonSnakeBar();
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  // This method is used to remove the participant from the conference call
  removeParticipantsUsers(phone) {
    let params = {
      Mute: true,
      ConferenceSid: this.conferenceName,
      To: phone
    }
    this.directCallingServicesService.removeParticipantsUsers(params).subscribe(response => {
      if (response.success) {
        this.conferenceSid = response.data;
        this.CandidateEndMutedataSource = this.CandidateEndMutedataSource.filter(item => item.phone != phone);

        if(this.CandidateEndMutedataSource.length ==0){
          this.hangUpCall();
        }
      }
      else {
        this.CandidateEndMutedataSource = this.CandidateEndMutedataSource.filter(item => item.phone != phone);
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  // This method is used to end the conference and their all running calls
  hangUpCall(action?: string) {
    if (action === 'event') {
      this.resetDialer();
      return;
    }

    let params = {
      ConferenceSid: this.conferenceName,
      To: ''
    }
    this.directCallingServicesService.hangUpCall(params).subscribe(response => {
      if (response.success) {
        this.conferenceSid = response.data;
        this.resetDialer();
      }
      else {
        ////this.commonService.conferenceNotCreatedYet(response.message);
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  /**
   * reset dialer popup 
   */
  resetDialer() {
    console.log('[resetDialer]');
    clearInterval(this.timerIntervalId)
    this.timerIntervalId = null;
    let minutesLabel = document.getElementById("minutes");
    let secondsLabel = document.getElementById("seconds");
    let hoursLabel = document.getElementById("hours");
    this.frontEndName = "";
    this.frontEndNum = "";
    this.empDropdownButtonMerge = false;
    this.CandidatedataSource.find(item => item.id == this.updatedId).statusId = 2;
    this.CandidateEndMutedataSource = []
    this.callInProgress = false;
    this.consentDiv = false;
    this.candidateDialListSpForm.get('Description').patchValue('');
    this.IsShowRecordingOption = false;

    if (minutesLabel != null) {
      minutesLabel.innerHTML = '00';
    }
    if (secondsLabel != null) {
      secondsLabel.innerHTML = '00';
    }
    if (hoursLabel != null) {
      hoursLabel.innerHTML = '00';
    }
    this.stopTimers = false;
    this.showCallDuration = false;
  }


  stopTimer() {
    //  clearInterval(this.interval)
    let minutesLabel = document.getElementById("minutes");
    let secondsLabel = document.getElementById("seconds");
    let hoursLabel = document.getElementById("hours");
    minutesLabel.innerHTML = '00';
    secondsLabel.innerHTML = '00';
    hoursLabel.innerHTML = '00';
  }

  startClientCall() {
    let params = {
      To: this.dropDownId,
      ConferenceName: this.conferenceName,
      UserId: this.userId
    }
    this.twilioService.connect(params, TwilioTokenType.Capability, () => {});
    this.CandidateEndMutedataSource.push({
      candidateName: this.dropDownName,
      phone: "client:" + this.dropDownId
    })
  }

  // This method is used for stop/delete the recording
  deleteRecording() {
    let params = {
      ConferenceSid: this.conferenceName,
      To: this.frontEndNum
    }
    this.directCallingServicesService.deleteRecording(params).subscribe(response => {
      if (response.success) {
        this.conferenceSid = response.data;
        this.IsRecording = true;
        this.IsShowRecordingOption = false;
        this.consentDiv = false;
      }
      else {
        this.commonService.commonSnakeBar();
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  editCandidate() {
    try {
      const formData = new FormData();
      formData.append("Id", this.id);
      formData.append("Name", this.ListingData[0].candidateName);
      formData.append("SMS", "");
      formData.append("VoiceMail", "true");
      formData.append("VoiceMailSMS", "");
      formData.append("CandidateSkill", "0");
      formData.append("Phone", this.ListingData[0].phone);
      formData.append("Email", "");
      this.directCallingServicesService.updateCandidateDirectCallFormRequest(formData).subscribe(response => {
        if (response.success) {
          this.candidateId = response.data;
          this.twilioGenerateConference();

          //  this.commonService.showSnakeBar(response.message);
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {

        this.commonService.checkTokenValidity(err);

      })
    }
    catch{
      this.commonService.commonSnakeBar();
    }
  }

  dismissConsent() {
    this.consentDiv = false;
  }
}
