import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResultModel } from '../../core/model/result.model';
import { ApiService } from '../../core/services/api.service';
import { ApiUrls } from '../../config/api-urls';

@Injectable({
  providedIn: 'root'
})
export class CustomizedApprovedPackagesServiceService {

  constructor(private apiService: ApiService,
    private apiUrl: ApiUrls) { }

  getCustomizeApprovedPackageSuperAdminListing(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.CustomizeApprovedPackageSuperAdminListing, reqModel);
  }

  addCustomizeApprovedPackage(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.AddCustomizeApprovedPackage, reqModel);
  }

  editCustomizeApprovedPackage(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.EditCustomizeApprovedPackage, reqModel);
  }

  
  getCompanyDropDown(): Observable<ResultModel> {
    return this.apiService.get<ResultModel>(this.apiUrl.CompanyAdmin.CompanyDropDown);
  }

  getComponentDetails(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.GetComponentDetails, reqModel);
  }

  addPackageArrayDetails(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.AddPackageArrayDetails, reqModel);
  }
  getComponentInsertedData(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.GetComponentInsertedData, reqModel);
  }

}
