import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from '../../core/services/auth.service';
import { CommonService } from '../../core/services/common.service';
import { AppSettings } from '../../config/app-settings';
import { StatusMaster } from '../../core/constants/status';
import { SharedService } from '../../shared/service/shared.service';
import { JwtService } from '../../core/services/jwt.service';
import { ViewMinutesStatusServiceService } from '../service/view-minutes-status-service.service';
import { EditViewMinutesStatusComponent } from '../edit-view-minutes-status/edit-view-minutes-status.component';

@Component({
  selector: 'app-view-minutes-status-list',
  templateUrl: './view-minutes-status-list.component.html',
  styleUrls: ['./view-minutes-status-list.component.scss']
})
export class ViewMinutesStatusListComponent implements OnInit {

  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  isLoadMore: boolean = false
  statusMstr: any = StatusMaster;

  fullControl: boolean = true;
  views: boolean = true;
  adds: boolean = true;
  edits: boolean = true;
  deletes: boolean = true;

  displayedColumns: string[] = ['Id',
    'Name', 'Description', 'Action'];

  dataSource = [];
  public manualPaging: boolean = false
  public ListingData = [];
  public AllCompanies = [];

  constructor(public dialog: MatDialog,
    private authService: AuthService,
    private commonService: CommonService,
    private sharedService: SharedService,
    private viewMinutesStatusServiceService: ViewMinutesStatusServiceService,
    private jwtService: JwtService,
    private appSettings: AppSettings) { }

  ngOnInit() {

    this.getSubcriberAllocatedMinutesListing(false)

    const role = this.jwtService.getUserRole();
    if (role != 'CompanyAdmin') {
      this.getAddEditDeletePermission();
    }
  }

  getSubcriberAllocatedMinutesListing(isPaging: boolean) {
    try {
      let params
      if (this.manualPaging == true && isPaging != true) {
        this.pageNumber = 1
        params = {
          PageNumber: this.pageNumber,
          PageSize: this.pageSize
        }
      }
      else {
        params = {
          PageNumber: this.pageNumber,
          PageSize: this.pageSize,
          SpOption: 'ViewMintsStatus'
        }
      }
      this.viewMinutesStatusServiceService.getSubcriberAllocatedMinutesListing(params).subscribe(response => {
        if (response.success) {
          if (response.data.length < this.pageSize) {
            this.isLoadMore = false;
          }
          else {
            this.isLoadMore = true;
          }

          if (isPaging) {
            this.appendCompanyListing(response.data);
          }
          else {
            this.dataSource = response.data;
          }
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch{
      this.commonService.commonSnakeBar();
    }
  }

  appendCompanyListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.dataSource.length > 0) {
        newState = this.dataSource.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.dataSource = newState;
    }
  }
  loadMore() {
    this.pageNumber = this.pageNumber + 1
    this.pageSize = this.pageSize
    this.getSubcriberAllocatedMinutesListing(true);
  }


  getAddEditDeletePermission() {
    let params = {
      MenuId: 24
    }
    this.sharedService.getAddEditDeletePermission(params).subscribe(response => {
      if (response.success) {
        if (response.data.length > 0) {
          this.adds = response.data[0].adds
          this.views = response.data[0].views
          this.fullControl = response.data[0].fullControle
          this.deletes = response.data[0].deletes
          this.edits = response.data[0].edit
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  EditSubscriberAllocatedMinsModal(userDetail): void {
    const dialogRef = this.dialog.open(EditViewMinutesStatusComponent, {
      disableClose: true,
      panelClass: "full-width-popup",
      data: {
        userDetail
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == undefined) {
        this.manualPaging = true
        this.getSubcriberAllocatedMinutesListing(false);
      }
    })
  }

}
