import { Component, OnInit, Inject } from '@angular/core';
import { AllocateMinutesToSubscriberServiceService } from '../service/allocate-minutes-to-subscriber-service.service'
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DatePipe } from '@angular/common';
import { isNullOrUndefined } from 'util';
import { AppSettings } from '../../config/app-settings';
import { forEach } from '@angular/router/src/utils/collection';


@Component({
  selector: 'app-edit-deallocate-minutes-to-subscriber',
  templateUrl: './edit-deallocate-minutes-to-subscriber.component.html',
  styleUrls: ['./edit-deallocate-minutes-to-subscriber.component.scss']
})
export class EditDeallocateMinutesToSubscriberComponent implements OnInit {


  removable = true;
  public deallocateMintsToSubSpForm: FormGroup;
  public isProcessing: boolean = false;
  public smsPrice: any
  public audioPrice: any
  public videoPrice: any
  componentPlanData = [];
  public lastInsertedStandardPackageId: any
  componentDataForDynamicInputs = [];
  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  subscribers = [];
  userData: any

  subscriberId: any
  packageId:any
  allocatedMainId:any

  constructor(private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: EditDeallocateMinutesToSubscriberComponent,
    private commonService: CommonService,
    private allocateMinutesToSubscriberServiceService: AllocateMinutesToSubscriberServiceService,
    private datePipe: DatePipe,
    private appSettings: AppSettings,
    private dialogRef: MatDialogRef<EditDeallocateMinutesToSubscriberComponent>) {
    this.userData = data;
    this.subscriberId = this.userData.userDetail.subscriberId
    this.packageId =this.userData.userDetail.packageId
    this.allocatedMainId =this.userData.userDetail.id
  }

  ngOnInit() {
    this.intiForm()
    this.getSubscriberDealloactionDropdown()
    this.getComponentDetails();
  }


  intiForm() {
    this.deallocateMintsToSubSpForm = this.formBuilder.group({
      'Subscriber': [this.subscriberId, [Validators.required]],
      'Description': [null],
      'AllComponent': this.formBuilder.array([])
    })
  }

  getSubscriberDealloactionDropdown() {
    let params = {
      SpOption: 'SubscriberAllocation'
    }
    this.allocateMinutesToSubscriberServiceService.getSubscriberDealloactionDropdown(params).subscribe(response => {
      if (response.success) {
        let subs = [];
        response.data.forEach(element => {
          subs.push({
            id: element.id,
            name: element.name
          })
        });
        this.subscribers = subs
        if (subs.length == 1) {
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }


  getComponentDetails() {
    let allcomponent = this.deallocateMintsToSubSpForm.get('AllComponent') as FormArray;
    let params = {
      PageNumber: this.pageNumber,
      PageSize: this.pageSize,
      AllocatedType: 'DeAllocatedMinutesToSubscriber',
      PackageId: this.packageId,
      AllocatedMainId: this.allocatedMainId,
      SpOption: 'Edit'
    }
    this.allocateMinutesToSubscriberServiceService.getComponentDeallocateInsertedData(params).subscribe(response => {
      if (response.success) {
        let count = [];
        response.data.forEach(element => {
          allcomponent.push(this.formBuilder.control({
            ComponentId: element.componentId,
            ComponentType: element.componentType,
            ComponentTypeName: element.componentTypeName,
            InputParameter: element.inputParameter,
            Id: element.id,
            Name: element.name,
            AvailableUnit: element.availableUnit
          }));
          count.push({
            ComponentId: element.componentId,
            ComponentType: element.componentType,
            ComponentTypeName: element.componentTypeName,
            InputParameter: element.inputParameter,
            Id: element.id,
            Name: element.name,
            AvailableUnit: element.availableUnit
          })
        });

        if (count.length >= 0) {
          this.componentDataForDynamicInputs = count
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

}
