import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResultModel } from '../../core/model/result.model';
import { ApiService } from '../../core/services/api.service';
import { ApiUrls } from '../../config/api-urls';

@Injectable({
  providedIn: 'root'
})
export class AllocateMinutesToRecruiterServiceService {

  constructor(private apiService: ApiService,
    private apiUrl: ApiUrls) { }

    getRecruiterAllocatedMinutesListing(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.RecruiterAllocatedMinutesListing, reqModel);
    }

    
    getRecruiterDropdown(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.Subscriber.SubscriberDropdown, reqModel);
    }

    getComponentDetailsForMintsAllocationForRecruiter(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.getComponentDetailsForMintsAllocationForRecruiter, reqModel);
    }
  
    addAllocateMintsToRecruiter(reqModel):Observable<ResultModel>{
      return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.addAllocateMintsToRecruiter, reqModel);
    }
  
    addAllocateMinsRecruiterArrayDetails(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.addAllocateMinsSubscriberArrayDetails, reqModel);
    }
  
    getComponentInsertedData(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.GetComponentInsertedAllocatedUnitsData, reqModel);
    }


    editAllocateMintsToRecruiter(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.EditAllocateMintsToRecruiter, reqModel);
    }

    editAllocateMintsArrayToRecruiter(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.addAllocateMinsSubscriberArrayDetails, reqModel);
    }

    

}
