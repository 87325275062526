import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from '../../core/services/auth.service';
import { CommonService } from '../../core/services/common.service';
import { MyPackageServiceService } from '../service/my-package-service.service';
import { AppSettings } from '../../config/app-settings';
import { StatusMaster } from '../../core/constants/status';
import { AutofillMonitor } from '@angular/cdk/text-field';
import { AddPaymentComponent } from '../../stripe-payment/add-payment/add-payment.component'
import { EditMyPackagesStandardCustomizeComponent } from '../edit-my-packages-standard-customize/edit-my-packages-standard-customize.component'
import { SharedService } from '../../shared/service/shared.service';
import { JwtService } from '../../core/services/jwt.service';
import { FormGroup, FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-my-packages-list',
  templateUrl: './my-packages-list.component.html',
  styleUrls: ['./my-packages-list.component.scss']
})
export class MyPackagesListComponent implements OnInit {



  displayedStandardPackColumns: string[] = ['Id', 'PackageName', 'CalCulatedPrice',
    'Price', 'AudioMins', 'VideoMins', 'SMS', 'PackageTenure', 'Status', 'Action'];

  displayedCustomizePackColumns: string[] = ['Id', 'CustomizePackageRefId', 'Name', 'Description',
    'AudioMinutes', 'VideoMinutes', 'SMS', 'Price', 'Purchased', 'PackageTenure', 'Status', 'Action']

  dataSourceStandardPack = [];

  standardPackForm: FormGroup

  dataSourceCustomizePack = [];

  componentInsertedPlanData = [];

  componentInsertedCustPlanData = []

  fullControl: boolean = true;
  views: boolean = true;
  adds: boolean = true;
  edits: boolean = true;
  deletes: boolean = true;

  constructor(public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private commonService: CommonService,
    private sharedService: SharedService,
    private jwtService: JwtService,
    private myPackageServiceService: MyPackageServiceService,
    private appSettings: AppSettings) { }

  statusMstr: any = StatusMaster;
  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE

  ngOnInit() {

    this.getStandardPackageMyPackgeListing(false)
    this.getCustomizePackageMyPackgeListing(false)

    const role = this.jwtService.getUserRole();
    if (role != 'CompanyAdmin') {
      this.getAddEditDeletePermission();
    }
    this.initForm()
    // this.getComponentDetails()
  }

  initForm() {
    this.standardPackForm = this.formBuilder.group({
      'AllComponent': this.formBuilder.array([]),
      'AllComponentComponent': this.formBuilder.array([])
    });
  }

  getStandardPackageMyPackgeListing(isPaging: boolean) {
    try {
      let params = {
        PageNumber: this.pageNumber,
        PageSize: this.pageSize,
        PackageType: 'StandardPackage',
        SpOption: ''
      }
      this.myPackageServiceService.standardPackageMyPackgeListing(params).subscribe(response => {
        if (response.success) {
          this.dataSourceStandardPack = response.data[0].packages;
          this.componentInsertedPlanData = response.data[0].packageComponents;
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch{
      this.commonService.commonSnakeBar();
    }
  }

  custPackageCount: boolean = false


  getCustomizePackageMyPackgeListing(isPaging: boolean) {
    try {
      let params = {
        PageNumber: this.pageNumber,
        PageSize: this.pageSize,
        PackageType: 'CustomizePackage',
        SpOption: ''
      }
      this.myPackageServiceService.customizePackageMyPackgeListing(params).subscribe(response => {
        if (response.success) {
          if (response.data[0].packages.length > 0) {
            this.custPackageCount = true
          }
          this.dataSourceCustomizePack = response.data[0].packages;
          this.componentInsertedCustPlanData = response.data[0].packageComponents;
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch{
      this.commonService.commonSnakeBar();
    }
  }

  EditStandardCustomizeCompanyModal(purchaseDetails): void {
    const dialogRef = this.dialog.open(EditMyPackagesStandardCustomizeComponent, {
      disableClose: true,
      panelClass: 'buying-Components',
      data: {
        purchaseDetails
      }
    });
  }

  getComponentForStandardDetails(id, packageType): Object {
    let arrayObject = [];
    for (let i = 0; i < this.componentInsertedPlanData.length; i++) {
      if (this.componentInsertedPlanData[i].id === id) {
        //  console.log(this.componentInsertedPlanData[i]);
        arrayObject.push(this.componentInsertedPlanData[i]);
      }
    }
    return arrayObject;
  }



  getComponentForCustDetails(id, packageType): Object {
    let arrayObject = [];
    for (let i = 0; i < this.componentInsertedCustPlanData.length; i++) {
      if (this.componentInsertedCustPlanData[i].id === id) {
        //  console.log(this.componentInsertedCustPlanData[i]);
        arrayObject.push(this.componentInsertedCustPlanData[i]);
      }
    }
    return arrayObject;
  }


  getAddEditDeletePermission() {
    let params = {
      MenuId: 23
    }
    this.sharedService.getAddEditDeletePermission(params).subscribe(response => {
      if (response.success) {

        if (response.data.length > 0) {
          this.adds = response.data[0].adds
          this.views = response.data[0].views
          this.fullControl = response.data[0].fullControle
          this.deletes = response.data[0].deletes
          this.edits = response.data[0].edit
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }


  PurchaseCompanyModal(id, packageType, PackageName, Description, amount, stripePlanId, subscriptionPrice, CurrentPlan): void {
    if (CurrentPlan != 'Current Plan') {
      const dialogRef = this.dialog.open(AddPaymentComponent, {
        disableClose: true,
        width: '600px',
        height: 'auto',
        panelClass: 'modal-outer',
        data: {
          PackageId: id,
          PackageType: packageType,
          PackageName: PackageName,
          Description: Description,
          Amount: amount,
          StripePlanId: stripePlanId,
          SubscriptionPrice: subscriptionPrice
        }
      });
      dialogRef.afterClosed().subscribe(result => {
      })
    }

  }

}
