import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CommonService } from '../../core/services/common.service';
import { CampaignService } from '../service/campaign.service';
import { StatusMaster } from '../../core/constants/status';
@Component({
  selector: 'app-start-campaign',
  templateUrl: './start-campaign.component.html',
  styleUrls: ['./start-campaign.component.scss']
})
export class StartCampaignComponent implements OnInit {


  campData: any
  campaignId: number

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: StartCampaignComponent,
    private commomService: CommonService,
    private campaignService: CampaignService,
    private dialogRef: MatDialogRef<StartCampaignComponent>
  ) {
    this.campData = data;

    this.campaignId = this.campData.campDetail.campaignId
  }

  ngOnInit() {
    console.log(this.campData.campDetail);
  }

  start() {
    let params = {
      CampaignId: this.campaignId,
      StatusId: StatusMaster.CallInProgress
    }
    this.campaignService.UpdateCampaignStatus(params).subscribe(resposne => {
      if (resposne.success) {
        this.commomService.showSnakeBar("Campaign started");
        this.closeDialog();
      }
      else {
        this.commomService.showSnakeBar("Unable to start campaign");
      }
    }, (err) => {
      this.commomService.checkTokenValidity(err);
      this.closeDialog();
    })
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
