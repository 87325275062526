import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { MaterialComponentsModule } from '../material-components.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmailCampaignListComponent } from './email-campaign-list/email-campaign-list.component';
import { CreateEmailCampaignComponent } from './create-email-campaign/create-email-campaign.component';
import { EditEmailCampaignComponent } from './edit-email-campaign/edit-email-campaign.component';
import { EmailCampaignDetailsComponent } from './email-campaign-details/email-campaign-details.component';
import { PauseCampaignComponent } from './pause-campaign/pause-campaign.component';
import { ResumeCampaignComponent } from './resume-campaign/resume-campaign.component';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';


@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    MaterialComponentsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule
  ],
  declarations: [EmailCampaignListComponent, CreateEmailCampaignComponent, EditEmailCampaignComponent, EmailCampaignDetailsComponent, PauseCampaignComponent, ResumeCampaignComponent],
  entryComponents: [CreateEmailCampaignComponent, EditEmailCampaignComponent, PauseCampaignComponent, ResumeCampaignComponent]
})
export class EmailCampaignsModule { }
