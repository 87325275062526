import { Component, OnInit, Renderer2 } from '@angular/core';
import { CommonService } from '../../../core/services/common.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  ForgetPassForm: FormGroup;
  public isProcessing: boolean = false;
  constructor(
    private renderer: Renderer2,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private userService: UserService
  ) {
    this.renderer.addClass(document.body, 'bg-theme-gradient');
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.ForgetPassForm = this.formBuilder.group({
      'EmailId': [null, [Validators.required, Validators.email]]
    })
  }

  ForgetPassword(form) {
    if (form.valid) {
      try {
        this.isProcessing = true;
        let params = {
          Email: this.ForgetPassForm.controls.EmailId.value
        }
        this.userService.forgetPassword(params).subscribe(response => {
          if (response.success) {
            this.commonService.showSnakeBar(response.message);
           // this.ForgetPassForm.get('EmailId').patchValue('')
          }
          else {
            this.commonService.showSnakeBar(response.message);
          }
        }, (err) => {
          this.commonService.checkTokenValidity(err);
          this.isProcessing = false;
        })

      }
      catch{
        this.commonService.commonSnakeBar();
      }
      this.isProcessing = false;

    }
    else {
      this.commonService.showSnakeBar("Form Not valid");
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'bg-theme-gradient');
  }

}
