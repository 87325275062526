import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { MaterialComponentsModule } from '../material-components.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectCallingListComponent } from './direct-calling-list/direct-calling-list.component';
import { CreateDirectCallingCandidateComponent } from './create-direct-calling-candidate/create-direct-calling-candidate.component';
import { EditDirectCallingCandidateComponent } from './edit-direct-calling-candidate/edit-direct-calling-candidate.component';
import { DirectDialListComponent } from './direct-dial-list/direct-dial-list.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    MaterialComponentsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [DirectCallingListComponent, CreateDirectCallingCandidateComponent, EditDirectCallingCandidateComponent, DirectDialListComponent],
  entryComponents:[CreateDirectCallingCandidateComponent,EditDirectCallingCandidateComponent,DirectDialListComponent]
  
})
export class DirectCallingModule { }
