import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResultModel } from '../../core/model/result.model';
import { ApiService } from '../../core/services/api.service';
import { ApiUrls } from '../../config/api-urls';

@Injectable({
  providedIn: 'root'
})
export class InboundService {

  constructor(
    private apiService: ApiService,
    private apiUrl: ApiUrls
  ) { }

  inboundListing(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Inbound.InboundListing, reqModel);
  }
}
