import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { JwtService } from './jwt.service';

@Injectable()
export class ApiService {

  constructor(
    private http: HttpClient,
    private jwtService: JwtService
  ) { }

  private setHeaders(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
    if (this.jwtService.getToken()) {
      headersConfig['Authorization'] = 'Bearer ' + this.jwtService.getToken();
      // `Bearer ${adminUser.access_token}`
    }
    return new HttpHeaders(headersConfig);
  };

  private formatErrors(error: any) {
    return throwError(error);
  }

  get<ResultModel>(path: string, searchParams: HttpParams = new HttpParams()): Observable<ResultModel> {
    return this.http.get<ResultModel>(`${environment.apiUrl}${path}`, { headers: this.setHeaders(), params: searchParams }
    ).pipe(catchError(this.formatErrors));
  }

  put<ResultModel>(path: string, body: object = {}): Observable<ResultModel> {
    return this.http.put<ResultModel>(`${environment.apiUrl}${path}`, JSON.stringify(body), { headers: this.setHeaders() })
      .pipe(catchError(this.formatErrors));
  }

  post<ResultModel>(path: string, body: Object = {}): Observable<ResultModel> {
    return this.http.post<ResultModel>(`${environment.apiUrl}${path}`, JSON.stringify(body), { headers: this.setHeaders() })
      .pipe(catchError(this.formatErrors));
  }

  delete<ResultModel>(path: string): Observable<ResultModel> {
    return this.http.delete<ResultModel>(`${environment.apiUrl}${path}`, { headers: this.setHeaders() })
      .pipe(catchError(this.formatErrors));
  }
}
