import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { SharedService } from '../service/shared.service';
import { JwtService } from '../../core/services/jwt.service';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  public ChangePasswordForm: FormGroup
  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private sharedService: SharedService,
    private jwtService: JwtService,
    public dialogRef: MatDialogRef<ChangePasswordComponent>
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.ChangePasswordForm = this.formBuilder.group({
      'OldPassword': [null, [Validators.required]],
      'NewPassword': [null, [Validators.required, Validators.pattern('^(?=.*[A-Z])(?=.*[@#$%^&+=]).*$')]],
      'ConfirmNewPassword': [null, [Validators.required, Validators.pattern('^(?=.*[A-Z])(?=.*[@#$%^&+=]).*$')]]
    })
  }

  Submit(form) {
    if (form.valid) {
      try {
        if (this.checkOldPassword()) {
          this.commonService.showSnakeBar("Old Password and New Password should not be same");
        }
        else {
          if (this.checkPasswords()) {

            let params = {
              Email: this.jwtService.getUserEmail(),
              OldPassword: this.ChangePasswordForm.controls.OldPassword.value,
              NewPassword: this.ChangePasswordForm.controls.NewPassword.value
            }

            this.sharedService.changePassword(params).subscribe(resposne => {
              if (resposne.success) {
                this.commonService.showSnakeBar(resposne.message);
                this.closeDialog();
                // this.jwtService.deleteToken();
                // this.commonService.navigate('/login');
              }
              else{
                this.commonService.showSnakeBar(resposne.message);
              }

            }, (err) => {
              this.commonService.commonSnakeBar();
            })
          }
          else {
            this.commonService.showSnakeBar("New Password and Confrim password does not match.");
          }
        }
      }
      catch{
        this.commonService.commonSnakeBar();
      }

    }
    else {
      this.commonService.showSnakeBar("Form not valid");
    }
  }

  checkOldPassword() {
    let Oldpass = this.ChangePasswordForm.controls.OldPassword.value;
    let newPass = this.ChangePasswordForm.controls.NewPassword.value;
    if (Oldpass === newPass) {
      return true;
    } else {
      return false;
    }
  }

  checkPasswords() { // here we have the 'passwords' group
    let pass = this.ChangePasswordForm.controls.NewPassword.value;
    let confirmPass = this.ChangePasswordForm.controls.ConfirmNewPassword.value;

    return pass === confirmPass ? true : false
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
