import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogRef, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { EmailCampaignServicesService } from '../services/email-campaign-services.service';
import { AppDateAdapter, APP_DATE_FORMATS } from '../email-campaign-list/date.adapter';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Console } from 'console';


@Component({
  selector: 'app-create-email-campaign',
  templateUrl: './create-email-campaign.component.html',
  styleUrls: ['./create-email-campaign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class CreateEmailCampaignComponent implements OnInit {

  public Editor = ClassicEditor;
  dummyDataForEmail = "";

  createEmailCampForm: FormGroup
  selectedFile: File
  selectedEmailFile: File
  isProcessing: boolean = false;
  numericNumberReg = '^-?[0-9]\\d*(\\.\\d{1,2})?$';
  selected: any;
  fileUrl: any

  constructor(private emailCampaignServicesService: EmailCampaignServicesService,
    private authService: AuthService,
    private formbuilder: FormBuilder,
    private commonService: CommonService,
    private sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<CreateEmailCampaignComponent>) {

  }

  ngOnInit() {
    this.intiForm();
    const data = 'Name,Phone,Email,Skill';
    const blob = new Blob([data], { type: 'application/octet-stream' });

    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
  }


  intiForm() {
    this.createEmailCampForm = this.formbuilder.group({
      'Name': [null, [Validators.required, this.noWhitespaceValidator]],
      'EmailDescription': [null, [Validators.required, this.noWhitespaceValidator]],
      'Description': [null],
      'StartDate': [null, [Validators.required]],
      'StartTime': [null, [Validators.required]],
      // 'EndTime': [null],
      'GstZone': [null, [Validators.required]]
    })
  }


  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onFileChanged(event) {
    if (event.target.value != "") {
      this.selectedFile = event.target.files[0]
    }
  }

  onFileEmailAttachmentChanged(event) {
    if (event.target.value != "") {
      this.selectedEmailFile = event.target.files[0]
    }
  }

  createEmailCampaign(form) {
    if (form.valid) {
      this.isProcessing = true;
      const formData = new FormData();

      if (this.selectedFile == null || this.selectedFile == undefined) {
        this.commonService.showSnakeBar("Please upload file");
        this.isProcessing = false;
        return;
      }
      else if (this.selectedFile.name.split('.').pop().toLocaleLowerCase() != "csv") {
        this.commonService.showSnakeBar("Only .csv extension allowed");
        this.isProcessing = false;
        return;
      }

      let startTimeVal = this.createEmailCampForm.controls.StartTime.value;
      var str = new String(startTimeVal);

      if (str.charAt(2) != ':' || str.charAt(5) != ':'
        || str.charAt(0) == ':' || str.charAt(1) == ':'
        || str.charAt(3) == ':' || str.charAt(4) == ':'
        || str.charAt(6) == ':' || str.charAt(7) == ':') {
        this.commonService.showSnakeBar("Please proper fill the start time in correct format");
        this.isProcessing = false;
        return;
      }
      formData.append(this.selectedFile.name, this.selectedFile);

      if (this.selectedEmailFile != null || this.selectedEmailFile != undefined) {
        formData.append(this.selectedEmailFile.name, this.selectedEmailFile);
      }
      else {
        formData.append("selectedEmailFile", "");
      }


    //  console.log(this.selectedEmailFile);

      formData.append("Name", this.createEmailCampForm.controls.Name.value);
      formData.append("EmailDescription", this.createEmailCampForm.controls.EmailDescription.value);
      // formData.append("EmailDescription", this.EditorData);
      formData.append("Description", this.createEmailCampForm.controls.Description.value);
      formData.append("GstZone", this.createEmailCampForm.controls.GstZone.value);
      formData.append("StartDate", this.createEmailCampForm.controls.StartDate.value.toLocaleDateString());
      formData.append("StartTime", this.createEmailCampForm.controls.StartTime.value);
      formData.append("EndTime", "12:00:00");


      this.emailCampaignServicesService.createEmailCamapignFormRequest(formData).subscribe(response => {
        if (response.success) {
          this.commonService.showSnakeBar(response.message);
          this.closeDialog();
          this.isProcessing = false;
        }
        else {
          this.commonService.showSnakeBar(response.message);
          this.isProcessing = false;
        }
      }, (err) => {
        this.isProcessing = false;
        this.commonService.checkTokenValidity(err);
        this.closeDialog();
      })
    }
    else {
      this.commonService.showSnakeBar("Please do the correction");
    }
  }


  public onTimeStartTimekeypress(event) {
    var val = event.target.value;
    var textlength = this.createEmailCampForm.controls.StartTime.value.length;

    if (textlength == 2) {
      if (this.createEmailCampForm.controls.StartTime.value < 24) {

        if (this.createEmailCampForm.controls.StartTime.value > 8 &&
          this.createEmailCampForm.controls.StartTime.value <= 19) {
          this.createEmailCampForm.get('StartTime').setValue(val + ':');
        }
        else {
          this.createEmailCampForm.get('StartTime').patchValue('');
          this.commonService.showSnakeBar("Start time cannot be below 8 AM and greater than 9 PM");
        }
      }
      else {
        this.createEmailCampForm.get('StartTime').patchValue('');
      }
    }
    if (textlength == 5) {
      const str = this.createEmailCampForm.controls.StartTime.value;
      const slug = str.split(':').pop();
      if (slug < 60) {
        this.createEmailCampForm.get('StartTime').setValue(val + ':');
      }
      else {
        this.createEmailCampForm.get('StartTime').patchValue('');
      }
    }
    if (textlength == 8) {
      var member = this.createEmailCampForm.controls.StartTime.value;

      var last2 = member.slice(-2);

      if (last2 < 60) {

      }
      else {
        this.createEmailCampForm.get('StartTime').patchValue('');
      }
    }
    var first2 = this.createEmailCampForm.controls.StartTime.value.substring(0, 2);


    if (textlength >= 2) {
      if (first2 > 8 &&
        first2 <= 19) {
      }
      else {
        this.createEmailCampForm.get('StartTime').patchValue('');
        this.commonService.showSnakeBar("Start time cannot be below 8 AM and greater than 9 PM");
      }

    }

  }


  public onTimeEndTimekeypress(event) {
    var val = event.target.value;
    var textlength = this.createEmailCampForm.controls.EndTime.value.length;

    if (textlength == 2) {
      if (this.createEmailCampForm.controls.EndTime.value < 24) {
        this.createEmailCampForm.get('EndTime').setValue(val + ':');
      }
      else {
        this.createEmailCampForm.get('EndTime').patchValue('');
      }
    }
    if (textlength == 5) {
      const str = this.createEmailCampForm.controls.EndTime.value;
      const slug = str.split(':').pop();
      if (slug < 60) {
        this.createEmailCampForm.get('EndTime').setValue(val + ':');
      }
      else {
        this.createEmailCampForm.get('EndTime').patchValue('');
      }
    }
    if (textlength == 8) {
      var member = this.createEmailCampForm.controls.EndTime.value;

      var last2 = member.slice(-2);

      if (last2 < 60) {

      }
      else {
        this.createEmailCampForm.get('EndTime').patchValue('');
      }
    }
  }


  SmsPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  // EditorData: any
  // change({ editor }) {
  //   this.EditorData = editor.getData();
  // }

}
