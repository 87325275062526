import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomizedApprovedPackagesListComponent } from './customized-approved-packages-list/customized-approved-packages-list.component';
import { CoreModule } from '../core/core.module';
import { MaterialComponentsModule } from '../material-components.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddCustomizedApprovedPackagesComponent } from './add-customized-approved-packages/add-customized-approved-packages.component';
import { EditCustomizedApprovedPackagesComponent } from './edit-customized-approved-packages/edit-customized-approved-packages.component';


@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    MaterialComponentsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [CustomizedApprovedPackagesListComponent, AddCustomizedApprovedPackagesComponent, EditCustomizedApprovedPackagesComponent],
  exports: [CustomizedApprovedPackagesListComponent],
  entryComponents: [AddCustomizedApprovedPackagesComponent, EditCustomizedApprovedPackagesComponent]
})
export class CustomizedApprovedPackagesModule { }
