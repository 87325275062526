import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, RequiredValidator, FormArray } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { BuyRequiredPackageServiceService } from '../service/buy-required-package-service.service'
import { DatePipe } from '@angular/common';
import { isNull } from 'util';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { AppSettings } from '../../config/app-settings';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-edit-buy-required-package',
  templateUrl: './edit-buy-required-package.component.html',
  styleUrls: ['./edit-buy-required-package.component.scss']
})
export class EditBuyRequiredPackageComponent implements OnInit {
  public editBuyRequiredForm: FormGroup
  userData: any
  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  componentDataForDynamicInputs = [];
  transactionId: any

  constructor(private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: EditBuyRequiredPackageComponent,
    public dialog: MatDialog,
    private commonService: CommonService,
    private buyRequiredPackageServiceService: BuyRequiredPackageServiceService,
    private datePipe: DatePipe,
    private appSettings: AppSettings,
    private dialogRef: MatDialogRef<EditBuyRequiredPackageComponent>) {
    this.userData = data;
  }

  ngOnInit() {
    this.initForm()
    this.getComponentDetails()
  }
  initForm() {
    this.editBuyRequiredForm = this.formBuilder.group({
      // 'Name': [{value:this.userData.userDetail.name, }, [Validators.required]],
      'Name': [{ value: this.userData.userDetail.name, disabled: true }, [Validators.required]],
      'Description': [{value:this.userData.userDetail.description, disabled:true}],
      'AllComponent': this.formBuilder.array([]),
      'CalCulatedPrice': [{value:this.userData.userDetail.calculatedPrice,disabled:true}],
      'TransactionId': [{value:this.userData.userDetail.transactionId, disabled:true}]
    });
  }

  getComponentDetails() {
    let allcomponent = this.editBuyRequiredForm.get('AllComponent') as FormArray;
    let params = {
      PageNumber: this.pageNumber,
      PageSize: this.pageSize,
      PackageType: 'RequiredPackage',
      PackageId: this.userData.userDetail.id,
      SpOption: 'Edit',
      PurchasedOption :'No'
    }
    this.buyRequiredPackageServiceService.getComponentInsertedData(params).subscribe(response => {
      if (response.success) {
        let count = [];
        response.data.forEach(element => {
          allcomponent.push(this.formBuilder.control({
            ComponentId: element.componentId,
            ComponentType: element.componentType,
            ComponentTypeName: element.componentTypeName,
            InputParameter: element.inputParameter,
            Id: element.id,
            Name: element.name,
            Price: element.price,
            CalculatedValue: element.calculatedValue
          }));
          count.push({
            ComponentId: element.componentId,
            ComponentType: element.componentType,
            ComponentTypeName: element.componentTypeName,
            InputParameter: element.inputParameter,
            Id: element.id,
            Name: element.name,
            Price: element.price,
            CalculatedValue: element.calculatedValue
          })
        });

        if (count.length >= 0) {
          this.componentDataForDynamicInputs = count
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  public numberWithOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode == 190 || charCode == 110)) {
      return false;
    }
    return true;
  }
}
