import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { MatDialogRef } from '@angular/material';
import { CompanyService } from '../service/company.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-add-new-company',
  templateUrl: './add-new-company.component.html',
  styleUrls: ['./add-new-company.component.scss']
})
export class AddNewCompanyComponent implements OnInit {
  public regWeb = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  public addCompForm: FormGroup
  public isProcessing: boolean = false
  allcountries = [];
  allcompanyType = [];
  pageNumber: any = 1
  pageSize: any = 20
  constructor(private formBuilder: FormBuilder,
    private commonService: CommonService,
    private companyService: CompanyService,
    private datePipe: DatePipe,
    private dialogRef: MatDialogRef<AddNewCompanyComponent>) { }


  initForm() {
    this.addCompForm = this.formBuilder.group({
      'CompName': [null, [Validators.required]],
      'Address': [null, [Validators.required]],
      'City': [null, [Validators.required]],
      'State': [null, [Validators.required]],
      'Zip': [null, [Validators.required]],
      'Country': [[], [Validators.required]],
      'CompanyType': [[], [Validators.required]],
      'Email': [null, [Validators.required, Validators.email]],
      'Phone': [null, [Validators.required]],
      'Website': [null, [Validators.pattern(this.regWeb)]],
      'AboutProfile': [null],
      'TwitterUrl': [null, [Validators.pattern(this.regWeb)]],
      'FacebookUrl': [null, [Validators.pattern(this.regWeb)]],
      'LinkedinUrl': [null, [Validators.pattern(this.regWeb)]],
      'Street': [null],
      'Ein': [null, [Validators.required]]
    });
  }

  ngOnInit() {
    this.initForm();
    this.getCountryDropdown();
    this.getCompanyTypeDropdown();
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  public addCompany(form) {
    if (form.valid) {
      try {
        this.isProcessing = true;

        let params = {
          CompName: this.addCompForm.controls.CompName.value.trim(),
          Address: this.addCompForm.controls.Address.value.trim(),
          City: this.addCompForm.controls.City.value.trim(),
          State: this.addCompForm.controls.State.value.trim(),
          Zip: this.addCompForm.controls.Zip.value.trim(),
          CountryId: this.addCompForm.controls.Country.value,
          CompanyType: this.addCompForm.controls.CompanyType.value,
          Email: this.addCompForm.controls.Email.value.trim(),
          Phone: (this.addCompForm.controls.Phone.value == '' || this.addCompForm.controls.Phone.value == null || this.addCompForm.controls.Phone.value == undefined) ? '' : this.addCompForm.controls.Phone.value,
          Website: (this.addCompForm.controls.Website.value == '' || this.addCompForm.controls.Website.value == null || this.addCompForm.controls.Website.value == undefined) ? '' : this.addCompForm.controls.Website.value,
          AboutProfile: (this.addCompForm.controls.AboutProfile.value == '' || this.addCompForm.controls.AboutProfile.value == null || this.addCompForm.controls.AboutProfile.value == undefined) ? '' : this.addCompForm.controls.AboutProfile.value,
          TwitterUrl: (this.addCompForm.controls.TwitterUrl.value == '' || this.addCompForm.controls.TwitterUrl.value == null || this.addCompForm.controls.TwitterUrl.value == undefined) ? '' : this.addCompForm.controls.TwitterUrl.value,
          FacebookUrl:  (this.addCompForm.controls.FacebookUrl.value == '' || this.addCompForm.controls.FacebookUrl.value == null || this.addCompForm.controls.FacebookUrl.value == undefined) ? '' : this.addCompForm.controls.FacebookUrl.value,
          LinkedinUrl: (this.addCompForm.controls.LinkedinUrl.value == '' || this.addCompForm.controls.LinkedinUrl.value == null || this.addCompForm.controls.LinkedinUrl.value == undefined) ? '' : this.addCompForm.controls.LinkedinUrl.value,
          Street: (this.addCompForm.controls.Street.value == '' || this.addCompForm.controls.Street.value == null || this.addCompForm.controls.Street.value == undefined) ? '' : this.addCompForm.controls.Street.value,
          Ein: (this.addCompForm.controls.Ein.value == '' || this.addCompForm.controls.Ein.value == null || this.addCompForm.controls.Ein.value == undefined) ? '' : this.addCompForm.controls.Ein.value
        }
        this.companyService.addCompany(params).subscribe(resposne => {
          if (resposne.success) {
            this.isProcessing = false;
            this.commonService.showSnakeBar(resposne.message);
            this.closeDialog();
          }
          else {
            this.isProcessing = false;
            this.commonService.showSnakeBar(resposne.message);
          }

        }, (err) => {
          this.isProcessing = false;
          this.commonService.checkTokenValidity(err);
          this.closeDialog();
        })
      }
      catch
      {
        this.isProcessing = false;
        this.commonService.commonSnakeBar();
      }
    }
  }

  getCountryDropdown() {
    let params = {
      PageNumber: this.pageNumber,
      PageSize: this.pageSize
    }
    this.companyService.getCountryDropdown(params).subscribe(response => {
      if (response.success) {
        let count = [];
        response.data.forEach(element => {
          count.push({
            id: element.id,
            name: element.name
          })
        });
        this.allcountries = count
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  closeDialog() {
    this.dialogRef.close();
  }

  public numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
      return false;
    }
    return true;
  }

  getCompanyTypeDropdown() {
    this.companyService.getCompanyTypeDropdown().subscribe(response => {
      if (response.success) {
        let count = [];
        response.data.forEach(element => {
          count.push({
            id: element.id,
            name: element.name
          })
        });
        this.allcompanyType = count
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

}
