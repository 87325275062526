import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CutomizePackagesListComponent } from './cutomize-packages-list/cutomize-packages-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialComponentsModule } from '../material-components.module';
import { AddCustomizePackageComponent } from './add-customize-package/add-customize-package.component';
import { AddCustomizePackageCommentComponent } from './add-customize-package-comment/add-customize-package-comment.component';
import { DeleteCustomizePackagesComponent } from '../shared/delete-customize-packages/delete-customize-packages.component';
import { CustomizePackageCommentListComponent } from './customize-package-comment-list/customize-package-comment-list.component';
import { EditCustomizePackageComponent } from './edit-customize-package/edit-customize-package.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    MaterialComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule
  ],
  declarations: [CutomizePackagesListComponent, AddCustomizePackageComponent, AddCustomizePackageCommentComponent, CustomizePackageCommentListComponent, EditCustomizePackageComponent],
  exports:[AddCustomizePackageComponent,AddCustomizePackageCommentComponent,CustomizePackageCommentListComponent,CutomizePackagesListComponent,EditCustomizePackageComponent],
  entryComponents:[AddCustomizePackageComponent,AddCustomizePackageCommentComponent,DeleteCustomizePackagesComponent,CustomizePackageCommentListComponent,EditCustomizePackageComponent]
})
export class CutomizePackagesModule { }
