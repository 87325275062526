import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { SharedService } from '../service/shared.service';
import { CommonService } from '../../core/services/common.service';
import { StatusMaster } from '../../core/constants/status';


@Component({
  selector: 'app-block-component-plan-model',
  templateUrl: './block-component-plan-model.component.html',
  styleUrls: ['./block-component-plan-model.component.scss']
})
export class BlockComponentPlanModelComponent implements OnInit {


  planId :number
  componentType: any
  mainComponentId: number

  statusId: number
  planStatus: string
  userData: any
  planStatusId: number
  statusMstr: any = StatusMaster

  constructor(@Inject(MAT_DIALOG_DATA) public data: BlockComponentPlanModelComponent,
  private sharedService: SharedService,
  private commomService: CommonService,
  private dialogRef: MatDialogRef<BlockComponentPlanModelComponent>) { 
    this.userData = data;
    this.planId = this.userData.planDetails.planId
    this.mainComponentId = this.userData.planDetails.mainComponentId
    this.componentType = this.userData.planDetails.componentType
    this.planStatus = this.userData.planDetails.status
    this.planStatusId = this.userData.planDetails.statusId

    if (this.planStatusId == this.statusMstr.InActive) {
      this.statusId = this.statusMstr.Active
    }
    else if (this.planStatusId == this.statusMstr.Active) {
      this.statusId = this.statusMstr.InActive
    }
  }

  ngOnInit() {
  }

  block() {
    try {
      let param = {
        planId: this.planId,
        componentType: this.componentType,
        mainComponentId: this.mainComponentId,
        statusId:this.statusId
      }
      this.sharedService.blockComponentPlan(param).subscribe(response => {
        if (response.success) {
          this.commomService.showSnakeBar("Component plan status has been updated successfully");
          this.closeDialog();
        }
        else {
          this.commomService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commomService.checkTokenValidity(err);
      })
    }
    catch{
      this.commomService.commonSnakeBar();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }


}
