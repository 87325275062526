import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { isNull, isNullOrUndefined, debug } from 'util';
import { StripePaymentServiceService } from '../service/stripe-payment-service.service'
import { StripeService, Elements, Element as StripeElement, ElementsOptions } from "ngx-stripe";
import { AppSettings } from '../../config/app-settings';
import { Router } from '@angular/router';



@Component({
  selector: 'app-add-payment',
  templateUrl: './add-payment.component.html',
  styleUrls: ['./add-payment.component.scss']
})
export class AddPaymentComponent implements OnInit {
  addStripePaymentForm: FormGroup
  public isProcessing: boolean = false;
  userData: any
  textOnlyReg = '^[a-zA-Z0-9_ ]*$';
  elements: Elements;
  card: StripeElement;
  stripeForm: FormGroup;
  Amount: any
  name: string
  accountHolderName: any
  stripeToken: string
  transationId: any

  packageName: any
  description: any
  price: any
  id: any
  packageType: any
  componentInsertedPlanData = [];

  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE

  elementsOptions: ElementsOptions = {
    locale: 'en'
  };
  currentURL: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: AddPaymentComponent,
    private formbuilder: FormBuilder,
    private commonService: CommonService,
    private fb: FormBuilder,
    private _dialog: MatDialog,
    private stripeService: StripeService,
    private appSettings: AppSettings,
    private router: Router,
    private stripePaymentServiceService: StripePaymentServiceService,
    private dialogRef: MatDialogRef<AddPaymentComponent>) {
    this.userData = data;
    this.packageName = this.userData.PackageName
    this.description = this.userData.Description
    this.price = this.userData.SubscriptionPrice
    this.id = this.userData.PackageId
    this.packageType = this.userData.PackageType
    this.currentURL = window.location.href;
  }



  ngOnInit() {
    this.getStandardPackageMyPackgeProRateListing(false)

    this.stripeForm = this.fb.group({
      name: [null, [Validators.required]],
      processingImage: [null]
    });
    this.stripeService.elements(this.elementsOptions)
      .subscribe(elements => {
        this.elements = elements;
        // Only mount the element the first time
        if (!this.card) {
          this.card = this.elements.create('card', {
            hidePostalCode: true,
            style: {
              base: {
                iconColor: '#666EE8',
                color: '#31325F',
                lineHeight: '40px',
                fontWeight: 300,
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSize: '18px',
                '::placeholder': {
                  color: '#CFD7E0'
                }
              }
            }
          });
          this.card.mount('#card-element');
        }
      });
  }

  buy() {
    this.isProcessing = false
    const name = this.stripeForm.get('name').value;
    // this.stripeForm.controls.processingImage.enable()
    if (name != null) {
      this.accountHolderName = this.stripeForm.get('name').value;
      this.stripeService
        .createToken(this.card, { name })
        .subscribe(result => {
          if (result.token) {
            this.stripeToken = (result.token.id);
            this.transationId = result.token.card.id;
            this.buyMainFunction();
           
          } else if (result.error) {
            console.log(result.error.message);
          }
        });
    }
    else {
      this.commonService.cardDetailsRequired();
    }

  }

  buyMainFunction() {
    let params = {
      Name: this.accountHolderName,
      Amount: this.userData.SubscriptionPrice,
      SubscriptionPrice: this.userData.Amount,
      StipeToken: this.stripeToken,
      PackageType: this.userData.PackageType,
      Id: this.userData.PackageId,
      PackageName: this.packageName
    }
    this.stripePaymentServiceService.stripePayment(params).subscribe(resposne => {
      if (resposne.success) {
        this.isProcessing = false;
        this.commonService.showSnakeBar(resposne.message);
        this.closeDialog();
        this._dialog.closeAll();
        this.router.navigate(['/dashboard']);
        //this.router.navigate(['/customer-home']);
        //location.reload();
      }
      else {
        this.isProcessing = false;
        this.commonService.showSnakeBar(resposne.message);
        this.closeDialog();
      }
    }, (err) => {
      this.isProcessing = false;
      this.commonService.checkTokenValidity(err);
      this.closeDialog();
    })
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getComponentForStandardDetails(id, packageType): Object {
    let arrayObject = [];
    for (let i = 0; i < this.componentInsertedPlanData.length; i++) {
      if (this.componentInsertedPlanData[i].id === id) {
        //  console.log(this.componentInsertedPlanData[i]);
        arrayObject.push(this.componentInsertedPlanData[i]);
      }
    }
    return arrayObject;
  }


  getStandardPackageMyPackgeProRateListing(isPaging: boolean) {
    try {
      let params = {
        PageNumber: this.pageNumber,
        PageSize: this.pageSize,
        // PackageType: 'StandardPackage',
        PackageType: this.packageType,
        SpOption: 'ReaminingDays',
        PackageId: this.id
      }
      this.stripePaymentServiceService.standardPackageMyPackgeProRateListing(params).subscribe(response => {
        if (response.success) {
          //  this.dataSourceStandardPack = response.data[0].packages;
          this.componentInsertedPlanData = response.data;
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch{
      this.commonService.commonSnakeBar();
    }
  }

  key: any
  allowAlphaandSpaceOnly(event) {
    this.key = event.keyCode;
    if(this.key ==32){
    }
    else{
      if ((this.key >= 15 && this.key <= 64) || (this.key >= 123) || (this.key >= 96 && this.key <= 105) && this.key == 32) {
        event.preventDefault();
      }
  
    }
   
  }

}


