import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { ResultModel } from '../../core/model/result.model';
import { Observable } from 'rxjs';
import { ApiUrls } from '../../config/api-urls';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionServiceService {

  constructor(private apiService: ApiService,
    private apiUrl: ApiUrls
  ) { 
    
  }

  getMainPermissonListing(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.MainPermissonListing, reqModel)
  }

  getmainListingForAddition(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.GetMainListingForAddition, reqModel)
  }

  getchildListingById(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.GetchildListingById, reqModel)
  }

  
  addMainPermission(addModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.AddMainPermission, addModel);
  }

  editMainPermission(addModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.EditMainPermission, addModel);
  }

  addChildPermission(addModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.AddChildPermission, addModel);
  }

  editChildPermission(addModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.EditChildPermission, addModel);
  }

}
