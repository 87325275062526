import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResultModel } from '../../core/model/result.model';
import { ApiService } from '../../core/services/api.service';
import { ApiUrls } from '../../config/api-urls';

@Injectable({
  providedIn: 'root'
})
export class StandardPackageService {

  constructor(
    private apiService: ApiService,
    private apiUrl: ApiUrls
  ) { }

  standardPackageListing(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.CompanyPakageListing, reqModel);
  }

  addstandardPackage(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.AddstandardPackage, reqModel);
  }

  addPackageArrayDetails(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.AddPackageArrayDetails, reqModel);
  }

  editStandardPackage(editModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.EditStandardPackage, editModel);
  }

  getComponentPlanCalculate(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.GetComponentPlanCalculate, reqModel);
  }

  getComponentInsertedData(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.GetComponentInsertedData, reqModel);
  }

  getComponentDetails(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.GetComponentDetails, reqModel);
  }

  getStripePlanIdDropdown(): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.StripePlanIdDropdown);
  }

  
}
