import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material';
import { NextCallModelComponent } from '../next-call-model/next-call-model.component';

export interface PeriodicElement {
  campaignId: string;
  candidateName: string;
  phone: string;
  phone_ic: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    campaignId: '1',
    candidateName: 'Chetan ChetanChetan Chetan',
    phone: '7340953011',
    phone_ic: 'call_made'
  },
  {
    campaignId: '2',
    candidateName: 'Chetan',
    phone: '7340953011',
    phone_ic: 'settings_phone'
  },
  {
    campaignId: '3',
    candidateName: 'Harjot',
    phone: '9501090026',
    phone_ic: 'settings_phone'
  },
];

@Component({
  selector: 'app-calling-active-model',
  templateUrl: './calling-active-model.component.html',
  styleUrls: ['./calling-active-model.component.css']
})

export class CallingActiveModelComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private dialogRef:MatDialog,
  ) { }

  displayedColumns: string[] = ['campaignId', 'candidateName', 'phone', 'phone_ic'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatSort) sort: MatSort;

  showhide: boolean = false;

  dialertoggle() {
    this.showhide = !this.showhide;
  }

  nextCalling(): void {
    const dialogRef = this.dialog.open(NextCallModelComponent, {
      disableClose: true,
      panelClass: ['modal-outer', 'modal-md'],
    });
  }


  ngOnInit() {
    this.dataSource.sort = this.sort;
  }

}
