import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllocateMinutesToSubscriberListComponent } from './allocate-minutes-to-subscriber-list/allocate-minutes-to-subscriber-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialComponentsModule } from '../material-components.module';
import { AddAllocateMinutesToSubscriberComponent } from './add-allocate-minutes-to-subscriber/add-allocate-minutes-to-subscriber.component';
import { EditAllocateMinutesToSubscriberComponent } from './edit-allocate-minutes-to-subscriber/edit-allocate-minutes-to-subscriber.component';
import { DeallocateMinutesToSubscriberComponent } from './deallocate-minutes-to-subscriber/deallocate-minutes-to-subscriber.component';
import { EditDeallocateMinutesToSubscriberComponent } from './edit-deallocate-minutes-to-subscriber/edit-deallocate-minutes-to-subscriber.component';


@NgModule({
  imports: [
    CommonModule,
    MaterialComponentsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [AllocateMinutesToSubscriberListComponent, AddAllocateMinutesToSubscriberComponent, EditAllocateMinutesToSubscriberComponent, DeallocateMinutesToSubscriberComponent, EditDeallocateMinutesToSubscriberComponent],
  entryComponents: [AddAllocateMinutesToSubscriberComponent, EditAllocateMinutesToSubscriberComponent, DeallocateMinutesToSubscriberComponent,EditDeallocateMinutesToSubscriberComponent]
})
export class AllocateMinutesToSubscriberModule { }
