import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from '../../core/services/common.service';
import { CampaignService } from '../service/campaign.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirm-blacklist',
  templateUrl: './confirm-blacklist.component.html',
  styleUrls: ['./confirm-blacklist.component.scss']
})
export class ConfirmBlacklistComponent implements OnInit {

  candidateData: any
  candidateId: number

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmBlacklistComponent,
    private commomService: CommonService,
    private campaignService: CampaignService,
    private dialogRef: MatDialogRef<ConfirmBlacklistComponent>
  ) {
    this.candidateData = data;
    this.candidateId = this.candidateData.candidateId
  }

  ngOnInit() {
  }

  blacklist() {
    try {
      let params = {
        CandidateId: this.candidateId
      }
      this.campaignService.BlacklistCandidate(params).subscribe(response => {
        if (response.success) {
          this.commomService.showSnakeBar(response.message);
          this.closeDialog();
        }
        else {
          this.commomService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commomService.checkTokenValidity(err);
      })
    }
    catch{
      this.commomService.commonSnakeBar();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
