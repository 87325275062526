import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { ApiUrls } from '../../config/api-urls';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtService } from '../../core/services/jwt.service';
import { ResultModel } from '../../core/model/result.model';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SmsCampaignServicesService {

  constructor( private apiService: ApiService,
    private apiUrl: ApiUrls,
    private http: HttpClient,
    private jwtService: JwtService) { }

    httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.jwtService.getToken()
      })
    };

    smsCampaignListing(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.Campaign.SMSCampaignListing, reqModel)
    }

    createSMSCamapignFormRequest(formData): Observable<ResultModel> {
      return this.http.post<ResultModel>(`${environment.apiUrl}${this.apiUrl.Campaign.CreateSMSCamapignFormRequest}`, formData, this.httpOptions);
    }

    smsCampaignDetail(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.Campaign.SMSCampaignDetail, reqModel)
    }
  
    UpdateSMSCamapignFormRequest(formData): Observable<ResultModel> {
      return this.http.post<ResultModel>(`${environment.apiUrl}${this.apiUrl.Campaign.UpdateSMSCamapign}`, formData, this.httpOptions);
    }

    UpdateCampaignStatus(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.Campaign.UpdateSMSCampaignStatus, reqModel)
    }

    GetCandidateCallStatusDropdown(): Observable<ResultModel> {
      return this.apiService.get<ResultModel>(this.apiUrl.Campaign.GetCandidateCallStatusDropdown)
    }
  
  
}
