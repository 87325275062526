import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { ApiUrls } from '../../config/api-urls';
import { Observable } from 'rxjs';
import { ResultModel } from '../../core/model/result.model';

@Injectable({
  providedIn: 'root'
})
export class SkillService {

  constructor(
    private apiService: ApiService,
    private apiUrl: ApiUrls
  ) { }

  getSkillList(reqModel): Observable<ResultModel> {

    return this.apiService.post<ResultModel>(this.apiUrl.Skill.SkillListing, reqModel)
  }

  addSkill(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Skill.AddSkill, reqModel);
  }

  editSkill(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Skill.EditSkill, reqModel);
  }

  deleteSkill(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Skill.DeleteSkill, reqModel);
  }
}
