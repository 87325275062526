import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CommonService } from '../../core/services/common.service';
import { SharedService } from '../service/shared.service';
import { StatusMaster } from '../../core/constants/status';


@Component({
  selector: 'app-delete-user-permission-company-admin',
  templateUrl: './delete-user-permission-company-admin.component.html',
  styleUrls: ['./delete-user-permission-company-admin.component.scss']
})
export class DeleteUserPermissionCompanyAdminComponent implements OnInit {

  permissionId: null
  statusId: number
  userStatus: string
  userData: any
  userStatusId: number
  statusMstr: any = StatusMaster

  constructor(@Inject(MAT_DIALOG_DATA) public data: DeleteUserPermissionCompanyAdminComponent,
  private commonService: CommonService,
  private sharedService: SharedService,
  private commomService: CommonService,
  private dialogRef: MatDialogRef<DeleteUserPermissionCompanyAdminComponent>) { 

    this.userData = data;
    this.permissionId = this.userData.permissionDetails.permissionId
    this.userStatus = this.userData.permissionDetails.status
    this.userStatusId = this.userData.permissionDetails.statusId

    this.statusId = this.statusMstr.Deleted
  }

  ngOnInit() {
  }

  delete() {
    try {
      let parms = {
        PermissionId: this.permissionId,
        statusId: this.statusId,
        Type:'CompAdmin'
      }
      this.sharedService.blockUserPermissionCompAdmin(parms).subscribe(response => {
        if (response.success) {
          this.commomService.showSnakeBar("User permission deleted successfully");
          this.closeDialog();
        }
        else {
          this.commomService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commomService.checkTokenValidity(err);
      })
    }
    catch{
      this.commomService.commonSnakeBar();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
