import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ApiUrls } from '../../config/api-urls';
import { Observable } from 'rxjs';
import { ResultModel } from '../model/result.model';

@Injectable({
  providedIn: 'root'
})
export class UserDetailService {

  constructor(
    private apiService: ApiService,
    private apiUrl: ApiUrls
  ) { }

  getDetails(userId): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Shared.GetUserDetails, { UserId: userId});
  }

  CandidateSearch(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Shared.CandidateSearch, reqModel);
  }

  SMSOptOut(ReqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Shared.SMSOptOut, ReqModel);
  }
}
