import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { MaterialComponentsModule } from '../material-components.module';
import { RouterModule } from '@angular/router';
import {  ReactiveFormsModule,FormsModule } from '@angular/forms';
import { ViewMinutesStatusListComponent } from './view-minutes-status-list/view-minutes-status-list.component';
import { EditViewMinutesStatusComponent } from './edit-view-minutes-status/edit-view-minutes-status.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    MaterialComponentsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [ViewMinutesStatusListComponent, EditViewMinutesStatusComponent],
  entryComponents:[EditViewMinutesStatusComponent]
  
})
export class ViewMinutesStatusModule { }
