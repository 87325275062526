import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserPermissionServiceService } from '../service/user-permission-service.service'
import { CommonService } from '../../core/services/common.service';
import { MatDialogRef } from '@angular/material';
import { AppSettings } from '../../config/app-settings';

@Component({
  selector: 'app-add-user-permission',
  templateUrl: './add-user-permission.component.html',
  styleUrls: ['./add-user-permission.component.scss']
})
export class AddUserPermissionComponent implements OnInit {

  menuId: number

  public addPermissionForm: FormGroup;
  public isProcessing: boolean = false;
  public lastInsertedStandardPackageId: any
  displayedColumns: string[] = ['menuId', 'id', 'menuName', 'menuLink', 'fullControle', 'views'
    , 'adds', 'edits', 'deletes'];
  dataSource = [];

  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  isLoadMore: boolean = false;

  constructor(private userPermissionServiceService: UserPermissionServiceService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private appSettings: AppSettings,
    private dialogRef: MatDialogRef<AddUserPermissionComponent>) { }

  ngOnInit() {
    this.initForm()
    this.getmainListingForAddition(false)
  }

  initForm() {
    this.addPermissionForm = this.formBuilder.group({
      'PermissionName': [null, [Validators.required, this.noWhitespaceValidator]]
    });
  }


  getmainListingForAddition(isPaging: boolean) {
    try {
      let params = {
        PageNumber: this.pageNumber,
        PageSize: this.pageSize
      }
      this.userPermissionServiceService.getmainListingForAddition(params).subscribe(response => {
        if (response.success) {
          this.dataSource = response.data
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch{
      this.commonService.commonSnakeBar();
    }
  }



  addMainPermission(form) {
    if (form.valid) {
      try {
        this.isProcessing = true;
        let params = {
          PermissionName: this.addPermissionForm.controls.PermissionName.value.trim()
        }
        this.userPermissionServiceService.addMainPermission(params).subscribe(resposne => {
          if (resposne.success) {
            this.isProcessing = false;
            this.addPermissionForm.get('PermissionName').patchValue('');
            this.lastInsertedStandardPackageId = resposne.data;
            let arrayAllComponent = this.dataSource;
            for (let item of arrayAllComponent) {

              let paramarr = {
                PermissionId: this.lastInsertedStandardPackageId,
                MenuId: item.menuId,
                FullControle: item.fullControle,
                Views: item.views,
                Adds: item.adds,
                Edit: item.edits,
                Deletes: item.deletes
              };

              try {
                this.userPermissionServiceService.addChildPermission(paramarr).subscribe(resposne => {
                  if (resposne.success) {
                    this.isProcessing = false;
                    this.closeDialog();
                  }
                  else {
                    this.isProcessing = false;
                    this.closeDialog();
                    this.commonService.showSnakeBar(resposne.message);
                  }

                }, (err) => {
                  this.isProcessing = false;
                  this.closeDialog();
                  this.commonService.checkTokenValidity(err);
                })
              }
              catch
              {
                this.isProcessing = false;
                this.closeDialog();
                this.commonService.commonSnakeBar();
              }
            }
            this.commonService.showSnakeBar(resposne.message);
            this.closeDialog();
          }
          else {
            this.addPermissionForm.get('PermissionName').patchValue('')
            this.isProcessing = false;
            this.closeDialog();
            this.commonService.showSnakeBar(resposne.message);
          }

        }, (err) => {
          this.isProcessing = false;
          this.closeDialog();
          this.commonService.checkTokenValidity(err);
        })
      }
      catch{
        this.isProcessing = false;
        this.closeDialog();
        this.commonService.commonSnakeBar();
      }

    }
    else {
      this.commonService.showSnakeBar("Please do the correction");
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  public checkValue(event: any, value) {
    let checkedType = value
    this.menuId = this.menuId

    switch (checkedType) {
      case "fullcontrol":
        this.dataSource.find(item => item.menuId == this.menuId).fullControle = event.checked;
        this.dataSource.find(item => item.menuId == this.menuId).views = event.checked;
        this.dataSource.find(item => item.menuId == this.menuId).adds = event.checked;
        this.dataSource.find(item => item.menuId == this.menuId).edits = event.checked;
        this.dataSource.find(item => item.menuId == this.menuId).deletes = event.checked;
        break;
      case "view":
        this.dataSource.find(item => item.menuId == this.menuId).views = event.checked;

        if(this.dataSource.find(item => item.menuId == this.menuId).views ==true &&
        this.dataSource.find(item => item.menuId == this.menuId).adds ==true &&
        this.dataSource.find(item => item.menuId == this.menuId).edits ==true &&
        this.dataSource.find(item => item.menuId == this.menuId).deletes ==true)
        {
          this.dataSource.find(item => item.menuId == this.menuId).fullControle = true
        }
        else{
          this.dataSource.find(item => item.menuId == this.menuId).fullControle = false
        }

        if(this.dataSource.find(item => item.menuId == this.menuId).views == false)
        {
          this.dataSource.find(item => item.menuId == this.menuId).fullControle = false;
          this.dataSource.find(item => item.menuId == this.menuId).adds = false;
          this.dataSource.find(item => item.menuId == this.menuId).edits = false;
          this.dataSource.find(item => item.menuId == this.menuId).deletes = false;
        }
        break;
      case "add":
        this.dataSource.find(item => item.menuId == this.menuId).adds = event.checked;

        if (this.dataSource.find(item => item.menuId == this.menuId).edits == false &&
          this.dataSource.find(item => item.menuId == this.menuId).deletes == false) {
          this.dataSource.find(item => item.menuId == this.menuId).views = event.checked;
        }

        if(this.dataSource.find(item => item.menuId == this.menuId).views ==true &&
        this.dataSource.find(item => item.menuId == this.menuId).adds ==true &&
        this.dataSource.find(item => item.menuId == this.menuId).edits ==true &&
        this.dataSource.find(item => item.menuId == this.menuId).deletes ==true)
        {
          this.dataSource.find(item => item.menuId == this.menuId).fullControle = true
        }
        else{
          this.dataSource.find(item => item.menuId == this.menuId).fullControle = false
        }

        break;
      case "edit":
        this.dataSource.find(item => item.menuId == this.menuId).edits = event.checked;

        if (this.dataSource.find(item => item.menuId == this.menuId).adds == false &&
          this.dataSource.find(item => item.menuId == this.menuId).deletes == false) {
          this.dataSource.find(item => item.menuId == this.menuId).views = event.checked;
        }

        if(this.dataSource.find(item => item.menuId == this.menuId).views ==true &&
        this.dataSource.find(item => item.menuId == this.menuId).adds ==true &&
        this.dataSource.find(item => item.menuId == this.menuId).edits ==true &&
        this.dataSource.find(item => item.menuId == this.menuId).deletes ==true)
        {
          this.dataSource.find(item => item.menuId == this.menuId).fullControle = true
        }
        else{
          this.dataSource.find(item => item.menuId == this.menuId).fullControle = false
        }

        break;
      case "delete":
        this.dataSource.find(item => item.menuId == this.menuId).deletes = event.checked;

        if (this.dataSource.find(item => item.menuId == this.menuId).adds == false &&
        this.dataSource.find(item => item.menuId == this.menuId).edits == false) {
        this.dataSource.find(item => item.menuId == this.menuId).views = event.checked;
      }

      if(this.dataSource.find(item => item.menuId == this.menuId).views ==true &&
      this.dataSource.find(item => item.menuId == this.menuId).adds ==true &&
      this.dataSource.find(item => item.menuId == this.menuId).edits ==true &&
      this.dataSource.find(item => item.menuId == this.menuId).deletes ==true)
      {
        this.dataSource.find(item => item.menuId == this.menuId).fullControle = true
      }
      else{
        this.dataSource.find(item => item.menuId == this.menuId).fullControle = false
      }
        break;


    }
  }

  onRowClicked(row) {
    this.menuId = row.menuId
  }

}
