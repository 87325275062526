import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { SharedService } from '../service/shared.service';
import { CommonService } from '../../core/services/common.service';
import { StatusMaster } from '../../core/constants/status';

@Component({
  selector: 'app-block-company-model',
  templateUrl: './block-company-model.component.html',
  styleUrls: ['./block-company-model.component.scss']
})
export class BlockCompanyModelComponent implements OnInit {


  companyId: null
  statusId: number
  userStatus: string
  userData: any
  userStatusId: number
  statusMstr: any = StatusMaster

  constructor(@Inject(MAT_DIALOG_DATA) public data: BlockCompanyModelComponent,
    private sharedService: SharedService,
    private commomService: CommonService,
    private dialogRef: MatDialogRef<BlockCompanyModelComponent>) {
    this.userData = data;
    this.companyId = this.userData.companyDetails.companyId
    this.userStatus = this.userData.companyDetails.status
    this.userStatusId = this.userData.companyDetails.statusId

    if (this.userStatusId == this.statusMstr.InActive) {
      this.statusId = this.statusMstr.Active
    }
    else if (this.userStatusId == this.statusMstr.Active) {
      this.statusId = this.statusMstr.InActive
    }
  }

  ngOnInit() {
  }

  block() {
    try {
      let parms = {
        id: this.companyId,
        statusId: this.statusId
      }
      this.sharedService.blockCompany(parms).subscribe(response => {
        if (response.success) {
          this.commomService.showSnakeBar("Company status has been updated successfully");
          this.closeDialog();
        }
        else {
          this.commomService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commomService.checkTokenValidity(err);
      })
    }
    catch{
      this.commomService.commonSnakeBar();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
