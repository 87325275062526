import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { ApiUrls } from '../../config/api-urls';
import { Observable } from 'rxjs';
import { ResultModel } from '../../core/model/result.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtService } from '../../core/services/jwt.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  constructor(
    private apiService: ApiService,
    private apiUrl: ApiUrls,
    private http: HttpClient,
    private jwtService: JwtService
  ) {


  }

  
  httpOptions = {
    headers: new HttpHeaders({
      'Authorization': 'Bearer ' + this.jwtService.getToken()
    })
  };
  getRecruiterDropdown(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Recruiter.RecruiterDropdown, reqModel);
  }

  getSubscriberDropdown(): Observable<ResultModel> {
    return this.apiService.get<ResultModel>(this.apiUrl.Subscriber.SubscriberDropdown);
  }

  // createCampaign(reqModel): Observable<ResultModel> {
  //   return this.apiService.post<ResultModel>(this.apiUrl.Campaign.CreateCampaign, reqModel);
  // }

  createCamapignFormRequest(formData): Observable<ResultModel> {
    // const uploadReq = new HttpRequest('POST', `http://localhost:5000/api/v1/Campaign/CreateCampaign`, formData, { responseType: 'json' });

    return this.http.post<ResultModel>(`${environment.apiUrl}${this.apiUrl.Campaign.CreateCampaign}`, formData, this.httpOptions);
  }

  campaignListing(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.CampaignListing, reqModel)
  }

  campaignDetail(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.CampaignDetail, reqModel)
  }

  UpdateCampaignStatus(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.UpdateCampaignStatus, reqModel)
  }

  UpdateCamapignFormRequest(formData): Observable<ResultModel> {
    // const uploadReq = new HttpRequest('POST', `http://localhost:5000/api/v1/Campaign/CreateCampaign`, formData, { responseType: 'json' });

    return this.http.post<ResultModel>(`${environment.apiUrl}${this.apiUrl.Campaign.UpdateCampaign}`, formData, this.httpOptions);
  }

  BlacklistCandidate(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.BlacklistCandidate, reqModel)
  }

  GetCandidateCallStatusDropdown(): Observable<ResultModel> {
    return this.apiService.get<ResultModel>(this.apiUrl.Campaign.GetCandidateCallStatusDropdown)
  }

  getSkillDropDown(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Recruiter.SkillDropDown, reqModel);
  }

  getRoleManagerDropdown(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.RoleManagerDropdown, reqModel);
  }



  getCandidateDetailsForFrontEnd(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.GetCandidateDetailsForFrontEnd, reqModel)
  }

  generateTokenForCall(): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.GenerateTokenForCall)
  }

  addNumbers(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.AddNumbers, reqModel)
  }


  hangUpCall(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.HangUpCall, reqModel)
  }

  disconnectedCall(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.DisconnectedCall, reqModel)
  }

  getEmployeeDropDownForCallMerge(): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.GetEmployeeDropDownForCallMerge)
  }



  muteAndUnmute(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.MuteAndUnmute, reqModel)
  }


  removeParticipantsUsers(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.RemoveParticipantsUsers, reqModel)
  }

  checkedValueForCallDuration(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.CheckedValueForCallDuration, reqModel)
  }


  createRecording(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.CreateRecording, reqModel)
  }

  deleteRecording(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.DeleteRecording, reqModel)
  }

  getCallingAvailability(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.GetCallingAvailability, reqModel)
  }

  generateTokenForClientCall(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.GenerateTokenForClientCall, reqModel)
  }

  updateCampaignStatusWithCampaign(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.UpdateCampaignStatusWithCampaign, reqModel)
  }
 
}
