import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from '../../core/services/auth.service';
import { CommonService } from '../../core/services/common.service';
import { AppSettings } from '../../config/app-settings';
import { StatusMaster } from '../../core/constants/status';
import { AutofillMonitor } from '@angular/cdk/text-field';
import { EditPurchasedPackageManagementComponent } from '../edit-purchased-package-management/edit-purchased-package-management.component'
import { PurchasedPackageManagementServiceService } from '../service/purchased-package-management-service.service'
import { SharedService } from '../../shared/service/shared.service'
import { JwtService } from '../../core/services/jwt.service'

@Component({
  selector: 'app-purchased-package-management-list',
  templateUrl: './purchased-package-management-list.component.html',
  styleUrls: ['./purchased-package-management-list.component.scss']
})
export class PurchasedPackageManagementListComponent implements OnInit {

  displayedColumns: string[] = ['Id', 'PackageId', 'Name', 'PackageType', 'AudioMinutes',
    'VideoMinutes', 'SMS', 'Price', 'CompanyId', 'CompName', 'PurchaseDate', 'TransactionId', 'PackageTenure', 'Status', 'Action'];

  dataSource = [];

  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  isLoadMore: boolean = false
  statusMstr: any = StatusMaster;
  NoRecFound: boolean = false;
  NoPackageFound: boolean = false;

  spanOption: boolean = true;
  spanOptionToggle() {
    this.spanOption = !this.spanOption;
  }
  public ListingData = [];
  public AllStandardPackages = [];
  firstTimeDataSource = []

  fullControl: boolean = true;
  views: boolean = true;
  adds: boolean = true;
  edits: boolean = true;
  deletes: boolean = true;
  disableds: boolean = false;

  constructor(public dialog: MatDialog,
    private authService: AuthService,
    private commonService: CommonService,
    private sharedService: SharedService,
    private jwtService: JwtService,
    private purchasedPackageManagementServiceService: PurchasedPackageManagementServiceService,
    private appSettings: AppSettings) { }

  ngOnInit() {
    this.getPurchasePackageListing(false)

    const role = this.jwtService.getUserRole();
    if (role == 'SuperSubAdmin') {
      this.getAddEditDeletePermission();
    }
  }

  noRecordFoundNotCon: boolean = false
  getPurchasePackageListing(isPaging: boolean) {
    try {
      let params = {
        PageNumber: this.pageNumber,
        PageSize: this.pageSize
      }
      this.purchasedPackageManagementServiceService.purchasedPackageManagementListing(params).subscribe(response => {
        if (response.success) {
         // this.dataSource = response.data;

         let len = response.data.length;
         if (len > 0) {
            this.noRecordFoundNotCon = true
          }
          else {
            this.noRecordFoundNotCon = false
          }
		  
         if (response.data.length > 0) {
          this.firstTimeDataSource = response.data;
        }
          if (response.data.length < this.pageSize) {
            this.isLoadMore = false;
          }
          else {
            this.isLoadMore = true;
          }

          if (response.data.length <= 0 && this.firstTimeDataSource.length >= 20) {
            this.NoPackageFound = true;
          }
          else {
            this.NoPackageFound = false;
          }

          if (isPaging) {
            this.appendStandardPackageListing(response.data);
          }
          else {
            this.dataSource = response.data;
          }
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch{
      this.commonService.commonSnakeBar();
    }
  }

  appendStandardPackageListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.dataSource.length > 0) {
        newState = this.dataSource.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.dataSource = newState;
    }
  }

  loadMore() {
    this.pageNumber = this.pageNumber +1
    this.pageSize = this.pageSize
    this.getPurchasePackageListing(true);
  }

  ViewPurchasedPackageManagementModal(userDetail): void {
    const dialogRef = this.dialog.open(EditPurchasedPackageManagementComponent, {
      disableClose: true,
      panelClass: "full-width-popup",
      data: {
        userDetail
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == undefined) {
        this.getPurchasePackageListing(false);
        //  this.pageNumber = this.pageNumber
      }
    })
  }

  getAddEditDeletePermission() {
    let params = {
      MenuId: 17
    }
    this.sharedService.getAddEditDeletePermission(params).subscribe(response => {
      if (response.success) {
        if (response.data.length > 0) {
          this.adds = response.data[0].adds
          this.views = response.data[0].views
          this.fullControl = response.data[0].fullControle
          this.deletes = response.data[0].deletes
          this.edits=response.data[0].edit
        }


        if (this.fullControl == true || this.edits == true) {
          this.disableds = false
        }
        else {
          this.disableds = true
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

}
