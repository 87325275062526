import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResultModel } from '../../core/model/result.model';
import { ApiService } from '../../core/services/api.service';
import { ApiUrls } from '../../config/api-urls';

@Injectable({
  providedIn: 'root'
})
export class StripePaymentServiceService {

  constructor(private apiService: ApiService,
    private apiUrl: ApiUrls) { }

    stripePayment(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.Shared.StandardAndCustomizeStripePayment, reqModel);
    }
    standardPackageMyPackgeListing(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.StandardPackageMyPackgeListing, reqModel);
    }
    standardPackageMyPackgeProRateListing(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.StandardPackageMyPackgeProRateListing, reqModel);
    }

}
