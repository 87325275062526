import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { ResultModel } from '../../core/model/result.model';
import { Observable } from 'rxjs';
import { ApiUrls } from '../../config/api-urls';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionCompanyAdminServiceService {

  constructor(private apiService: ApiService,
    private apiUrl: ApiUrls) { }

    getmainPermissonListingForCompAdmin(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.MainPermissonListingForCompAdmin, reqModel)
    }
  
    getmainListingForCompanyAdminAddition(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.GetmainListingForCompanyAdminAddition, reqModel)
    }
  
    getchildListingCompAdminById(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.GetchildListingCompAdminById, reqModel)
    }
  
    
    addMainPermissionByCompanyAdmin(addModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.AddMainPermissionByCompanyAdmin, addModel);
    }
  
    editChildPermissionCompAdmin(addModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.EditChildPermissionCompAdmin, addModel);
    }
  
    addChildPermissionByCompanyAdmin(addModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.AddChildPermissionByCompanyAdmin, addModel);
    }
  
    editMainPermissionCompAdmin(addModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.EditMainPermissionCompAdmin, addModel);
    }

    

    getRoleDropdown(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.GetRoleDropdown, reqModel)
    }
}
