import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from '../../core/services/auth.service';
import { CommonService } from '../../core/services/common.service';
import { AppSettings } from '../../config/app-settings';
import { StatusMaster } from '../../core/constants/status';
import { AutofillMonitor } from '@angular/cdk/text-field';
import { CustomizedApprovedPackagesServiceService } from '../service/customized-approved-packages-service.service'
import { AddCustomizedApprovedPackagesComponent } from '../add-customized-approved-packages/add-customized-approved-packages.component'
import { BlockApprovedCustomizePackageComponent } from '../../shared/block-approved-customize-package/block-approved-customize-package.component';
import { EditCustomizedApprovedPackagesComponent } from '../edit-customized-approved-packages/edit-customized-approved-packages.component';
import { SharedService } from '../../shared/service/shared.service'
import { JwtService } from '../../core/services/jwt.service'


@Component({
  selector: 'app-customized-approved-packages-list',
  templateUrl: './customized-approved-packages-list.component.html',
  styleUrls: ['./customized-approved-packages-list.component.scss']
})
export class CustomizedApprovedPackagesListComponent implements OnInit {

  displayedColumns: string[] = ['CustomizePackageRefId', 'Name', 'Description',
    'AudioMinutes', 'VideoMinutes', 'SMS', 'Price', 'CalculatedPrice', 'CompanyId', 'CompName',
    'Purchased', 'PurchaseStatus', 'PackageTenure', 'Status', 'Action'];

  dataSource = [];
  public ListingData = [];
  public AllStandardPackages = [];
  public manualPaging: boolean = false

  fullControl: boolean = true;
  views: boolean = true;
  adds: boolean = true;
  edits: boolean = true;
  deletes: boolean = true;
  disableds: boolean = false;

  constructor(public dialog: MatDialog,
    private authService: AuthService,
    private commonService: CommonService,
    private appSettings: AppSettings,
    private sharedService: SharedService,
    private jwtService: JwtService,
    private customizedApprovedPackagesServiceService: CustomizedApprovedPackagesServiceService) {
    this.getuserDetails();
  }

  ngOnInit() {
    this.getCustomizeApprovedPackageSuperAdminListing(false)
    const role = this.jwtService.getUserRole();
    if (role == 'SuperSubAdmin') {
      this.getAddEditDeletePermission();
    }
  }

  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  isLoadMore: boolean = false
  statusMstr: any = StatusMaster;
  NoRecFound: boolean = false;
  NoPackageFound: boolean = false;
  levelOneTrigger: any;
  totalAmount: any = [];

  noRecordFoundNotCon: boolean = false
  getCustomizeApprovedPackageSuperAdminListing(isPaging: boolean) {
    try {
      let params
      if (this.manualPaging == true && isPaging != true) {
        this.pageNumber = 1
        params = {
          PageNumber: this.pageNumber,
          PageSize: this.pageSize
        }
      }
      else {
        params = {
          PageNumber: this.pageNumber,
          PageSize: this.pageSize
        }
      }
      this.customizedApprovedPackagesServiceService.getCustomizeApprovedPackageSuperAdminListing(params).subscribe(response => {
        if (response.success) {
          let len = response.data.length;
          if (len > 0) {
             this.noRecordFoundNotCon = true
           }
           else {
             this.noRecordFoundNotCon = false
           }

          if (response.data.length < this.pageSize) {
            this.isLoadMore = false;
          }
          else {
            this.isLoadMore = true;
          }
          if (isPaging) {
            this.appendCustomizePackageSuperAdminListing(response.data);
          }
          else {
            this.dataSource = response.data;
          }
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch {
      this.commonService.commonSnakeBar();
    }
  }

  appendCustomizePackageSuperAdminListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.dataSource.length > 0) {
        newState = this.dataSource.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.dataSource = newState;
    }
  }

  AddCustomizeApprovedPackageModal(): void {
    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(AddCustomizedApprovedPackagesComponent, {
        disableClose: true,
        panelClass: "full-width-popup"
      }
      )

      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.getCustomizeApprovedPackageSuperAdminListing(false);
        }
      })
    }
    else {
      this.commonService.userDeActivatedOrDeleted();
    }


  };

  EditApprovedCustomizeModal(planDetails): void {
    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(EditCustomizedApprovedPackagesComponent, {
        disableClose: true,
        panelClass: "full-width-popup",
        data: {
          planDetails
        }
  
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.getCustomizeApprovedPackageSuperAdminListing(false);
        }
      })
    }
    else {
      this.commonService.userDeActivatedOrDeleted();
    }

   
  }

  blockApprovedCustomizeModal(packageDetails): void {
    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(BlockApprovedCustomizePackageComponent, {
        width: '600px',
        height: 'auto',
        data: {
          packageDetails
        }
      })
  
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.getCustomizeApprovedPackageSuperAdminListing(false);
        }
      })
    }
    else {
      this.commonService.userDeActivatedOrDeleted();
    }

  
  };

  onChange(enable: boolean, packageDetails) {

    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(BlockApprovedCustomizePackageComponent, {
        width: '600px',
        height: 'auto',
        data: {
          packageDetails
        }
      })
  
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.getCustomizeApprovedPackageSuperAdminListing(false);
        }
      })
    }
    else {
      this.commonService.userDeActivatedOrDeleted();
    }

   
  }

  loadMore() {
    this.pageNumber = this.pageNumber + 1
    this.pageSize = this.pageSize
    this.getCustomizeApprovedPackageSuperAdminListing(true);
  }

  getAddEditDeletePermission() {
    let params = {
      MenuId: 19
    }
    this.sharedService.getAddEditDeletePermission(params).subscribe(response => {
      if (response.success) {

        if (response.data.length > 0) {
          this.adds = response.data[0].adds
          this.views = response.data[0].views
          this.fullControl = response.data[0].fullControle
          this.deletes = response.data[0].deletes
          this.edits = response.data[0].edit
        }


        if (this.fullControl == true || this.edits == true) {
          this.disableds = false
        }
        else {
          this.disableds = true
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  userDetails = []
  statusId: number
  getuserDetails(): number {
    try {
      let userId = this.jwtService.getCurrentUserId();
      let param = {
        UserId: userId
      }
      this.sharedService.getUserDetails(param).subscribe(response => {
        if (response.success) {
          this.userDetails = response.data;
          this.statusId = response.data[0].statusId;
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch {
      this.commonService.commonSnakeBar();
    }
    return this.statusId;
  }
}
