import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { ApiUrls } from '../../config/api-urls';
import { Observable } from 'rxjs';
import { ResultModel } from '../../core/model/result.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtService } from '../../core/services/jwt.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DirectCallingServicesService {

  constructor(private apiService: ApiService,
    private apiUrl: ApiUrls,
    private http: HttpClient,
    private jwtService: JwtService) { }

    httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.jwtService.getToken()
      })
    };

  directCandidateCallingListing(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.DirectCandidateCallingListing, reqModel);
  }

  getSkillDropDown(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Recruiter.SkillDropDown, reqModel);
  }

  createCandidateDirectCall(formData): Observable<ResultModel> {
   return this.http.post<ResultModel>(`${environment.apiUrl}${this.apiUrl.Campaign.CreateCandidateDirectCall}`, formData, this.httpOptions);
  }


  updateCandidateDirectCallFormRequest(formData): Observable<ResultModel> {
    return this.http.post<ResultModel>(`${environment.apiUrl}${this.apiUrl.Campaign.UpdateCandidateDirectCallFormRequest}`, formData, this.httpOptions);
  }

  getEmployeeDropDownForCallMerge(): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.GetEmployeeDropDownForCallMerge)
  }

  generateTokenForCall(): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.GenerateTokenForCall)
  }

  getCallingAvailability(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.GetCallingAvailability, reqModel)
  }

  checkedValueForCallDuration(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.CheckedValueForCallDuration, reqModel)
  }

  createRecording(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.CreateRecording, reqModel)
  }

  addNumbers(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.AddNumbers, reqModel)
  }

  hangUpCall(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.HangUpCall, reqModel)
  }


  generateTokenForClientCall(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.GenerateTokenForClientCall, reqModel)
  }


  deleteRecording(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.DeleteRecording, reqModel)
  }


  removeParticipantsUsers(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.RemoveParticipantsUsers, reqModel)
  }

  muteAndUnmute(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Campaign.MuteAndUnmute, reqModel)
  }


}
