import { Component, OnInit } from '@angular/core';
import { AllocateMinutesToSubscriberServiceService } from '../service/allocate-minutes-to-subscriber-service.service'
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { MatDialogRef } from '@angular/material';
import { DatePipe } from '@angular/common';
import { isNullOrUndefined } from 'util';
import { AppSettings } from '../../config/app-settings';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-add-allocate-minutes-to-subscriber',
  templateUrl: './add-allocate-minutes-to-subscriber.component.html',
  styleUrls: ['./add-allocate-minutes-to-subscriber.component.scss']
})
export class AddAllocateMinutesToSubscriberComponent implements OnInit {

  currentURL: any;
  removable = true;
  public allocateMintsToSubSpForm: FormGroup;
  public isProcessing: boolean = false;
  public smsPrice: any
  public audioPrice: any
  public videoPrice: any
  componentPlanData = [];
  public lastInsertedStandardPackageId: any
  componentDataForDynamicInputs = [];
  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  subscribers = [];
  constructor(private formBuilder: FormBuilder,
    private commonService: CommonService,
    private allocateMinutesToSubscriberServiceService: AllocateMinutesToSubscriberServiceService,
    private datePipe: DatePipe,
    private appSettings: AppSettings,
    private dialogRef: MatDialogRef<AddAllocateMinutesToSubscriberComponent>) { }

  ngOnInit() {
    this.intiForm()
    this.getSubscriberDropdown()
    this.getComponentDetailsForMintsAllocationForSubscriber()
    this.currentURL = window.location.href;
  }

  intiForm() {
    this.allocateMintsToSubSpForm = this.formBuilder.group({
      'Subscriber': [[], [Validators.required]],
      'Description': [null],
      'AllComponent': this.formBuilder.array([])
    })
  }

  getSubscriberDropdown() {
    let params = {
      SpOption: ''
    }
    this.allocateMinutesToSubscriberServiceService.getSubscriberDropdown(params).subscribe(response => {
      if (response.success) {
        let subs = [];
        response.data.forEach(element => {
          subs.push({
            id: element.id,
            name: element.name
          })
        });
        this.subscribers = subs
        if (subs.length == 1) {
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }
  packageId: any

  getComponentDetailsForMintsAllocationForSubscriber() {
    let allcomponent = this.allocateMintsToSubSpForm.get('AllComponent') as FormArray;
    let params = {
      PageNumber: this.pageNumber,
      PageSize: this.pageSize
    }
    this.allocateMinutesToSubscriberServiceService.getComponentDetailsForMintsAllocationForSubscriber(params).subscribe(response => {
      if (response.success) {
        let count = [];
        response.data.forEach(element => {
          allcomponent.push(this.formBuilder.control({
            ComponentId: element.componentId,
            ComponentType: element.componentType,
            ComponentTypeName: element.componentTypeName,
            AvailableUnit: element.availableUnit,
            Id: element.id,
            Name: element.name,
            PackageId: element.packageId,
            InputParameter: ''
          }));
          count.push({
            ComponentId: element.componentId,
            ComponentType: element.componentType,
            ComponentTypeName: element.componentTypeName,
            AvailableUnit: element.availableUnit,
            Id: element.id,
            Name: element.name,
            PackageId: element.packageId,
            InputParameter: ''
          })
        });

        if (count.length >= 0) {
          this.componentDataForDynamicInputs = count
          this.packageId = this.componentDataForDynamicInputs[0].PackageId
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  public focusoutAllComponentHandler(event, AvailableUnit, id, name, ComponentType, ComponentTypeName, item) {
    if (AvailableUnit != undefined) {
      item.setValue({
        ComponentType: ComponentType,
        ComponentTypeName: ComponentTypeName,
        AvailableUnit: AvailableUnit,
        Id: id,
        Name: name,
        PackageId: '',
        InputParameter: event.target.value
      })

      let allcomponent = this.allocateMintsToSubSpForm.get('AllComponent') as FormArray;

      if (AvailableUnit >= event.currentTarget.value) {
        return true
      }
      else {
        event.currentTarget.value = ''
        this.commonService.allottedValueSnakeBar();
        return false
      }
    }
  }

  public addAllocateMintsToSubscriber(form) {
    if (form.valid) {
      try {
        let params = {
          Description: (this.allocateMintsToSubSpForm.controls.Description.value == '' || this.allocateMintsToSubSpForm.controls.Description.value == null || this.allocateMintsToSubSpForm.controls.Description.value == undefined) ? '' : this.allocateMintsToSubSpForm.controls.Description.value,
          PackageId: this.packageId,
          AudioMinutes: 0,
          VideoMinutes: 0,
          SMS: 0,
          SubscriberId: this.allocateMintsToSubSpForm.controls.Subscriber.value
        }
        this.allocateMinutesToSubscriberServiceService.addAllocateMintsToSubscriber(params).subscribe(resposne => {
          if (resposne.success) {
            this.isProcessing = false;
            this.lastInsertedStandardPackageId = resposne.data;

            let arrayAllComponent = this.allocateMintsToSubSpForm.controls.AllComponent.value

            for (let item of arrayAllComponent) {
              let inputparm
              if (item.InputParameter != '' && item.InputParameter != undefined) {
                inputparm = item.InputParameter
              }
              else {
                inputparm = 0
              }

              let paramarr = {
                ComponentId: item.Id,
                PackageId: this.packageId,
                Name: item.Name,
                ComponentType: item.ComponentType,
                InputParameter: inputparm,
                LastPackageInsertedId: this.lastInsertedStandardPackageId,
                AllocattedType: 'AllocatedMinutesToSubscriber',
                SpOptions: 'Insert'
              };

              try {
                this.allocateMinutesToSubscriberServiceService.addAllocateMinsSubscriberArrayDetails(paramarr).subscribe(resposne => {
                  if (resposne.success) {
                    this.isProcessing = false;
                    //  this.commonService.showSnakeBar(resposne.message);
                    this.closeDialog();
                  }
                  else {
                    this.isProcessing = false;
                    this.commonService.showSnakeBar(resposne.message);
                  }
                }, (err) => {
                  this.isProcessing = false;
                  this.commonService.checkTokenValidity(err);
                  this.closeDialog();
                })
              }
              catch
              {
                this.isProcessing = false;
                this.commonService.commonSnakeBar();
              }
            }
            this.commonService.showSnakeBar(resposne.message);
            this.closeDialog();
            this.currentURL= this.currentURL.includes("purchasedpackages")
            if(this.currentURL == true){
              location.reload();
            }
          }
          else {
            this.isProcessing = false;
            this.commonService.showSnakeBar(resposne.message);
          }
        }, (err) => {
          this.isProcessing = false;
          this.commonService.checkTokenValidity(err);
          this.closeDialog();
        })
      }
      catch
      {
        this.isProcessing = false;
        this.commonService.commonSnakeBar();
      }
    }
  }
  closeDialog() {
    this.dialogRef.close();
  }

  clearFields(){
    this.allocateMintsToSubSpForm.get('AllComponent').patchValue([]);
  }
}
