import { Injectable } from '@angular/core';
import { ApiUrls } from '../../config/api-urls';
import { ApiService } from '../../core/services/api.service';
import { Observable } from 'rxjs';
import { ResultModel } from '../../core/model/result.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtService } from '../../core/services/jwt.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(
    private apiUrl: ApiUrls,
    private apiService: ApiService,
    private http: HttpClient,
    private jwtService: JwtService
  ) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Authorization': 'Bearer ' + this.jwtService.getToken()
    })
  };

  createPassword(credentials): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Shared.CreatePassword, credentials);
  }

  changePassword(credentials): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Shared.ChangePassword, credentials);
  }

  deleteUser(credentials): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Shared.DeleteUser, { userId: credentials.userid, statusId: credentials.statusId });
  }

  blockUser(credentials): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Shared.DeleteUser, { userId: credentials.userid, statusId: credentials.statusId });
  }

  blockStandardPackage(credentials): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Shared.BlockStandardPackage, { PackageId: credentials.id, statusId: credentials.statusId });
  }

  blockComponentPlan(credentials): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Shared.BlockComponentPlan, { PlanId: credentials.planId, ComponentType: credentials.componentType, MainComponentId: credentials.mainComponentId, StatusId: credentials.statusId });
  }

  blockCompany(credentials): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Shared.BlockCompany, { CompanyId: credentials.id, statusId: credentials.statusId });
  }

  blockUserPermission(credentials): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Shared.BlockUserPermission, { PermissionId: credentials.PermissionId, StatusId: credentials.statusId });
  }

  blockUserPermissionCompAdmin(credentials): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Shared.BlockUserPermission, { PermissionId: credentials.PermissionId, StatusId: credentials.statusId, Type: credentials.Type });
  }

  blockApprovedCustomizedPackage(credentials): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Shared.BlockApprovedCustomizedPackage, { Id: credentials.id, StatusId: credentials.statusId });
  }

  deleteStandardPackage(credentials): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Shared.DeleteStandardPackage, { PackageId: credentials.id, statusId: credentials.statusId });
  }

  deleteCompany(credentials): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Shared.DeleteCompany, { CompanyId: credentials.id, statusId: credentials.statusId });
  }

  deleteCustomizePackage(credentials): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Shared.DeleteCustomizePackage, { CustomizePackageId: credentials.CustomizePackageId, statusId: credentials.statusId });
  }

  deleteComponentPlan(credentials): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Shared.DeleteComponentPlan, { PlanId: credentials.planId, ComponentType: credentials.componentType, MainComponentId: credentials.mainComponentId, StatusId: credentials.statusId });
  }

  // updateUserProfile(reqModel): Observable<ResultModel> {
  //   return this.apiService.post<ResultModel>(this.apiUrl.Shared.UpdateUserProfile, reqModel);
  // }

  updateUserProfileFormRequest(formData): Observable<ResultModel> {
    return this.http.post<ResultModel>(`${environment.apiUrl}${this.apiUrl.Shared.UpdateUserProfile}`, formData, this.httpOptions);
  }

  getAddEditDeletePermission(credentials): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Shared.GetAddEditDeletePermission, { MenuId: credentials.MenuId, Type: credentials.Type });
  }



  getUserDetails(credentials): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Shared.GetUserDetailsbYId, { UserId: credentials.UserId });
  }

  getWebCallerDetails(credentials): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Shared.GetWebCallerDetails, { UserId: credentials.UserId });
  }

  getDashBoardDetails(credentials): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Shared.GetDashBoardDetails, { UserId: credentials.UserId, FilterType: credentials.FilterType });
  }

  blockDirectCallingCandidateStatus(credentials): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Shared.BlockDirectCallingCandidateStatus, { Id: credentials.id, statusId: credentials.statusId });
  }

  deleteCandidate(credentials): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Shared.DeleteCandidate, { Id: credentials.id, statusId: credentials.statusId });
  }

  sendSms(credentials): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Shared.SendSms, { CampaignId: credentials.CampaignId });
  }

  sendEmail(credentials): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Shared.SendEmail, { CampaignId: credentials.CampaignId });
  }


}
