import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../../core/services/common.service';
import { MatDialog } from '@angular/material';
import { AppSettings } from '../../config/app-settings';
import { StatusMaster } from '../../core/constants/status';
import { SmsCampaignServicesService } from '../service/sms-campaign-services.service';

@Component({
  selector: 'app-sms-campaign-detail',
  templateUrl: './sms-campaign-detail.component.html',
  styleUrls: ['./sms-campaign-detail.component.scss']
})
export class SmsCampaignDetailComponent implements OnInit {
  displayedColumns: string[] = ['referenceId', 'candidateName', 'phone', 'description', 'skill', 'status'];
  dataSource = [];

  campaignId: string
  dateFormat: any

  pageNumber: number = 1
  pageSize: number = this.appSetings.PAGINATOR_SIZE
  searchBy: string
  filterBy: number
  searchByType: string

  isLoadMore: boolean = false
  noRecordFound: boolean = false

  audio: any = new Audio();
  playingId: number

  createdDate: string
  createdBy: string
  campaignName: string
  connected: number
  totalUser: number
  smsSent: number

  isAudioPlayed: boolean = false;
  selected = '0';
  SeacrchByTypeSelected = 'Everything'
  status: any = StatusMaster

  CandidateStatus = []

  constructor(private route: ActivatedRoute,
    private smsCampaignServicesService: SmsCampaignServicesService,
    private commonService: CommonService,
    public dialog: MatDialog,
    private appSetings: AppSettings) {
    this.campaignId = this.route.snapshot.paramMap.get("id");

    this.dateFormat = this.appSetings.CAMPAIGN_DATE
  }

  ngOnInit() {
    this.campaignDetail(false)
    this.campaignData()
    this.GetCandidateStatusDropdown()
  }

  noRecordFoundNotCon: boolean = false
  campaignDetail(isPaging: boolean) {
    let params = {
      CampaignId: this.campaignId,
      PageNumber: this.pageNumber,
      PageSize: this.pageSize,
      SearchBy: this.searchBy,
      FilterBy: this.filterBy,
      SearchByType: this.searchByType
    }
    this.smsCampaignServicesService.smsCampaignDetail(params).subscribe(response => {
      if (response.success) {
        let len = response.data.length;
        if (len > 0) {
           this.noRecordFoundNotCon = true
         }
         else {
           this.noRecordFoundNotCon = false
         }

        if (response.data.length < this.pageSize) {
          this.isLoadMore = false;
        }
        else {
          this.isLoadMore = true;
        }

        if (isPaging) {
          this.appendRecruiterListing(response.data);
        }
        else {
          this.dataSource = response.data;
        }
      }
      else {
        this.commonService.showSnakeBar(response.message)
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  loadMore() {
    this.pageNumber = this.pageNumber + 1
    this.pageSize = this.pageSize
    this.campaignDetail(true);
  }

  appendRecruiterListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.dataSource.length > 0) {
        newState = this.dataSource.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.dataSource = newState;
    }
  }


  campaignData() {
    let params = {
      CampaignId: this.campaignId
    }
    this.smsCampaignServicesService.smsCampaignListing(params).subscribe(response => {
      if (response.success) {
        this.connected = response.data[0]["connected"]
        this.totalUser = response.data[0]["users"]
        this.smsSent = response.data[0]["smsSent"]
        this.campaignName = response.data[0]["name"]
        this.createdBy = response.data[0]["createdBy"]
        this.createdDate = response.data[0]["createdDate"]
      }
      else {
        this.commonService.commonSnakeBar();
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  onChange(value: string) {
    this.searchBy = value;
    this.pageNumber = 1;

    if (this.searchByType == undefined || this.searchByType == null) {
      this.searchByType = this.SeacrchByTypeSelected
    }
    else {
      this.searchByType = this.searchByType;
    }
    this.campaignDetail(false);
  }

  dropdownChange(value: number) {
    this.filterBy = value;
    this.pageNumber = 1;
    this.campaignDetail(false);
  }

  dropdownChangeSearchByType(type: string) {
    this.searchByType = type;
  }

  GetCandidateStatusDropdown() {
    this.smsCampaignServicesService.GetCandidateCallStatusDropdown().subscribe(response => {
      if (response.success) {
        let rec = [];

        response.data.forEach(element => {
          rec.push({
            id: element.id,
            name: element.name
          })
        });
        this.CandidateStatus = rec
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }
}
