/*
* @ngdoc Config
* @name app.configs.ApiUrls
* @description
* The service is used to define all the Api urls
* */

export class ApiUrls {
    UserManagment = {
        Login: 'Authentication/Token',
        ForgetPassword: 'Account/ForgotPassword'
    };
    Subscriber = {
        SubscriberListing: 'Subscriber/GetSubscriberLisitng',
        AddSubscriber: 'Account/SubscriberSignUp',
        UpdateSubscriber: 'Subscriber/EditSubscriber',
        SubscriberDropdown: 'Subscriber/GetSubscriberDropdown'
    };
    SuperSubAdmin = {
        SuperSubAdminListing: 'SuperSubAdmin/GetAllSuperSubAdmin',
        AddSuperSubAdmin: 'Account/SuperSubAdminSignUp',
        UpdateSuperSubAdmin: 'SuperSubAdmin/EditSuperSubAdmin',
        GetRoleTypeDropdown: 'SuperAdmin/GetRoleTypeDropdown'
    };

    Recruiter = {
        RecruiterListing: 'Recruiter/GetRecruiterLisitng',
        SkillDropDown: 'Recruiter/GetSkillDropdown',
        AddRecruiter: 'Account/RecruiterSignUp',
        UpdateRecruiter: 'Recruiter/EditRecruiter',
        RecruiterDropdown: 'Recruiter/GetRecruiterDropdown'
    };
    Skill = {
        SkillListing: 'Skill/GetSkillLisitng',
        AddSkill: 'Skill/AddSkill',
        EditSkill: 'Skill/EditSkill',
        DeleteSkill: 'Skill/DeleteSkill'
    };
    Campaign = {
        CreateCampaign: 'Campaign/CreateCampaign',
        CampaignListing: 'Campaign/CampaignListing',
        CampaignDetail: 'Campaign/CampaignDetail',
        UpdateCampaignStatus: 'Campaign/UpdateCampaignStatus',
        BlacklistCandidate: 'Campaign/BlacklistCandidate',
        GetCandidateCallStatusDropdown: 'Campaign/GetCandidateCallStatusDropdown',
        UpdateCampaign: 'Campaign/UpdateCampaign',
        RoleManagerDropdown: 'Campaign/GetCompanyFirstLevelUsers',
        GetCandidateDetailsForFrontEnd: 'Campaign/GetCandidateDetailsForFrontEnd',
        GenerateTokenForCall: 'Campaign/GenerateTokenForTwilioCalls',
        AddNumbers: 'Campaign/addNumbers',
        HangUpCall: 'Campaign/HangUpCall',
        GetEmployeeDropDownForCallMerge: 'Campaign/GetEmployeeDropDownForCallMerge',
        MuteAndUnmute: 'Campaign/muteAndUnmuteUsers',
        RemoveParticipantsUsers: 'Campaign/removeParticipantsUsers',
        CheckedValueForCallDuration: 'Campaign/CheckedValueForCallDuration',
        CreateRecording: 'Campaign/CreateRecording',
        DeleteRecording: 'Campaign/DeleteRecording',
        GetCallingAvailability: 'Campaign/CheckedAvailableTimeForUserForCall',
        GenerateTokenForClientCall: 'Campaign/GenerateTokenForTwilioClientCalls',
        UpdateCampaignStatusWithCampaign: 'Campaign/UpdateCampaignStatusWithCall',
        DirectCandidateCallingListing: 'Campaign/DirectCandidateCallingListing',
        CreateCandidateDirectCall: 'Campaign/CreateCandidateDirectCalling',
        UpdateCandidateDirectCallFormRequest: 'Campaign/UpdateDirectCallingCandidate',
        SMSCampaignListing: 'Campaign/SMSCampaignListing',
        CreateSMSCamapignFormRequest :'Campaign/CreateSMSCampaign',
        SMSCampaignDetail :'Campaign/SMSCampaignDetail',
        UpdateSMSCamapign :'Campaign/UpdateSMSCampaign',
        UpdateSMSCampaignStatus:'Campaign/UpdateSMSCampaignStatus',
        EmailCampaignListing: 'Campaign/EmailCampaignListing',
        CreateEmailCamapignFormRequest :'Campaign/CreateEmailCampaign',
        UpdateEmailCamapign :'Campaign/UpdateEmailCampaign',
        EmailCampaignDetail:'Campaign/EmailCampaignDetail',
        UpdateEmailCampaignStatus:'Campaign/UpdateEmailCampaignStatus',
        DisconnectedCall:'Campaign/GetDisconnectedUsers'
    };
    Inbound = {
        InboundListing: 'Campaign/InboundCallListing'
    };
    CallForwarding = {
        CallForwarding: 'Account/CallForwarding'
    };
    Shared = {
        CreatePassword: 'Account/CreatePassword',
        ChangePassword: 'Account/ChangePassword',
        DeleteUser: 'Account/UpdateUserStatus',
        BlockUser: 'Account/UpdateUserStatus',
        GetUserDetails: 'Account/GetUserDetails',
        UpdateUserProfile: 'Account/UpdateUserProfile',
        CandidateSearch: 'Account/CandidateSearch',
        SMSOptOut: 'Account/SMSOptOut',
        BlockStandardPackage: 'SuperAdmin/UpdatePackageStatus',
        BlockCompany: 'SuperAdmin/UpdateCompanyStatus',
        DeleteStandardPackage: 'SuperAdmin/UpdatePackageStatus',
        GetMenuDetails: 'Account/GetMenuDetails',
        DeleteComponentPlan: 'SuperAdmin/UpdateComponentPlanStatus',
        DeleteCompany: 'SuperAdmin/UpdateCompanyStatus',
        BlockComponentPlan: 'SuperAdmin/UpdateComponentPlanStatus',
        DeleteCustomizePackage: 'CompanyAdmin/UpdateCustomizePackageStatus',
        BlockApprovedCustomizedPackage: 'SuperAdmin/UpdateApprovedCustomizedPackageStatus',
        StandardAndCustomizeStripePayment: 'Account/StandardAndCustomizeStripePayment',
        BlockUserPermission: 'SuperAdmin/BlockUserPermission',
        GetAddEditDeletePermission: 'Account/GetAddEditDeletePermission',
        GetUserDetailsbYId: 'Account/GetUserDetailsbYId',
        GetWebCallerDetails: 'Campaign/GetWebCallingUserDetails',
        GetDashBoardDetails: 'Campaign/GetDashboardDetails',
        BlockDirectCallingCandidateStatus: 'SuperAdmin/UpdateStatusDirectCallingCandidates',
        DeleteCandidate: 'SuperAdmin/UpdateStatusDirectCallingCandidates',
        SendSms:'Campaign/SMSCampaignListingForSendSMS',
        SendEmail:'Campaign/EmailCampaignListingForSendEmail'
    };

    SuperAdmin = {
        CompanyPakageListing: 'SuperAdmin/CompanyPakageListing',
        AddstandardPackage: 'SuperAdmin/AddStandardPackage',
        EditStandardPackage: 'SuperAdmin/EditStandardPackage',
        CompanyListing: 'SuperAdmin/CompanyListing',
        GetPurchasedPlanBasicDetails: 'SuperAdmin/GetPurchasedPlanBasicDetails',
        GetCompanyDetailsById: 'SuperAdmin/GetCompanyDetailsById',
        GetPurchasdPlanComponentForCompanyDetailsDetails: 'SuperAdmin/GetPurchasdPlanComponentForCompanyDetailsDetails',
        AddCompany: 'SuperAdmin/AddCompany',
        EditCompany: 'SuperAdmin/EditCompany',
        CountryDropdown: 'SuperAdmin/GetCountry',
        GetMainComponentDropdown: 'SuperAdmin/GetMainComponentDropdown',
        GetComponentTypeDropdown: 'SuperAdmin/GetComponentTypeDropdown',
        ComponentPlanListing: 'SuperAdmin/ComponentPlanListing',
        AddComponentPlan: 'SuperAdmin/AddComponentPlan',
        AddComponentType: 'SuperAdmin/AddComponentType',
        EditComponentPlan: 'SuperAdmin/EditComponentPlan',
        GetComponentPlanCalculate: 'SuperAdmin/GetComponentPlanCalculate',
        CustomizeApprovedPackageSuperAdminListing: 'SuperAdmin/CustomizeApprovedListing',
        AddCustomizeApprovedPackage: 'SuperAdmin/AddCustomizeApprovedPackage',
        EditCustomizeApprovedPackage: 'SuperAdmin/EditCustomizeApprovedPackage',
        GetComponentDetails: 'SuperAdmin/GetMainAndSubComponentDetails',
        AddPackageArrayDetails: 'SuperAdmin/AddPackageArrayDetails',
        GetComponentInsertedData: 'SuperAdmin/GetComponentInsertedData',
        PurchasedPackageManagementListing: 'SuperAdmin/PackagePurchaseListing',
        GetRequestedBasicDetails: 'SuperAdmin/GetRequestedBasicDetails',
        MainPermissonListing: 'SuperAdmin/GetMainPermissionListing',
        GetMainListingForAddition: 'SuperAdmin/GetMainListingForAddition',
        AddMainPermission: 'SuperAdmin/AddMainPermission',
        AddChildPermission: 'SuperAdmin/AddMChildPermission',
        GetchildListingById: 'SuperAdmin/GetChildListingById',
        EditMainPermission: 'SuperAdmin/EditMainPermission',
        EditChildPermission: 'SuperAdmin/EditChildPermission',
        CompanyTypeDropdown: 'SuperAdmin/GetCompanyType',
        StripePlanIdDropdown: 'SuperAdmin/StripePlanIdDropdown'
    };

    CompanyAdmin = {
        CustomizePackageListing: 'CompanyAdmin/CustomizePakageRequestListing',
        AddCustomizePackage: 'CompanyAdmin/AddCustomizePackage',
        AddCustPackageComment: 'CompanyAdmin/AddCustPackageComment',
        CustomizePackageCommentsListing: 'CompanyAdmin/CustomizePackageCommentsListing',
        CompanyDropDown: 'CompanyAdmin/CompanyDropDown',
        ViewAndMailSentCustomized: 'CompanyAdmin/ViewAndMailSentCustomizedPlan',
        SuperAdminEmailId: 'CompanyAdmin/SuperAdminEmailId',
        StandardPackageMyPackgeListing: 'CompanyAdmin/GetStandardPackagesMyPlanListing',
        CustomizePackageMyPackgeListing: 'CompanyAdmin/GetCustomizeCompanyPackagesMyPlanListing',
        PurchasedPackageListing: 'CompanyAdmin/GetPurchasedCompanyPackagesListing',
        AddbuyRequiredPackage: 'CompanyAdmin/AddbuyRequiredPackage',
        BuyRequiredPackageListing: 'CompanyAdmin/BuyRequestPackageListing',
        SubcriberAllocatedMinutesListing: 'CompanyAdmin/GetAllocateMinutesToSubscriberListing',
        getComponentDetailsForMintsAllocationForSubscriber: 'CompanyAdmin/GetComponentForAllocateMinsToSubscriber',
        addAllocateMintsToSubscriber: 'CompanyAdmin/AddAllocateMinsToSubscriber',
        addAllocateMinsSubscriberArrayDetails: 'CompanyAdmin/AddAllocateMinsSubscriberArrayDetails',
        GetComponentInsertedAllocatedUnitsData: 'CompanyAdmin/GetComponentInsertedAllocatedUnitsData',
        EditAllocateMintsToSubscriber: 'CompanyAdmin/EditAllocateMintsToSubscriber',
        getPurchasedPackageOrNot: 'CompanyAdmin/GetPurchasedPackageOrNot',
        MainPermissonListingForCompAdmin: 'CompanyAdmin/GetMainPermissionForCompAdminListing',
        GetmainListingForCompanyAdminAddition: 'CompanyAdmin/GetmainListingForCompanyAdminAddition',
        AddMainPermissionByCompanyAdmin: 'CompanyAdmin/AddMainPermissionByCompanyAdmin',
        AddChildPermissionByCompanyAdmin: 'CompanyAdmin/AddChildPermissionByCompanyAdmin',
        GetchildListingCompAdminById: 'CompanyAdmin/GetchildListingCompAdminById',
        EditMainPermissionCompAdmin: 'CompanyAdmin/EditMainPermissionCompAdmin',
        EditChildPermissionCompAdmin: 'CompanyAdmin/EditChildPermissionCompAdmin',
        GetRoleDropdown: 'CompanyAdmin/GetRoleDropdown',
        GetRoleChange: 'CompanyAdmin/GetRoleChange',
        UserDropDownForStaff: 'CompanyAdmin/UserDropDownForStaff',
        RecruiterAllocatedMinutesListing: 'CompanyAdmin/GetAllocateMinutesToRecruiterListing',
        getComponentDetailsForMintsAllocationForRecruiter: 'CompanyAdmin/GetComponentForAllocateMinsToRecruiter',
        addAllocateMintsToRecruiter: 'CompanyAdmin/AddAllocateMinsToRecruiter',
        EditAllocateMintsToRecruiter: 'CompanyAdmin/EditAllocateMintsToRecruiter',
        GetSubscriberDealloactionDropdown: 'CompanyAdmin/DealloactionMinstDropDown',
        GetComponentDetailsForMintsDeAllocationForSubscriber: 'CompanyAdmin/GetComponentForAvailableMinsToSubscriber',
        AddDeAllocateMintsToSubscriber: 'CompanyAdmin/AddDeAllocateMintsToSubscriber',
        AddDeAllocateMinsSubscriberArrayDetails: 'CompanyAdmin/AddDeAllocateMinsSubscriberArrayDetails',
        GetPurchasePackageAndAllocateandUsedListing: 'CompanyAdmin/GetPurchasePackageAndAllocateandUsedListing',
        GetAllocateManagerMinsListing: 'CompanyAdmin/GetAllocatedMinsManagers',
        StandardPackageMyPackgeProRateListing: 'CompanyAdmin/StandardPackageMyPackgeProRateListing',
        SubcriberDeAllocatedMinutesListing: 'CompanyAdmin/GetDeAllocateMinutesToSubscriberListing',
        GetComponentDeallocateInsertedData: 'CompanyAdmin/GetComponentInsertedDeAllocatedUnitsData'
    };
}