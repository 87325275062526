/**
  * @ngdoc Services
  * @name app.services.common
  * @description
  * The common service is used for getting and setting common vars used across the application
  * */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material';
import { JwtService } from './jwt.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    public snackBar: MatSnackBar,
    public router: Router,
    public titleService: Title,
    private jwtService: JwtService
  ) { }


  /**
  * logging console data
  * @name logging
  * @param  none
  * @return {String}
  */
  logging(data) {
    console.log(data);
  }

  currentUrl() {
    return this.router.url
  }


  transErrorResponse(response) {
    response = response ? response.json() : {};
    return response;
  }

  /**
  * Show snakebar message
  * @name showSnakeBar
  * @param  msg{String,action:action to show}
  * @return none
  */
  showSnakeBar(msg, action = 'Done') {
    this.snackBar.open(msg, action, {
      duration: 3000
    });
  }

  showSnakeMinuteAllocatedBar(msg, action = 'Done') {
    this.snackBar.open(msg, action, {
      duration: 3000
    });
  }

  /**
  * Showing common error message
  * @name commonSnakeBar
  * @param  none
  * @return none
  */
  commonSnakeBar() {
    this.showSnakeBar('An error occurred while fetching the response. Please try again.', 'Done');
  }

  callingDataNotAvailable() {
    this.showSnakeBar('Insufficient data for calling or logged in as recruiter, Kindly get the more data for this feaures', 'Done');
  }

  decimalNumberSnakeBar() {
    this.showSnakeBar('Please Insert Appropriate Value', 'Done');
  }

  allottedValueSnakeBar() {
    this.showSnakeBar('Entered Value is greater than available Unit, please upgrade your package to increase this limit."', 'Done');
  }

  alredyPurchaePackageSnakeBar() {
    this.showSnakeBar('Unable to update because already purchased', 'Done');
  }




  /**
  * Showing common error message
  * @name commonSnakeBar
  * @param  none
  * @return none
  */
  requiredValidationSnakeBar() {
    this.showSnakeBar('Audio, Video and SMS, Kindly fill one of the these', 'Done');
  }


  /**
  * navigate user to given url
  * @name navigate
  * @param  url {String}
  * @return none
  */
  navigate(url) {
    this.router.navigate([url]);
  }


  navigateSkipLocation(url) {
    this.router.navigateByUrl(url, { skipLocationChange: true });
  }

  /**
  * set page title
  * @name title
  * @param  {String}
  * @return none
  */
  setTitle(title) {
    // let appName = this.appSettings.APP_NAME;
    // let pageTitle = appName;
    // if (title.length > 0) {
    //   pageTitle = pageTitle + " | " + title;
    // }
    // this.titleService.setTitle(pageTitle);
  }

  /**
  * Send user to given route
  * @name sendUserTo
  * @param  link:String,params:Object
  * @return None
  */
  sendUserTo(link = 'dashboard', params = {}) {
    this.router.navigate([link], { queryParams: params })
  }

  /**
  * Transform date
  * @name transFormDate
  * @param  date, format(Date Format)
  * @return String
  */
  transFormDate(date, format = null) {
    // let dateFormat = this.appSettings.COMMON_DATE_FORMAT;
    // if (format != null) {
    //   dateFormat = format;
    // }
    // return this.date.transform(date, dateFormat);
  }

  checkTokenValidity(error: any) {
    if (error.status == 401) {
      this.jwtService.deleteToken();
      this.showSnakeBar("Your token has been expired. Please login again.")
      this.navigate("/login");
      location.reload();

      // this.router    
      // .navigateByUrl("/RefrshComponent", { skipLocationChange: true })
      // .then(() => this.router.navigate(["/login"]));
    }
    else {
      this.commonSnakeBar();
    }
  }

  conferenceNotCreatedYet(error: any) {
    // this.showSnakeBar('Caller is not ready yet.. wait for the connecting to the caller', 'Done');
    this.showSnakeBar(error, 'Done');
  }

  conferenceNotCreatedClientSideYet() {
    this.showSnakeBar('Caller is not ready yet.. wait for connecting to the caller or close the screen', 'Done');
  }

  userisAlreadyinCall() {
    this.showSnakeBar('User is already in call', 'Done');
  }

  cardDetailsRequired() {
    this.showSnakeBar('Please fill all card details', 'Done');
  }

  userDeActivatedOrDeleted() {
    console.trace('user admin')
    this.showSnakeBar('User deactivated or deleted', 'Done');
  }

}