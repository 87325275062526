import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SharedService } from '../service/shared.service';
import { CommonService } from '../../core/services/common.service';
import { JwtService } from '../../core/services/jwt.service';
import { DataService } from '../../core/services/data.service';


@Component({
  selector: 'app-profile-modal',
  templateUrl: './profile-modal.component.html',
  styleUrls: ['./profile-modal.component.scss']
})

export class ProfileModalComponent implements OnInit {
  public updateProfileFrom: FormGroup;

  userData: any

  email: string
  name: string
  phone: number
  imageUrl: string
  userId: number
  previewImg: any = '';
  selectedFile: File
  fileName: string

  isProcessing: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ProfileModalComponent>,
    private fromBuilder: FormBuilder,
    private sharedService: SharedService,
    private commonService: CommonService,
    private jwtServie: JwtService,
    private dataService: DataService,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.userData = data;
    this.previewImg = (data && data.userInfo && data.userInfo.image) ? (data.userInfo.image) : '';
    this.email = this.userData.userInfo.email
    this.name = this.userData.userInfo.username
    this.phone = this.userData.userInfo.number
    this.imageUrl = this.userData.userInfo.image

    this.userId = this.jwtServie.getCurrentUserId();
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.updateProfileFrom = this.fromBuilder.group({
      'Name': [this.name, [Validators.required]],
      'Phone': [this.phone, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]]
    })
    // 'Email': { value: this.email, disabled: true },

  }

  updateProfile(form) {

    setInterval(() => 1000);
    if (form.valid) {
      try {
        this.isProcessing = true;
        const formData = new FormData();

        if (this.selectedFile != null || this.selectedFile != undefined) {
          if (this.selectedFile.name.split('.').pop() != "jpg" && this.selectedFile.name.split('.').pop() != "png") {
            this.commonService.showSnakeBar("Only jpg and png file extension allowed");
            this.isProcessing = false;
            return;
          }
          else {
            formData.append(this.selectedFile.name, this.selectedFile);
          }
        }

        formData.append("UserId", this.userId.toString());
        formData.append("Name", this.updateProfileFrom.controls.Name.value.trim());
        formData.append("Phone", this.updateProfileFrom.controls.Phone.value.trim());


        // let params = {
        //   UserId: this.userId,
        //   Name: this.updateProfileFrom.controls.Name.value.trim(),
        //   Phone: this.updateProfileFrom.controls.Phone.value.trim(),
        //   UserImage: this.selectedFile

        // }

        this.sharedService.updateUserProfileFormRequest(formData).subscribe(response => {
          if (response.success) {
            this.commonService.showSnakeBar(response.message);
            this.closeDialog();
            this.dataService.SetMessage(true);
            this.isProcessing = false;
          }
          else {
            this.commonService.showSnakeBar(response.message);
            this.isProcessing = false;
          }
        }, (err) => {
          this.commonService.commonSnakeBar();
          this.isProcessing = false;
        })
      }
      catch {
        this.isProcessing = false;
        this.commonService.commonSnakeBar();
      }
    }
    else {
      this.commonService.showSnakeBar("Form not valid");
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onFileChanged(event) {
    this.selectedFile = event.target.files[0]
    const reader = new FileReader();
    let _this = this;
    reader.addEventListener("load", function () {
      _this.previewImg = reader.result;
    }, false);
    if (this.selectedFile) {
      reader.readAsDataURL(this.selectedFile);
    }
    this.fileName = this.selectedFile["name"];
  }

}
