import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CommonService } from '../../core/services/common.service';
import { SharedService } from '../service/shared.service';
import { StatusMaster } from '../../core/constants/status';
@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent implements OnInit {
  userId: number
  statusId: Number
  userData: any




  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDeleteComponent,
    private commonService: CommonService,
    private sharedService: SharedService,
    private dialogRef: MatDialogRef<ConfirmDeleteComponent>
  ) {
    this.userData = this.data

    this.userId = this.userData.userId
    this.statusId = StatusMaster.Deleted
  }

  ngOnInit() {
  }

  delete() {
    let param = {
      userid: this.userId,
      statusId: this.statusId
    }
    try {
      this.sharedService.deleteUser(param).subscribe(response => {
        if (response.success) {
          this.commonService.showSnakeBar("User deleted successfully.");
          this.closeDialog();
        }
        else {
          this.commonService.showSnakeBar(response.message);
          this.closeDialog();
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
        this.closeDialog();
      })
    }
    catch{
      this.commonService.commonSnakeBar();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
