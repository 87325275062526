import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { SharedService } from '../service/shared.service';
import { CommonService } from '../../core/services/common.service';
import { StatusMaster } from '../../core/constants/status';

@Component({
  selector: 'app-block-approved-customize-package',
  templateUrl: './block-approved-customize-package.component.html',
  styleUrls: ['./block-approved-customize-package.component.scss']
})
export class BlockApprovedCustomizePackageComponent implements OnInit {

  Id: null
  statusId: number
  userStatus: string
  userData: any
  userStatusId: number
  statusMstr: any = StatusMaster
  purchaseId: number

  constructor(@Inject(MAT_DIALOG_DATA) public data: BlockApprovedCustomizePackageComponent,
    private sharedService: SharedService,
    private commomService: CommonService,
    private dialogRef: MatDialogRef<BlockApprovedCustomizePackageComponent>) {
    this.userData = data;
    this.Id = this.userData.packageDetails.id
    this.userStatus = this.userData.packageDetails.status
    this.userStatusId = this.userData.packageDetails.statusId
    this.purchaseId = this.userData.packageDetails.purchased

    if (this.userStatusId == this.statusMstr.InActive) {
      this.statusId = this.statusMstr.Active
    }
    else if (this.userStatusId == this.statusMstr.Active) {
      this.statusId = this.statusMstr.InActive
    }
  }

  ngOnInit() {
  }

  block() {
    if (this.purchaseId == 0) {
      try {
        let parms = {
          id: this.Id,
          statusId: this.statusId
        }
        this.sharedService.blockApprovedCustomizedPackage(parms).subscribe(response => {
          if (response.success) {
            this.commomService.showSnakeBar("Package status has been updated successfully");
            this.closeDialog();
          }
          else {
            this.commomService.showSnakeBar(response.message);
          }
        }, (err) => {
          this.commomService.checkTokenValidity(err);
        })
      }
      catch{
        this.commomService.commonSnakeBar();
      }
    }
    else {
      this.commomService.alredyPurchaePackageSnakeBar();
    }

  }

  closeDialog() {
    this.dialogRef.close();
  }

}
