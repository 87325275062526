import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AuthService } from '../../core/services/auth.service';
import { CommonService } from '../../core/services/common.service';
import { SharedService } from '../../shared/service/shared.service';
import { JwtService } from '../../core/services/jwt.service';
import { ActivatedRoute } from '@angular/router';
import { AppSettings } from '../../config/app-settings';
import { StatusMaster } from '../../core/constants/status';
import { DirectCallingServicesService } from '../services/direct-calling-services.service';
import { CreateDirectCallingCandidateComponent } from '../create-direct-calling-candidate/create-direct-calling-candidate.component';
import { EditDirectCallingCandidateComponent } from '../edit-direct-calling-candidate/edit-direct-calling-candidate.component';
import { BlockDirectCallCandidateComponent } from '../../shared/block-direct-call-candidate/block-direct-call-candidate.component';
import { ConfirmCandiateDirectCallDeleteComponent } from '../../shared/confirm-candiate-direct-call-delete/confirm-candiate-direct-call-delete.component';
import { DirectDialListComponent } from '../direct-dial-list/direct-dial-list.component';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { ConfirmWebCallAcceptdialogComponent } from '../../shared/confirm-web-call-acceptdialog/confirm-web-call-acceptdialog.component';

declare const Twilio: any;
@Component({
  selector: 'app-direct-calling-list',
  templateUrl: './direct-calling-list.component.html',
  styleUrls: ['./direct-calling-list.component.scss']
})
export class DirectCallingListComponent implements OnInit {

  displayedColumns: string[] = ['Id', 'Name',
    'Email', 'Phone', 'CallStatus', 'Status', 'Action'];
  public manualPaging: boolean = false

  dataSource = [];
  audio: any = new Audio();
  playingId: number
  isAudioPlayed: boolean = false;
  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  isLoadMore: boolean = false
  Status: any = StatusMaster;
  NoRecFound: boolean = false;
  fullControl: boolean = true;
  views: boolean = true;
  adds: boolean = true;
  edits: boolean = true;
  deletes: boolean = true;
  disableds: boolean = false;
  finalToken: any
  userId: any
  popupResult: boolean = false;
  popupResults: boolean = false;
  device: any
  noRecordFoundNotCon: boolean = false
  callingUserName: any
  userDetails = []
  statusId: number
  
  constructor(public dialog: MatDialog,
    private authService: AuthService,
    private commonService: CommonService,
    private sharedService: SharedService,
    private jwtService: JwtService,
    private route: ActivatedRoute,
    private directCallingServicesService: DirectCallingServicesService,
    private appSettings: AppSettings) {
      this.getuserDetails();
     }

  ngOnInit() {
    this.getDirectCandidateCallListing(false);
    const role = this.jwtService.getUserRole();
    if (role != 'CompanyAdmin') {
      this.getAddEditDeletePermission();
    }
  }

  getDirectCandidateCallListing(isPaging: boolean) {
    try {
      let params
      if (this.manualPaging == true && isPaging != true) {
        this.pageNumber = 1
        params = {
          PageNumber: this.pageNumber,
          PageSize: this.pageSize
        }
      }
      else {
        params = {
          PageNumber: this.pageNumber,
          PageSize: this.pageSize
        }
      }

      this.directCallingServicesService.directCandidateCallingListing(params).subscribe(response => {
        if (response.success) {

          let len = response.data.length;
          if (len > 0) {
             this.noRecordFoundNotCon = true
           }
           else {
             this.noRecordFoundNotCon = false
           }

          if (response.data.length < this.pageSize) {
            this.isLoadMore = false;
          }
          else {
            this.isLoadMore = true;
          }

          if (isPaging) {
            this.appendDirectCandidateCallListing(response.data);
          }
          else {
            this.dataSource = response.data;
          }
          // console.log(this.dataSource)
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch{
      this.commonService.commonSnakeBar();
    }
  }

  appendDirectCandidateCallListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.dataSource.length > 0) {
        newState = this.dataSource.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.dataSource = newState;
    }
  }

  loadMore() {
    this.pageNumber = this.pageNumber + 1
    this.pageSize = this.pageSize
    this.getDirectCandidateCallListing(true);
  }

  CreateCandidateDirectCallingModal(): void {

    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(CreateDirectCallingCandidateComponent, {
        disableClose: true,
        panelClass: "full-width-popup"
      });
  
      dialogRef.afterClosed().subscribe(result => {
        this.getDirectCandidateCallListing(false);
      })
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }
   
  }

  EditCandidateDirectCallModal(candidateDetails): void {

    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(EditDirectCallingCandidateComponent, {
        disableClose: true,
        panelClass: "full-width-popup",
        data: {
          candidateDetails
        }
  
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.getDirectCandidateCallListing(false);
  
        }
      })
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }
 
  }

  onChange(enable: boolean, candidateDetails) {
    if (this.statusId == 10) {
	    const dialogRef = this.dialog.open(BlockDirectCallCandidateComponent, {
        width: '600px',
        height: 'auto',
        data: {
          candidateDetails
        }
      })
  
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.getDirectCandidateCallListing(false);
        }
      })
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }

  }

  deleteCandidateConfirmModal(candidateDetails): void {
    if (this.statusId == 10) {
	    const dialogRef = this.dialog.open(ConfirmCandiateDirectCallDeleteComponent, {
        width: '600px',
        height: 'auto',
        data: {
          candidateDetails
        }
      })

      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.getDirectCandidateCallListing(false);
  
        }
      })
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }

  };

  playAudio(element) {
    if (this.statusId == 10) {
	    if (element.id == this.playingId && this.isAudioPlayed) {
        this.audio.pause();
        this.isAudioPlayed = false
      }
      else {
        var index = this.dataSource.findIndex(ds => ds.id == element.id);
  
        if (index != -1) {
          this.audio.src = element.recordingURL;
          this.audio.load();
          this.audio.play();
          this.playingId = element.id
          this.isAudioPlayed = true;
        } else {
          this.commonService.showSnakeBar("Audio not found");
        }
      }
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }

  }

  StartSecondFrontEndCallModal(element): void {
    if (this.statusId == 10) {
      if (this.statusId == 10) {
        let candidateDetail = {
          Id: element.id,
          Name: element.name,
          Phone: element.phone,
          StatusId: 13,
          Status: 'Pending',
          SpAction: "Edit"
        }
        const dialogRef = this.dialog.open(DirectDialListComponent, {
          disableClose: true,
          panelClass: ['modal-outer', 'modal-md'],
          data: {
            candidateDetail
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          //  this.getDirectCandidateCallListing();
          location.reload();
        })
      }
        else {
          this.commonService.userDeActivatedOrDeleted();
        }
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }
  
  }

  public getWebCallerDetails() {
    var promise = new Promise((resolve, reject) => {
      let params = {
        UserId: this.userId
      }
      this.sharedService.getWebCallerDetails(params).subscribe(response => {
        if (response.success) {
          if (response.data.length > 0) {
            this.callingUserName = response.data[0].name
            resolve(this.callingUserName);
          }
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    });
    return promise;
  }

  getAddEditDeletePermission() {
    let params = {
      MenuId: 26
    }
    this.sharedService.getAddEditDeletePermission(params).subscribe(response => {
      if (response.success) {
        if (response.data.length > 0) {
          this.adds = response.data[0].adds
          this.views = response.data[0].views
          this.fullControl = response.data[0].fullControle
          this.deletes = response.data[0].deletes
          this.edits = response.data[0].edit
        }

        if (this.fullControl == true || this.edits == true) {
          this.disableds = false
        }
        else {
          this.disableds = true
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  getuserDetails(): number {
    try {
      let userId = this.jwtService.getCurrentUserId();
      let param = {
        UserId: userId
      }
      this.sharedService.getUserDetails(param).subscribe(response => {
        if (response.success) {
          this.userDetails = response.data;
          this.statusId = response.data[0].statusId;
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch {
      this.commonService.commonSnakeBar();
    }
    return this.statusId;
  }

}
