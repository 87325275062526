import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { ApiUrls } from '../../config/api-urls';
import { Observable } from 'rxjs';
import { ResultModel } from '../../core/model/result.model';

@Injectable({
  providedIn: 'root'
})
export class RecruiterService {

  constructor(
    private apiService: ApiService,
    private apiUrl: ApiUrls
  ) { }


  getRecruiterListing(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Recruiter.RecruiterListing, reqModel);
  }

  getSubscriberDropdown(): Observable<ResultModel> {
    return this.apiService.get<ResultModel>(this.apiUrl.Subscriber.SubscriberDropdown);
  }

  getSkillDropDown(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Recruiter.SkillDropDown, reqModel);
  }

  addRecruiter(addModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Recruiter.AddRecruiter, addModel);
  }

  editRecruiter(editModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Recruiter.UpdateRecruiter, editModel);
  }

  getRoleDropdown(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.GetRoleDropdown, reqModel)
  }

  getRoleManagerDropdown(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.GetRoleDropdown, reqModel)
  }

  roleChange(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.GetRoleChange, reqModel)
  }

  userDropDownForStaff(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.UserDropDownForStaff, reqModel)
  }




}
