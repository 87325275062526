import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CommonService } from '../../core/services/common.service';
import { SharedService } from '../service/shared.service';
import { StatusMaster } from '../../core/constants/status';

@Component({
  selector: 'app-confirm-send-email',
  templateUrl: './confirm-send-email.component.html',
  styleUrls: ['./confirm-send-email.component.scss']
})
export class ConfirmSendEmailComponent implements OnInit {
  userId: number
  statusId: Number
  userData: any
  campaignId :any

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmSendEmailComponent,
  private commonService: CommonService,
  private sharedService: SharedService,
  private dialogRef: MatDialogRef<ConfirmSendEmailComponent>) { 
    this.userData = this.data

    this.campaignId = this.userData.campDetail.campaignId
    this.statusId = StatusMaster.Deleted
  }

  ngOnInit() {
  }

  sendEmail() {
    let param = {
      CampaignId: this.campaignId
    }
    try {
      this.sharedService.sendEmail(param).subscribe(response => {
        if (response.success) {
          this.commonService.showSnakeBar("Email sent successfully.");
          this.closeDialog();
        }
        else {
          this.commonService.showSnakeBar(response.message);
          this.closeDialog();
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
        this.closeDialog();
      })
    }
    catch{
      this.commonService.commonSnakeBar();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
