import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { StandardPackageService } from '../service/standardpackage.service';
import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AppSettings } from '../../config/app-settings';


@Component({
  selector: 'app-edit-standard-package',
  templateUrl: './edit-standard-package.component.html',
  styleUrls: ['./edit-standard-package.component.scss']
})
export class EditStandardPackageComponent implements OnInit {

  removable = true;
  public editSpForm: FormGroup;
  public isProcessing: boolean = false;
  userData: any
  packageId: any
  componentPlanData = [];
  componentInsertedPlanData = [];
  audioMins: any
  compName: any
  companyId: any
  endDate: any
  id: any
  packageName: any
  decription: any
  calCulatedPrice: any
  price: any
  priceUnit: any
  sms: any
  startDate: any
  status: any
  statusId: any
  videoMins: any
  totalPrice: number = 0
  packageTenure: any

  public smsPrice: any
  public audioPrice: any
  public videoPrice: any
  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  finalPrice: any = 0;
  componentDataForDynamicInputs = [];
  PackageTenure: any
  insetedComponentsForStandardData = [];
  numericNumberReg = '^-?[0-9]\\d*(\\.\\d{1,2})?$';
  sripePlanId: any

  constructor(private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: EditStandardPackageComponent,
    private commonService: CommonService,
    private standardPackageService: StandardPackageService,
    private datePipe: DatePipe,
    private appSettings: AppSettings,
    private dialogRef: MatDialogRef<EditStandardPackageComponent>
  ) {
    this.userData = data;
    this.id = this.userData.userDetail.id;
    this.compName = this.userData.userDetail.compName;
    this.companyId = this.userData.userDetail.companyId;
    this.endDate = this.userData.userDetail.endDate;
    this.packageName = this.userData.userDetail.packageName;
    this.calCulatedPrice = this.userData.userDetail.calCulatedPrice;
    this.priceUnit = this.userData.userDetail.priceUnit;
    this.startDate = this.userData.userDetail.startDate;
    this.status = this.userData.userDetail.status;
    this.statusId = this.userData.userDetail.statusId;
    this.price = this.userData.userDetail.price;
    this.packageTenure = this.userData.userDetail.packageTenure
    this.decription = this.userData.userDetail.description
    this.stripePlans = this.userData.userDetail.stripePlanId
  }

  initForm() {
    this.editSpForm = this.formBuilder.group({
      'PackageName': [this.packageName, [Validators.required, this.noWhitespaceValidator]],
      'Description': [this.decription],
      'AllComponent': this.formBuilder.array([]),
      'CalCulatedPrice': [{ value: this.calCulatedPrice, disabled: true }, [Validators.required, Validators.minLength(1)]],
      'Price': [this.price, [Validators.required, Validators.pattern(this.numericNumberReg)]],
      'StartDate': [this.startDate],
      'EndDate': [this.endDate],
      'Id': [this.id],
      'PackageTenure': [String(this.packageTenure), [Validators.required]],
      'StripePlan': [Number(this.stripePlans), [Validators.required]]
    });
  }

  ngOnInit() {
    this.userData = this.data;
    this.initForm();
    this.getComponentDetails();
    //   this.getStripePlanIdDropdown()
  }

  closeDialog() {
    this.dialogRef.close();
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  updateStandardPackage(form) {
    if (form.valid) {
      try {
        this.isProcessing = true;
        this.isProcessing = true
        let params = {
          Id: this.id,
          PackageName: this.editSpForm.controls.PackageName.value,
          Description: this.editSpForm.controls.Description.value,
          AudioMins: 0,
          EndDate: '',
          CalCulatedPrice: this.editSpForm.controls.CalCulatedPrice.value,
          Price: this.editSpForm.controls.Price.value,
          SMS: 0,
          StartDate: '',
          VideoMins: 0,
          PackageTenure: this.editSpForm.controls.PackageTenure.value,
          StripePlanId: this.editSpForm.controls.StripePlan.value
        }
        this.standardPackageService.editStandardPackage(params).subscribe(response => {
          if (response.success) {

            let arrayAllComponent = this.editSpForm.controls.AllComponent.value

            for (let item of arrayAllComponent) {

              let calculatedValue
              if (item.CalculatedValue != '' && item.CalculatedValue != undefined) {
                calculatedValue = item.CalculatedValue
              }
              else {
                calculatedValue = 0.00
              }

              let itemParam
              if (item.InputParameter != '' && item.InputParameter != undefined) {
                itemParam = item.InputParameter
              }
              else {
                itemParam = 0.00
              }

              let paramarr = {
                ComponentId: item.Id,
                Name: item.Name,
                Price: item.Price,
                CalculatedValue: calculatedValue,
                ComponentType: item.ComponentType,
                InputParameter: itemParam,
                LastPackageInsertedId: this.id,
                SpOptions: 'Update'
              };

              try {

                this.standardPackageService.addPackageArrayDetails(paramarr).subscribe(resposne => {
                  if (resposne.success) {
                    this.isProcessing = false;
                    //   this.commonService.showSnakeBar(resposne.message);
                    this.closeDialog();
                  }
                  else {
                    this.isProcessing = false;
                    this.commonService.showSnakeBar(resposne.message);
                  }

                }, (err) => {
                  this.isProcessing = false;
                  this.commonService.checkTokenValidity(err);
                  this.closeDialog();
                })
              }
              catch
              {
                this.isProcessing = false;
                this.commonService.commonSnakeBar();
              }
            }

            this.commonService.showSnakeBar(response.message);
            this.closeDialog();
            this.isProcessing = false;
          }
          else {
            this.commonService.showSnakeBar(response.message);
            this.isProcessing = false;
          }
        }, (err) => {
          this.commonService.checkTokenValidity(err);
          this.closeDialog();
          this.isProcessing = false;
        })
      }
      catch{
        this.commonService.commonSnakeBar();
        this.isProcessing = false;
      }
    }
    else {
      this.isProcessing = false;
      this.commonService.requiredValidationSnakeBar();
    }
  }


  public numberWithOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode == 190 || charCode == 110)) {
      return false;
    }
    return true;
  }


  public focusoutAllComponentHandler(event, id, name, price, componentType, CalculatedValue, item, index) {
    if (event.target.value.match(this.numericNumberReg) || event.target.value == '') {
      if (id) {
        let allcomponent = this.editSpForm.get('AllComponent') as FormArray;

        let c = 0
        for (let control of allcomponent.controls) {

          if (control.value.CalculatedValue != '' || control.value.CalculatedValue != undefined) {
            c = c + control.value.CalculatedValue
          }
        }

        this.finalPrice = c;

        if (id != 'calculatedText') {
          let single_price = event.target.value * price;
          allcomponent.controls[index].value.InputParameter = event.target.value;
          allcomponent.controls[index].value.CalculatedValue = single_price;
          this.editSpForm.controls.CalCulatedPrice.setValue(this.finalPrice.toFixed(2));
        }
        else {
          this.editSpForm.controls.CalCulatedPrice.setValue(this.finalPrice.toFixed(2));
        }
      }
    }
    else {
      if ((event.target.value).split('.').length - 1 == 1) {
      }
      else {
        event.target.value = 0
        if (id != 'calculatedText') {
          this.editSpForm.controls.CalCulatedPrice.setValue(0)

          this.commonService.decimalNumberSnakeBar()
        }

      }
    }

  }

  getComponentDetails() {
    let allcomponent = this.editSpForm.get('AllComponent') as FormArray;
    let params = {
      PageNumber: this.pageNumber,
      PageSize: this.pageSize,
      PackageType: 'StandardPackage',
      PackageId: this.id,
      SpOption: 'Edit',
      PurchasedOption: 'No'
    }
    this.standardPackageService.getComponentInsertedData(params).subscribe(response => {
      if (response.success) {
        let count = [];
        response.data.forEach(element => {
          allcomponent.push(this.formBuilder.control({
            ComponentId: element.componentId,
            ComponentType: element.componentType,
            ComponentTypeName: element.componentTypeName,
            InputParameter: element.inputParameter,
            Id: element.id,
            Name: element.name,
            Price: element.price,
            CalculatedValue: element.calculatedValue
          }));
          count.push({
            ComponentId: element.componentId,
            ComponentType: element.componentType,
            ComponentTypeName: element.componentTypeName,
            InputParameter: element.inputParameter,
            Id: element.id,
            Name: element.name,
            Price: element.price,
            CalculatedValue: element.calculatedValue
          })
        });

        if (count.length >= 0) {
          this.componentDataForDynamicInputs = count
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  getComponentInsertedData() {
    let params = {
      PageNumber: this.pageNumber,
      PageSize: this.pageSize,
      PackageType: 'StandardPackage'
    }
    this.standardPackageService.getComponentInsertedData(params).subscribe(response => {
      if (response.success) {
        let count = [];
        response.data.forEach(element => {
          count.push({
            componentId: element.componentId,
            componentType: element.componentType,
            id: element.id,
            name: element.name,
            price: element.price,
            calculatedValue: element.calculatedValue
          })
        });
        this.componentInsertedPlanData = count

      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }


  stripePlans = []

  getStripePlanIdDropdown() {
    this.standardPackageService.getStripePlanIdDropdown().subscribe(response => {
      if (response.success) {
        let count = [];
        response.data.forEach(element => {
          count.push({
            id: element.id,
            name: element.sripePlanName
          })
        });
        this.stripePlans = count
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }
}
