import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, RequiredValidator, FormArray } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { BuyRequiredPackageServiceService } from '../service/buy-required-package-service.service'
import { DatePipe } from '@angular/common';
import { isNull } from 'util';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { AppSettings } from '../../config/app-settings';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AddPaymentComponent } from '../../stripe-payment/add-payment/add-payment.component'

@Component({
  selector: 'app-add-buy-required-package',
  templateUrl: './add-buy-required-package.component.html',
  styleUrls: ['./add-buy-required-package.component.scss']
})
export class AddBuyRequiredPackageComponent implements OnInit {
  public addBuyRequiredForm: FormGroup
  public isProcessing: boolean = false
  companyList = [];
  componentDataForDynamicInputs = [];

  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  public lastInsertedStandardPackageId: any
  numericNumberReg = '^-?[0-9]\\d*(\\.\\d{1,2})?$';

  constructor(private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private commonService: CommonService,
    private buyRequiredPackageServiceService: BuyRequiredPackageServiceService,
    private datePipe: DatePipe,
    private appSettings: AppSettings,
    private dialogRef: MatDialogRef<AddBuyRequiredPackageComponent>) { }

  ngOnInit() {
    this.initForm()
    this.getComponentDetails();
  }

  initForm() {
    this.addBuyRequiredForm = this.formBuilder.group({
      'Name': [null, [Validators.required, this.noWhitespaceValidator]],
      'Description': [null, [this.noWhitespaceValidator]],
      'AllComponent': this.formBuilder.array([]),
      'CalCulatedPrice': [{ value: null, disabled: true }, [Validators.required, Validators.minLength(1), Validators.pattern(this.numericNumberReg)]]
    });
  }

  public addBuyRequiredPackage(form) {
    if (form.valid) {
      try {
        this.isProcessing = true;

        let params = {
          Name: this.addBuyRequiredForm.controls.Name.value.trim(),
          Description: (this.addBuyRequiredForm.controls.Description.value == '' || this.addBuyRequiredForm.controls.Description.value == null || this.addBuyRequiredForm.controls.Description.value == undefined) ? '' : this.addBuyRequiredForm.controls.Description.value,
          AudioMinutes: 0,
          VideoMinutes: 0,
          SMS: 0,
          Price: 0,
          CalculatedPrice: (this.addBuyRequiredForm.controls.CalCulatedPrice.value == '' || this.addBuyRequiredForm.controls.CalCulatedPrice.value == null || this.addBuyRequiredForm.controls.CalCulatedPrice.value == undefined) ? 0.00 : this.addBuyRequiredForm.controls.CalCulatedPrice.value
        }
        this.buyRequiredPackageServiceService.addbuyRequiredPackage(params).subscribe(resposne => {
          if (resposne.success) {
            this.lastInsertedStandardPackageId = resposne.data;
            let arrayAllComponent = this.addBuyRequiredForm.controls.AllComponent.value

            for (let item of arrayAllComponent) {
              let inputparm
              if (item.param != '' && item.param != undefined) {
                inputparm = item.param
              }
              else {
                inputparm = 0.00
              }

              let itemValue
              if (item.value != '' && item.value != undefined) {
                itemValue = item.value
              }
              else {
                itemValue = 0.00
              }

              let itemPrice
              if (item.price != '' && item.price != undefined) {
                itemPrice = item.price
              }
              else {
                itemPrice = 0.00
              }

              let paramarr = {
                ComponentId: item.id,
                Name: item.name,
                Price: itemPrice,
                CalculatedValue: inputparm,
                ComponentType: item.componentType,
                InputParameter: itemValue,
                LastPackageInsertedId: this.lastInsertedStandardPackageId,
                PackageType: 'RequiredPackage',
                SpOptions: 'Insert'
              };

              try {
                this.buyRequiredPackageServiceService.addPackageArrayDetails(paramarr).subscribe(resposne => {
                  if (resposne.success) {
                    this.isProcessing = false;
                    this.closeDialog();
                  }
                  else {
                    this.isProcessing = false;
                    this.commonService.showSnakeBar(resposne.message);
                  }
                }, (err) => {
                  this.isProcessing = false;
                  this.commonService.checkTokenValidity(err);
                  this.closeDialog();
                })
              }
              catch
              {
                this.isProcessing = false;
                this.commonService.commonSnakeBar();
              }
            }
            const dialogRef = this.dialog.open(AddPaymentComponent, {
              disableClose: true,
              panelClass: 'buying-Components',
              data: {
                PackageType: 'RequiredPackage',
                PackageId: this.lastInsertedStandardPackageId,
                SubscriptionPrice: this.addBuyRequiredForm.controls.CalCulatedPrice.value
              }
            });
            dialogRef.afterClosed().subscribe(result => {
              location.reload();
            })
            this.isProcessing = false;
            this.closeDialog();

          }
          else {
            this.isProcessing = false;
            this.commonService.showSnakeBar(resposne.message);
          }

        }, (err) => {
          this.isProcessing = false;
          this.commonService.checkTokenValidity(err);
          this.closeDialog();
        })
      }
      catch
      {
        this.isProcessing = false;
        this.commonService.commonSnakeBar();
      }
    }
  }

  finalPrice: any = 0;
  public focusoutAllComponentHandler(event, id, name, price, componentType, componentTypeName, item) {

    if (event.target.value.match(this.numericNumberReg) || event.target.value == '') {
      item.setValue({
        id: id,
        name: name,
        price: price,
        componentType: componentType,
        componentTypeName: componentTypeName,
        param: Math.round(event.target.value * price),
        value: event.target.value
      })

      let allcomponent = this.addBuyRequiredForm.get('AllComponent') as FormArray;
      let c = 0
      for (let control of allcomponent.controls) {
        if (control.value.param != '') {
          c = c + control.value.param
        }
      }
      this.finalPrice = c
      this.addBuyRequiredForm.controls.CalCulatedPrice.setValue(this.finalPrice);
    }
    else {
      if ((event.target.value).split('.').length - 1 == 1) {
      }
      else {
        event.target.value = 0
        this.addBuyRequiredForm.controls.CalCulatedPrice.setValue(0)
        this.commonService.decimalNumberSnakeBar()
      }
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  public numberWithOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode == 190 || charCode == 110)) {
      return false;
    }
    return true;
  }

  public numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public numberWithDotOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode == 190 || charCode == 110)) {
      return false;
    }
    return true;
  }

  getComponentDetails() {
    let allcomponent = this.addBuyRequiredForm.get('AllComponent') as FormArray;
    let params = {
      PageNumber: this.pageNumber,
      PageSize: this.pageSize
    }
    this.buyRequiredPackageServiceService.getComponentDetails(params).subscribe(response => {
      if (response.success) {
        let count = [];
        response.data.forEach(element => {
          allcomponent.push(this.formBuilder.control({
            id: element.id,
            name: element.name,
            price: element.price,
            componentType: element.componentType,
            componentTypeName: element.componentTypeName,
            param: "",
            enter: ""
          }));
          count.push({
            id: element.id,
            name: element.name,
            price: element.price,
            componentType: element.componentType,
            componentTypeName: element.componentTypeName
          })
        });
        if (count.length >= 0) {
          this.componentDataForDynamicInputs = count
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }
}
