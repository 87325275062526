import { Component, OnInit, Renderer2 } from '@angular/core';
import { UserService } from '../../services/user.service';
import { JwtService } from '../../../core/services/jwt.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from '../../../core/services/common.service';
import { CookieService } from 'angular2-cookie/core';
import { AuthService } from '../../../core/services/auth.service';
import { MatDialog } from '@angular/material';
import { SharedService } from '../../../shared/service/shared.service';

///declare const Twilio: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public isProcessing: boolean = false;
  device: any;
  public formError: any = {};
  hide = false;
  userId: any
  constructor(
    private renderer: Renderer2,
    private authService: AuthService,
    public dialog: MatDialog,
    private userService: UserService,
    private jwtService: JwtService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private sharedService: SharedService,
    private cookieService: CookieService,
  ) {
    this.renderer.addClass(document.body, 'bg-theme-gradient');


  }

  ngOnInit() {

    this.initForm();
    if (this.jwtService.getToken()) {
      // this.commonService.navigate('/campaign')
      const role = this.jwtService.getUserRole();

      if (role == "SuperAdmin") {
        this.commonService.navigate('/companies');
      }
      else {
        this.commonService.navigate('/campaign')
      }
    }

  }

  initForm() {
    this.loginForm = this.formBuilder.group({
      'Username': [null, [Validators.required, Validators.email]],
      'Password': [null, [Validators.required]],
      'RememberMe': ['']
    });
  }

  // ngAfterViewInit() {
  //   setTimeout(() => {
  //     if (this.cookieService.get('RememberMe')) {
  //       this.loginForm.controls.Username.setValue(this.cookieService.get('Username'));
  //       this.loginForm.controls.Password.setValue(this.cookieService.get('Password'));
  //       this.loginForm.controls.RememberMe.setValue(this.cookieService.get('RememberMe'));
  //     }
  //   });
  // }

  login(form) {
    if (form.valid) {
      // if (this.loginForm.controls.RememberMe.value) {
      //   this.cookieService.put('Username', this.loginForm.controls.Username.value);
      //   this.cookieService.put('Password', this.loginForm.controls.Password.value);
      //   this.cookieService.put('RememberMe', this.loginForm.controls.RememberMe.value);
      // }
      // else {
      //   this.cookieService.removeAll();
      // }
      // console.log(this.loginForm.controls.RememberMe.value);

      this.isProcessing = true;
      this.formError = {}
      this.userService.login(form.value).subscribe(response => {
        try {
          if (response.success) {
            this.jwtService.saveToken(response.data.access_token, this.loginForm.controls.RememberMe.value);
            const role = this.jwtService.getUserRole();
            this.userId = this.jwtService.getCurrentUserId();
            if (role == "SuperAdmin") {
              this.commonService.navigate('/companies');
            }
            else if (role == "SuperSubAdmin" || role == "Recruiter") {
              this.commonService.navigate('/profilemodal');
            }
            else if (role == "CompanyAdmin") {
              this.getPurchasedPackageOrNot()
            }
          }
          else {
            if (response.message) {
              this.commonService.showSnakeBar(response.message);
            } else {
              this.commonService.commonSnakeBar();
            }
          }
        }
        catch (e) {
          this.commonService.commonSnakeBar();
        }
        this.isProcessing = false;
      }, (err) => {
        let response = err.json();
        this.isProcessing = false;
        if (response.message) {
          if ('errors' in response.errors) {
            this.handleServerFormError(response.errors)
          }
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      }, () => {
        this.isProcessing = false;
      })
    }
    else {
      this.formValidate(form);
    }
  }

  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.loginForm.contains(key)) {
          let control = this.loginForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }
  }

  formValidate(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.formValidate(control);
      }
    });
  }


  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'bg-theme-gradient');
  }

  // login() {
  //   // [routerLink]="['/campaign']"
  //   let reqModel = { Username: "akshdeep.patrai@softobiz.com", Password: "Welcome@12" }

  //   this.userService.login(reqModel).subscribe((res) => {
  //     console.log(res);
  //     if (res.success) {
  //       this.jwtService.saveToken(res.data.access_token)
  //       this.router.navigate(['/campaign']);
  //     }
  //     else {
  //     }
  //   }, (error) => {
  //     alert(error);
  //   })
  // }


  getPurchasedPackageOrNot(): boolean {
    try {
      let params = {
        PageNumber: 1,
        PageSize: 20
      }
      this.userService.getPurchasedPackageOrNot(params).subscribe(response => {
        if (response.data.length > 0) {
          this.commonService.navigate('/campaign')
          return true;
        }
        else {
          this.commonService.navigate('/mypackages')
          return false;
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
        return false;
      })
    } catch {
      this.commonService.commonSnakeBar();
      return false;
    }
  }

  popupResult: boolean = false;
  popupResults: boolean = false;
  finalToken: any
  callingUserName: any
  public getWebCallerDetails() {
    var promise = new Promise((resolve, reject) => {
      let params = {
        UserId: this.userId
      }
      this.sharedService.getWebCallerDetails(params).subscribe(response => {
        if (response.success) {
          if (response.data.length > 0) {
            this.callingUserName = response.data[0].name
            resolve(this.callingUserName);
          }
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    });
    return promise;
  }


}
