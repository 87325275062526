import { CustomizePackageSuperAdminserviceService } from '../service/customize-package-super-adminservice.service'
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { AppSettings } from '../../config/app-settings';
import { EditNewCustomizePackageSuperAdminComponent } from '../edit-new-customize-package-super-admin/edit-new-customize-package-super-admin.component';

@Component({
  selector: 'app-customize-package-super-admincomment-list',
  templateUrl: './customize-package-super-admincomment-list.component.html',
  styleUrls: ['./customize-package-super-admincomment-list.component.scss']
})
export class CustomizePackageSuperAdmincommentListComponent implements OnInit {

  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  isLoadMore: boolean = false
  NoRecFound: boolean = false;
  NoPackageFound: boolean = false;

  addCustPackForm: FormGroup
  displayedColumns: string[] = ['Id', 'Comment',
    'CompName', 'CreatedName', 'CreatedDate'];

  dataSource = [];

  spanOption: boolean = true;
  isProcessing: boolean = false;
  spanOptionToggle() {
    this.spanOption = !this.spanOption;
  }

  public ListingData: any
  public AllStandardPackages = [];
  public customizePackageId: number
  constructor(private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: CustomizePackageSuperAdmincommentListComponent,
    public dialog: MatDialog,
    private commonService: CommonService,
    private customizePackageSuperAdminserviceService: CustomizePackageSuperAdminserviceService,
    private datePipe: DatePipe,
    private appSettings: AppSettings,
    private dialogRef: MatDialogRef<CustomizePackageSuperAdmincommentListComponent>) {
    this.ListingData = data;
    this.customizePackageId = this.ListingData.planDetail.customizePackageId
  }

  ngOnInit() {
    this.initForm()
    this.getCustomizePackageCommentsListing(false);
  }

  initForm() {
    this.addCustPackForm = this.formBuilder.group({
      'Comment': [null, [Validators.required]],

    });
  }

  getCustomizePackageCommentsListing(isPaging: boolean) {
    try {
      let params = {
        PageNumber: this.pageNumber,
        PageSize: this.pageSize,
        CustomizePackageId: this.customizePackageId
      }
      this.customizePackageSuperAdminserviceService.getCustomizePackageCommentsListing(params).subscribe(response => {
        if (response.success) {
          this.dataSource = response.data;
          if (response.data.length < this.pageSize) {
            this.isLoadMore = false;
          }
          else {
            this.isLoadMore = true;
          }

          if (response.data.length <= 0) {
            this.NoPackageFound = true;
          }
          else {
            this.NoPackageFound = false;
          }

          if (isPaging) {
            this.appendCustomizePackageListing(response.data);
          }
          else {
            this.dataSource = response.data;
          }
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch{
      this.commonService.commonSnakeBar();
    }
  }

  appendCustomizePackageListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.dataSource.length > 0) {
        newState = this.dataSource.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.dataSource = newState;
    }
  }

  loadMore() {
    this.pageNumber = this.pageNumber + 1
    this.pageSize = this.pageSize
    this.getCustomizePackageCommentsListing(true);
  }

  public addCustomizePackgeComment(form) {
    if (form.valid) {
      if (form.valid) {
        try {
          this.isProcessing = true;
          let params = {
            Comment: this.addCustPackForm.controls.Comment.value.trim(),
            CustomizePackageId: this.customizePackageId
          }
          this.customizePackageSuperAdminserviceService.addCustPackageComment(params).subscribe(resposne => {
            if (resposne.success) {
              this.isProcessing = false;
              this.addCustPackForm.get('Comment').patchValue([]);
              this.commonService.showSnakeBar(resposne.message);
              this.getCustomizePackageCommentsListing(false)

            }
            else {
              this.isProcessing = false;
              this.commonService.showSnakeBar(resposne.message);
            }

          }, (err) => {
            this.isProcessing = false;
            this.commonService.checkTokenValidity(err);
          })
        }
        catch
        {
          this.isProcessing = false;
          this.commonService.commonSnakeBar();
        }
      }
    }
  }



}
