import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CommonService } from '../../core/services/common.service';
import { SharedService } from '../service/shared.service';
import { StatusMaster } from '../../core/constants/status';

@Component({
  selector: 'app-confirm-package-delete',
  templateUrl: './confirm-package-delete.component.html',
  styleUrls: ['./confirm-package-delete.component.scss']
})
export class ConfirmPackageDeleteComponent implements OnInit {
  userId: number
  statusId: Number
  userData: any
  packageId: null

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmPackageDeleteComponent,
  private commonService: CommonService,
  private sharedService: SharedService,
  private dialogRef: MatDialogRef<ConfirmPackageDeleteComponent>) { 
    this.userData = this.data
    this.statusId = StatusMaster.Deleted
    this.packageId = this.userData.userDetail.id

  }

  ngOnInit() {
    
  }

  delete() {
    let param = {
      id: this.packageId,
      statusId: this.statusId
    }
    try {
      this.sharedService.deleteStandardPackage(param).subscribe(response => {
        if (response.success) {
          this.commonService.showSnakeBar("Package deleted successfully.");
          this.closeDialog();
        }
        else {
          this.commonService.showSnakeBar(response.message);
          this.closeDialog();
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
        this.closeDialog();
      })
    }
    catch{
      this.commonService.commonSnakeBar();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
