/*
* @ngdoc Config
* @name app.configs.app-settings
* @description
* The service is used to define global app settings
* */

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class AppSettings {
    APP_NAME = "SmartKlick";
    PAGINATOR_SIZE = 20;
    PAGINATOR_SIZE_OPTIONS = [10, 20, 30];
    ASSETS_BASE_PATH = 'assets/images/';
    FULL_DATE = 'dd/mm/yyyy, h:mm a';
    COMMON_DATE_FORMAT = 'dd/MM/yyyy';
    HISTORY_TIME_FORMAT = 'MMM d, y' //mediumDate
    HISTORY_COMMENT_TIME = 'h:mm a'
    CAMPAIGN_DATE = 'MMM d, y, h:mm a';
    INBOUND_DATE = 'h:mm a';
    CREARTED_DATE_INBOUND_LISTING = "MMM d, y";
}