import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SkillListComponent } from './skill-list/skill-list.component';
import { CoreModule } from '../core/core.module';
import { MaterialComponentsModule } from '../material-components.module';
import { RouterModule } from '@angular/router';
import { NewSkillModalComponent } from './new-skill-modal/new-skill-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditSkillComponent } from './edit-skill/edit-skill.component';
import { DeleteSkillComponent } from './delete-skill/delete-skill.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    MaterialComponentsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [SkillListComponent, NewSkillModalComponent, EditSkillComponent, DeleteSkillComponent],
  exports: [NewSkillModalComponent, EditSkillComponent, DeleteSkillComponent],
  entryComponents: [NewSkillModalComponent, EditSkillComponent, DeleteSkillComponent]
})
export class SkillModule { }
