import { Component, OnInit} from '@angular/core';
import { RecruiterService } from '../service/recruiter.service';
import { AuthService } from '../../core/services/auth.service';
import { CommonService } from '../../core/services/common.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AddNewRecruiterComponent } from '../add-new-recruiter/add-new-recruiter.component'
import { EditRecruiterComponent } from '../edit-recruiter/edit-recruiter.component';
import { ConfirmDeleteComponent } from '../../shared/confirm-delete/confirm-delete.component';
import { BlockModalComponent } from '../../shared/block-modal/block-modal.component';
import { AppSettings } from '../../config/app-settings';
import { StatusMaster } from '../../core/constants/status';
import { SharedService } from '../../shared/service/shared.service';
import { JwtService } from '../../core/services/jwt.service';

export interface PeriodicElement {
  user_id: string
  name: string;
  email: string;
  phone: string;
  skill: string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  
];

@Component({
  selector: 'app-recruiter-list',
  templateUrl: './recruiter-list.component.html',
  styleUrls: ['./recruiter-list.component.scss']
})
export class RecruiterListComponent implements OnInit {
  displayedColumns: string[] = ['userId', 'name', 'email', 'phone', 'permissionId','selectUserId','skillList', 'Status', 'status'];
  dataSource = [];
  spanOption: boolean = true;
  spanOptionToggle() {
    this.spanOption = !this.spanOption;
  }

  fullControl: boolean = true;
  views: boolean = true;
  adds: boolean = true;
  edits: boolean = true;
  deletes: boolean = true;
  disableds: boolean = false;

  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  isLoadMore: boolean = false

  statusMstr: any = StatusMaster;

  //NoRecFound: boolean = false;
  public manualPaging: boolean = false
  constructor(
    private recruiterService: RecruiterService,
    private authService: AuthService,
    private commonService: CommonService,
    private sharedService: SharedService,
    public dialog: MatDialog,
    private jwtService: JwtService,
    private appSettings: AppSettings
  ) {
    this.getuserDetails();
  }

  ngOnInit() {
    this.getRecruiterList(false);

    const role = this.jwtService.getUserRole();
    if (role != 'CompanyAdmin') {
      this.getAddEditDeletePermission();
    }
  }
  AddRecruiterModal(): void {
    if (this.statusId == 10) {
	    const dialogRef = this.dialog.open(AddNewRecruiterComponent, {
        disableClose: true,
        panelClass: "full-width-popup"
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.getRecruiterList(false);
        }
      })
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }

  }

  EditRecruiterModal(userDetail): void {
    if (this.statusId == 10) {
	    const dialogRef = this.dialog.open(EditRecruiterComponent, {
        disableClose: true,
        panelClass: "full-width-popup",
        data: {
          userDetail
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.getRecruiterList(false);
        }
      })
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }

  }

  deleteConfirmModal(userId): void {
    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
        width: '600px',
        height: 'auto',
        data: {
          userId
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.getRecruiterList(false);
        }
  
      })
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }
 
  }
  blockModal(userDetail): void {
    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(BlockModalComponent, {
        width: '600px',
        height: 'auto',
        data: {
          userDetail
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.getRecruiterList(false);
        }
      })
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }
  
  }
  
  noRecordFoundNotCon: boolean = false
  getRecruiterList(isPaging: boolean) {
    if (this.authService.isCompanyAdmin() || this.authService.isSubscriber() || this.authService.isRecruiter() ) {
      try {
        let params
        if (this.manualPaging == true && isPaging != true) {
          this.pageNumber = 1
          params = {
            PageNumber: this.pageNumber,
            PageSize: this.pageSize
          }
        }
        else {
          params = {
            PageNumber: this.pageNumber,
            PageSize: this.pageSize
          }
        }
        this.recruiterService.getRecruiterListing(params).subscribe(response => {
          if (response.success) {

            let len = response.data.length;
            if (len > 0) {
               this.noRecordFoundNotCon = true
             }
             else {
               this.noRecordFoundNotCon = false
             }

            if (response.data.length < this.pageSize) {
              this.isLoadMore = false;
            }
            else {
              this.isLoadMore = true;
            }

            // if (response.data.length <= 0) {
            //   this.NoRecFound = true;
            // }
            // else {
            //   this.NoRecFound = false;
            // }

            if (isPaging) {
              this.appendRecruiterListing(response.data);
            }
            else {
              this.dataSource = response.data;
              // console.log(response.data);

              // console.log(response.data[0].skillList);
            }

            let skills = [];

            //            skills.forEach(response.data.skillList => {

            // });

            // response.data.skillList.forEach(element => {
            //   skills.push({
            //     name: element.skillName
            //   })
            // });

            // this.skillList = skills

          }
          else {
            this.commonService.showSnakeBar(response.message);
          }
        }, (err) => {
          this.commonService.checkTokenValidity(err);
        })
      } catch{
        this.commonService.commonSnakeBar();
      }

    }
    else {
      this.commonService.showSnakeBar("Unauthorized");
    }
  }

  // loadMore() {
  //   this.pageNumber = this.pageNumber + 1
  //   this.pageSize = this.pageSize
  //   this.getRecruiterList(true);
  // }

  appendRecruiterListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.dataSource.length > 0) {
        newState = this.dataSource.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.dataSource = newState;
    }
  }

  onChange(enable: boolean, userDetail) {
    if (this.statusId == 10) {
	    const dialogRef = this.dialog.open(BlockModalComponent, {
        width: '600px',
        height: 'auto',
        data: {
          userDetail
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.getRecruiterList(false);
        }
      })
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }

  }

  loadMore() {
    this.pageNumber = this.pageNumber + 1
    this.pageSize = this.pageSize
    this.getRecruiterList(true);
  }


  getAddEditDeletePermission() {
    let params = {
      MenuId: 4
    }
    this.sharedService.getAddEditDeletePermission(params).subscribe(response => {
      if (response.success) {
        if (response.data.length > 0) {
          this.adds = response.data[0].adds
          this.views = response.data[0].views
          this.fullControl = response.data[0].fullControle
          this.deletes = response.data[0].deletes
          this.edits=response.data[0].edit
        }


        if (this.fullControl == true || this.edits == true) {
          this.disableds = false
        }
        else {
          this.disableds = true
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  userDetails = []
  statusId: number
  getuserDetails(): number {
    try {
      let userId = this.jwtService.getCurrentUserId();
      let param = {
        UserId: userId
      }
      this.sharedService.getUserDetails(param).subscribe(response => {
        if (response.success) {
          this.userDetails = response.data;
          this.statusId = response.data[0].statusId;
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch {
      this.commonService.commonSnakeBar();
    }
    return this.statusId;
  }
}
