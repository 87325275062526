import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../services/common.service';
import { UserDetailService } from '../services/user-detail.service';

@Component({
  selector: 'app-unsubscribe-from-sms',
  templateUrl: './unsubscribe-from-sms.component.html',
  styleUrls: ['./unsubscribe-from-sms.component.scss']
})
export class UnsubscribeFromSmsComponent implements OnInit {


  referenceid: string

  constructor(
    private route: ActivatedRoute,
    private commonService: CommonService,
    private userDetailService: UserDetailService
  ) {
    this.referenceid = this.route.snapshot.paramMap.get("id");

    console.log(this.referenceid);
  }

  ngOnInit() {
  }


  Unsubscribe() {
    try {
      let params = {
        ReferenceId: this.referenceid,
        SmsStatus: false
      }
      this.userDetailService.SMSOptOut(params).subscribe(resposne => {
        if (resposne.success) {
          this.commonService.showSnakeBar(resposne.message);
        }
        else {
          this.commonService.showSnakeBar(resposne.message);
        }
      }, (err) => {
        this.commonService.commonSnakeBar();
      })
    }
    catch{
      this.commonService.commonSnakeBar();
    }
  }
}
