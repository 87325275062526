import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { CustomizedApprovedPackagesServiceService } from '../service/customized-approved-packages-service.service'
import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { isNull, isNullOrUndefined } from 'util';
import { AppSettings } from '../../config/app-settings';
//import { DISABLED, FormArray } from '@angular/forms/src/model';

@Component({
  selector: 'app-edit-customized-approved-packages',
  templateUrl: './edit-customized-approved-packages.component.html',
  styleUrls: ['./edit-customized-approved-packages.component.scss']
})
export class EditCustomizedApprovedPackagesComponent implements OnInit {
  userData: any
  removable = true
  audioMinutes: any
  compName: any
  companyId: any
  customizePackageRefId: any
  description: any
  id: any
  name: any
  price: any
  purchaseStatus: any
  purchased: any
  sms: any
  status: any
  statusId: any
  videoMinutes: any
  companyList = [];
  isProcessing: boolean = false
  calCulatedPrice: any
  componentDataForDynamicInputs = [];
  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  public editCustPackApprovedForm: FormGroup
  companyIds = []
  packageTenure: any
  numericNumberReg = '^-?[0-9]\\d*(\\.\\d{1,2})?$';
  PackageTenure: any

  constructor(@Inject(MAT_DIALOG_DATA) public data: EditCustomizedApprovedPackagesComponent,
    private customizedApprovedPackagesServiceService: CustomizedApprovedPackagesServiceService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private appSettings: AppSettings,
    private dialogRef: MatDialogRef<EditCustomizedApprovedPackagesComponent>) {

    this.userData = data;
    this.audioMinutes = this.userData.planDetails.audioMinutes
    this.compName = this.userData.planDetails.compName
    this.companyId = this.userData.planDetails.companyId
    this.customizePackageRefId = this.userData.planDetails.customizePackageRefId
    this.description = this.userData.planDetails.description
    this.id = this.userData.planDetails.id
    this.name = this.userData.planDetails.name
    this.price = this.userData.planDetails.price
    this.purchaseStatus = this.userData.planDetails.purchaseStatus
    this.purchased = this.userData.planDetails.purchased
    this.sms = this.userData.planDetails.sms
    this.status = this.userData.planDetails.status
    this.statusId = this.userData.planDetails.statusId
    this.videoMinutes = this.userData.planDetails.videoMinutes
    this.calCulatedPrice = this.userData.planDetails.calculatedPrice;
    this.packageTenure = this.userData.planDetails.packageTenure
  }

  ngOnInit() {

    this.intiForm();
    this.getCompanyDropDown();
    this.getComponentDetails();

    this.companyIds =
      this.userData.planDetails.companyIdList.forEach(element => {
        this.companyIds.push({
          id: element.companyId,
          name: element.compName
        })
      });
  }

  intiForm() {
    this.editCustPackApprovedForm = this.formBuilder.group({
      'Name': [this.name, [Validators.required]],
      'Description': [this.description],
      'AllComponent': this.formBuilder.array([]),
      'CalCulatedPrice': [{ value: this.calCulatedPrice, disabled: false }, [Validators.required, Validators.minLength(1)]],
      'Price': [this.price, [Validators.required, Validators.pattern(this.numericNumberReg), Validators.minLength(1)]],
      'Company': [this.companyIds, [Validators.required]],
      'CustomizePackageRefId': [this.customizePackageRefId],
      'PackageTenure': [String(this.packageTenure), [Validators.required]]
    })
  }

  editCustomizeApprovedPackage(form) {
    if (form.valid) {
      if (this.purchased == 0) {
        try {
          this.isProcessing = true
          let company: any
          let CompanyIdds = Array.prototype.map.call(this.editCustPackApprovedForm.controls.Company.value, s => s.id).toString();

          let params = {
            Id: this.id,
            Name: this.editCustPackApprovedForm.controls.Name.value,
            Description: this.editCustPackApprovedForm.controls.Description.value,
            AudioMinutes: 0,
            VideoMinutes: 0,
            SMS: 0,
            Price: this.editCustPackApprovedForm.controls.Price.value,
            CalCulatedPrice: this.editCustPackApprovedForm.controls.CalCulatedPrice.value,
            CompanyId: CompanyIdds,
            CustomizePackageRefId: this.editCustPackApprovedForm.controls.CustomizePackageRefId.value,
            PackageTenure: this.editCustPackApprovedForm.controls.PackageTenure.value
          }

          this.customizedApprovedPackagesServiceService.editCustomizeApprovedPackage(params).subscribe(response => {

            if (response.success) {

              let arrayAllComponent = this.editCustPackApprovedForm.controls.AllComponent.value

              for (let item of arrayAllComponent) {

                let inputparm
                if (item.InputParameter != '' && item.InputParameter != undefined) {
                  inputparm = item.InputParameter
                }
                else {
                  inputparm = 0.00
                }

                let itemCal
                if (item.CalculatedValue != '' && item.CalculatedValue != undefined) {
                  itemCal = item.CalculatedValue
                }
                else {
                  itemCal = 0.00
                }

                let paramarr = {
                  ComponentId: item.Id,
                  Name: item.Name,
                  Price: item.Price,
                  CalculatedValue: itemCal,
                  ComponentType: item.ComponentType,
                  InputParameter: inputparm,
                  LastPackageInsertedId: this.id,
                  PackageType: 'CustomizePackage',
                  SpOptions: 'Update'
                };

                try {
                  this.customizedApprovedPackagesServiceService.addPackageArrayDetails(paramarr).subscribe(resposne => {
                    if (resposne.success) {
                      this.isProcessing = false;
                      this.closeDialog();
                    }
                    else {
                      this.isProcessing = false;
                      this.commonService.showSnakeBar(resposne.message);
                    }

                  }, (err) => {
                    this.isProcessing = false;
                    this.commonService.checkTokenValidity(err);
                    this.closeDialog();
                  })
                }
                catch
                {
                  this.isProcessing = false;
                  this.commonService.commonSnakeBar();
                }
              }
              this.commonService.showSnakeBar(response.message);
              this.closeDialog();
              this.isProcessing = false;
            }
            else {
              this.commonService.showSnakeBar(response.message);
              this.isProcessing = false;
            }
          }, (err) => {
            this.commonService.checkTokenValidity(err);
            this.closeDialog();
            this.isProcessing = false;
          })
        }
        catch{
          this.commonService.commonSnakeBar();
          this.isProcessing = false;
        }
      }
      else {
        this.commonService.alredyPurchaePackageSnakeBar();
        this.isProcessing = false;
      }
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getComponentDetails() {
    let allcomponent = this.editCustPackApprovedForm.get('AllComponent') as FormArray;
    let params = {
      PageNumber: this.pageNumber,
      PageSize: this.pageSize,
      PackageType: 'CustomizePackage',
      PackageId: this.id,
      SpOption: 'Edit',
      PurchasedOption: 'No'
    }
    this.customizedApprovedPackagesServiceService.getComponentInsertedData(params).subscribe(response => {
      if (response.success) {
        let count = [];
        response.data.forEach(element => {
          allcomponent.push(this.formBuilder.control({
            ComponentId: element.componentId,
            ComponentType: element.componentType,
            ComponentTypeName: element.componentTypeName,
            InputParameter: element.inputParameter,
            Id: element.id,
            Name: element.name,
            Price: element.price,
            CalculatedValue: element.calculatedValue
          }));
          count.push({
            ComponentId: element.componentId,
            ComponentType: element.componentType,
            ComponentTypeName: element.componentTypeName,
            InputParameter: element.inputParameter,
            Id: element.id,
            Name: element.name,
            Price: element.price,
            CalculatedValue: element.calculatedValue
          })
        });

        if (count.length >= 0) {
          this.componentDataForDynamicInputs = count
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  getCompanyDropDown() {
    this.customizedApprovedPackagesServiceService.getCompanyDropDown().subscribe(response => {
      if (response.success) {
        let company = [];

        response.data.forEach(element => {
          company.push({
            id: element.id,
            name: element.name
          })
        });
        this.companyList = company
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  public numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public numberWithOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode == 190 || charCode == 110)) {
      return false;
    }
    return true;
  }

  public numberWithDotOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode == 190 || charCode == 110)) {
      return false;
    }
    return true;
  }
  finalPrice: any = 0;
  public focusoutAllComponentHandler(event, id, name, price, componentType, ComponentTypeName, CalculatedValue, item, index) {
  
    if (event.target.value.match(this.numericNumberReg) || event.target.value == '') {
      if (id) {
        let allcomponent = this.editCustPackApprovedForm.get('AllComponent') as FormArray;

        let c = 0
        for (let control of allcomponent.controls) {

          if (control.value.CalculatedValue != '' || control.value.CalculatedValue != undefined) {
            c = c + control.value.CalculatedValue
          }
        }

        this.finalPrice = c;

        if (id != 'calculatedText') {
          let single_price = (event.target.value * price);
          allcomponent.controls[index].value.InputParameter = event.target.value;
          allcomponent.controls[index].value.CalculatedValue = single_price;
          this.editCustPackApprovedForm.controls.CalCulatedPrice.setValue(this.finalPrice.toFixed(2));
        }
        else {
          this.editCustPackApprovedForm.controls.CalCulatedPrice.setValue(this.finalPrice.toFixed(2));
        }
      }
    }
    else {
      if ((event.target.value).split('.').length - 1 == 1) {
      }
      else {
        event.target.value = 0
        this.editCustPackApprovedForm.controls.CalCulatedPrice.setValue(0)
        this.commonService.decimalNumberSnakeBar()
      }
    }

  }

  compareCompanies(c1, c2): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  removeCompany(index) {
    this.editCustPackApprovedForm.controls.Company.value.splice(index, 1);
    this.editCustPackApprovedForm.controls.Company.patchValue(this.editCustPackApprovedForm.controls.Company.value)
  }

}
