import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { CampaignService } from '../service/campaign.service';
import { CommonService } from '../../core/services/common.service';
import { AppSettings } from '../../config/app-settings';
import { MatDialog } from '@angular/material';
import { ConfirmBlacklistComponent } from '../confirm-blacklist/confirm-blacklist.component';
import { StatusMaster } from '../../core/constants/status';
import { ExcelService } from './excelservice';


@Component({
  selector: 'app-campaigns-detail',
  templateUrl: './campaigns-detail.component.html',
  styleUrls: ['./campaigns-detail.component.css']
})

export class CampaignsDetailComponent implements OnInit {
  displayedColumns: string[] = ['referenceId', 'candidateName', 'phone', 'description', 'skill', 'status'];
  dataSource = [];

  campaignId: string
  dateFormat: any

  pageNumber: number = 1
  pageSize: number = this.appSetings.PAGINATOR_SIZE
  searchBy: string
  filterBy: number
  searchByType: string

  isLoadMore: boolean = false
  noRecordFound: boolean = false

  audio: any = new Audio();
  playingId: number

  createdDate: string
  createdBy: string
  campaignName: string
  connected: number
  totalUser: number
  smsSent: number

  isAudioPlayed: boolean = false;
  selected = '0';
  SeacrchByTypeSelected = 'Everything'
  status: any = StatusMaster

  CandidateStatus = []

  constructor(
    private route: ActivatedRoute,
    private campaignService: CampaignService,
    private commonService: CommonService,
    public dialog: MatDialog,
    private excelService: ExcelService,
    private appSetings: AppSettings,
  ) {

    this.campaignId = this.route.snapshot.paramMap.get("id");

    this.dateFormat = this.appSetings.CAMPAIGN_DATE
  }


  ngOnInit() {
    this.campaignData();
    this.GetCandidateStatusDropdown();
    this.campaignDetail(false);
  }

  BlackListCampaignModal(candidateId): void {
    const dialogRef = this.dialog.open(ConfirmBlacklistComponent, {
      width: '500px',
      height: 'auto',
      data: {
        candidateId
      }

    });
    // dialogRef.afterClosed().subscribe(result => {
    //   this.campaignListing();
    // })
  }

  campaignData() {
    let params = {
      CampaignId: this.campaignId
    }
    this.campaignService.campaignListing(params).subscribe(response => {
      if (response.success) {
        this.connected = response.data[0]["connected"]
        this.totalUser = response.data[0]["users"]
        this.smsSent = response.data[0]["smsSent"]
        this.campaignName = response.data[0]["name"]
        this.createdBy = response.data[0]["createdBy"]
        this.createdDate = response.data[0]["createdDate"]
      }
      else {
        this.commonService.commonSnakeBar();
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }
  noRecordFoundNotCon: boolean = false
  campaignDetail(isPaging: boolean) {
    let params = {
      CampaignId: this.campaignId,
      PageNumber: this.pageNumber,
      PageSize: this.pageSize,
      SearchBy: this.searchBy,
      FilterBy: this.filterBy,
      SearchByType: this.searchByType
    }
    this.campaignService.campaignDetail(params).subscribe(response => {
      if (response.success) {
        if (response.data.length < this.pageSize) {
          this.isLoadMore = false;
        }
        else {
          this.isLoadMore = true;
        }

        let len = response.data.length;
        if (len > 0) {
          this.noRecordFoundNotCon = true
        }
        else {
          this.noRecordFoundNotCon = false
        }

        if (isPaging) {
          this.appendRecruiterListing(response.data);
        }
        else {
          this.dataSource = response.data;
        }
      }
      else {
        this.commonService.showSnakeBar(response.message)
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  loadMore() {
    this.pageNumber = this.pageNumber + 1
    this.pageSize = this.pageSize
    this.campaignDetail(true);
  }

  onChange(value: string) {
    
    this.searchBy = value;
    this.pageNumber = 1;

    if (this.searchByType == undefined || this.searchByType == null) {
      this.searchByType = this.SeacrchByTypeSelected
    }
    else {
      this.searchByType = this.searchByType;
    }
    this.campaignDetail(false);
  }

  dropdownChange(value: number) {
    
    this.filterBy = value;
    this.pageNumber = 1;
    this.campaignDetail(false);
  }

  dropdownChangeSearchByType(type: string) {
    
    this.searchByType = type;
  }

  appendRecruiterListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.dataSource.length > 0) {
        newState = this.dataSource.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.dataSource = newState;

    }
  }

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.dataSource, 'sample');
  }

  playAudio(element) {
    // console.log(element, 'Auido Works');

    if (element.id == this.playingId && this.isAudioPlayed) {
      this.audio.pause();
      //  console.log('audio Paused');
      this.isAudioPlayed = false
    }
    else {
      var index = this.dataSource.findIndex(ds => ds.id == element.id);

      if (index != -1) {
        //  console.log(element.recordingURL)

        // this.audio.src = "https://www.radiantmediaplayer.com/media/bbb-360p.mp4";
        this.audio.src = element.recordingURL;
        this.audio.load();
        this.audio.play();
        this.playingId = element.id
        this.isAudioPlayed = true;

        //  console.log(this.playingId)

        //  console.log('audio played');


      } else {
        this.commonService.showSnakeBar("Audio not found");
      }
    }
  }

  GetCandidateStatusDropdown() {
    this.campaignService.GetCandidateCallStatusDropdown().subscribe(response => {
      if (response.success) {
        let rec = [];

        response.data.forEach(element => {
          rec.push({
            id: element.id,
            name: element.name
          })
        });
        this.CandidateStatus = rec
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }
}
