import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallForwardingListComponent } from './call-forwarding-list/call-forwarding-list.component';
import { CoreModule } from '../core/core.module';
import { MaterialComponentsModule } from '../material-components.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    MaterialComponentsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [CallForwardingListComponent]
})
export class CallForwardingModule { }
