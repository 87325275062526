import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialComponentsModule } from './material-components.module';
import { AppRoutingModule } from './app-routing.module';
import { UsersModule } from './users/users.module';
import { CampaignsModule } from './campaigns/campaigns.module';
import { SharedModule } from './shared/shared.module';
import { InboundModule } from './inbound/inbound.module';
import { SubscribeModule } from './subscribe/subscribe.module';
import { RecruiterModule } from './recruiter/recruiter.module';
import { SkillModule } from './skill/skill.module';
import { CallForwardingModule } from './call-forwarding/call-forwarding.module';
import { ApiUrls } from './config/api-urls';
import { CoreModule } from './core/core.module';
import { StandardpackagesModule } from './standardpackages/standardpackages.module';
import { CompaniesModule } from './companies/companies.module';
import { ComponentPlanModule } from './component-plan/component-plan.module';
import { CutomizePackagesModule } from './cutomize-packages/cutomize-packages.module';
import { CustomizePackageSuperAdminModule } from './customize-package-super-admin/customize-package-super-admin.module'
import { CustomizedApprovedPackagesModule } from './customized-approved-packages/customized-approved-packages.module'
import { MyPackagesModule } from './my-packages/my-packages.module'
import { StripePaymentModule } from './stripe-payment/stripe-payment.module'
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '../environments/environment';
import { PurchaedPackagesModule } from './purchaed-packages/purchaed-packages.module';
import { BuyRequiredPackageModule } from './buy-required-package/buy-required-package.module'
import { AllocateMinutesToSubscriberModule } from './allocate-minutes-to-subscriber/allocate-minutes-to-subscriber.module'
import { PurchasedPackageManagementModule } from './purchased-package-management/purchased-package-management.module'
import { PackageManagementModule } from './package-management/package-management.module'
import { SuperSubAdminModule } from './super-sub-admin/super-sub-admin.module'
import { UserPermissionModule } from './user-permission/user-permission.module'
import { UserPermissionCompanyAdminModule } from './user-permission-company-admin/user-permission-company-admin.module';
import { PlanmanagementCompanyAdminModule } from './planmanagement-company-admin/planmanagement-company-admin.module';
import { ViewMinutesStatusModule } from './view-minutes-status/view-minutes-status.module';
import { AllocateMinutesToRecruiterModule } from './allocate-minutes-to-recruiter/allocate-minutes-to-recruiter.module';
import { HubConnection } from '@aspnet/signalr';
import { DirectCallingModule } from './direct-calling/direct-calling.module';
import { SmsCampaignsModule } from './sms-campaigns/sms-campaigns.module';
import { EmailCampaignsModule } from './email-campaigns/email-campaigns.module';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialComponentsModule,
    AppRoutingModule,
    UsersModule,
    CampaignsModule,
    SharedModule,
    InboundModule,
    SubscribeModule,
    RecruiterModule,
    SkillModule,
    CallForwardingModule,
    CoreModule,
    StandardpackagesModule,
    CompaniesModule,
    ComponentPlanModule,
    CutomizePackagesModule,
    CustomizePackageSuperAdminModule,
    CustomizedApprovedPackagesModule,
    MyPackagesModule,
    StripePaymentModule,
    PurchaedPackagesModule,
    NgxStripeModule.forRoot(environment.stripePublishableKey),
    BuyRequiredPackageModule,
    AllocateMinutesToSubscriberModule,
    PurchasedPackageManagementModule,
    PackageManagementModule,
    SuperSubAdminModule,
    UserPermissionModule,
    UserPermissionCompanyAdminModule,
    PlanmanagementCompanyAdminModule,
    ViewMinutesStatusModule,
    AllocateMinutesToRecruiterModule,
    DirectCallingModule,
    SmsCampaignsModule,
    EmailCampaignsModule
  ],
  providers: [
    ApiUrls
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
