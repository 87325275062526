import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CommonService } from '../../core/services/common.service';
import { SkillService } from '../service/skill.service';

@Component({
  selector: 'app-delete-skill',
  templateUrl: './delete-skill.component.html',
  styleUrls: ['./delete-skill.component.scss']
})
export class DeleteSkillComponent implements OnInit {

  skillId: number

  skillData: any
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DeleteSkillComponent,
    private dialogRef :MatDialogRef<DeleteSkillComponent>,
    private commonService:CommonService,
    private skillService:SkillService
  ) {
    this.skillData = data

    this.skillId = this.skillData.id
  }

  ngOnInit() {
  }

  delete(){
    let param = {
      SkillId: this.skillId,
    }
    try {
      this.skillService.deleteSkill(param).subscribe(response => {
        if (response.success) {
          this.commonService.showSnakeBar("Skill deleted successfully.");
          this.closeDialog();
        }
        else {
          this.commonService.showSnakeBar(response.message);
          this.closeDialog();
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
        this.closeDialog();
      })
    }
    catch{
      this.commonService.commonSnakeBar();
    }
  }

  closeDialog(){
    this.dialogRef.close();
  }
}
