import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurchasedPackagesListComponent } from './purchased-packages-list/purchased-packages-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialComponentsModule } from '../material-components.module';
import { EditPurchasedPackagesComponent } from './edit-purchased-packages/edit-purchased-packages.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialComponentsModule
  ],
  declarations: [PurchasedPackagesListComponent, EditPurchasedPackagesComponent],
  entryComponents:[EditPurchasedPackagesComponent]

})
export class PurchaedPackagesModule { }
