import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AppSettings } from '../../config/app-settings';
import { PurchasedPackageManagementServiceService } from '../service/purchased-package-management-service.service'



@Component({
  selector: 'app-edit-purchased-package-management',
  templateUrl: './edit-purchased-package-management.component.html',
  styleUrls: ['./edit-purchased-package-management.component.scss']
})
export class EditPurchasedPackageManagementComponent implements OnInit {

  removable = true;
  public viewPurchasedPackManageForm: FormGroup;
  public isProcessing: boolean = false;
  userData: any
  packageId: any
  componentPlanData = [];
  componentInsertedPlanData = [];
  audioMins: any
  compName: any
  companyId: any
  endDate: any
  id: any
  packageName: any
  calCulatedPrice: any
  price: any
  priceUnit: any
  sms: any
  startDate: any
  status: any
  statusId: any
  videoMins: any
  packageType: any
  transactionId: any

  totalPrice: number = 0
  packageTenure: any

  public smsPrice: any
  public audioPrice: any
  public videoPrice: any
  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE

  componentDataForDynamicInputs = [];

  insetedComponentsForStandardData = [];
  companyList = []
  PackageTenure: any

  constructor(private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: EditPurchasedPackageManagementComponent,
    private commonService: CommonService,
    private datePipe: DatePipe,
    private appSettings: AppSettings,
    private purchasedPackageManagementServiceService: PurchasedPackageManagementServiceService,
    private dialogRef: MatDialogRef<EditPurchasedPackageManagementComponent>) {
    this.userData = data;
    this.id = this.userData.userDetail.id;
    this.companyId = this.userData.userDetail.companyId;
    this.packageName = this.userData.userDetail.name;
    this.calCulatedPrice = this.userData.userDetail.price;
    this.startDate = this.userData.userDetail.purchaseDate;
    this.status = this.userData.userDetail.status;
    this.statusId = this.userData.userDetail.statusId;
    this.price = this.userData.userDetail.price;
    this.packageId = this.userData.userDetail.packageId
    this.packageType = this.userData.userDetail.packageType
    this.transactionId = this.userData.userDetail.transactionId
    this.packageTenure = this.userData.userDetail.packageTenure
  }

  ngOnInit() {
    this.initForm()
    this.getComponentDetails()
    this.getCompanyDropDown()

  }

  initForm() {
    this.viewPurchasedPackManageForm = this.formBuilder.group({
      'PackageName': [{ value: this.packageName, disabled: true }, [Validators.required, this.noWhitespaceValidator]],
      'AllComponent': this.formBuilder.array([]),
      'Price': [{ value: this.calCulatedPrice, disabled: true }],
      'PurchaseDate': [{ value: this.startDate, disabled: true }],
      'EndDate': [this.endDate],
      'Id': [this.id],
      'Company': [{ value: Number(this.companyId), disabled: true }],
      'TransactionId': [{ value: this.transactionId, disabled: true }],
      'PackageTenure': [{ value: String(this.packageTenure), disabled: true }]
    });
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }


  getComponentDetails() {
    let allcomponent = this.viewPurchasedPackManageForm.get('AllComponent') as FormArray;
    let params = {
      PageNumber: this.pageNumber,
      PageSize: this.pageSize,
      PackageType: this.packageType,
      PackageId: this.packageId,
      SpOption: 'Edit'
    }
    this.purchasedPackageManagementServiceService.getComponentInsertedData(params).subscribe(response => {
      if (response.success) {
        let count = [];
        response.data.forEach(element => {
          allcomponent.push(this.formBuilder.control({
            ComponentId: element.componentId,
            ComponentType: element.componentType,
            ComponentTypeName: element.componentTypeName,
            InputParameter: element.inputParameter,
            Id: element.id,
            Name: element.name,
            Price: element.price,
            CalculatedValue: element.calculatedValue
          }));
          count.push({
            ComponentId: element.componentId,
            ComponentType: element.componentType,
            ComponentTypeName: element.componentTypeName,
            InputParameter: element.inputParameter,
            Id: element.id,
            Name: element.name,
            Price: element.price,
            CalculatedValue: element.calculatedValue
          })
        });

        if (count.length >= 0) {
          this.componentDataForDynamicInputs = count
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
    //  console.log(this.viewPurchasedPackForm.get('AllComponent'));
  }


  getCompanyDropDown() {
    this.purchasedPackageManagementServiceService.getCompanyDropDown().subscribe(response => {
      if (response.success) {
        let company = [];

        response.data.forEach(element => {
          company.push({
            id: element.id,
            name: element.name
          })
        });
        this.companyList = company
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

}
