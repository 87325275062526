import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AuthService } from '../../core/services/auth.service';
import { SubscriberService } from '../service/subscriber.service';
import { CommonService } from '../../core/services/common.service';
import { MatDialogRef } from '@angular/material';
import { validateConfig } from '@angular/router/src/config';
import { AppSettings } from '../../config/app-settings';

@Component({
  selector: 'app-add-subscribe',
  templateUrl: './add-subscribe.component.html',
  styleUrls: ['./add-subscribe.component.scss']
})
export class AddSubscribeComponent implements OnInit {
  public addSubscriberFrom: FormGroup;
  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  isProcessing: boolean = false
  allroles = [];
  constructor(
    private fromBuilder: FormBuilder,
    private commonService: CommonService,
    private appSettings: AppSettings,
    private subscriberService: SubscriberService,
    public dialogRef: MatDialogRef<AddSubscribeComponent>
  ) { }

  ngOnInit() {
    this.initForm();
    this.getRoleDropdown()
  }

  initForm() {
    this.addSubscriberFrom = this.fromBuilder.group({
      'Name': ['', [Validators.required, this.noWhitespaceValidator]],
      'Email': ['', [Validators.required,
      Validators.pattern(/[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g),
      Validators.email]],
      'Phone': ['', [Validators.required, Validators.minLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      'RoleLevel': [null, [Validators.required]]
    })
  }

  addSubscriber(form) {
    if (form.valid) {
      try {
        this.isProcessing = true;

        let params = {
          Name: this.addSubscriberFrom.controls.Name.value,
          Phone: this.addSubscriberFrom.controls.Phone.value,
          Email: this.addSubscriberFrom.controls.Email.value,
          PermissionId: this.addSubscriberFrom.controls.RoleLevel.value
        }
        this.subscriberService.addSubscriber(params).subscribe(response => {
          if (response.success) {
            this.commonService.showSnakeBar(response.message);
            this.closeDialog();
            this.isProcessing = false;
          }
          else {
            this.commonService.showSnakeBar(response.message);
            this.isProcessing = false;
          }
        }, (error) => {
          this.commonService.checkTokenValidity(error);
          this.isProcessing = false;
        })
      }
      catch{
        this.commonService.commonSnakeBar();
        this.isProcessing = false;
      }

    }
    else {
      this.commonService.showSnakeBar("Please do the correction");
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }


  formValidate(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.formValidate(control);
      }
    });
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  public numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getRoleDropdown() {
    let params = {
      PageNumber: this.pageNumber,
      PageSize: this.pageSize,
      SpOption: 'EditUser'
    }
    this.subscriberService.getRoleDropdown(params).subscribe(response => {
      if (response.success) {
        let count = [];
        response.data.forEach(element => {
          count.push({
            id: element.id,
            name: element.name
          })
        });
        this.allroles = count
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }
}
