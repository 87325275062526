import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { MaterialComponentsModule } from '../material-components.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UserPermissionCompanyAdminListComponent } from './user-permission-company-admin-list/user-permission-company-admin-list.component';
import { AddUserPermissionCompanyAdminComponent } from './add-user-permission-company-admin/add-user-permission-company-admin.component';
import { EditUserPermissionCompanyAdminComponent } from './edit-user-permission-company-admin/edit-user-permission-company-admin.component';


@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    MaterialComponentsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [UserPermissionCompanyAdminListComponent, AddUserPermissionCompanyAdminComponent, EditUserPermissionCompanyAdminComponent],
  entryComponents: [AddUserPermissionCompanyAdminComponent, EditUserPermissionCompanyAdminComponent]
})
export class UserPermissionCompanyAdminModule { }
