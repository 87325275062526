import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { JwtService } from '../../core/services/jwt.service';
import { CallForwardingService } from '../service/call-forwarding.service';

@Component({
  selector: 'app-call-forwarding-list',
  templateUrl: './call-forwarding-list.component.html',
  styleUrls: ['./call-forwarding-list.component.css']
})
export class CallForwardingListComponent implements OnInit {
  callForwarding: boolean = false;
  callForwardingNo: string = '';
  IsCallForwardingParam: boolean
  UserId: number

  public CallForwardingForm: FormGroup;

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private jwtService: JwtService,
    private callForwService: CallForwardingService,
  ) { }

  ngOnInit() {
    this.GetDetails();
    this.initForm();
  }


  initForm() {
    this.CallForwardingForm = this.formBuilder.group({
      'PhoneNumber': [null, [Validators.required, Validators.minLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]]
    });
  }


  updateCallForwardingStatus(form) {
    if (form.valid) {

      if (this.callForwarding) {
        this.IsCallForwardingParam = false;
      }
      else {
        this.IsCallForwardingParam = true;
      }

      let param = {
        UserId: this.UserId,
        IsCallForwarding: this.IsCallForwardingParam,
        PhonNumber: this.CallForwardingForm.controls.PhoneNumber.value
      }

      this.callForwService.ActivateCallForw(param).subscribe(response => {
        if (response.success) {
          this.commonService.showSnakeBar(response.message);
          if (!this.IsCallForwardingParam) {
            this.callForwarding = false
          }
          else {
            this.callForwarding = true
          }
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })

    }
    else {
      this.commonService.showSnakeBar("Please do the correction");
    }
  }




  GetDetails() {
    this.UserId = this.jwtService.getCurrentUserId();
    this.callForwService.getDetails(this.UserId).subscribe(response => {
      if (response.success) {
        // console.log(response.data[0].callForwardingNumber, "callForwardingNumber")
        this.callForwarding = response.data[0].isCallForwarding
        this.callForwardingNo = response.data[0].callForwardingNumber;
        this.CallForwardingForm.controls.PhoneNumber.patchValue(this.callForwardingNo);
        // if (!this.callForwarding) {
        //   this.CallForwardingForm.controls.PhoneNumber.disable();
        // }
      }
    })
  }

  public numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

}
