import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './users/user-auth/login/login.component';
import { CampaignsListComponent } from './campaigns/campaigns-list/campaigns-list.component';
import { CampaignsDetailComponent } from './campaigns/campaigns-detail/campaigns-detail.component';
import { InboundListComponent } from './inbound/inbound-list/inbound-list.component';
import { SubscribeListComponent } from './subscribe/subscribe-list/subscribe-list.component';
import { RecruiterListComponent } from './recruiter/recruiter-list/recruiter-list.component';
import { SkillListComponent } from './skill/skill-list/skill-list.component';
import { CallForwardingListComponent } from './call-forwarding/call-forwarding-list/call-forwarding-list.component';
import { SearchResultComponent } from './core/search-result/search-result.component';
import { ForgotPasswordComponent } from './users/user-auth/forgot-password/forgot-password.component';
import { CreatePasswordComponent } from './shared/create-password/create-password.component';
import { AuthGuard } from './core/guards/auth.guard';
import { UnsubscribeFromSmsComponent } from './core/unsubscribe-from-sms/unsubscribe-from-sms.component';
import { StandardpackagesListComponent } from './standardpackages/standardpackages-list/standardpackages-list.component';
import { CompaniesListComponent } from './companies/companies-list/companies-list.component';
import { ComponentPlansListComponent } from './component-plan/component-plans-list/component-plans-list.component';
import { CutomizePackagesListComponent } from './cutomize-packages/cutomize-packages-list/cutomize-packages-list.component';
import { CustomizePackageSuperAdminListComponent } from './customize-package-super-admin/customize-package-super-admin-list/customize-package-super-admin-list.component'
import { CustomizedApprovedPackagesListComponent } from './customized-approved-packages/customized-approved-packages-list/customized-approved-packages-list.component'
import { MyPackagesListComponent } from './my-packages/my-packages-list/my-packages-list.component'
import { PurchasedPackagesListComponent } from './purchaed-packages/purchased-packages-list/purchased-packages-list.component'
import { BuyRequiredPackageListComponent } from './buy-required-package/buy-required-package-list/buy-required-package-list.component'
import { AllocateMinutesToSubscriberListComponent } from './allocate-minutes-to-subscriber/allocate-minutes-to-subscriber-list/allocate-minutes-to-subscriber-list.component'
import { PurchasedPackageManagementListComponent } from './purchased-package-management/purchased-package-management-list/purchased-package-management-list.component'
import { UiKitComponent } from './shared/ui-kit/ui-kit.component'
import { PackageManagementListComponent } from './package-management/package-management-list/package-management-list.component'
import { CompanyDetailsComponent } from './companies/company-details/company-details.component'
import { EditCustomizePackageComponent } from './cutomize-packages/edit-customize-package/edit-customize-package.component'
import { SuperSubAdminListComponent } from './super-sub-admin/super-sub-admin-list/super-sub-admin-list.component'
import { UserPermissionListComponent } from './user-permission/user-permission-list/user-permission-list.component'
import { UserPermissionCompanyAdminListComponent } from './user-permission-company-admin/user-permission-company-admin-list/user-permission-company-admin-list.component';
import { ProfileBigScreenComponent } from './shared/profile-big-screen/profile-big-screen.component';
import { PlanmanagementCompanyAdminListComponent } from './planmanagement-company-admin/planmanagement-company-admin-list/planmanagement-company-admin-list.component';
import { ViewMinutesStatusListComponent } from './view-minutes-status/view-minutes-status-list/view-minutes-status-list.component';
import { AllocateMinutesToRecruiterListComponent } from './allocate-minutes-to-recruiter/allocate-minutes-to-recruiter-list/allocate-minutes-to-recruiter-list.component';
import { DashboardComponent } from './shared/dashboard/dashboard.component';
import { DirectCallingListComponent } from './direct-calling/direct-calling-list/direct-calling-list.component';
import { SmsCampaignListComponent } from './sms-campaigns/sms-campaign-list/sms-campaign-list.component';
import { SmsCampaignDetailComponent } from './sms-campaigns/sms-campaign-detail/sms-campaign-detail.component';
import { EmailCampaignListComponent } from './email-campaigns/email-campaign-list/email-campaign-list.component';
import { EmailCampaignDetailsComponent } from './email-campaigns/email-campaign-details/email-campaign-details.component';


const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'campaign', component: CampaignsListComponent, canActivate: [AuthGuard] },
  { path: 'campaign-detail/:id', component: CampaignsDetailComponent, canActivate: [AuthGuard] },
  { path: 'smscampaign-detail/:id', component: SmsCampaignDetailComponent, canActivate: [AuthGuard] },
  { path: 'emailcampaign-detail/:id', component: EmailCampaignDetailsComponent, canActivate: [AuthGuard] },
  { path: 'inbound', component: InboundListComponent, canActivate: [AuthGuard] },
  { path: 'subscriber', component: SubscribeListComponent, canActivate: [AuthGuard] },
  { path: 'recruiter', component: RecruiterListComponent, canActivate: [AuthGuard] },
  { path: 'skill', component: SkillListComponent, canActivate: [AuthGuard] },
  { path: 'call-forwarding', component: CallForwardingListComponent, canActivate: [AuthGuard] },
  { path: 'search-result/:id/:val', component: SearchResultComponent, canActivate: [AuthGuard] },
  { path: 'create-password', component: CreatePasswordComponent },
  { path: 'unsubscribe-sms/:id', component: UnsubscribeFromSmsComponent },
  { path: 'standardpackage', component: StandardpackagesListComponent, canActivate: [AuthGuard] },
  { path: 'companies', component: CompaniesListComponent, canActivate: [AuthGuard] },
  { path: 'componentplan', component: ComponentPlansListComponent, canActivate: [AuthGuard] },
  { path: 'customizeplanreqbycompanyadmin', component: CutomizePackagesListComponent, canActivate: [AuthGuard] },
  { path: 'customizeplanreqbysuperadmin', component: CustomizePackageSuperAdminListComponent, canActivate: [AuthGuard] },
  { path: 'customizedApprovedPackages', component: CustomizedApprovedPackagesListComponent, canActivate: [AuthGuard] },
  { path: 'mypackages', component: MyPackagesListComponent, canActivate: [AuthGuard] },
  { path: 'purchasedpackages', component: PurchasedPackagesListComponent, canActivate: [AuthGuard] },
  { path: 'buyrequiredpackages', component: BuyRequiredPackageListComponent, canActivate: [AuthGuard] },
  { path: 'allocatesminutestomanager', component: AllocateMinutesToSubscriberListComponent, canActivate: [AuthGuard] },
  { path: 'purchasedpackagement', component: PurchasedPackageManagementListComponent, canActivate: [AuthGuard] },
  { path: 'ui-kit', component: UiKitComponent },
  { path: 'packagemanagement', component: PackageManagementListComponent, canActivate: [AuthGuard] },
  { path: 'companydetails/:id', component: CompanyDetailsComponent, canActivate: [AuthGuard] },
  { path: 'editcustomizePackage/:id/:type', component: EditCustomizePackageComponent, canActivate: [AuthGuard] },
  { path: 'supersubadmin', component: SuperSubAdminListComponent, canActivate: [AuthGuard] },
  { path: 'userpermission', component: UserPermissionListComponent, canActivate: [AuthGuard] },
  { path: 'userpermissioncompadmin', component: UserPermissionCompanyAdminListComponent, canActivate: [AuthGuard] },
  { path: 'profilemodal', component: ProfileBigScreenComponent, canActivate: [AuthGuard] },
  { path: 'planmanagementcompanyadmin', component: PlanmanagementCompanyAdminListComponent, canActivate: [AuthGuard] },
  { path: 'viewminutesstatus', component: ViewMinutesStatusListComponent, canActivate: [AuthGuard] },
  { path: 'allocatesminutestorecruiter', component: AllocateMinutesToRecruiterListComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'candidatedirectcalling', component: DirectCallingListComponent, canActivate: [AuthGuard] },
  { path: 'smscampaign', component: SmsCampaignListComponent, canActivate: [AuthGuard] },
  { path: 'emailcampaign', component: EmailCampaignListComponent, canActivate: [AuthGuard] }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
