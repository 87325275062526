import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSort, MatTableDataSource } from '@angular/material';
import { AuthService } from '../../core/services/auth.service';
import { CommonService } from '../../core/services/common.service';
import { CompanyService } from '../service/company.service';
import { AppSettings } from '../../config/app-settings';
import { StatusMaster } from '../../core/constants/status';
import { AddNewCompanyComponent } from '../add-new-company/add-new-company.component';
import { EditCompanyComponent } from '../edit-company/edit-company.component';
import { BlockCompanyModelComponent } from '../../shared/block-company-model/block-company-model.component';
import { ConfirmCompanyDeleteComponent } from '../../shared/confirm-company-delete/confirm-company-delete.component';
import { CompanyDetailsComponent } from '../company-details/company-details.component'
import { ActivatedRoute } from '@angular/router';
import { SharedService } from '../../shared/service/shared.service'
import { JwtService } from '../../core/services/jwt.service'

@Component({
  selector: 'app-companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.scss']
})
export class CompaniesListComponent implements OnInit {
  displayedColumns: string[] = ['CompanyId', 'CompName',
    'Address', 'City', 'State', 'Zip',
    'Country', 'Email', 'Phone', 'Status', 'Action'];

  @ViewChild(MatSort) sort: MatSort;

  dataSource = [];

  spanOption: boolean = true;
  spanOptionToggle() {
    this.spanOption = !this.spanOption;
  }
  public ListingData = [];
  public AllCompanies = [];

  public manualPaging: boolean = false

  constructor(public dialog: MatDialog,
    private authService: AuthService,
    private commonService: CommonService,
    private companyService: CompanyService,
    private sharedService: SharedService,
    private jwtService: JwtService,
    private route: ActivatedRoute,
    private appSettings: AppSettings) {
    this.getuserDetails();
  }

  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  isLoadMore: boolean = false
  statusMstr: any = StatusMaster;
  NoRecFound: boolean = false;

  fullControl: boolean = true;
  views: boolean = true;
  adds: boolean = true;
  edits: boolean = true;
  deletes: boolean = true;
  disableds: boolean = false;

  ngOnInit() {
    this.getCompanyListing(false);
    const role = this.jwtService.getUserRole();
    if (role == 'SuperSubAdmin') {
      this.getAddEditDeletePermission();
    }

  }
  companyCount: any
  noRecordFoundNotCon: boolean = false
  getCompanyListing(isPaging: boolean) {
    try {
      let params
      if (this.manualPaging == true && isPaging != true) {
        this.pageNumber = 1
        params = {
          PageNumber: this.pageNumber,
          PageSize: this.pageSize
        }
      }
      else {
        params = {
          PageNumber: this.pageNumber,
          PageSize: this.pageSize
        }
      }

      this.companyService.companyListing(params).subscribe(response => {
        if (response.success) {
          this.companyCount = response.data.length;
          let len = response.data.length;
          if (len > 0) {
             this.noRecordFoundNotCon = true
           }
           else {
             this.noRecordFoundNotCon = false
           }
       

          if (response.data.length < this.pageSize) {
            this.isLoadMore = false;
          }
          else {
            this.isLoadMore = true;
          }

          if (isPaging) {
            this.appendCompanyListing(response.data);
          }
          else {
            this.dataSource = response.data;
          }
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch {
      this.commonService.commonSnakeBar();
    }
  }

  appendCompanyListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.dataSource.length > 0) {
        newState = this.dataSource.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.dataSource = newState;
      this.companyCount = this.dataSource.length;
    }
  }
  loadMore() {
    this.pageNumber = this.pageNumber + 1
    this.pageSize = this.pageSize
    this.getCompanyListing(true);
  }

  AddCompanyModal(): void {
    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(AddNewCompanyComponent, {
        disableClose: true,
        panelClass: "full-width-popup"
      })

      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.getCompanyListing(false);
        }
      })
    }
    else {
      this.commonService.userDeActivatedOrDeleted();
    }

  };


  CompanyDetailModal(companyDetails): void {
    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(CompanyDetailsComponent, {
        disableClose: true,
        panelClass: "full-width-popup",
        data: {
          companyDetails
        }
      })

      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.pageNumber = 1
          this.getCompanyListing(false);
        }
      })
    }
    else {
      this.commonService.userDeActivatedOrDeleted();
    }
  };


  EditCompanyModal(companyDetails): void {

    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(EditCompanyComponent, {
        disableClose: true,
        panelClass: "full-width-popup",
        data: {
          companyDetails
        }

      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.getCompanyListing(false);

        }
      })
    }
    else {
      this.commonService.userDeActivatedOrDeleted();
    }


  }

  deleteCompanyConfirmModal(companyDetails): void {
    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(ConfirmCompanyDeleteComponent, {
        width: '600px',
        height: 'auto',
        data: {
          companyDetails
        }
      })

      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.getCompanyListing(false);

        }
      })
    }
    else {
      this.commonService.userDeActivatedOrDeleted();
    }
  };

  onChange(enable: boolean, companyDetails) {
    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(BlockCompanyModelComponent, {
        width: '600px',
        height: 'auto',
        data: {
          companyDetails
        }
      })

      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.getCompanyListing(false);
        }
      })
    }
    else {
      this.commonService.userDeActivatedOrDeleted();
    }
  }

  cellClicked(companyDetails) {
    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(CompanyDetailsComponent, {
        disableClose: true,
        panelClass: "full-width-popup",
        data: {
          companyDetails
        }
      })

      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.pageNumber = 1
          this.getCompanyListing(false);
        }
      })
    }

    else {
      this.commonService.userDeActivatedOrDeleted();
    }

  }

  getAddEditDeletePermission() {
    let params = {
      MenuId: 8
    }
    this.sharedService.getAddEditDeletePermission(params).subscribe(response => {
      if (response.success) {
        if (response.data.length > 0) {
          this.adds = response.data[0].adds
          this.views = response.data[0].views
          this.fullControl = response.data[0].fullControle
          this.deletes = response.data[0].deletes
          this.edits = response.data[0].edit
        }


        if (this.fullControl == true || this.edits == true) {
          this.disableds = false
        }
        else {
          this.disableds = true
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }


  userDetails = []
  statusId: number
  getuserDetails(): number {
    try {
      let userId = this.jwtService.getCurrentUserId();
      let param = {
        UserId: userId
      }
      this.sharedService.getUserDetails(param).subscribe(response => {
        if (response.success) {
          this.userDetails = response.data;
          this.statusId = response.data[0].statusId;
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch {
      this.commonService.commonSnakeBar();
    }
    return this.statusId;
  }


}
