import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CommonService } from '../../core/services/common.service';
import { EmailCampaignServicesService } from '../services/email-campaign-services.service';
import { StatusMaster } from '../../core/constants/status';

@Component({
  selector: 'app-resume-campaign',
  templateUrl: './resume-campaign.component.html',
  styleUrls: ['./resume-campaign.component.scss']
})
export class ResumeCampaignComponent implements OnInit {
  campData: any
  campaignId: number

  constructor(@Inject(MAT_DIALOG_DATA) public data: ResumeCampaignComponent,
  private commomService: CommonService,
  private emailCampaignServicesService: EmailCampaignServicesService,
  private dialogRef: MatDialogRef<ResumeCampaignComponent>) { 
    this.campData = data;

    this.campaignId = this.campData.campDetail.campaignId
  }

  ngOnInit() {
  }

  resume() {
    let params = {
      CampaignId: this.campaignId,
      StatusId: StatusMaster.Paused
    }
    this.emailCampaignServicesService.UpdateCampaignStatus(params).subscribe(resposne => {
      if (resposne.success) {
        this.commomService.showSnakeBar("Campaign resumed");
        this.closeDialog();
      }
      else {
        this.commomService.showSnakeBar("Unable to resumed campaign");
      }
    }, (err) => {
      this.commomService.checkTokenValidity(err);
      this.closeDialog();
    })
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
