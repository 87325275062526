import { CustomizepackageserviceService } from '../service/customizepackageservice.service';
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AppSettings } from '../../config/app-settings';


@Component({
  selector: 'app-customize-package-comment-list',
  templateUrl: './customize-package-comment-list.component.html',
  styleUrls: ['./customize-package-comment-list.component.scss']
})
export class CustomizePackageCommentListComponent implements OnInit {

  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  isLoadMore: boolean = false
  NoRecFound: boolean = false;
  NoPackageFound: boolean = false;
  addCustPackForm: FormGroup
  isProcessing: boolean = false

  displayedColumns: string[] = ['Id', 'Comment', 'CompName', 'CreatedName', 'CreatedDate'];

  dataSource = [];

  spanOption: boolean = true;
  spanOptionToggle() {
    this.spanOption = !this.spanOption;
  }

  public ListingData: any
  public AllStandardPackages = [];
  public customizePackageId: number
  constructor(private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: CustomizePackageCommentListComponent,
    private commonService: CommonService,
    private customizepackageserviceService: CustomizepackageserviceService,
    private datePipe: DatePipe,
    private appSettings: AppSettings,
    private dialogRef: MatDialogRef<CustomizePackageCommentListComponent>) {
    this.ListingData = data;
    this.customizePackageId = this.ListingData.planDetail.customizePackageId
  }

  ngOnInit() {
    this.initForm()
    this.getCustomizePackageCommentsListing(false)
  }

  initForm() {
    this.addCustPackForm = this.formBuilder.group({
      'Comment': [null, [Validators.required]]
    });
  }

  getCustomizePackageCommentsListing(isPaging: boolean) {
    try {
      let params = {
        PageNumber: this.pageNumber,
        PageSize: this.pageSize,
        CustomizePackageId: this.customizePackageId
      }
      this.customizepackageserviceService.getCustomizePackageCommentsListing(params).subscribe(response => {
        if (response.success) {
        //  console.log(response.data);
          this.dataSource = response.data;
          if (response.data.length < this.pageSize) {
            this.isLoadMore = false;
          }
          else {
            this.isLoadMore = true;
          }

          if (response.data.length <= 0) {
            this.NoPackageFound = true;
          }
          else {
            this.NoPackageFound = false;
          }

          if (isPaging) {
            this.appendCustomizePackageListing(response.data);
          }
          else {
            this.dataSource = response.data;
          }
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch{
      this.commonService.commonSnakeBar();
    }
  }

  appendCustomizePackageListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.dataSource.length > 0) {
        newState = this.dataSource.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.dataSource = newState;
    }
  }

  loadMore() {
    this.pageNumber = this.pageNumber + 1
    this.pageSize = this.pageSize
    this.getCustomizePackageCommentsListing(true);
  }

  public addCustomizePackgeComment(form) {
    if (form.valid) {
      try {
        this.isProcessing = true;
        let params = {
          Comment: this.addCustPackForm.controls.Comment.value.trim(),
          CustomizePackageId: this.customizePackageId
        }
        this.customizepackageserviceService.addCustPackageComment(params).subscribe(resposne => {
          if (resposne.success) {
            this.isProcessing = false;
            this.getCustomizePackageCommentsListing(false)
            this.addCustPackForm.get('Comment').patchValue([]);
            this.commonService.showSnakeBar(resposne.message);
          }
          else {
            this.isProcessing = false;
            this.commonService.showSnakeBar(resposne.message);
          }

        }, (err) => {
          this.isProcessing = false;
          this.commonService.checkTokenValidity(err);
        })
      }
      catch
      {
        this.isProcessing = false;
        this.commonService.commonSnakeBar();
      }
    }
  }

  

}
