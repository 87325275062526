import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { MatDialogRef, MatDialog } from '@angular/material';
import { element } from '@angular/core/src/render3/instructions';
import { AuthService } from '../../core/services/auth.service';
import { DirectCallingServicesService } from '../services/direct-calling-services.service';
import { DirectDialListComponent } from '../direct-dial-list/direct-dial-list.component';

@Component({
  selector: 'app-create-direct-calling-candidate',
  templateUrl: './create-direct-calling-candidate.component.html',
  styleUrls: ['./create-direct-calling-candidate.component.scss']
})
export class CreateDirectCallingCandidateComponent implements OnInit {
  createCandForm: FormGroup
  skillList = [];

  IsVoiceMailSMS: boolean = false;
  isProcessing: boolean = false;
  constructor(private directCallingServicesService: DirectCallingServicesService,
    private authService: AuthService,
    public dialog: MatDialog,
    private formbuilder: FormBuilder,
    private commonService: CommonService,
    private dialogRef: MatDialogRef<CreateDirectCallingCandidateComponent>) { }

  ngOnInit() {
    this.intiForm()
    this.getSkillDropDown();
  }

  intiForm() {
    this.createCandForm = this.formbuilder.group({
      'Name': [null, [Validators.required, this.noWhitespaceValidator]],
      'Email': [null],
      'Phone': [null, [Validators.required, this.noWhitespaceValidator]],
      'SMS': [null],
      'CandidateSkill': [[], [Validators.required]],
      'VoiceMail': [false],
      'VoiceMailSMS': [null]
    })
  }

  showOptions(event) {
    if (event.checked) {
      this.IsVoiceMailSMS = true
    }
    else {
      this.IsVoiceMailSMS = false
    }
  }

  getSkillDropDown() {
    let params = {
      SpOption: 'Add'
    }
    this.directCallingServicesService.getSkillDropDown(params).subscribe(response => {
      if (response.success) {
        let skill = [];

        response.data.forEach(element => {
          skill.push({
            id: element.id,
            name: element.name
          })
        });
        this.skillList = skill
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }


  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  candidateId: any
  createDirectCallingCandidate(form) {
    if (form.valid) {
      this.isProcessing = true;
      const formData = new FormData();
      formData.append("Name", this.createCandForm.controls.Name.value);
      formData.append("SMS", this.createCandForm.controls.SMS.value);
      formData.append("Phone", this.createCandForm.controls.Phone.value);
      formData.append("Email", this.createCandForm.controls.Email.value);
      formData.append("VoiceMail", this.createCandForm.controls.VoiceMail.value);
      formData.append("VoiceMailSMS", this.createCandForm.controls.VoiceMailSMS.value);
      formData.append("CandidateSkill", this.createCandForm.controls.CandidateSkill.value);

      this.directCallingServicesService.createCandidateDirectCall(formData).subscribe(response => {
        if (response.success) {
          //  this.commonService.showSnakeBar(response.message);
          this.closeDialog();
          this.isProcessing = false;
          this.candidateId = response.data;
          this.StartSecondFrontEndCallModal();
        }
        else {
          this.commonService.showSnakeBar(response.message);
          this.isProcessing = false;
        }
      }, (err) => {
        this.isProcessing = false;
        this.commonService.checkTokenValidity(err);
        this.closeDialog();
      })
    }
    else {
      this.commonService.showSnakeBar("Please do the correction");
    }
  }

  closeDialog() {
    this.dialogRef.close();

  }

  public numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  StartSecondFrontEndCallModal(): void {
    let candidateDetail = {
      Id: this.candidateId,
      Name: this.createCandForm.controls.Name.value,
      Phone: this.createCandForm.controls.Phone.value,
      StatusId: 13,
      Status: 'Pending'
    }
    const dialogRef = this.dialog.open(DirectDialListComponent, {
      disableClose: true,
      panelClass: ['modal-outer', 'modal-md'],
      data: {
        candidateDetail
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.getCandidateDetailsForFrontEnd();
      location.reload();
    })
  }


}
