import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResultModel } from '../../core/model/result.model';
import { ApiService } from '../../core/services/api.service';
import { ApiUrls } from '../../config/api-urls';


@Injectable({
  providedIn: 'root'
})
export class ComponentPlanService {

  constructor(  private apiService: ApiService,
    private apiUrl: ApiUrls) { }


  componentPlanListingListing(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.ComponentPlanListing, reqModel);
  }

  addComponentPlan(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.AddComponentPlan, reqModel);
  }

  addComponentType(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.AddComponentType, reqModel);
  }

  editComponentPlan(editModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.EditComponentPlan, editModel)
  }
 

  getMainComponentDropdown(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.GetMainComponentDropdown, reqModel);
  }

  getComponentTypeDropdown(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.GetComponentTypeDropdown, reqModel);
  }

  getComponentPlanCalculate(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.GetComponentPlanCalculate, reqModel);
  }
}
