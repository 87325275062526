import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { AppSettings } from '../../config/app-settings';
import { CommonService } from '../../core/services/common.service';
import { MenuDetails } from '../services/Menu.details';
import { ActivatedRoute } from '@angular/router';
import { JwtService } from '../services/jwt.service';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  public menu_items: any;
  pageNumber: number = 1

  userId: number = 1;
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  isLoadMore: boolean = false

  userData: any

  constructor(
    private authService: AuthService,
    private appSettings: AppSettings,
    private commonService: CommonService,
    private MenuDetails: MenuDetails,
    private route: ActivatedRoute,
    private jwtService: JwtService,
  ) {
    //this.ReferenceId = this.route.snapshot.paramMap.get("id");
    this.route.params.subscribe((value) => {
      this.userId = 1; // get param
      this.allMenuListng();
      // console.log(this.ReferenceId, "this.ReferenceId")
    });

  }

  ngOnInit() {
    this.menu_items = this.allMenuListng();

    if (this.authService.isCompanyAdmin()) {
    //  this.menu_items = this.companyCompanyAdminTiles();
    }
    else if (this.authService.isSubscriber()) {
     // this.menu_items = this.companySubscriberTiles();
    }
    else if (this.authService.isRecruiter()) {
     // this.menu_items = this.companyRecuriterTiles();
    }
    else if (this.authService.isSuperAdmin()) {
    //  this.menu_items = this.companySuperAdminTiles();
    }

    // this.menu_items = this.companyAdminTiles();
  }

  companyCompanyAdminTiles() {
    return {
      items: [
        {
          "id": "1",
          "menu_name": "Campaigns",
          "menu_link": "/campaign/",
        },
        {
          "id": "2",
          "menu_name": "Inbound",
          "menu_link": "/inbound/",
        },
        {
          "id": "3",
          "menu_name": "Manager",
          "menu_link": "/subscriber/",
        },
        {
          "id": "4",
          "menu_name": "Recruiter",
          "menu_link": "/recruiter/",
        },
        {
          "id": "5",
          "menu_name": "Skill",
          "menu_link": "/skill/",
        },
        {
          "id": "6",
          "menu_name": "Call Forwarding",
          "menu_link": "/call-forwarding/",
        }
      ]
    }
  }

  companySubscriberTiles() {
    return {
      items: [
        {
          "id": "1",
          "menu_name": "Campaigns",
          "menu_link": "/campaign/",
        },
        {
          "id": "2",
          "menu_name": "Inbound",
          "menu_link": "/inbound/",
        },
        {
          "id": "4",
          "menu_name": "Recruiter",
          "menu_link": "/recruiter/",
        },
        {
          "id": "5",
          "menu_name": "Skill",
          "menu_link": "/skill/",
        },
        {
          "id": "6",
          "menu_name": "Call Forwarding",
          "menu_link": "/call-forwarding/",
        }
      ]
    }
  }

  companyRecuriterTiles() {
    return {
      items: [
        {
          "id": "1",
          "menu_name": "Campaigns",
          "menu_link": "/campaign/",
        },
        {
          "id": "2",
          "menu_name": "Inbound",
          "menu_link": "/inbound/",
        },
        {
          "id": "6",
          "menu_name": "Call Forwarding",
          "menu_link": "/call-forwarding/",
        }
      ]
    }
  }

  companySuperAdminTiles() {
    return {
      items: [
        {
          "id": "7",
          "menu_name": "Standard Packages",
          "menu_link": "/standardpackage/",
        },
        {
          "id": "8",
          "menu_name": "Customize Packages",
          "menu_link": "/standardpackage/",
        }
      ]
    }
  }

  allMenuListng() {
    try {
      let params = {
        UserId: this.userId
      }
      this.MenuDetails.getMenuList(params).subscribe(response => {
        
        if (response.success) {
          if(response.data.length >0)
          {
            this.menu_items=(response.data);
          } 
          else{
            this.jwtService.deleteToken();
            this.commonService.showSnakeBar("User is deactivated");
            this.commonService.navigate('/login');
          }
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch{
      this.commonService.commonSnakeBar();
    }
  }

}
