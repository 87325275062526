import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CreateCampaignComponent } from '../create-campaign/create-campaign.component';
import { CampaignService } from '../service/campaign.service';
import { CommonService } from '../../core/services/common.service';
import { AppSettings } from '../../config/app-settings';
import { StatusMaster } from '../../core/constants/status';
import { SharedService } from '../../shared/service/shared.service';
import { JwtService } from '../../core/services/jwt.service';
import { CampaignDialListComponent } from '../campaign-dial-list/campaign-dial-list.component';



@Component({
  selector: 'app-campaign-auto-dial',
  templateUrl: './campaign-auto-dial.component.html',
  styleUrls: ['./campaign-auto-dial.component.scss']
})
export class CampaignAutoDialComponent implements OnInit {
  status: any = StatusMaster;

  displayedColumns: string[] = ['Id', 'CampaignId', 'ReferenceId', 'CandidateName',
    'Phone', 'Skill', 'StatusId', 'Status'];
  ListingData = []
  userdata: any
  campaignId: any

  constructor(@Inject(MAT_DIALOG_DATA) public data: CampaignAutoDialComponent,
    public dialog: MatDialog,
    private campaignService: CampaignService,
    private commonService: CommonService,
    private sharedService: SharedService,
    private jwtService: JwtService,
    private dialogRef: MatDialogRef<CampaignAutoDialComponent>,
    private appSettings: AppSettings) {
    this.userdata = data;
    this.campaignId = this.userdata.campDetail.campaignId;
  }

  ngOnInit() {
    this.getCandidateDetailsForFrontEnd()
  }

  getCandidateDetailsForFrontEnd() {
    let params = {
      CampaignId: this.campaignId
    }
    this.campaignService.getCandidateDetailsForFrontEnd(params).subscribe(response => {
      if (response.success) {
        this.ListingData = response.data;
      }
      else {
        this.commonService.commonSnakeBar();
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  StartSecondFrontEndCallModal(): void {
    let campDetail = this.ListingData
    const dialogRef = this.dialog.open(CampaignDialListComponent, {
      disableClose: true,
      panelClass: ['modal-outer', 'modal-md'],
      data: {
        campDetail
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    //  this.getCandidateDetailsForFrontEnd();  here we have to set the api for status change
    })
  }

}
