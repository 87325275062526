import { Component, OnInit } from '@angular/core';
import { UserDetailService } from '../services/user-detail.service';
import { ActivatedRoute } from '@angular/router';
import { StatusMaster } from '../constants/status';
import { CommonService } from '../services/common.service';
import { AppSettings } from '../../config/app-settings';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit {

  ReferenceId: string
  CandidateName: string
  CampaignName: string
  Status: string
  RecordingURL: string
  RecordingDuration: any
  CallTime: string


  status: any = StatusMaster
  dateFormat: any

  StatusId: number

  audio: any = new Audio();
  IsPlaying: boolean = false;

  isSMSAllowed: boolean

  IsNoRecordFound: boolean = false
  IsRecordFound: boolean = false
  campType: any

  constructor(
    private route: ActivatedRoute,
    private userDetailService: UserDetailService,
    private commonService: CommonService,
    private appSettings: AppSettings
  ) {
    //this.ReferenceId = this.route.snapshot.paramMap.get("id");
    this.route.params.subscribe((value) => {

      this.ReferenceId = value["id"]; // get param
      this.campType = value["val"];
      console.log(this.campType);
      this.getCandidateDetails();
      // console.log(this.ReferenceId, "this.ReferenceId")

      this.dateFormat = this.appSettings.CAMPAIGN_DATE
    });


  }

  ngOnInit() {
    //this.getCandidateDetails();
  }


  getCandidateDetails() {
    if (this.ReferenceId == null || this.ReferenceId == undefined || this.ReferenceId == "") {
      this.IsRecordFound = true;
      return;
    }
    let params = {
      ReferenceId: this.ReferenceId,
      CampaignType: this.campType
    }
    this.userDetailService.CandidateSearch(params).subscribe(response => {
      if (response.success) {
        if (response.data.length > 0) {
          this.CandidateName = response.data[0].candidateName;
          this.CampaignName = response.data[0].campaignName;
          this.Status = response.data[0].status;
          this.RecordingURL = response.data[0].recordingURL;
          this.StatusId = response.data[0].statusId;
          this.isSMSAllowed = response.data[0].isSMSAllowed;
          this.RecordingDuration = this.SecondsTohhmmss(response.data[0].recordingDuration);//response.data[0].recordingDuration;
          this.CallTime = response.data[0].callTime;

          this.IsRecordFound = true;
        }
        else {
          this.IsNoRecordFound = true;
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  playAudio() {
    if (this.IsPlaying) {
      this.audio.pause();
      this.IsPlaying = false;
    }
    else {
      this.audio.src = this.RecordingURL;
      this.audio.load();
      this.audio.play();
      this.IsPlaying = true;
    }
  }

  onChange(value: any) {
    this.UpdateSMSStatus(value.checked);
  }

  UpdateSMSStatus(smsStatus: boolean) {
    try {
      let params = {
        ReferenceId: this.ReferenceId,
        SmsStatus: smsStatus,
        CampaignType: this.campType
      }
      this.userDetailService.SMSOptOut(params).subscribe(resposne => {
        if (resposne.success) {
          this.commonService.showSnakeBar(resposne.message);
          // this.getCandidateDetails();
        }
        else {
          this.commonService.showSnakeBar(resposne.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    }
    catch{
      this.commonService.commonSnakeBar();
    }
  }

  SecondsTohhmmss = function (totalSeconds) {
    var hours = Math.floor(totalSeconds / 3600);
    var minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
    var seconds = totalSeconds - (hours * 3600) - (minutes * 60);

    // round seconds
    seconds = Math.round(seconds * 100) / 100

    var result = (hours < 10 ? "0" + hours : hours);
    result += ":" + (minutes < 10 ? "0" + minutes : minutes);
    result += ":" + (seconds < 10 ? "0" + seconds : seconds);
    return result;
  }

}
