import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SharedService } from '../service/shared.service';
import { JwtService } from '../../core/services/jwt.service';
import { DataService } from '../../core/services/data.service';
import { CommonService } from '../../core/services/common.service';

@Component({
  selector: 'app-profile-big-screen',
  templateUrl: './profile-big-screen.component.html',
  styleUrls: ['./profile-big-screen.component.scss']
})
export class ProfileBigScreenComponent implements OnInit {
  public updateProfileFrom: FormGroup;
  userData: any
  email: string
  name: string
  phone: number
  imageUrl: string
  userId: number
  selectedFile: File
  fileName: string
  isProcessing: boolean = false;
  // this.email = this.userData.userInfo.email
  // this.name = this.userData.userInfo.username
  // this.phone = this.userData.userInfo.number
  // this.imageUrl = this.userData.userInfo.image

  constructor(
    private fromBuilder: FormBuilder,
    private sharedService: SharedService,
    private commonService: CommonService,
    private jwtServie: JwtService,
    private dataService: DataService,
  ) {
    console.log('ProfileBigScreenComponent');
  }

  ngOnInit() {
    this.userId = this.jwtServie.getCurrentUserId();
    this.userDetails()
    this.initForm()
  }

  initForm() {
    this.updateProfileFrom = this.fromBuilder.group({
    })

  }

  userDetails() {
    try {
      let param =
      {
        UserId: this.userId
      }
      this.sharedService.getUserDetails(param).subscribe(response => {
        if (response.success) {
          if (response.data.length > 0) {
            this.email = response.data[0].email;
            this.name = response.data[0].name
            this.phone = response.data[0].phone
            if (response.data[0].profileImage != null) {
              this.imageUrl = response.data[0].profileImage
            }
            else {
              this.imageUrl = './assets/img/user.jpg'
            }
          }
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch{
      this.commonService.commonSnakeBar();
    }
  }

  updateProfile(form) {
    if (form.valid) {
      try {
        this.isProcessing = true;
        const formData = new FormData();

        if (this.selectedFile != null || this.selectedFile != undefined) {
          if (this.selectedFile.name.split('.').pop() != "jpg" && this.selectedFile.name.split('.').pop() != "png") {
            this.commonService.showSnakeBar("Only jpg and png file extension allowed");
            this.isProcessing = false;
            return;
          }
          else {
            formData.append(this.selectedFile.name, this.selectedFile);
          }
        }

        formData.append("UserId", this.userId.toString());
        formData.append("Name", this.updateProfileFrom.controls.Name.value.trim());
        formData.append("Phone", this.updateProfileFrom.controls.Phone.value.trim());

        this.sharedService.updateUserProfileFormRequest(formData).subscribe(response => {
          if (response.success) {
            this.commonService.showSnakeBar(response.message);
            this.dataService.SetMessage(true);
            this.isProcessing = false;
          }
          else {
            this.commonService.showSnakeBar(response.message);
            this.isProcessing = false;
          }
        }, (err) => {
          this.commonService.commonSnakeBar();
          this.isProcessing = false;
        })
      }
      catch{
        this.isProcessing = false;
        this.commonService.commonSnakeBar();
      }
    }
    else {
      this.commonService.showSnakeBar("Form not valid");
    }
  }

}
