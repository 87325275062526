import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';


@Component({
  selector: 'app-confirm-web-call-acceptdialog',
  templateUrl: './confirm-web-call-acceptdialog.component.html',
  styleUrls: ['./confirm-web-call-acceptdialog.component.scss']
})
export class ConfirmWebCallAcceptdialogComponent implements OnInit {
  message: string;
  constructor(public dialogRef: MatDialogRef<ConfirmWebCallAcceptdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmWebCallAcceptdialogComponent) {
    this.message = data.message;
  }

  ngOnInit() {
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

  startTimer() {
    let totalSeconds = 0;

    setInterval(function () {
      let minutesLabel = document.getElementById("minutes");
      let secondsLabel = document.getElementById("seconds");
      let hoursLabel = document.getElementById("hours");

      ++totalSeconds;
      let valStringSec = Number(totalSeconds % 60).toFixed() + "";
      if (valStringSec.length < 2) {
        valStringSec = "0" + valStringSec;
      }

      secondsLabel.innerHTML = valStringSec;

      let valStringMin = totalSeconds / 60 + "";

      if (valStringMin.length < 2) {
        valStringMin = "0" + valStringMin;
      }

      minutesLabel.innerHTML = String(Math.floor(Number(valStringMin)));

      let valStringHour = totalSeconds / 3600 + "";

      if (valStringHour.length < 2) {
        valStringHour = "0" + valStringHour;
      }
      hoursLabel.innerHTML = String(Math.floor(Number(valStringHour)));
    }, 1000);
  }
}

export class ConfirmDialogModel {
  constructor(public message: string) {
  }

}
