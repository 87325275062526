import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialComponentsModule } from '../material-components.module';
import { PackageManagementListComponent } from './package-management-list/package-management-list.component';
import { AppRoutingModule } from '../app-routing.module'
import { StandardpackagesModule } from '../standardpackages/standardpackages.module'
import { CustomizePackageSuperAdminModule } from '../customize-package-super-admin/customize-package-super-admin.module'
import { ComponentPlanModule } from '../component-plan/component-plan.module'
import { CustomizedApprovedPackagesModule } from '../customized-approved-packages/customized-approved-packages.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialComponentsModule,
    AppRoutingModule,
    StandardpackagesModule,
    CustomizePackageSuperAdminModule,
    ComponentPlanModule,
    CustomizedApprovedPackagesModule
  ],
  declarations: [PackageManagementListComponent]
})
export class PackageManagementModule { }
