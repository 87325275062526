import { Component, OnInit, HostListener } from '@angular/core';
import { Detail } from '../../users/user-auth/detail';
import { UserDetailService } from '../services/user-detail.service';
import { JwtService } from '../services/jwt.service';
import { DataService } from '../services/data.service';
import { Router } from '@angular/router';
import { TwilioService, TwilioTokenType } from '../services/twilio.service';
import { SharedService } from '../../shared/service/shared.service';
import { CommonService } from '../services/common.service';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ConfirmWebCallAcceptdialogComponent } from '../../shared/confirm-web-call-acceptdialog/confirm-web-call-acceptdialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  userDetail: Detail;
  imageUrl: string
  public UpdatedValues: boolean;
  public innerWidth: any;
  public showLeftMenu: boolean;
  role: any
  incomingDialog: MatDialogRef<ConfirmDialogComponent> = null;
  acceptCallDialog: MatDialogRef<ConfirmWebCallAcceptdialogComponent> = null;
  userId: any;
  popupResult: boolean = false;
  popupResults: boolean = false;
  callingUserName: any
  constructor(
    public dialog: MatDialog,
    private userDetailsService: UserDetailService,
    private jwtService: JwtService,
    private dataService: DataService,
    private router: Router,
    private readonly twilioService: TwilioService,
    private sharedService: SharedService,
    private commonService: CommonService
  ) {
    this.dataService.getMessage().subscribe(message => {
      if (message) {
        this.GetDetails();
      }
    })
  }

  ngOnInit() {
    this.GetDetails();
    this.userDetail = new Detail("", "", "", "");
    this.innerWidth = window.innerWidth;
    this.showLeftMenu = false;
    this.role = this.jwtService.getUserRole();
    this.userId = this.jwtService.getCurrentUserId();
    //console.log('role',this.role)
    if (this.role != "SuperAdmin" && this.role != "SuperSubAdmin") {
      this.twilioListener();
    }
  }
  /**
   * setup listener for twilio events
   */
  twilioListener() {
    this.twilioService.listner(TwilioTokenType.Capability, async (type, conn) => {
      //console.log('twilioListener_incomming..', conn);
      if (type === 'incoming') {
        this.incomingHandler(conn);
      }
      if (type === 'cancel') {
        this.cancelHandler(conn);
      }
      if (type === 'disconnect') {
        this.disconnectHandler(conn);
      }
    });
  }

  /**
   * incoming call handler
   * @param conn 
   */
  async incomingHandler(conn: any) {
    let promise = this.getWebCallerDetails();
    let callingUserName = await promise.then(function (val) {
      return val;
    })
    const dialogData = new ConfirmDialogModel(this.callingUserName);
    this.incomingDialog = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      width: '320px',
      height: '330px',
      panelClass: "calling-popup"
    });
    this.incomingDialog.afterClosed().subscribe(dialogResult => {
      this.popupResult = dialogResult;
      if (this.popupResult == true) {
        conn.accept();
        const dialogData = new ConfirmDialogModel(this.callingUserName);
        this.acceptCallDialog = this.dialog.open(ConfirmWebCallAcceptdialogComponent, {
          data: dialogData,
          width: '320px',
          height: '330px',
          panelClass: "calling-popup"
        });
        this.acceptCallDialog.afterClosed().subscribe(dialogResults => {
          this.popupResult = dialogResults;
          if (this.popupResults == false) {
            location.reload();
          }
        });
      }
      else {
        conn.reject();
      }
    });

  }
  /**
   * cancel handler
   * @param conn 
   */
  cancelHandler(conn: any) {
    //console.log('cancelHandler');
    if (this.incomingDialog) {
      this.incomingDialog.close(false);
      this.incomingDialog = null;
    }
  }
  /**
   * disconnect handler
   * @param conn 
   */
  disconnectHandler(conn: any) {
    //console.log('disconnectHandler');
    if (this.acceptCallDialog) {
      this.acceptCallDialog.close(false);
      this.acceptCallDialog = null;
    }
  }
  /**
   * get caller detail for identity
   */
  public getWebCallerDetails() {
    var promise = new Promise((resolve, reject) => {
      let params = {
        UserId: this.userId
      }
      this.sharedService.getWebCallerDetails(params).subscribe(response => {
        if (response.success) {
          if (response.data.length > 0) {
            this.callingUserName = response.data[0].name
            resolve(this.callingUserName);
          }
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    });
    return promise;
  }
  /**
   * mute
   */
  menuToggle() {
    this.showLeftMenu = true;
    document.body.className = 'slide-menu-open';
  }
  /**
   * close
   */
  closeToggle() {
    this.showLeftMenu = false;
    document.body.removeAttribute("class");
    //document.body.className = '';
  }
  /**
   * get details
   */
  GetDetails() {
    let userId = this.jwtService.getCurrentUserId();
    this.userDetailsService.getDetails(userId).subscribe(response => {
      if (response.success) {
        // console.log(response.data[0])
        // console.log(response.data[0].name,"Name")

        if (response.data[0].profileImage == null) {
          this.imageUrl = "./assets/img/user.jpg"
        }
        else {
          this.imageUrl = response.data[0].profileImage
        }
        this.userDetail = new Detail(response.data[0].name, this.imageUrl, response.data[0].email, response.data[0].phone);
      }
    })
  }

  /**
   * Check device width
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
  /**
   * navigate dashboard
   */
  dashboard() {
    if (this.role != 'SuperAdmin' && this.role != 'SuperSubAdmin') {
      this.router.navigate(["/dashboard"]);
    }
  }

} 
