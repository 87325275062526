import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { InboundService } from '../service/inbound.service';
import { CommonService } from '../../core/services/common.service';
import { AppSettings } from '../../config/app-settings';
import { StatusMaster } from '../../core/constants/status';
import { FormControl } from '@angular/forms';
import { SharedService } from '../../shared/service/shared.service';
import { JwtService } from '../../core/services/jwt.service';

export interface PeriodicElement {
  ref: string
  phone: string;
  recording_time: string;
  time: string;
  received: string;
  list: string;
  skill: string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  { ref: 'VL-111', phone: '+1-740-956-5600', recording_time: '0:30:55', time: '06:40 AM', received: 'Flyod j. Felker', list: 'Upgrade Prompt', skill: '.Net Developer' },
  { ref: 'VL-112', phone: '+1-863-603-6221', recording_time: '1:09:25', time: '11:10 AM', received: 'Steven G. Ashurst', list: 'Pricing Changes', skill: 'Graphics Designer' },
  { ref: 'VL-113', phone: '+1-904-923-2279', recording_time: '0:00:08', time: '01:50 PM', received: 'Wanda R. Harmon', list: 'Unknown', skill: 'PHP Developer' },
  { ref: 'VL-114', phone: '+1-559-674-8543', recording_time: '0:08:32', time: '09:00 PM', received: 'Floyd J. Felker', list: 'Pricing Changes', skill: 'Software Tester' },

];

@Component({
  selector: 'app-inbound-list',
  templateUrl: './inbound-list.component.html',
  styleUrls: ['./inbound-list.component.scss']
})
export class InboundListComponent implements OnInit {
  displayedColumns: string[] = ['referenceId', 'phone', 'startTime', 'receivedBy', 'date', 'campaignName', 'skill'];
  displayedColumnsNotConnected: string[] = ['referenceId', 'phone', 'startTime', 'date', 'campaignName', 'skill'];
  conRecords = [];
  dataSourceNotConnected = [];
  expanded: boolean = false;
  panelOpenState: boolean = true;

  customCollapsedHeight: string = '38px';
  customExpandedHeight: string = '38px';


  pageNumberConnected: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE

  pageNumberNotConnected: number = 1

  pageNumberParameter: number
  searchBy: string
  filterBy: string

  statusMstr: any = StatusMaster;
  dateFormat: any
  createdDateFormat: any

  audio: any = new Audio();
  playingId: number
  isAudioPlayed: boolean = false;

  isLoadMore: boolean = true
  isNonLoadMore: boolean = true
 // noRecordFoundCon: boolean = false
  noRecordFoundNotCon: boolean = false

  StartDateValue = new FormControl(new Date());
  EndDateValue = new FormControl(new Date());

  StartDateParam: any = this.StartDateValue.value.toLocaleDateString();
  EndDateParam: any = this.EndDateValue.value.toLocaleDateString();

  selected = 'Everything';

  constructor(
    private inboundService: InboundService,
    private commonService: CommonService,
    private appSettings: AppSettings,
    private sharedService: SharedService,
    private jwtService: JwtService
  ) {
    this.dateFormat = this.appSettings.INBOUND_DATE;
    this.createdDateFormat = this.appSettings.CREARTED_DATE_INBOUND_LISTING;
  }

  ngOnInit() {
    const role = this.jwtService.getUserRole();
    if (role != 'CompanyAdmin') {
      this.getAddEditDeletePermission();
    }

    this.inboboundListing(StatusMaster.Connected, false);
    this.inboboundListing(StatusMaster.NotConnected, false);
  }


  noRecordFoundNotConConnected: boolean = false
  noRecordFoundNotConNotConnected: boolean = false
  inboboundListing(statusId: number, isPagign: boolean) {
    if (statusId == StatusMaster.Connected) {
      this.pageNumberParameter = this.pageNumberConnected
    }
    else {
      this.pageNumberParameter = this.pageNumberNotConnected
    }

    let pararms = {
      StatusId: statusId,
      PageNumber: this.pageNumberParameter,
      PageSize: this.pageSize,
      SearchBy: this.searchBy,
      FilterBy: this.filterBy,
      StartDate: this.StartDateParam,
      EndDate: this.EndDateParam
    }

    this.inboundService.inboundListing(pararms).subscribe(resposne => {
      
      if (resposne.success) {
        let len = resposne.data.length;

          // Check if record Exists
          if (len > 0) {
            this.noRecordFoundNotCon = true
          }
          else {
            this.noRecordFoundNotCon = false
          }
     //   }

        if (isPagign) {
          if (resposne.data.length < this.pageSize && statusId == StatusMaster.Connected) {
            this.isLoadMore = false;

          }
          else if (resposne.data.length < this.pageSize && statusId == StatusMaster.NotConnected) {
            this.isNonLoadMore = false;

          }

          this.appendInboundCallListing(resposne.data, statusId);
        }
        else {
          // Connected records
          if (resposne.data.length > 0 && statusId == StatusMaster.Connected) {
            let len = resposne.data.length;
            if (len > 0) {
               this.noRecordFoundNotConConnected = true
             }
             else {
               this.noRecordFoundNotConConnected = false
             }

          }
          else {
            this.isLoadMore = false;
          }

          //Not Connected records
          if (resposne.data.length <= 0 && statusId == StatusMaster.NotConnected) {
            this.isNonLoadMore = false;

            let len = resposne.data.length;
            if (len > 0) {
               this.noRecordFoundNotConNotConnected = true
             }
             else {
               this.noRecordFoundNotConNotConnected = false
             }

          }
          else {
            this.isNonLoadMore = false;
          }

          if (pararms.StatusId == StatusMaster.Connected) {
            this.conRecords = resposne.data;
          }
          else {
            this.dataSourceNotConnected = resposne.data;
          }
        }
      }
      else {
        this.commonService.commonSnakeBar();
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  playAudio(element) {
    if (element.id == this.playingId && this.isAudioPlayed) {
      this.audio.pause();
      this.isAudioPlayed = false
    }
    else {
      var index = this.conRecords.findIndex(ds => ds.id == element.id);
      this.audio.src = element.recordingURL;
      this.audio.load();
      this.audio.play();
      this.playingId = element.id
      this.isAudioPlayed = true;
    }
  }

  loadMore(statusId: number) {
    if (statusId == StatusMaster.Connected) {
      this.pageNumberConnected = this.pageNumberConnected + 1
    }
    else {
      this.pageNumberNotConnected = this.pageNumberNotConnected + 1
    }
    this.pageSize = this.pageSize
    this.inboboundListing(statusId, true);
  }


  appendInboundCallListing(newList = [], statusId: number) {
    if (newList.length > 0) {
      // let newState = [];

      if (statusId == StatusMaster.Connected) {

        let newState = [];
        if (this.conRecords.length > 0) {
          newState = this.conRecords.slice();
        }
        newList.forEach(element => {
          newState.push(element)
        });
        this.conRecords = newState;

        // newList.forEach(element => {

        //   var index = this.conRecords.findIndex(ds => ds.date === element.date)
        //   console.log(index, "index")

        //   if (index != -1) {
        //     element.activities.forEach(act => {
        //       console.log(act)
        //       this.conRecords[index]["activities"].push(act);
        //     });
        //     this.conRecords[index]["activities"] = this.conRecords[index]["activities"].slice()
        //   }
        //   else {
        //     this.conRecords.push(element)
        //   }
        // });
        // this.conRecords = this.conRecords.slice()

      }
      else {
        let newState = [];
        if (this.dataSourceNotConnected.length > 0) {
          newState = this.dataSourceNotConnected.slice();
        }
        newList.forEach(element => {
          newState.push(element)
        });
        this.dataSourceNotConnected = newState;

      }
    }
  }

  onChange(value: string) {
    this.searchBy = value;

    if (this.filterBy == undefined || this.filterBy == null) {
      this.filterBy = this.selected
    }
    else {
      this.filterBy = this.filterBy;
    }
    this.pageNumberConnected = 1;
    this.pageNumberNotConnected = 1;

    this.inboboundListing(StatusMaster.Connected, false);
    this.inboboundListing(StatusMaster.NotConnected, false);
  }

  dropdownChange(value: string) {
    this.filterBy = value;
  }

  SecondsTohhmmss = function (totalSeconds) {
    var hours = Math.floor(totalSeconds / 3600);
    var minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
    var seconds = totalSeconds - (hours * 3600) - (minutes * 60);

    // round seconds
    seconds = Math.round(seconds * 100) / 100

    var result = (hours < 10 ? "0" + hours : hours);
    result += ":" + (minutes < 10 ? "0" + minutes : minutes);
    result += ":" + (seconds < 10 ? "0" + seconds : seconds);
    return result;
  }

  StartdateChange() {
    if (this.StartDateValue.value.toLocaleDateString() > this.EndDateValue.value.toLocaleDateString()) {
      this.commonService.showSnakeBar("Start date must be less then End date");
      return;
    }

    this.EndDateParam = this.EndDateValue.value.toLocaleDateString();
    this.StartDateParam = this.StartDateValue.value.toLocaleDateString();

    // console.log(this.EndDateParam,"this.EndDateParam")
    // console.log(this.StartDateParam,"this.StartDateParam")
    this.pageNumberConnected = 1;
    this.pageNumberNotConnected = 1;
    this.inboboundListing(StatusMaster.Connected, false);
    this.inboboundListing(StatusMaster.NotConnected, false);
  }

  EnddateChange() {
    if (this.StartDateValue.value.toLocaleDateString() > this.EndDateValue.value.toLocaleDateString()) {
      this.commonService.showSnakeBar("Start date must be less then End date");
      return;
    }
    this.EndDateParam = this.EndDateValue.value.toLocaleDateString();
    this.StartDateParam = this.StartDateValue.value.toLocaleDateString();

    this.pageNumberConnected = 1;
    this.pageNumberNotConnected = 1;

    this.inboboundListing(StatusMaster.Connected, false);
    this.inboboundListing(StatusMaster.NotConnected, false);
  }



  fullControl: boolean = true;
  views: boolean = true;
  adds: boolean = true;
  edits: boolean = true;
  deletes: boolean = true;
  disableds: boolean = false;
  status: any = StatusMaster;
  getAddEditDeletePermission() {
    let params = {
      MenuId: 2
    }
    this.sharedService.getAddEditDeletePermission(params).subscribe(response => {
      if (response.success) {
        if (response.data.length > 0) {
          this.adds = response.data[0].adds
          this.views = response.data[0].views
          this.fullControl = response.data[0].fullControle
          this.deletes = response.data[0].deletes
          this.edits = response.data[0].edit
        }

        if (this.fullControl == true || this.edits == true) {
          this.disableds = false
        }
        else {
          this.disableds = true
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

}
