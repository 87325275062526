import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResultModel } from '../../core/model/result.model';
import { ApiService } from '../../core/services/api.service';
import { ApiUrls } from '../../config/api-urls';

@Injectable({
  providedIn: 'root'
})
export class PurchasedPackageServiceService {

  constructor( private apiService: ApiService,
    private apiUrl: ApiUrls) { }

    purchasedPackageListing(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.PurchasedPackageListing, reqModel);
    }
    getComponentInsertedData(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.GetComponentInsertedData, reqModel);
    }

    getPurchasePackageAndAllocateandUsedListing(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.GetPurchasePackageAndAllocateandUsedListing, reqModel);
    }

    getAllocateManagerMinsListing(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.GetAllocateManagerMinsListing, reqModel);
    }
    
    getSubcriberAllocatedMinutesListing(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.SubcriberAllocatedMinutesListing, reqModel);
    }

    getSubcriberDeAllocatedMinutesListing(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.SubcriberDeAllocatedMinutesListing, reqModel);
    }

    
    

}
