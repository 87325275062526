import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from '../../core/services/common.service';
import { SharedService } from '../../shared/service/shared.service';
import { JwtService } from '../../core/services/jwt.service';
import { AppSettings } from '../../config/app-settings';
import { SmsCampaignServicesService } from '../service/sms-campaign-services.service';
import { StatusMaster } from '../../core/constants/status';
import { CreateSMSCampaignComponent } from '../create-smscampaign/create-smscampaign.component';
import { EditSmsCampaignComponent } from '../edit-sms-campaign/edit-sms-campaign.component';
import { ConfirmSendSMSComponent } from '../../shared/confirm-send-sms/confirm-send-sms.component';
import { PauseCampaignComponent } from '../pause-campaign/pause-campaign.component';
import { ResumeCampaignComponent } from '../resume-campaign/resume-campaign.component';

@Component({
  selector: 'app-sms-campaign-list',
  templateUrl: './sms-campaign-list.component.html',
  styleUrls: ['./sms-campaign-list.component.scss']
})
export class SmsCampaignListComponent implements OnInit {
  public ListingData = [];
  public InActiveCampaign = [];

  NoActiveCampaign: boolean = false;
  NoInActiveCampaign: boolean = false;
  status: any = StatusMaster;

  public ActiveCampaign = [];

  constructor(public dialog: MatDialog,
    private smsCampaignServicesService: SmsCampaignServicesService,
    private commonService: CommonService,
    private sharedService: SharedService,
    private jwtService: JwtService,
    private appSettings: AppSettings) {
    this.getuserDetails();
  }

  ngOnInit() {
    this.smsCampaignListing();
  }


  smsCampaignListing() {
    let params = {
      CampaignId: 0
    }
    this.smsCampaignServicesService.smsCampaignListing(params).subscribe(response => {
      if (response.success) {
        this.ListingData = response.data;

        this.InActiveCampaign = this.ListingData.filter(Campstatus => Campstatus["statusId"] == StatusMaster.Stopped);

        if (this.InActiveCampaign.length > 0) {
          this.NoInActiveCampaign = false;
        }
        else {
          this.NoInActiveCampaign = true;
        }

        this.ActiveCampaign = this.ListingData.filter(Campstatus => Campstatus["statusId"] != StatusMaster.Stopped);

        if (this.ActiveCampaign.length > 0) {
          this.NoActiveCampaign = false;
        }
        else {
          this.NoActiveCampaign = true;
        }

      }
      else {
        this.commonService.commonSnakeBar();
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  CreateCampaignModal(): void {

    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(CreateSMSCampaignComponent, {
        disableClose: true,
        panelClass: "full-width-popup"
      });

      dialogRef.afterClosed().subscribe(result => {
        this.smsCampaignListing();
      })
    }
    else {
      this.commonService.userDeActivatedOrDeleted();
    }

  }

  EditCampaignModal(campDetail): void {


    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(EditSmsCampaignComponent, {
        disableClose: true,
        panelClass: "full-width-popup",
        data: {
          campDetail
        }

      });
      dialogRef.afterClosed().subscribe(result => {
        this.smsCampaignListing();
      })
    }
    else {
      this.commonService.userDeActivatedOrDeleted();
    }

  }

  SendSmsCampaignModal(campDetail): void {
    
    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(ConfirmSendSMSComponent, {
        width: '600px',
        height: 'auto',
        data: {
          campDetail
        }

      });
      dialogRef.afterClosed().subscribe(result => {
        this.smsCampaignListing();
      })
    }
    else {
      this.commonService.userDeActivatedOrDeleted();
    }

  }


  PauseCampaignModal(campDetail): void {

    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(PauseCampaignComponent, {
        width: '600px',
        height: 'auto',
        data: {
          campDetail
        }

      });
      dialogRef.afterClosed().subscribe(result => {
        this.smsCampaignListing();
      })
    }
    else {
      this.commonService.userDeActivatedOrDeleted();
    }

  }

  ResumeCampaignModal(campDetail): void {

    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(ResumeCampaignComponent, {
        width: '600px',
        height: 'auto',
        data: {
          campDetail
        }

      });
      dialogRef.afterClosed().subscribe(result => {
        this.smsCampaignListing();
      })
    }
    else {
      this.commonService.userDeActivatedOrDeleted();
    }

  }

  fullControl: boolean = true;
  views: boolean = true;
  adds: boolean = true;
  edits: boolean = true;
  deletes: boolean = true;
  disableds: boolean = false;
  getAddEditDeletePermission() {
    let params = {
      MenuId: 27
    }
    this.sharedService.getAddEditDeletePermission(params).subscribe(response => {
      if (response.success) {
        if (response.data.length > 0) {
          this.adds = response.data[0].adds
          this.views = response.data[0].views
          this.fullControl = response.data[0].fullControle
          this.deletes = response.data[0].deletes
          this.edits = response.data[0].edit
        }

        if (this.fullControl == true || this.edits == true) {
          this.disableds = false
        }
        else {
          this.disableds = true
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  userDetails = []
  statusId: number
  getuserDetails(): number {
    try {
      let userId = this.jwtService.getCurrentUserId();
      let param = {
        UserId: userId
      }
      this.sharedService.getUserDetails(param).subscribe(response => {
        if (response.success) {
          this.userDetails = response.data;
          this.statusId = response.data[0].statusId;
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch {
      this.commonService.commonSnakeBar();
    }
    return this.statusId;
  }

}
