import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { Observable } from 'rxjs';
import { ResultModel } from '../../core/model/result.model';
import { ApiUrls } from '../../config/api-urls';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private apiService: ApiService,
    private apiUrls: ApiUrls
  ) { }


  login(credentials): Observable<ResultModel> {

    return this.apiService.post<ResultModel>(this.apiUrls.UserManagment.Login, credentials);
  }

  forgetPassword(credentials): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrls.UserManagment.ForgetPassword, credentials);
  }

  getPurchasedPackageOrNot(params): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrls.CompanyAdmin.getPurchasedPackageOrNot, params);
  }

  generateTokenForClientCall(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrls.Campaign.GenerateTokenForClientCall, reqModel)
  }


}
