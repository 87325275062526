import { Injectable } from '@angular/core';
import * as jwt_decode from "jwt-decode";


@Injectable()
export class JwtService {
  token_name = 'access_token';
  user_Role = 'Role';
  user_id = 'UserId';
  user_email = 'Email';
  constructor() { }

  getToken(): string {
    const localToken = window.localStorage[this.token_name];
    const sessionToken = window.sessionStorage[this.token_name];
    if (localToken && localToken != '' && localToken != null && localToken != undefined) {
      return localToken;
    }
    else if (sessionToken && sessionToken != '' && sessionToken != null && sessionToken != undefined) {
      return sessionToken;
    }
    else {
      return '';
    }
  }

  saveToken(data: any, rememberMe: boolean) {
    if (rememberMe) {
      window.localStorage[this.token_name] = data;
    }
    else {
      window.sessionStorage[this.token_name] = data;
    }
  }

  deleteToken() {
    window.localStorage.clear();
    window.sessionStorage.clear();
  }

  getUserRole(): string {
    var decode = this.getDecodedAccessToken(this.getToken())

    return decode[this.user_Role];
  }

  getCurrentUserId(): number {
    var decode = this.getDecodedAccessToken(this.getToken())

    return decode[this.user_id];
  }

  getUserEmail(): string {

    var decode = this.getDecodedAccessToken(this.getToken())

    return decode[this.user_email];
  }

  getDecodedAccessToken(token: string): any {

    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }
}
