import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-email-candidate',
  templateUrl: './search-email-candidate.component.html',
  styleUrls: ['./search-email-candidate.component.scss']
})
export class SearchEmailCandidateComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  onEnter(value: string) {
    this.router.navigate(['/search-result/', value, 'EmailCampaign']); //search-result
  }

}
