import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SkillService } from '../service/skill.service';
import { CommonService } from '../../core/services/common.service';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-new-skill-modal',
  templateUrl: './new-skill-modal.component.html',
  styleUrls: ['./new-skill-modal.component.scss']
})
export class NewSkillModalComponent implements OnInit {
  public addSkillForm: FormGroup;

  isProcessing: boolean = false
  constructor(
    private formBuilder: FormBuilder,
    private skillService: SkillService,
    private commonService: CommonService,
    private dialogRef: MatDialogRef<NewSkillModalComponent>
  ) { }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  ngOnInit() {
    this.initForm();
  }
  initForm() {
    this.addSkillForm = this.formBuilder.group({
      'Name': [null, [Validators.required, this.noWhitespaceValidator]],
      // 'IVRNumber': [null, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.min(0), Validators.max(9)]]
      'IVRNumber': [null]
    })
  }

  addSkill(form) {
    if (form.valid) {
      try {
        this.isProcessing = true
        let params = {
          Name: this.addSkillForm.controls.Name.value.trim(),
          // IVRNumber: this.addSkillForm.controls.IVRNumber.value.trim()
          IVRNumber: 0
        }
        this.skillService.addSkill(params).subscribe(resposne => {
          if (resposne.success) {
            this.isProcessing = false;
            this.commonService.showSnakeBar(resposne.message)
            this.closeDialog();
          }
          else {
            this.isProcessing = false;
            this.commonService.showSnakeBar(resposne.message)
          }
        }, (err) => {
          this.commonService.checkTokenValidity(err);
          this.closeDialog();
          this.isProcessing = false;
        })
      }
      catch{
        this.isProcessing = false;
        this.commonService.commonSnakeBar();
      }
    }
    else {
      this.commonService.showSnakeBar("Please do the correction");
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
}
