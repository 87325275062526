import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AppSettings } from '../../config/app-settings';
import { ViewMinutesStatusServiceService } from '../service/view-minutes-status-service.service';


@Component({
  selector: 'app-edit-view-minutes-status',
  templateUrl: './edit-view-minutes-status.component.html',
  styleUrls: ['./edit-view-minutes-status.component.scss']
})
export class EditViewMinutesStatusComponent implements OnInit {

  removable = true;
  public isProcessing: boolean = false;
  userData: any
  packageId: any
  componentPlanData = [];
  componentInsertedPlanData = [];
  audioMins: any
  compName: any
  companyId: any
  endDate: any
  id: any
  packageName: any
  calCulatedPrice: any
  price: any
  priceUnit: any
  sms: any
  startDate: any
  status: any
  statusId: any
  videoMins: any

  totalPrice: number = 0
  description: any

  public smsPrice: any
  public audioPrice: any
  public videoPrice: any
  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE

  componentDataForDynamicInputs = [];

  insetedComponentsForStandardData = [];
  public editAllocateMintsToSubSpForm: FormGroup
  subscribers = [];
  public name: any
  public subscriberId: any
  public componentId: any
  public allocatedMainId: any

  constructor(private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: EditViewMinutesStatusComponent,
    private commonService: CommonService,
    private datePipe: DatePipe,
    private appSettings: AppSettings,
    private viewMinutesStatusServiceService: ViewMinutesStatusServiceService,
    private dialogRef: MatDialogRef<EditViewMinutesStatusComponent>) {

    this.userData = data;
    this.description = this.userData.userDetail.description
    this.name = this.userData.userDetail.name
    this.packageId = this.userData.userDetail.packageId
    this.subscriberId = this.userData.userDetail.subscriberId
    this.companyId = this.userData.userDetail.companyId
    this.allocatedMainId = this.userData.userDetail.id
  }

  ngOnInit() {
    this.intiForm()
    this.getSubscriberDropdown()
    this.getComponentDetails()

  }


  intiForm() {
    this.editAllocateMintsToSubSpForm = this.formBuilder.group({
      'Subscriber': [this.userData.userDetail.id, [Validators.required]],
      'Description': [this.description, [this.noWhitespaceValidator]],
      'AllComponent': this.formBuilder.array([])
    })
  }


  getSubscriberDropdown() {
    let params={
      SpOption :'ViewStatusMints'
    }
    this.viewMinutesStatusServiceService.getSubscriberDropdown(params).subscribe(response => {
      if (response.success) {
        let subs = [];
        response.data.forEach(element => {
          subs.push({
            id: element.id,
            name: element.name
          })
        });
        this.subscribers = subs
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }
  getComponentDetails() {
    let allcomponent = this.editAllocateMintsToSubSpForm.get('AllComponent') as FormArray;
    let params = {
      PageNumber: this.pageNumber,
      PageSize: this.pageSize,
      AllocatedType: 'AllocatedMinutesToSubscriber',
      PackageId: 0,
      AllocatedMainId: 0,
      SpOption: 'ViewStatusMinutes',
      SubscriberId: this.subscriberId
    }
    this.viewMinutesStatusServiceService.getComponentInsertedData(params).subscribe(response => {
      if (response.success) {
        let count = [];
        response.data.forEach(element => {
          allcomponent.push(this.formBuilder.control({
            ComponentId: element.componentId,
            ComponentType: element.componentType,
            ComponentTypeName: element.componentTypeName,
            InputParameter: element.inputParameter,
            Id: element.id,
            Name: element.name,
            AvailableUnit: element.availableUnit
          }));
          count.push({
            ComponentId: element.componentId,
            ComponentType: element.componentType,
            ComponentTypeName: element.componentTypeName,
            InputParameter: element.inputParameter,
            Id: element.id,
            Name: element.name,
            AvailableUnit: element.availableUnit
          })
        });

        if (count.length >= 0) {
          this.componentDataForDynamicInputs = count
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
