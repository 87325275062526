import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CommonService } from '../../core/services/common.service';
import { SharedService } from '../service/shared.service';
import { StatusMaster } from '../../core/constants/status';

@Component({
  selector: 'app-confirm-send-sms',
  templateUrl: './confirm-send-sms.component.html',
  styleUrls: ['./confirm-send-sms.component.scss']
})
export class ConfirmSendSMSComponent implements OnInit {

  userId: number
  statusId: Number
  userData: any
  campaignId :any


  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmSendSMSComponent,
    private commonService: CommonService,
    private sharedService: SharedService,
    private dialogRef: MatDialogRef<ConfirmSendSMSComponent>) {
    this.userData = this.data

    this.campaignId = this.userData.campDetail.campaignId
    this.statusId = StatusMaster.Deleted
  }

  ngOnInit() {
  }


  sendSms() {
    let param = {
      CampaignId: this.campaignId
    }
    try {
      this.sharedService.sendSms(param).subscribe(response => {
        if (response.success) {
          this.commonService.showSnakeBar("SMS sent successfully.");
          this.closeDialog();
        }
        else {
          this.commonService.showSnakeBar(response.message);
          this.closeDialog();
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
        this.closeDialog();
      })
    }
    catch{
      this.commonService.commonSnakeBar();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
