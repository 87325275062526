import { Component, OnInit, Renderer2, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog, DateAdapter, MAT_DATE_FORMATS} from '@angular/material';
import { StartCallingComponent } from '../start-calling/start-calling.component';
import { AppDateAdapter, APP_DATE_FORMATS} from './date.adapter';
import { FormGroup, FormControl, Validators } from '@angular/forms';




export interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-ui-kit',
  templateUrl: './ui-kit.component.html',
  styleUrls: ['./ui-kit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
        provide: DateAdapter, useClass: AppDateAdapter
    },
    {
        provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
    ]
})

export class UiKitComponent implements OnInit {
  

  seconds = true;

  public dateTime: Date;

  title = 'Angular 6 Project!';
  foods: Food[] = [
    { value: 'steak-0', viewValue: 'Steak' },
    { value: 'pizza-1', viewValue: 'Pizza' },
    { value: 'tacos-2', viewValue: 'Tacos' }
  ];
  
  constructor(
    private ren: Renderer2 , 
    public dialog: MatDialog) {
    this.modulesList = ModulesList;
    
  }

  changemonths(event) {
    alert("Changed month from the Dropdown");
  }
  months = ["January", "Feburary", "March", "April",
    "May", "June", "July", "August", "September",
    "October", "November", "December"];

  isavailable = false;
  myClickFunction(event) {
    this.isavailable = true;
  }

  startCalling(): void {
    const dialogRef = this.dialog.open(StartCallingComponent, {
      disableClose: true,
      panelClass: ['modal-outer', 'modal-md'],
    });
  }

  ngOnInit() {

  }


  modulesList: Array<any>;
  enteredButton = false;
  isMatMenuOpen = false;
  isMatMenu2Open = false;
  prevButtonTrigger;


  menuenter() {
    this.isMatMenuOpen = true;
    if (this.isMatMenu2Open) {
      this.isMatMenu2Open = false;
    }
  }

  menuLeave(trigger, button) {
    setTimeout(() => {
      if (!this.isMatMenu2Open && !this.enteredButton) {
        this.isMatMenuOpen = false;
        trigger.closeMenu();
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } else {
        this.isMatMenuOpen = false;
      }
    }, 80)
  }

  buttonEnter(trigger) {
    setTimeout(() => {
      if (this.prevButtonTrigger && this.prevButtonTrigger != trigger) {
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        trigger.openMenu()
      }
      else if (!this.isMatMenuOpen) {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger
        trigger.openMenu()
      }
      else {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger
      }
    })
  }

  buttonLeave(trigger, button) {
    setTimeout(() => {
      if (this.enteredButton && !this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } if (!this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } else {
        this.enteredButton = false;
      }
    }, 100)
  }

}

export const ModulesList = [{
  label: 'User',
  children: [{
    label: 'User 1',
    children: [{
      label: 'User 5'
    }, {
      label: 'User 6'
    }, {
      label: 'User 7'
    }]
  }, {
    label: 'User 3'
  }, {
    label: 'User 4'
  }]
}];