import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-next-call-model',
  templateUrl: './next-call-model.component.html',
  styleUrls: ['./next-call-model.component.css']
})
export class NextCallModelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  activeCalling(){
    
  }

}
