import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CommonService } from '../../core/services/common.service';
import { CampaignService } from '../service/campaign.service';
import { StatusMaster } from '../../core/constants/status';

@Component({
  selector: 'app-resume-campaign',
  templateUrl: './resume-campaign.component.html',
  styleUrls: ['./resume-campaign.component.scss']
})
export class ResumeCampaignComponent implements OnInit {

  campData: any
  campaignId: number

  constructor(   @Inject(MAT_DIALOG_DATA) public data: ResumeCampaignComponent,
  private commomService: CommonService,
  private campaignService: CampaignService,
  private dialogRef: MatDialogRef<ResumeCampaignComponent>) {
    this.campData = data;

    this.campaignId = this.campData.campDetail.campaignId
   }

  ngOnInit() {
  }

  resume() {
    let params = {
      CampaignId: this.campaignId,
      StatusId: StatusMaster.Paused
    }
    this.campaignService.UpdateCampaignStatus(params).subscribe(resposne => {
      if (resposne.success) {
        this.commomService.showSnakeBar("Campaign resumed");
        this.closeDialog();
      }
      else {
        this.commomService.showSnakeBar("Unable to resumed campaign");
      }
    }, (err) => {
      this.commomService.checkTokenValidity(err);
      this.closeDialog();
    })
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
