import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { MatDialogRef } from '@angular/material';
import { StandardPackageService } from '../service/standardpackage.service';
import { DatePipe } from '@angular/common';
import { isNullOrUndefined } from 'util';
import { AppSettings } from '../../config/app-settings';
import { forEach } from '@angular/router/src/utils/collection';
import { count } from 'rxjs/operators';


@Component({
  selector: 'app-add-new-standard-package',
  templateUrl: './add-new-standard-package.component.html',
  styleUrls: ['./add-new-standard-package.component.scss']
})
export class AddNewStandardPackageComponent implements OnInit {
  removable = true;
  public addSpForm: FormGroup;
  public isProcessing: boolean = false;
  public smsPrice: any
  public audioPrice: any
  public videoPrice: any
  componentPlanData = [];
  public lastInsertedStandardPackageId: any
  componentDataForDynamicInputs = [];
  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  public numericNumberReg = '^-?[0-9]\\d*(\\.\\d{1,2})?$';
  public selected: any
  stripePlans = []

  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private standardPackageService: StandardPackageService,
    private datePipe: DatePipe,
    private appSettings: AppSettings,
    private dialogRef: MatDialogRef<AddNewStandardPackageComponent>) { }

  initForm() {
    this.addSpForm = this.formBuilder.group({
      'PackageName': [null, [Validators.required, this.noWhitespaceValidator]],
      'Description': [null],
      'CalCulatedPrice': [{ value: null, disabled: true }, [Validators.required, Validators.minLength(1)]],
      'Price': [null, [Validators.required, Validators.pattern(this.numericNumberReg), Validators.minLength(1)]],
      'StartDate': [null],
      'EndDate': [null],
      'AudioMins1': [null],
      'AllComponent': this.formBuilder.array([]),
      'PackageTenure': [null, [Validators.required, this.noWhitespaceValidator]],
      'StripePlanId': [null]
    });
  }

  ngOnInit() {
    this.initForm();
    this.getComponentDetails();
   // this.getStripePlanIdDropdown();
  }

  public addStandardPackage(form) {
    if (form.valid) {

      try {
        let params = {
          PackageName: this.addSpForm.controls.PackageName.value.trim(),
          Description: (this.addSpForm.controls.Description.value == '' || this.addSpForm.controls.Description.value == null || this.addSpForm.controls.Description.value == undefined) ? '' : this.addSpForm.controls.Description.value,
          AudioMins: 0,
          VideoMins: 0,
          SMS: 0,
          CalCulatedPrice: (this.addSpForm.controls.CalCulatedPrice.value == '' || this.addSpForm.controls.CalCulatedPrice.value == null || this.addSpForm.controls.CalCulatedPrice.value == undefined) ? 0.00 : this.addSpForm.controls.CalCulatedPrice.value,
          Price: (this.addSpForm.controls.Price.value == '' || this.addSpForm.controls.Price.value == null || this.addSpForm.controls.Price.value == undefined) ? 0.00 : this.addSpForm.controls.Price.value,
          StartDate: this.datePipe.transform(this.addSpForm.controls.StartDate.value, 'yyyy-MM-dd'),
          EndDate: this.datePipe.transform(this.addSpForm.controls.EndDate.value, 'yyyy-MM-dd'),
          PackageTenure: this.addSpForm.controls.PackageTenure.value,
          StripePlanId: (this.addSpForm.controls.StripePlanId.value == '' || this.addSpForm.controls.StripePlanId.value == null || this.addSpForm.controls.StripePlanId.value == undefined) ? 0 : this.addSpForm.controls.StripePlanId.value
        }

        this.standardPackageService.addstandardPackage(params).subscribe(resposne => {
          if (resposne.success) {
            this.isProcessing = false;
            this.lastInsertedStandardPackageId = resposne.data;
            let arrayAllComponent = this.addSpForm.controls.AllComponent.value
            for (let item of arrayAllComponent) {
              let inputparm
              if (item.value != '' && item.value != undefined) {
                inputparm = item.value
              }
              else {
                inputparm = 0.00
              }

              let itemParam
              if (item.param != '' && item.param != undefined) {
                itemParam = item.param
              }
              else {
                itemParam = 0.00
              }

              let paramarr = {
                ComponentId: item.id,
                Name: item.name,
                Price: item.price,
                CalculatedValue: itemParam,
                ComponentType: item.componentType,
                InputParameter: inputparm,
                LastPackageInsertedId: this.lastInsertedStandardPackageId,
                PackageType: 'StandardPackage',
                SpOptions: 'Insert'
              };

              try {
                this.standardPackageService.addPackageArrayDetails(paramarr).subscribe(resposne => {
                  if (resposne.success) {
                    this.isProcessing = false;
                    this.closeDialog();
                  }
                  else {
                    this.isProcessing = false;
                    this.commonService.showSnakeBar(resposne.message);
                  }

                }, (err) => {
                  this.isProcessing = false;
                  this.commonService.checkTokenValidity(err);
                  this.closeDialog();
                })
              }
              catch
              {
                this.isProcessing = false;
                this.commonService.commonSnakeBar();
              }
            }

            this.commonService.showSnakeBar(resposne.message);
            this.closeDialog();
          }
          else {
            this.isProcessing = false;
            this.commonService.showSnakeBar(resposne.message);
          }

        }, (err) => {
          this.isProcessing = false;
          this.commonService.checkTokenValidity(err);
          this.closeDialog();
        })
      }
      catch
      {
        this.isProcessing = false;
        this.commonService.commonSnakeBar();
      }
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  // public numberOnly(event): boolean {
  //   const charCode = (event.which) ? event.which : event.keyCode;
  //   if (charCode > 31 && (charCode < 48 || charCode > 57)) {
  //     return false;
  //   }
  //   return true;
  // }

  audioMins: number = 0
  newPrice: any = 0
  videoMins: number = 0
  sms: number = 0
  totalPrice: number = 0
  totalAllPrice: any = 0
  lastPrice: any = 0
  calculatedPrice: any = 0;
  calculatedMainPrice: any = 0;
  getcalculatedMainPrice: any = 0;
  finalPrice: any = 0;

  public focusoutAllComponentHandler(event, id, name, price, componentType, componentTypeName, item) {
    if (event.target.value.match(this.numericNumberReg) || event.target.value == '') {

      item.setValue({
        id: id,
        name: name,
        price: price,
        componentType: componentType,
        componentTypeName: componentTypeName,
        param: (event.target.value * price),
        value: event.target.value
      })

      let allcomponent = this.addSpForm.get('AllComponent') as FormArray;
      let c = 0
      for (let control of allcomponent.controls) {

        if (control.value.param != '') {
          c = c + control.value.param
        }
      }
      this.finalPrice = c
      this.addSpForm.controls.CalCulatedPrice.setValue(this.finalPrice.toFixed(2));
    }
    else {

      if ((event.target.value).split('.').length - 1 == 1) {
      }
      else {
        event.target.value = 0
        this.addSpForm.controls.CalCulatedPrice.setValue(0)
        this.commonService.decimalNumberSnakeBar()
      }
    }
  }

  public numberWithOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode == 190 || charCode == 110)) {
      return false;
    }
    return true;
  }

  getComponentDetails() {
    let allcomponent = this.addSpForm.get('AllComponent') as FormArray;
    let params = {
      PageNumber: this.pageNumber,
      PageSize: this.pageSize
    }
    this.standardPackageService.getComponentDetails(params).subscribe(response => {
      if (response.success) {
        let count = [];
        response.data.forEach(element => {
          allcomponent.push(this.formBuilder.control({
            id: element.id,
            name: element.name,
            price: element.price,
            componentType: element.componentType,
            componentTypeName: element.componentTypeName,
            param: "",
            enter: ""
          }));
          count.push({
            id: element.id,
            name: element.name,
            price: element.price,
            componentType: element.componentType,
            componentTypeName: element.componentTypeName,
          })
        });

        if (count.length >= 0) {
          this.componentDataForDynamicInputs = count
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  public numberOnly(event): boolean {
    //  var index = (event.currentTarget.value).indexOf('.');
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))) {
      return false;
    }
    return true;
  }

  getStripePlanIdDropdown() {
    this.standardPackageService.getStripePlanIdDropdown().subscribe(response => {
      if (response.success) {
        let count = [];
        response.data.forEach(element => {
          count.push({
            id: element.id,
            name: element.sripePlanName
          })
        });
        this.stripePlans = count
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }
}
