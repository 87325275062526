import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AuthService } from '../../core/services/auth.service';
import { SuperSubAdminServiceService } from '../service/super-sub-admin-service.service';
import { CommonService } from '../../core/services/common.service';
import { MatDialogRef } from '@angular/material';
import { validateConfig } from '@angular/router/src/config';


@Component({
  selector: 'app-add-super-sub-admin',
  templateUrl: './add-super-sub-admin.component.html',
  styleUrls: ['./add-super-sub-admin.component.scss']
})
export class AddSuperSubAdminComponent implements OnInit {

  public addSuperSubAdminFrom: FormGroup
  isProcessing: boolean = false
  allrolesTypes = []
  constructor(private fromBuilder: FormBuilder,
    private commonService: CommonService,
    private superSubAdminServiceService: SuperSubAdminServiceService,
    public dialogRef: MatDialogRef<AddSuperSubAdminComponent>) { }

  ngOnInit() {
    this.initForm();
    this.getRoleTypeDropdown()
  }

  initForm() {
    this.addSuperSubAdminFrom = this.fromBuilder.group({
      'Name': ['', [Validators.required, this.noWhitespaceValidator]],
      'Email': [null, [Validators.required, Validators.email]],
      'Phone': [null, [Validators.required]],
      'Role': [[], [Validators.required]],
    })
  }


  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  public addSuperSubAdmin(form) {
    if (form.valid) {
      try {
        this.isProcessing = true;

        let params = {
          Name: this.addSuperSubAdminFrom.controls.Name.value.trim(),
          Email: this.addSuperSubAdminFrom.controls.Email.value.trim(),
          Phone: this.addSuperSubAdminFrom.controls.Phone.value.trim(),
          PermissionId: this.addSuperSubAdminFrom.controls.Role.value
        }

        this.superSubAdminServiceService.addSuperSubAdmin(params).subscribe(response => {
          if (response.success) {
            this.commonService.showSnakeBar(response.message);
            this.closeDialog();
            this.isProcessing = false;
          }
          else {
            this.commonService.showSnakeBar(response.message);
            this.isProcessing = false;
          }
        }, (error) => {
          this.commonService.checkTokenValidity(error);
          this.isProcessing = false;
        })
      }
      catch{
        this.commonService.commonSnakeBar();
        this.isProcessing = false;
      }

    }
    else {
      this.commonService.showSnakeBar("Please do the correction");
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  public numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getRoleTypeDropdown() {
    let params =
    {
      SpOption: 'Add'
    }
    this.superSubAdminServiceService.getRoleTypeDropdown(params).subscribe(response => {
      if (response.success) {
        let count = [];
        response.data.forEach(element => {
          count.push({
            id: element.id,
            name: element.name
          })
        });
        this.allrolesTypes = count
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }
}
