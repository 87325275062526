import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { AppSettings } from '../../config/app-settings';
import { SelectionModel } from '@angular/cdk/collections';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UserPermissionCompanyAdminServiceService } from '../service/user-permission-company-admin-service.service';

@Component({
  selector: 'app-edit-user-permission-company-admin',
  templateUrl: './edit-user-permission-company-admin.component.html',
  styleUrls: ['./edit-user-permission-company-admin.component.scss']
})
export class EditUserPermissionCompanyAdminComponent implements OnInit {
  userData: any
  permissionId: any
  permissionName: any
  editPermissionForm: FormGroup
  dataSource = []
  allroles = []
  menuId: any
  roleId: any
  displayedColumns: string[] = ['menuId', 'id', 'menuName', 'menuLink', 'fullControle', 'views'
    , 'adds', 'edits', 'deletes'];
  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  isLoadMore: boolean = false;
  public isProcessing: boolean = false;

  constructor(private userPermissionCompanyAdminServiceService: UserPermissionCompanyAdminServiceService,
    @Inject(MAT_DIALOG_DATA) public data: EditUserPermissionCompanyAdminComponent,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private appSettings: AppSettings,
    private dialogRef: MatDialogRef<EditUserPermissionCompanyAdminComponent>) {
    this.userData = data;
    this.permissionId = this.userData.userDetail.permissionId
    this.permissionName = this.userData.userDetail.permissionName
    this.roleId = this.userData.userDetail.roleId
  }

  ngOnInit() {

    this.initForm();
    this.getchildListingCompAdminById();
    this.getRoleDropdown()
  }


  initForm() {
    this.editPermissionForm = this.formBuilder.group({
      'PermissionName': [this.permissionName, [Validators.required, this.noWhitespaceValidator]],
      'RoleLevel': [this.roleId, [Validators.required]]
    });
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  getchildListingCompAdminById() {
    try {
      let params = {
        PermissionId: this.permissionId
      }
      this.userPermissionCompanyAdminServiceService.getchildListingCompAdminById(params).subscribe(response => {
        if (response.success) {
          this.dataSource = response.data
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch{
      this.commonService.commonSnakeBar();
    }
  }

  public checkValue(event: any, value) {
    let checkedType = value
    this.menuId = this.menuId

    switch (checkedType) {
      case "fullcontrol":
        this.dataSource.find(item => item.menuId == this.menuId).fullControle = event.checked;
        this.dataSource.find(item => item.menuId == this.menuId).views = event.checked;
        this.dataSource.find(item => item.menuId == this.menuId).adds = event.checked;
        this.dataSource.find(item => item.menuId == this.menuId).edits = event.checked;
        this.dataSource.find(item => item.menuId == this.menuId).deletes = event.checked;
        break;
      case "view":
        this.dataSource.find(item => item.menuId == this.menuId).views = event.checked;

        if(this.dataSource.find(item => item.menuId == this.menuId).views ==true &&
        this.dataSource.find(item => item.menuId == this.menuId).adds ==true &&
        this.dataSource.find(item => item.menuId == this.menuId).edits ==true &&
        this.dataSource.find(item => item.menuId == this.menuId).deletes ==true)
        {
          this.dataSource.find(item => item.menuId == this.menuId).fullControle = true
        }
        else{
          this.dataSource.find(item => item.menuId == this.menuId).fullControle = false
        }

        if(this.dataSource.find(item => item.menuId == this.menuId).views == false)
        {
          this.dataSource.find(item => item.menuId == this.menuId).fullControle = false;
          this.dataSource.find(item => item.menuId == this.menuId).adds = false;
          this.dataSource.find(item => item.menuId == this.menuId).edits = false;
          this.dataSource.find(item => item.menuId == this.menuId).deletes = false;
        }
        break;
      case "add":
        this.dataSource.find(item => item.menuId == this.menuId).adds = event.checked;

        if (this.dataSource.find(item => item.menuId == this.menuId).edits == false &&
        this.dataSource.find(item => item.menuId == this.menuId).deletes == false) {
        this.dataSource.find(item => item.menuId == this.menuId).views = event.checked;
      }

      if(this.dataSource.find(item => item.menuId == this.menuId).views ==true &&
      this.dataSource.find(item => item.menuId == this.menuId).adds ==true &&
      this.dataSource.find(item => item.menuId == this.menuId).edits ==true &&
      this.dataSource.find(item => item.menuId == this.menuId).deletes ==true)
      {
        this.dataSource.find(item => item.menuId == this.menuId).fullControle = true
      }
      else{
        this.dataSource.find(item => item.menuId == this.menuId).fullControle = false
      }
        break;
      case "edit":
        this.dataSource.find(item => item.menuId == this.menuId).edits = event.checked;

        if (this.dataSource.find(item => item.menuId == this.menuId).adds == false &&
        this.dataSource.find(item => item.menuId == this.menuId).deletes == false) {
        this.dataSource.find(item => item.menuId == this.menuId).views = event.checked;
      }

      if(this.dataSource.find(item => item.menuId == this.menuId).views ==true &&
      this.dataSource.find(item => item.menuId == this.menuId).adds ==true &&
      this.dataSource.find(item => item.menuId == this.menuId).edits ==true &&
      this.dataSource.find(item => item.menuId == this.menuId).deletes ==true)
      {
        this.dataSource.find(item => item.menuId == this.menuId).fullControle = true
      }
      else{
        this.dataSource.find(item => item.menuId == this.menuId).fullControle = false
      }
        break;
      case "delete":
        this.dataSource.find(item => item.menuId == this.menuId).deletes = event.checked;

        if (this.dataSource.find(item => item.menuId == this.menuId).adds == false &&
        this.dataSource.find(item => item.menuId == this.menuId).edits == false) {
        this.dataSource.find(item => item.menuId == this.menuId).views = event.checked;
      }

      if(this.dataSource.find(item => item.menuId == this.menuId).views ==true &&
      this.dataSource.find(item => item.menuId == this.menuId).adds ==true &&
      this.dataSource.find(item => item.menuId == this.menuId).edits ==true &&
      this.dataSource.find(item => item.menuId == this.menuId).deletes ==true)
      {
        this.dataSource.find(item => item.menuId == this.menuId).fullControle = true
      }
      else{
        this.dataSource.find(item => item.menuId == this.menuId).fullControle = false
      }
        break;

    }
  }

  onRowClicked(row) {
    this.menuId = row.menuId
  }

  editMainPermissionCompAdmin(form) {
    if (form.valid) {
      try {
        this.isProcessing = true;
        let params = {
          PermissionId: this.permissionId,
          PermissionName: this.editPermissionForm.controls.PermissionName.value.trim(),
          RoleLevelId: this.editPermissionForm.controls.RoleLevel.value,
        }
        this.userPermissionCompanyAdminServiceService.editMainPermissionCompAdmin(params).subscribe(resposne => {
          if (resposne.success) {
            this.isProcessing = false;
            this.editPermissionForm.get('PermissionName').patchValue('');

            let arrayAllComponent = this.dataSource;
            for (let item of arrayAllComponent) {

              let paramarr = {
                Id: item.id,
                PermissionId: this.permissionId,
                MenuId: item.menuId,
                FullControle: item.fullControle,
                Views: item.views,
                Adds: item.adds,
                Edit: item.edits,
                Deletes: item.deletes
              };

              try {
                this.userPermissionCompanyAdminServiceService.editChildPermissionCompAdmin(paramarr).subscribe(resposne => {
                  if (resposne.success) {
                    this.isProcessing = false;
                    this.closeDialog();
                  }
                  else {
                    this.isProcessing = false;
                    this.closeDialog();
                    this.commonService.showSnakeBar(resposne.message);
                  }

                }, (err) => {
                  this.isProcessing = false;
                  this.closeDialog();
                  this.commonService.checkTokenValidity(err);
                })
              }
              catch
              {
                this.isProcessing = false;
                this.closeDialog();
                this.commonService.commonSnakeBar();
              }
            }
            this.commonService.showSnakeBar(resposne.message);
            this.closeDialog();
          }
          else {
            this.editPermissionForm.get('PermissionName').patchValue('')
            this.isProcessing = false;
            this.closeDialog();
            this.commonService.showSnakeBar(resposne.message);
          }

        }, (err) => {
          this.isProcessing = false;
          this.closeDialog();
          this.commonService.checkTokenValidity(err);
        })
      }
      catch{
        this.isProcessing = false;
        this.closeDialog();
        this.commonService.commonSnakeBar();
      }

    }
    else {
      this.commonService.showSnakeBar("Please do the correction");
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getRoleDropdown() {
    let params = {
      PageNumber: this.pageNumber,
      PageSize: this.pageSize,
      PermissionId: this.permissionId,
      SpOption: 'Edit'
    }
    this.userPermissionCompanyAdminServiceService.getRoleDropdown(params).subscribe(response => {
      if (response.success) {
        let count = [];
        response.data.forEach(element => {
          count.push({
            id: element.id,
            name: element.name
          })
        });
        this.allroles = count
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }
}
