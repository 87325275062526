import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, EmailValidator, FormControl } from '@angular/forms';
import { RecruiterService } from '../service/recruiter.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
//import { DISABLED } from '@angular/forms/src/model';
import { CommonService } from '../../core/services/common.service';
import { AuthService } from '../../core/services/auth.service';
import { isBoolean } from 'util';
import { AppSettings } from '../../config/app-settings';

@Component({
  selector: 'app-edit-recruiter',
  templateUrl: './edit-recruiter.component.html',
  styleUrls: ['./edit-recruiter.component.scss']
})
export class EditRecruiterComponent implements OnInit {
  editRcForm: FormGroup
  skillList = [];
  subscribers = []
  removable = true
  public isProcessing: boolean = false;

  userData: any
  userId: number
  name: string
  email: string
  phone: number
  // SubscriberId: number
  userSkills = []
  status: string
  permissionId: any
  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  allroles = []
  userSelectedId: any
  selectedUser = []
  managersrole = []
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditRecruiterComponent,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private recruiterService: RecruiterService,
    private commonService: CommonService,
    private appSettings: AppSettings,
    private dialogRef: MatDialogRef<EditRecruiterComponent>
  ) {

    this.userData = data;
    this.userId = this.userData.userDetail.userId
    this.name = this.userData.userDetail.name
    this.email = this.userData.userDetail.email
    this.phone = this.userData.userDetail.phone
    // this.SubscriberId = this.userData.userDetail.subscriberId
    this.permissionId = this.userData.userDetail.permissionId
    this.userSelectedId = this.userData.userDetail.selectUserId
  }

  ngOnInit() {
    this.initForm();
    this.getSkillDropDown();
    //this.getSubscriberDropdown();
    this.userSkills =
      this.userData.userDetail.skillList.forEach(element => {
        this.userSkills.push({
          id: element.skillId,
          name: element.skillName
        })
      });

    this.getRoleDropdown()
    this.userDropDownForStaff()
  }

  initForm() {
    this.editRcForm = this.formBuilder.group({
      'Name': [this.name, [Validators.required, this.noWhitespaceValidator]],
      'Email': [{ value: this.email, disabled: this.emailIdDisable() }, [Validators.required, Validators.email]],
      'PhoneNumber': [this.phone, [Validators.required, Validators.minLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      'Skills': [this.userSkills, [Validators.required]],
      'RoleLevel': [this.permissionId, [Validators.required]],
      'RoleManagerId': [this.userSelectedId]
    });
  }

  get getRoleManagerControls() {
    return this.editRcForm.get('RoleManagerId') as FormControl;
  }

  editRecruiter(form) {
    if (form.valid) {
      try {
        this.isProcessing = true
        let skillIds = Array.prototype.map.call(this.editRcForm.controls.Skills.value, s => s.id).toString();
        let dataType = (typeof (this.editRcForm.controls.RoleManagerId.value));
        let rollmangerId
        if (dataType == 'object') {
          if (this.editRcForm.controls.RoleManagerId.value.length > 0) {
            rollmangerId = this.editRcForm.controls.RoleManagerId.value.Id

          }
          else {
            rollmangerId = 0
          }
        }
        else {
          if (this.editRcForm.controls.RoleManagerId.value != null) {
            rollmangerId = this.editRcForm.controls.RoleManagerId.value

          }
          else {
            rollmangerId = 0
          }

        }

        let params = {
          UserId: this.userId,
          Name: this.editRcForm.controls.Name.value,
          Email: this.editRcForm.controls.Email.value,
          Phone: this.editRcForm.controls.PhoneNumber.value,
          SkillIds: skillIds,
          PermissionId: this.editRcForm.controls.RoleLevel.value,
          RoleManagerId: rollmangerId
        }
        this.recruiterService.editRecruiter(params).subscribe(response => {

          if (response.success) {
            this.commonService.showSnakeBar(response.message);
            this.closeDialog();
            this.isProcessing = false;
          }
          else {
            this.commonService.showSnakeBar(response.message);
            this.isProcessing = false;
          }
        }, (err) => {
          this.commonService.checkTokenValidity(err);
          this.closeDialog();
          this.isProcessing = false;
        })
      }
      catch{
        this.commonService.commonSnakeBar();
        this.isProcessing = false;
      }
    }
    else {
      this.commonService.showSnakeBar("Please do the correction");
    }
  }

  emailIdDisable(): boolean {
    if (this.authService.isCompanyAdmin()) {
      return false;
    }
    else {
      return true;
    }
  }

  getSkillDropDown() {
    let params={
      SpOption :'Edit'
    }
    this.recruiterService.getSkillDropDown(params).subscribe(response => {
      if (response.success) {
        let skill = [];

        response.data.forEach(element => {
          skill.push({
            id: element.id,
            name: element.name
          })
        });
        this.skillList = skill
      }
    })
  }

  compareSkills(c1, c2): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  removeSkill(index) {
    this.editRcForm.controls.Skills.value.splice(index, 1);
    this.editRcForm.controls.Skills.patchValue(this.editRcForm.controls.Skills.value)
  }

  closeDialog() {
    this.dialogRef.close();
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  public numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getRoleDropdown() {
    let params = {
      PageNumber: this.pageNumber,
      PageSize: this.pageSize,
      SpOption: 'EditUser'
    }
    this.recruiterService.getRoleDropdown(params).subscribe(response => {
      if (response.success) {
        console.log(response.data);
        let count = [];
        response.data.forEach(element => {
          this.allroles.push({
            id: element.id,
            name: element.name,
            roleId: element.roleId
          })
        });
        // this.allroles = count
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }


  userDropDownForStaff() {
    let params = {
      Selectuserid: this.userSelectedId,
      SpOption: 'EditUser'
    }
    this.recruiterService.userDropDownForStaff(params).subscribe(response => {
      if (response.success) {
        let count = [];
        response.data.forEach(element => {
          count.push({
            id: element.id,
            name: element.name
          })
        });
        this.managersrole = count
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  roleManagerDiv: boolean = false
  roleChange(event) {
    let index = this.allroles.map(item => {
      return item.id;
    }).indexOf(event.value);

    let params = {
      RoleId: parseInt(this.allroles[index].roleId)
    }
    this.recruiterService.roleChange(params).subscribe(response => {
      if (response.success) {
        let count = [];
        if (response.data.length > 0) {
          response.data.forEach(element => {
            count.push({
              id: element.id,
              name: element.name
            })
          });
        }
        else {
          this.editRcForm.get('RoleManagerId').patchValue([]);
        }

        this.managersrole = count

        if (this.managersrole.length > 0) {
          this.roleManagerDiv = true
        }
        else {
          this.roleManagerDiv = false
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }
}
