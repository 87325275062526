import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { MatDialogRef, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { SmsCampaignServicesService } from '../service/sms-campaign-services.service';
import { AppDateAdapter, APP_DATE_FORMATS } from '../sms-campaign-list/date.adapter';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-create-smscampaign',
  templateUrl: './create-smscampaign.component.html',
  styleUrls: ['./create-smscampaign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class CreateSMSCampaignComponent implements OnInit {
  createSMSCampForm: FormGroup
  selectedFile: File
  isProcessing: boolean = false;
  numericNumberReg = '^-?[0-9]\\d*(\\.\\d{1,2})?$';
  selected: any;
  fileUrl: any
  constructor(private smsCampaignServicesService: SmsCampaignServicesService,
    private authService: AuthService,
    private formbuilder: FormBuilder,
    private commonService: CommonService,
    private sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<CreateSMSCampaignComponent>) { }

  ngOnInit() {
    this.intiForm();

    const data = 'Name,Phone,Skill';
    const blob = new Blob([data], { type: 'application/octet-stream' });

    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
  }

  intiForm() {
    this.createSMSCampForm = this.formbuilder.group({
      'Name': [null, [Validators.required, this.noWhitespaceValidator]],
      'SMS': [null, [Validators.required, this.noWhitespaceValidator]],
      'Description': [null],
      'StartDate': [null, [Validators.required]],
      'StartTime': [null, [Validators.required]],
      // 'EndTime': [null],
      'GstZone': [null, [Validators.required]]
    })
  }

  createSMSCampaign(form) {
    if (form.valid) {
      this.isProcessing = true;
      const formData = new FormData();

      if (this.selectedFile == null || this.selectedFile == undefined) {
        this.commonService.showSnakeBar("Please upload file");
        this.isProcessing = false;
        return;
      }
      else if (this.selectedFile.name.split('.').pop().toLocaleLowerCase() != "csv") {
        this.commonService.showSnakeBar("Only .csv extension allowed");
        this.isProcessing = false;
        return;
      }

      let startTimeVal = this.createSMSCampForm.controls.StartTime.value;
      var str = new String(startTimeVal);

      if (str.charAt(2) != ':' || str.charAt(5) != ':'
        || str.charAt(0) == ':' || str.charAt(1) == ':'
        || str.charAt(3) == ':' || str.charAt(4) == ':'
        || str.charAt(6) == ':' || str.charAt(7) == ':') {
        this.commonService.showSnakeBar("Please proper fill the start time in correct format");
        this.isProcessing = false;
        return;
      }


      // let endTimeVal = this.createSMSCampForm.controls.EndTime.value;
      // var strendTime = new String(endTimeVal);

      // if (strendTime.charAt(2) != ':' || strendTime.charAt(5) != ':'
      //   || strendTime.charAt(0) == ':' || strendTime.charAt(1) == ':'
      //   || strendTime.charAt(3) == ':' || strendTime.charAt(4) == ':'
      //   || strendTime.charAt(6) == ':' || strendTime.charAt(7) == ':') {
      //   this.commonService.showSnakeBar("Please proper fill the end time in correct format");
      //   this.isProcessing = false;
      //   return;
      // }

      formData.append(this.selectedFile.name, this.selectedFile);
      formData.append("Name", this.createSMSCampForm.controls.Name.value);
      formData.append("SMS", this.createSMSCampForm.controls.SMS.value);
      formData.append("Description", this.createSMSCampForm.controls.Description.value);
      formData.append("GstZone", this.createSMSCampForm.controls.GstZone.value);
      formData.append("StartDate", this.createSMSCampForm.controls.StartDate.value.toLocaleDateString());
      formData.append("StartTime", this.createSMSCampForm.controls.StartTime.value);
      formData.append("EndTime", "12:00:00");

      // console.log(this.createSMSCampForm.controls.StartDate.value.toLocaleDateString());

      this.smsCampaignServicesService.createSMSCamapignFormRequest(formData).subscribe(response => {
        if (response.success) {
          this.commonService.showSnakeBar(response.message);
          this.closeDialog();
          this.isProcessing = false;
        }
        else {
          this.commonService.showSnakeBar(response.message);
          this.isProcessing = false;
        }
      }, (err) => {
        this.isProcessing = false;
        this.commonService.checkTokenValidity(err);
        this.closeDialog();
      })
    }
    else {
      this.commonService.showSnakeBar("Please do the correction");
    }
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onFileChanged(event) {
    if (event.target.value != "") {
      this.selectedFile = event.target.files[0]
    }
  }

  public onTimeStartTimekeypress(event) {
    var val = event.target.value;
    var textlength = this.createSMSCampForm.controls.StartTime.value.length;

    if (textlength == 2) {
      if (this.createSMSCampForm.controls.StartTime.value < 24) {

        if (this.createSMSCampForm.controls.StartTime.value > 8 &&
          this.createSMSCampForm.controls.StartTime.value <= 19) {
          this.createSMSCampForm.get('StartTime').setValue(val + ':');
        }
        else {
          this.createSMSCampForm.get('StartTime').patchValue('');
          this.commonService.showSnakeBar("Start time cannot be below 8 AM and greater than 9 PM");
        }
      }
      else {
        this.createSMSCampForm.get('StartTime').patchValue('');
      }
    }
    if (textlength == 5) {
      const str = this.createSMSCampForm.controls.StartTime.value;
      const slug = str.split(':').pop();
      if (slug < 60) {
        this.createSMSCampForm.get('StartTime').setValue(val + ':');
      }
      else {
        this.createSMSCampForm.get('StartTime').patchValue('');
      }
    }
    if (textlength == 8) {
      var member = this.createSMSCampForm.controls.StartTime.value;

      var last2 = member.slice(-2);

      if (last2 < 60) {

      }
      else {
        this.createSMSCampForm.get('StartTime').patchValue('');
      }
    }
    var first2 = this.createSMSCampForm.controls.StartTime.value.substring(0, 2);


    if (textlength >= 2) {
      if (first2 > 8 &&
        first2 <= 19) {
      }
      else {
        this.createSMSCampForm.get('StartTime').patchValue('');
        this.commonService.showSnakeBar("Start time cannot be below 8 AM and greater than 9 PM");
      }

    }

  }


  public onTimeEndTimekeypress(event) {
    var val = event.target.value;
    var textlength = this.createSMSCampForm.controls.EndTime.value.length;

    if (textlength == 2) {
      if (this.createSMSCampForm.controls.EndTime.value < 24) {
        this.createSMSCampForm.get('EndTime').setValue(val + ':');
      }
      else {
        this.createSMSCampForm.get('EndTime').patchValue('');
      }
    }
    if (textlength == 5) {
      const str = this.createSMSCampForm.controls.EndTime.value;
      const slug = str.split(':').pop();
      if (slug < 60) {
        this.createSMSCampForm.get('EndTime').setValue(val + ':');
      }
      else {
        this.createSMSCampForm.get('EndTime').patchValue('');
      }
    }
    if (textlength == 8) {
      var member = this.createSMSCampForm.controls.EndTime.value;

      var last2 = member.slice(-2);

      if (last2 < 60) {

      }
      else {
        this.createSMSCampForm.get('EndTime').patchValue('');
      }
    }
  }


  SmsPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

}
