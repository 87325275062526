import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RecruiterService } from '../service/recruiter.service';
import { CommonService } from '../../core/services/common.service';
import { MatDialogRef } from '@angular/material';
import { AppSettings } from '../../config/app-settings';

@Component({
  selector: 'app-add-new-recruiter',
  templateUrl: './add-new-recruiter.component.html',
  styleUrls: ['./add-new-recruiter.component.scss']
})
export class AddNewRecruiterComponent implements OnInit {
  // skills = new FormControl();

  skillList = [];
  subscribers = [];
  managersrole = []

  userSkillIds: string
  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  removable = true;
  public addRcForm: FormGroup;
  public isProcessing: boolean = false;
  // dropdownList = [];
  // selectedItems = [];
  // dropdownSettings = {};
  allroles = [];

  constructor(
    private recruiterService: RecruiterService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private appSettings: AppSettings,
    private dialogRef: MatDialogRef<AddNewRecruiterComponent>
  ) { }

  initForm() {
    this.addRcForm = this.formBuilder.group({
      'Name': [null, [Validators.required, this.noWhitespaceValidator]],
      'Email': [null, [Validators.required, Validators.email]],
      //  'SubscriberId': [[], [Validators.required]],
      'PhoneNumber': [null, [Validators.required, Validators.minLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      'Skills': [[], [Validators.required]],
      'RoleLevel': [null, [Validators.required]],
      'RoleManagerId': [null]
    });
  }


  ngOnInit() {
    this.initForm();
    this.getSkillDropDown();
    this.getRoleDropdown()
  }

  removeSkill(index) {
    this.addRcForm.controls.Skills.value.splice(index, 1);
    this.addRcForm.controls.Skills.patchValue(this.addRcForm.controls.Skills.value)
  }


  addRecruiter(form) {

    if (form.valid) {

      let rollmangerId
      let dataType = (typeof (this.addRcForm.controls.RoleManagerId.value));
      if (dataType == 'object') {
        if (this.addRcForm.controls.RoleManagerId.value.length > 0) {
          rollmangerId = this.addRcForm.controls.RoleManagerId.value.Id

        }
        else {
          rollmangerId = 0
        }
      }
      else {
        if (this.addRcForm.controls.RoleManagerId.value != null) {
          rollmangerId = this.addRcForm.controls.RoleManagerId.value

        }
        else {
          rollmangerId = 0
        }

      }
      try {
        this.isProcessing = true;
        this.userSkillIds = Array.prototype.map.call(this.addRcForm.controls.Skills.value, s => s.id).toString();
        let params = {
          Name: this.addRcForm.controls.Name.value.trim(),
          Email: this.addRcForm.controls.Email.value.trim(),
          Phone: this.addRcForm.controls.PhoneNumber.value,
          //  SubscriberId: this.addRcForm.controls.SubscriberId.value,
          SkillId: this.userSkillIds,
          PermissionId: this.addRcForm.controls.RoleLevel.value.Id,
          RoleManagerId: rollmangerId
        }
        this.recruiterService.addRecruiter(params).subscribe(resposne => {
          if (resposne.success) {
            this.isProcessing = false;
            this.commonService.showSnakeBar(resposne.message);
            this.closeDialog();
          }
          else {
            if (resposne.message == "Email already exist.Please try another email.") {
              this.addRcForm.get('Email').patchValue('')
            }
            this.isProcessing = false;
            this.commonService.showSnakeBar(resposne.message);
          }

        }, (err) => {
          this.isProcessing = false;
          this.commonService.checkTokenValidity(err);
          this.closeDialog();
        })
      }
      catch{
        this.isProcessing = false;
        this.commonService.commonSnakeBar();
      }

    }
    else {
      this.commonService.showSnakeBar("Please do the correction");
    }
  }

  getSkillDropDown() {
    let params={
      SpOption :'Add'
    }
    this.recruiterService.getSkillDropDown(params).subscribe(response => {
      if (response.success) {
        let skill = [];

        response.data.forEach(element => {
          skill.push({
            id: element.id,
            name: element.name
          })
        });
        this.skillList = skill
      }
    })
  }


  closeDialog() {
    this.dialogRef.close();
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  public numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


  getRoleDropdown() {

    let params = {
      PageNumber: this.pageNumber,
      PageSize: this.pageSize,
      SpOption: 'EditUser'
    }
    this.recruiterService.getRoleDropdown(params).subscribe(response => {
      if (response.success) {
        console.log(response.data);
        let count = [];
        response.data.forEach(element => {
          this.allroles.push({
            id: element.id,
            name: element.name,
            roleId: element.roleId
          })
        });
        // this.allroles = count
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }


  getRoleManagerDropdown() {
    let params = {
      PageNumber: this.pageNumber,
      PageSize: this.pageSize,
      SpOption: 'EditUser'
    }
    this.recruiterService.getRoleManagerDropdown(params).subscribe(response => {
      if (response.success) {
        let count = [];
        response.data.forEach(element => {
          count.push({
            id: element.id,
            name: element.name
          })
        });
        this.managersrole = count
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  roleManagerDiv: boolean = false
  roleChange(event) {

    let params = {
      RoleId: event.value.RoleId
    }
    this.recruiterService.roleChange(params).subscribe(response => {
      if (response.success) {
        let count = [];
        if (response.data.length > 0) {
          response.data.forEach(element => {
            count.push({
              id: element.id,
              name: element.name
            })
          });
        }
        else {
          this.addRcForm.get('RoleManagerId').patchValue([]);
        }
        this.managersrole = count

        if (this.managersrole.length > 0) {
          this.roleManagerDiv = true
        }
        else {
          this.roleManagerDiv = false
        }

      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

}
