

export const StatusMaster = {
    NotStarted: 1,
    Completed: 2,
    CallInProgress: 3,
    Paused: 4,
    Connected: 5,
    NotConnected: 6,
    Failed: 7,
    Busy: 8,
    NoAnswer: 9,
    Active: 10,
    InActive: 11,
    Deleted: 12,
    Pending: 13,
    Stopped: 14,
    SmsSent :15,
    SmsNotSent:16,
    EmailSent :17,
    EmailNotSent:18
};