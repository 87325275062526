import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InboundListComponent } from './inbound-list/inbound-list.component';
import { CoreModule } from '../core/core.module';
import { MaterialComponentsModule } from '../material-components.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    MaterialComponentsModule,
    RouterModule,
    ReactiveFormsModule
  ],
  declarations: [InboundListComponent]
})
export class InboundModule { }
