import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CompanyService } from '../service/company.service';
import { DatePipe } from '@angular/common';
import { isNull, isNullOrUndefined } from 'util';
import { CommonService } from '../../core/services/common.service'
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})
export class CompanyDetailsComponent implements OnInit {
  userData: any
  isProcessing: boolean = false
  companyId: any
  address: any
  city: any
  compName: any
  country: any
  countryId: any
  email: any
  phone: any
  state: any
  status: any
  zip: any
  countries = []
  website: any
  aboutProfile: any
  twitterUrl: any
  facebookUrl: any
  linkedinUrl: any
  street: any
  ein: any
  price: any
  packageName: any
  description: any
  packageType: any
  packageId: any
  packageTenure: any
  brandType: any
  expiryMonth: any
  expiryYear: any
  last4Digit: any
  cardType: any
  purchasedDate: any
  public custAndStandardpackageDataAvailability: boolean = false
  public custAndStandardpackageDataNotAvailability: boolean = false

  public requiredpackageDataAvailability: boolean = false

  public firstTimeDataSource = []

  compNameFirstChar: any

  constructor(
    private companyService: CompanyService,
    private authService: AuthService,
    private formbuilder: FormBuilder,
    private commonService: CommonService,
    private route: ActivatedRoute) {
    this.route.params.subscribe((companyId) => {
      this.companyId = companyId.id;

    });
    this.getCompanyDetailsById();
  }

  ngOnInit() {
    this.getPurchasedPlanBasicDetails()
  }


  getCompanyDetailsById() {
    try {
      let params = {
        CompanyId: this.companyId
      }
      this.companyService.getCompanyDetailsById(params).subscribe(response => {
        if (response.success) {
          if (response.data.length > 0) {
            this.compName = response.data[0].compName
            this.compNameFirstChar = this.compName.charAt(0)
            this.address = response.data[0].address
            this.city = response.data[0].city
            this.country = response.data[0].countryName
            this.countryId = response.data[0].countryId
            this.email = response.data[0].email
            this.phone = response.data[0].phone
            this.state = response.data[0].state
            this.status = response.data[0].status
            this.zip = response.data[0].zip
            this.website = response.data[0].website
            this.aboutProfile = response.data[0].aboutProfile
            this.twitterUrl = response.data[0].twitterUrl
            this.facebookUrl = response.data[0].facebookUrl
            this.linkedinUrl = response.data[0].linkedinUrl
            this.street = response.data[0].street
            this.ein = response.data[0].ein
          }
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch{
      this.commonService.commonSnakeBar();
    }
  }

  getPurchasedPlanBasicDetails() {
    try {
      let params = {
        CompanyId: this.companyId
      }
      this.companyService.getPurchasedPlanBasicDetails(params).subscribe(response => {
        if (response.success) {
          if (response.data.length > 0) {
            this.packageName = response.data[0].name;
            this.description = response.data[0].description
            this.price = response.data[0].price
            this.packageId = response.data[0].packageId
            this.packageType = response.data[0].packageType
            this.packageTenure = response.data[0].packageTenure
            this.brandType = response.data[0].brandType;
            this.expiryMonth = ('0' + response.data[0].expiryMonth).slice(-2)
            this.expiryYear = response.data[0].expiryYear;
            this.last4Digit = response.data[0].last4Digit;
            this.cardType = response.data[0].cardType;
            this.purchasedDate = response.data[0].purchasedDate;

            if (this.packageType != 'RequiredPackage') {
              this.custAndStandardpackageDataAvailability = true
              this.getPurchasdPlanComponentForCompanyDetailsDetails()
            }
            else {
              this.getPurchasdPlanComponentForCompanyDetailsDetails()
              this.custAndStandardpackageDataAvailability = true
              this.requiredpackageDataAvailability = true
            }
          }
          else {
            this.custAndStandardpackageDataNotAvailability = true;
          }
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch{
      this.commonService.commonSnakeBar();
    }
  }


  getPurchasdPlanComponentForCompanyDetailsDetails() {
    try {
      let params = {
        CompanyId: this.companyId,
        PackageId: this.packageId,
        PackageType: this.packageType
      }
      this.companyService.getPurchasdPlanComponentForCompanyDetailsDetails(params).subscribe(response => {
        if (response.success) {
          if (response.data.length > 0) {
            this.firstTimeDataSource = response.data;
          }
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch{
      this.commonService.commonSnakeBar();
    }
  }
}
