import { Injectable } from '@angular/core';
import * as signalR from "@aspnet/signalr";
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SignalRService {
    private hubConnection: signalR.HubConnection
    public startConnection(cb:(type:string,res:any)=>void) {
        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(environment.ws)
            .build();
        this.hubConnection
            .start()
            .then(() => {
                console.log('Connection started')
                this.eventSub(cb)
            })
            .then(() => this.getConnectionId())
            .catch(err => console.log('Error while starting connection: ' + err))
    }

    public eventSub(cb:(type:string,res:any)=>void) {
        this.hubConnection.on('respConf', (data) => {
            cb('respConf',data);
        });

        this.hubConnection.on('callConf', (data) => {
            cb('callConf',data);
        });
    }


    public getConnectionId = () => {
        this.hubConnection.invoke('getconnectionid').then(
            (data) => {
                console.log('getconnectionid',data);
                this.connectionId = data;
            }
        );
    }

    public broadcastChartData = () => {
        this.hubConnection.invoke('broadcastchartdata', this.data, this.connectionId)
            .catch(err => console.error(err));
    }
    public addBroadcastChartDataListener = () => {
        this.hubConnection.on('broadcastchartdata', (data) => {
            this.bradcastedData = data;
        })
    }
    connectionId: any;
    data: any;
    bradcastedData: any;


}