import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddNewCompanyComponent } from './add-new-company/add-new-company.component';
import { CompaniesListComponent } from './companies-list/companies-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialComponentsModule } from '../material-components.module';
import { BlockPackageModelComponent } from '../shared/block-package-model/block-package-model.component';
import { ConfirmPackageDeleteComponent } from '../shared/confirm-package-delete/confirm-package-delete.component';
import { EditCompanyComponent } from './edit-company/edit-company.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { RouterModule } from '@angular/router';



@NgModule({
  imports: [
    CommonModule,
    MaterialComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule
  ],
  declarations: [AddNewCompanyComponent, CompaniesListComponent, EditCompanyComponent, CompanyDetailsComponent],
  entryComponents:[AddNewCompanyComponent,EditCompanyComponent,CompanyDetailsComponent]
})
export class CompaniesModule { }
