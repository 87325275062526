import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from '../../core/services/auth.service';
import { CommonService } from '../../core/services/common.service';
import { AppSettings } from '../../config/app-settings';
import { StatusMaster } from '../../core/constants/status';
import { AllocateMinutesToSubscriberServiceService } from '../service/allocate-minutes-to-subscriber-service.service'
import { AddAllocateMinutesToSubscriberComponent } from '../add-allocate-minutes-to-subscriber/add-allocate-minutes-to-subscriber.component'
import { EditAllocateMinutesToSubscriberComponent } from '../edit-allocate-minutes-to-subscriber/edit-allocate-minutes-to-subscriber.component'
import { SharedService } from '../../shared/service/shared.service';
import { JwtService } from '../../core/services/jwt.service';
import { DeallocateMinutesToSubscriberComponent } from '../deallocate-minutes-to-subscriber/deallocate-minutes-to-subscriber.component';


@Component({
  selector: 'app-allocate-minutes-to-subscriber-list',
  templateUrl: './allocate-minutes-to-subscriber-list.component.html',
  styleUrls: ['./allocate-minutes-to-subscriber-list.component.scss']
})
export class AllocateMinutesToSubscriberListComponent implements OnInit {

  constructor(public dialog: MatDialog,
    private authService: AuthService,
    private commonService: CommonService,
    private sharedService: SharedService,
    private jwtService: JwtService,
    private allocateMinutesToSubscriberServiceService: AllocateMinutesToSubscriberServiceService,
    private appSettings: AppSettings) {
      this.getuserDetails();
     }

  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  isLoadMore: boolean = false
  statusMstr: any = StatusMaster;

  fullControl: boolean = true;
  views: boolean = true;
  adds: boolean = true;
  edits: boolean = true;
  deletes: boolean = true;

  displayedColumns: string[] = ['Id', 'PackageId',
    'Name', 'Description', 'AudioMinutes', 'VideoMinutes',
    'SMS', 'CompanyId', 'CompName', 'SubscriberId',
    'SubscriberName', 'CreatedDate', 'Status', 'Action'];

  dataSource = [];
  public manualPaging: boolean = false
  public ListingData = [];
  public AllCompanies = [];

  ngOnInit() {
    this.getSubcriberAllocatedMinutesListing(false)

    const role = this.jwtService.getUserRole();
    if (role != 'CompanyAdmin') {
      this.getAddEditDeletePermission();
    }
  }

  noRecordFoundNotCon: boolean = false
  getSubcriberAllocatedMinutesListing(isPaging: boolean) {
    try {
      let params
      if (this.manualPaging == true && isPaging != true) {
        this.pageNumber = 1
        params = {
          PageNumber: this.pageNumber,
          PageSize: this.pageSize
        }
      }
      else {
        params = {
          PageNumber: this.pageNumber,
          PageSize: this.pageSize
        }
      }
      this.allocateMinutesToSubscriberServiceService.getSubcriberAllocatedMinutesListing(params).subscribe(response => {
        if (response.success) {
          let len = response.data.length;
          if (len > 0) {
             this.noRecordFoundNotCon = true
           }
           else {
             this.noRecordFoundNotCon = false
           }

          if (response.data.length < this.pageSize) {
            this.isLoadMore = false;
          }
          else {
            this.isLoadMore = true;
          }

          if (isPaging) {
            this.appendCompanyListing(response.data);
          }
          else {
            this.dataSource = response.data;
          }
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch{
      this.commonService.commonSnakeBar();
    }
  }

  appendCompanyListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.dataSource.length > 0) {
        newState = this.dataSource.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.dataSource = newState;
    }
  }
  loadMore() {
    this.pageNumber = this.pageNumber + 1
    this.pageSize = this.pageSize
    this.getSubcriberAllocatedMinutesListing(true);
  }


  
  AddAllocateMinutesToSubscriberModal(): void {

    if (this.statusId == 10) {
	    const dialogRef = this.dialog.open(AddAllocateMinutesToSubscriberComponent, {
        disableClose: true,
        panelClass: "full-width-popup"
      }
      )
  
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.getSubcriberAllocatedMinutesListing(false);
        }
      })
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }

  };

  AddDeAllocateMinutesToSubscriberModal():void{

    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(DeallocateMinutesToSubscriberComponent, {
        disableClose: true,
        panelClass: "full-width-popup"
      }
      )
  
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.getSubcriberAllocatedMinutesListing(false);
        }
      })
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }
 
  };

  EditSubscriberAllocatedMinsModal(userDetail): void {
    if (this.statusId == 10) {
	    const dialogRef = this.dialog.open(EditAllocateMinutesToSubscriberComponent, {
        disableClose: true,
        panelClass: "full-width-popup",
        data: {
          userDetail
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.getSubcriberAllocatedMinutesListing(false);
        }
      })
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }

  }

  getAddEditDeletePermission() {
    let params = {
      MenuId: 16
    }
    this.sharedService.getAddEditDeletePermission(params).subscribe(response => {
      if (response.success) {
        if (response.data.length > 0) {
          this.adds = response.data[0].adds
          this.views = response.data[0].views
          this.fullControl = response.data[0].fullControle
          this.deletes = response.data[0].deletes
          this.edits = response.data[0].edit
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  userDetails = []
  statusId: number
  getuserDetails(): number {
    try {
      let userId = this.jwtService.getCurrentUserId();
      let param = {
        UserId: userId
      }
      this.sharedService.getUserDetails(param).subscribe(response => {
        if (response.success) {
          this.userDetails = response.data;
          this.statusId = response.data[0].statusId;
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch {
      this.commonService.commonSnakeBar();
    }
    return this.statusId;
  }

}
