import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from '../../core/services/auth.service';
import { CommonService } from '../../core/services/common.service';
import { StandardPackageService } from '../service/standardpackage.service';
import { AppSettings } from '../../config/app-settings';
import { StatusMaster } from '../../core/constants/status';
import { AddNewStandardPackageComponent } from '../../standardpackages/add-new-standard-package/add-new-standard-package.component';
import { EditStandardPackageComponent } from '../../standardpackages/edit-standard-package/edit-standard-package.component';
import { AutofillMonitor } from '@angular/cdk/text-field';
import { BlockPackageModelComponent } from '../../shared/block-package-model/block-package-model.component';
import { ConfirmPackageDeleteComponent } from '../../shared/confirm-package-delete/confirm-package-delete.component';
import { SharedService } from '../../shared/service/shared.service'
import { JwtService } from '../../core/services/jwt.service'

export interface PeriodicElement {
  Id: any
  PackageName: string;
  Price: any;
}

@Component({
  selector: 'app-standardpackages-list',
  templateUrl: './standardpackages-list.component.html',
  styleUrls: ['./standardpackages-list.component.scss']
})


export class StandardpackagesListComponent implements OnInit {
  displayedColumns: string[] = ['Id', 'PackageName', 'Description', 'AudioMins',
    'VideoMins', 'SMS', 'CalCulatedPrice', 'Price', 'PriceUnit', 'StartDate',
    'EndDate', 'PackageTenure', 'StripePlanId', 'SripePlanName', 'Status', 'Action'];

  dataSource = [];
  public ListingData = [];
  public AllStandardPackages = [];
  public NoPackageFound: boolean = false
  constructor(
    public dialog: MatDialog,
    private authService: AuthService,
    private commonService: CommonService,
    private sharedService: SharedService,
    private jwtService: JwtService,
    private standardPackageService: StandardPackageService,
    private appSettings: AppSettings) {
    this.getuserDetails();

  }

  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  isLoadMore: boolean = false
  statusMstr: any = StatusMaster;
  NoRecFound: boolean = false;
  public manualPaging: boolean = false

  fullControl: boolean = true;
  views: boolean = true;
  adds: boolean = true;
  edits: boolean = true;
  deletes: boolean = true;
  disableds: boolean = false;

  ngOnInit() {
    this.getStandardPackageListing(false);

    const role = this.jwtService.getUserRole();
    if (role == 'SuperSubAdmin') {
      this.getAddEditDeletePermission();
    }

  }

  noRecordFoundNotCon: boolean = false
  getStandardPackageListing(isPaging: boolean) {
    try {
      let params
      if (this.manualPaging == true && isPaging != true) {
        this.pageNumber = 1
        params = {
          PageNumber: this.pageNumber,
          PageSize: this.pageSize
        }
      }
      else {
        params = {
          PageNumber: this.pageNumber,
          PageSize: this.pageSize
        }
      }
      this.standardPackageService.standardPackageListing(params).subscribe(response => {
        if (response.success) {

          let len = response.data.length;
         if (len > 0) {
            this.noRecordFoundNotCon = true
          }
          else {
            this.noRecordFoundNotCon = false
          }

          if (response.data.length < this.pageSize) {
            this.isLoadMore = false;
          }
          else {
            this.isLoadMore = true;
          }

          if (isPaging) {
            this.appendStandardPackageListing(response.data);
          }
          else {
            this.dataSource = response.data;
          }
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch {
      this.commonService.commonSnakeBar();
    }
  }


  appendStandardPackageListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.dataSource.length > 0) {
        newState = this.dataSource.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.dataSource = newState;
    }
  }

  AddStandardPackageModal(): void {


    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(AddNewStandardPackageComponent, {
        disableClose: true,
        panelClass: "full-width-popup"
      })
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          //this.pageNumber = 1
          this.manualPaging = true
          this.getStandardPackageListing(false);
        }
      })
    }
    else {
      this.commonService.userDeActivatedOrDeleted();
    }



  };

  EditStandardPackageModal(userDetail): void {

    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(EditStandardPackageComponent, {
        disableClose: true,
        panelClass: "full-width-popup",
        data: {
          userDetail
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.getStandardPackageListing(false);
          //  this.pageNumber = this.pageNumber
        }
      })
    }
    else {
      this.commonService.userDeActivatedOrDeleted();
    }
  }

  loadMore() {
    this.pageNumber = this.pageNumber + 1
    this.pageSize = this.pageSize
    this.getStandardPackageListing(true);
  }

  deletePackageConfirmModal(userDetail): void {

    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(ConfirmPackageDeleteComponent, {
        width: '600px',
        height: 'auto',
        data: {
          userDetail
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        // if (result == undefined || result == true || result == '') {
        if (result == undefined) {
          this.manualPaging = true
          this.getStandardPackageListing(false);
          //  this.pageNumber = this.pageNumber
        }
      })
    }
    else {
      this.commonService.userDeActivatedOrDeleted();
    }


  }

  onChange(enable: boolean, userDetail) {

    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(BlockPackageModelComponent, {
        width: '600px',
        height: 'auto',
        data: {
          userDetail
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.getStandardPackageListing(false);
        }
      })
    }
    else {
      this.commonService.userDeActivatedOrDeleted();
    }
  }

  getAddEditDeletePermission() {
    let params = {
      MenuId: 19
    }
    this.sharedService.getAddEditDeletePermission(params).subscribe(response => {
      if (response.success) {

        if (response.data.length > 0) {
          this.adds = response.data[0].adds
          this.views = response.data[0].views
          this.fullControl = response.data[0].fullControle
          this.deletes = response.data[0].deletes
          this.edits = response.data[0].edit
        }


        if (this.fullControl == true || this.edits == true) {
          this.disableds = false
        }
        else {
          this.disableds = true
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  userDetails = []
  statusId: number
  getuserDetails(): number {
    try {
      let userId = this.jwtService.getCurrentUserId();
      let param = {
        UserId: userId
      }
      this.sharedService.getUserDetails(param).subscribe(response => {
        if (response.success) {
          this.userDetails = response.data;
          this.statusId = response.data[0].statusId;
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch {
      this.commonService.commonSnakeBar();
    }
    return this.statusId;
  }

}
