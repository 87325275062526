import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-sms-candidate',
  templateUrl: './search-sms-candidate.component.html',
  styleUrls: ['./search-sms-candidate.component.scss']
})
export class SearchSmsCandidateComponent implements OnInit {

  constructor(  private router: Router) { }

  ngOnInit() {
  }

  onEnter(value: string) {
    this.router.navigate(['/search-result/', value, 'SmsCampaign']); //search-result
  }

}
