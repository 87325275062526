import { Component, OnInit } from '@angular/core';
import { JwtService } from '../../core/services/jwt.service';
import { SharedService } from '../service/shared.service';
import { CommonService } from '../../core/services/common.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  userId: any
  srcUrl: any
  constructor(private jwtService: JwtService,
    private sharedService: SharedService,
    private sanitizer: DomSanitizer,
    private commonService: CommonService) {
    this.userId = this.jwtService.getCurrentUserId();
    console.log(this.userId);
  }

  ngOnInit() {
    this.getDashBoardDetails();
    // this.srcUrl = this.sanitizer.bypassSecurityTrustResourceUrl("https://app.redash.io/softobiz-technology/public/dashboards/tXL93Jb0j0hqq8RN7TlPgryAzz6eC0MA3HhlgJsN?p_UserId=" + this.userId);
  //  this.srcUrl = this.sanitizer.bypassSecurityTrustResourceUrl("https://app.redash.io/varun-sodhi/public/dashboards/eSPgJti4LWYEfbAlYyPAGgl5eBp1mXWR5uXT0TBN?p_UserId=" + this.userId);
  
    this.srcUrl = this.sanitizer.bypassSecurityTrustResourceUrl("https://app.redash.io/smartklick/public/dashboards/ShGGR5sWYTXFl4Ig2XaycbsAk1yRa640rVtH1Zbc?p_UserId=" + this.userId);

  }

  totalCampaign: any
  campaignCompleted: any
  campaignCompletedPercentage: any
  campaignPaused: any
  campaignPausedPercentage: any

  totalCalls: any
  connectedCalls: any
  connectedCallsPercentage: any
  nonWorkingCalls: any
  nonWorkingCallsPercentage: any
  voiceMails: any
  voiceMailsPercentage: any
  callUsages: any
  callUsagesHoursMints: any
  smsUsages: any



  getDashBoardDetails() {
    let params = {
      UserId: this.userId,
      FilterType: 0
    }
    this.sharedService.getDashBoardDetails(params).subscribe(response => {
      if (response.success) {
        if (response.data.length > 0) {

          this.totalCampaign = response.data[0].totalCamp;

          this.campaignCompleted = response.data[0].completedCamp;
          this.campaignCompletedPercentage = response.data[0].campCompletedPercentage;

          this.campaignPaused = response.data[0].pausedCamp;
          this.campaignPausedPercentage = response.data[0].campPausedPercentage;

          this.totalCalls = response.data[0].totalNumOfCalls;

          this.connectedCalls = response.data[0].connectedCalls;
          this.connectedCallsPercentage = response.data[0].connectedCallsPercentage;

          this.nonWorkingCalls = response.data[0].nonWorkingCalls;
          this.nonWorkingCallsPercentage = response.data[0].nonWorkingCallsPercentage;

          this.voiceMails = response.data[0].voiceMail;
          this.voiceMailsPercentage = response.data[0].voiceMailPercentage;

          this.callUsages = response.data[0].callUsages;
          this.callUsagesHoursMints = response.data[0].callUsagesHoursMints;

          this.smsUsages = response.data[0].smsUsages;


        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })


  }

  // JustKlick() {
  //   
  //   // var iframe = document.getElementById("dashboardFrame");
  //   // var iframe = document.getElementsByClassName("parameter-block");
  //   // var iframe = document.getElementById("dashboardFrame");

  // }

}
