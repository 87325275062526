import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyPackagesListComponent } from './my-packages-list/my-packages-list.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialComponentsModule } from '../material-components.module';
import { EditMyPackagesStandardCustomizeComponent } from './edit-my-packages-standard-customize/edit-my-packages-standard-customize.component';

import { SliderItemDirective } from './slider-item.directive';
import { SliderComponent } from './slider/slider.component';
@NgModule({
  imports: [
    CommonModule,
    MaterialComponentsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [MyPackagesListComponent, EditMyPackagesStandardCustomizeComponent,SliderItemDirective,SliderComponent],
  exports:[MyPackagesListComponent],
  entryComponents:[EditMyPackagesStandardCustomizeComponent,MyPackagesListComponent]
})
export class MyPackagesModule { }
