import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StandardpackagesListComponent } from './standardpackages-list/standardpackages-list.component';
import { MaterialComponentsModule } from '../material-components.module';
import { AddNewStandardPackageComponent } from './add-new-standard-package/add-new-standard-package.component';
import { EditStandardPackageComponent } from './edit-standard-package/edit-standard-package.component';
import { BlockPackageModelComponent } from '../shared/block-package-model/block-package-model.component';
import { ConfirmPackageDeleteComponent } from '../shared/confirm-package-delete/confirm-package-delete.component';


@NgModule({
  imports: [
    CommonModule,
    MaterialComponentsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [StandardpackagesListComponent, AddNewStandardPackageComponent, EditStandardPackageComponent],
  exports:[AddNewStandardPackageComponent,EditStandardPackageComponent,StandardpackagesListComponent],
  entryComponents:[AddNewStandardPackageComponent,EditStandardPackageComponent,BlockPackageModelComponent,ConfirmPackageDeleteComponent]
})
export class StandardpackagesModule { }
