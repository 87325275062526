import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubscriberService } from '../service/subscriber.service';
import { CommonService } from '../../core/services/common.service';
import { AuthService } from '../../core/services/auth.service';
import { AppSettings } from '../../config/app-settings';

@Component({
  selector: 'app-edit-subscribe',
  templateUrl: './edit-subscribe.component.html',
  styleUrls: ['./edit-subscribe.component.scss']
})
export class EditSubscribeComponent implements OnInit {
  EditSubsForm: FormGroup
  userId: number
  name: string
  email: string
  phone: number
  status: string
  statusId: number
  userData: any;
  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  isProcessing: boolean = false
  allroles = []
  permissionId: any

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditSubscribeComponent,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private subscriberService: SubscriberService,
    private commonService: CommonService,
    private appSettings: AppSettings,
    public dialogRef: MatDialogRef<EditSubscribeComponent>
  ) {
    this.userData = this.data;

    this.userId = this.userData.userDetail.userId
    this.name = this.userData.userDetail.name
    this.email = this.userData.userDetail.email
    this.phone = this.userData.userDetail.phone
    this.status = this.userData.userDetail.status
    this.statusId = this.userData.userDetail.statusId
    this.permissionId = this.userData.userDetail.permissionId

  }

  ngOnInit() {
    this.initFrom();
    this.getRoleDropdown()
  }

  initFrom() {
    this.EditSubsForm = this.formBuilder.group({
      'Name': [this.name, [Validators.required, this.noWhitespaceValidator]],
      'Email': [{ value: this.email, disabled: this.emailIdDisable() }, [Validators.required, Validators.email]],
      'PhoneNumber': [this.phone, [Validators.required, Validators.minLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      'RoleLevel': [this.permissionId, [Validators.required]]
    })
  }

  Edit(form) {
    if (form.valid) {
      this.isProcessing = true;
      try {
        let params = {
          UserId: this.userId,
          Name: this.EditSubsForm.controls.Name.value,
          Phone: this.EditSubsForm.controls.PhoneNumber.value,
          Email: this.EditSubsForm.controls.Email.value,
          PermissionId: this.EditSubsForm.controls.RoleLevel.value
        }
        this.subscriberService.updateSubscriber(params).subscribe(response => {
          if (response.success) {
            this.commonService.showSnakeBar(response.message);

            let updatedValues = {
              UserId: this.userId,
              Name: this.EditSubsForm.controls.Name.value,
              Email: this.EditSubsForm.controls.Email.value,
              Phone: this.EditSubsForm.controls.PhoneNumber.value,
              PermissionId: this.EditSubsForm.controls.RoleLevel.value,
              Status: this.status,
              StatusId: this.statusId
            }

            this.closeDialog(updatedValues);
            this.isProcessing = false;
          }
          else {
            this.commonService.commonSnakeBar();
            this.isProcessing = false;
          }
        }, (error) => {
          this.commonService.checkTokenValidity(error);
          this.isProcessing = false;
        })
      }
      catch{
        this.commonService.commonSnakeBar();
        this.isProcessing = false;
      }

    }
    else {
      this.commonService.showSnakeBar("Please do the correction");
    }
  }

  closeDialog(updatedValues) {
    this.dialogRef.close(updatedValues);
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  emailIdDisable(): boolean {
    if (this.authService.isCompanyAdmin()) {
      return false;
    }
    else {
      return true;
    }
  }

  public numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getRoleDropdown() {
    let params = {
      PageNumber: this.pageNumber,
      PageSize: this.pageSize,
      SpOption: 'EditUser'
    }
    this.subscriberService.getRoleDropdown(params).subscribe(response => {
      if (response.success) {
        let count = [];
        response.data.forEach(element => {
          count.push({
            id: element.id,
            name: element.name
          })
        });
        this.allroles = count
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

}
