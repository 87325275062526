import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CreateCampaignComponent } from '../create-campaign/create-campaign.component';
import { CampaignService } from '../service/campaign.service';
import { CommonService } from '../../core/services/common.service';
import { AppSettings } from '../../config/app-settings';
import { StartCampaignComponent } from '../start-campaign/start-campaign.component';
import { PauseCampaignComponent } from '../pause-campaign/pause-campaign.component';
import { StopCampaignComponent } from '../stop-campaign/stop-campaign.component';
import { EditCampaignComponent } from '../edit-campaign/edit-campaign.component';
import { StatusMaster } from '../../core/constants/status';
import { SharedService } from '../../shared/service/shared.service';
import { JwtService } from '../../core/services/jwt.service';
import { CampaignDialListComponent } from '../campaign-dial-list/campaign-dial-list.component';
import { CampaignAutoDialComponent } from '../campaign-auto-dial/campaign-auto-dial.component';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { ConfirmWebCallAcceptdialogComponent } from '../../shared/confirm-web-call-acceptdialog/confirm-web-call-acceptdialog.component';
import { ResumeCampaignComponent } from '../resume-campaign/resume-campaign.component';

//declare const Twilio: any;

@Component({
  selector: 'app-campaigns-list',
  templateUrl: './campaigns-list.component.html',
  styleUrls: ['./campaigns-list.component.scss']
})
export class CampaignsListComponent implements OnInit {
  public ListingData = [];
  public InActiveCampaign = [];
  public ActiveCampaign = [];
  public demoTime: any
  @Output() open: EventEmitter<any> = new EventEmitter();
  fullControl: boolean = true;
  views: boolean = true;
  adds: boolean = true;
  edits: boolean = true;
  deletes: boolean = true;
  disableds: boolean = false;
  status: any = StatusMaster;
  NoActiveCampaign: boolean = false;
  NoInActiveCampaign: boolean = false;
  finalToken: any
  dateFormat: any;
  public device: any;
  public _hubConnecton: HubConnection;
  public speakerDevices: any = document.getElementById('speaker-devices');
  public ringtoneDevices: any = document.getElementById('ringtone-devices');
  public outputVolumeBar: any = document.getElementById('output-volume');
  public inputVolumeBar: any = document.getElementById('input-volume');
  public volumeIndicators: any = document.getElementById('volume-indicators');
  public token = "";
  userId: any
  popupResult: boolean = false;
  popupResults: boolean = false;

  constructor(
    public dialog: MatDialog,
    private campaignService: CampaignService,
    private commonService: CommonService,
    private sharedService: SharedService,
    private jwtService: JwtService,
    private appSettings: AppSettings
  ) {
    this.getuserDetails();
    this.dateFormat = this.appSettings.CAMPAIGN_DATE
    this.userId = this.jwtService.getCurrentUserId();
  }

  ngOnInit() {
    this.campaignListing();
    const role = this.jwtService.getUserRole();
    if (role != 'CompanyAdmin') {
      this.getAddEditDeletePermission();
    }
  }

  CreateCampaignModal(): void {
    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(CreateCampaignComponent, {
        disableClose: true,
        panelClass: "full-width-popup"
      });

      dialogRef.afterClosed().subscribe(result => {
        this.campaignListing();
      })
    }
    else {
      this.commonService.userDeActivatedOrDeleted();
    }

  }

  StartCampaignModal(campDetail): void {

    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(StartCampaignComponent, {
        width: '600px',
        height: 'auto',
        data: {
          campDetail
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        this.campaignListing();
      })
    }
    else {
      this.commonService.userDeActivatedOrDeleted();
    }
    if (campDetail.statusId == this.status.CallInProgress) {
      this.commonService.showSnakeBar("Campaign already Started");
      return;
    }

  }

  // StartFrontEndCallModal(campDetail): void {
  //   if (campDetail.statusId == this.status.CallInProgress) {
  //     this.commonService.showSnakeBar("Campaign already Started");
  //     return;
  //   }
  //   const dialogRef = this.dialog.open(CampaignDialListComponent, {
  //     width: 'auto',
  //     height: 'auto',
  //     data: {
  //       campDetail
  //     }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     this.campaignListing();
  //   })
  // }

  // const dialogRef = this.dialog.open(StartCallingComponent, {
  //   disableClose: true,
  //   panelClass: ['modal-outer', 'modal-md'],
  // });


  StartFrontEndCallModal(campDetail): void {
    // if (campDetail.statusId == this.status.CallInProgress) {
    //   this.commonService.showSnakeBar("Campaign already Started");
    //   return;
    // }

    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(CampaignAutoDialComponent, {
        disableClose: true,
        panelClass: ['modal-outer', 'modal-md'],
        data: {
          campDetail
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        this.campaignListing();
      })
    }
    else {
      this.commonService.userDeActivatedOrDeleted();
    }

  }

  PauseCampaignModal(campDetail): void {
    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(PauseCampaignComponent, {
        width: '600px',
        height: 'auto',
        data: {
          campDetail
        }

      });
      dialogRef.afterClosed().subscribe(result => {
        this.campaignListing();
      })
    }
    else {
      this.commonService.userDeActivatedOrDeleted();
    }

  }

  ResumeCampaignModal(campDetail): void {
    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(ResumeCampaignComponent, {
        width: '600px',
        height: 'auto',
        data: {
          campDetail
        }

      });
      dialogRef.afterClosed().subscribe(result => {
        this.campaignListing();
      })
    }
    else {
      this.commonService.userDeActivatedOrDeleted();
    }

  }

  StopCampaignModal(campDetail): void {

    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(StopCampaignComponent, {
        width: '600px',
        height: 'auto',
        data: {
          campDetail
        }

      });
      dialogRef.afterClosed().subscribe(result => {
        this.campaignListing();
      })
    }
    else {
      this.commonService.userDeActivatedOrDeleted();
    }

  }

  EditCampaignModal(campDetail): void {
    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(EditCampaignComponent, {
        disableClose: true,
        panelClass: "full-width-popup",
        data: {
          campDetail
        }

      });
      dialogRef.afterClosed().subscribe(result => {
        this.campaignListing();
      })
    }
    else {
      this.commonService.userDeActivatedOrDeleted();
    }


  }

  campaignListing() {

    let params = {
      CampaignId: 0
    }
    this.campaignService.campaignListing(params).subscribe(response => {
      if (response.success) {
        this.ListingData = response.data;

        this.InActiveCampaign = this.ListingData.filter(Campstatus => Campstatus["statusId"] == StatusMaster.Stopped);

        if (this.InActiveCampaign.length > 0) {
          this.NoInActiveCampaign = false;
        }
        else {
          this.NoInActiveCampaign = true;
        }

        this.ActiveCampaign = this.ListingData.filter(Campstatus => Campstatus["statusId"] != StatusMaster.Stopped);

        if (this.ActiveCampaign.length > 0) {
          this.NoActiveCampaign = false;
        }
        else {
          this.NoActiveCampaign = true;
        }

      }
      else {
        this.commonService.commonSnakeBar();
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  getAddEditDeletePermission() {
    let params = {
      MenuId: 1
    }
    this.sharedService.getAddEditDeletePermission(params).subscribe(response => {
      if (response.success) {
        if (response.data.length > 0) {
          this.adds = response.data[0].adds
          this.views = response.data[0].views
          this.fullControl = response.data[0].fullControle
          this.deletes = response.data[0].deletes
          this.edits = response.data[0].edit
        }

        if (this.fullControl == true || this.edits == true) {
          this.disableds = false
        }
        else {
          this.disableds = true
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }



  callingUserName: any
  public getWebCallerDetails() {
    var promise = new Promise((resolve, reject) => {
      let params = {
        UserId: this.userId
      }
      this.sharedService.getWebCallerDetails(params).subscribe(response => {
        if (response.success) {
          if (response.data.length > 0) {
            this.callingUserName = response.data[0].name
            resolve(this.callingUserName);
          }
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    });
    return promise;
  }

  userDetails = []
  statusId: number
  getuserDetails(): number {
    try {
      let userId = this.jwtService.getCurrentUserId();
      let param = {
        UserId: userId
      }
      this.sharedService.getUserDetails(param).subscribe(response => {
        if (response.success) {
          this.userDetails = response.data;
          this.statusId = response.data[0].statusId;
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch {
      this.commonService.commonSnakeBar();
    }
    return this.statusId;
  }

}
