import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SuperSubAdminServiceService } from '../service/super-sub-admin-service.service';
import { CommonService } from '../../core/services/common.service';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'app-edit-super-sub-admin',
  templateUrl: './edit-super-sub-admin.component.html',
  styleUrls: ['./edit-super-sub-admin.component.scss']
})
export class EditSuperSubAdminComponent implements OnInit {
  EditSuperSubAdminFrom: FormGroup
  userId: number
  name: string
  email: string
  phone: number
  status: string
  statusId: number
  userData: any;
  isProcessing: boolean = false
  allrolesTypes = []
  permissionId :any

  constructor(@Inject(MAT_DIALOG_DATA) public data: EditSuperSubAdminComponent,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private superSubAdminServiceService: SuperSubAdminServiceService,
    private commonService: CommonService,
    public dialogRef: MatDialogRef<EditSuperSubAdminComponent>) {
    this.userData = this.data;

    this.userId = this.userData.userDetail.userId,
      this.name = this.userData.userDetail.name,
      this.email = this.userData.userDetail.email,
      this.phone = this.userData.userDetail.phone,
      this.status = this.userData.userDetail.status
    this.statusId = this.userData.userDetail.statusId
    this.permissionId =this.userData.userDetail.permissionId
  }

  ngOnInit() {
    this.initFrom()
    this.getRoleTypeDropdown()
  }

  initFrom() {
    this.EditSuperSubAdminFrom = this.formBuilder.group({
      'Name': [this.name, [Validators.required, this.noWhitespaceValidator]],
      'Email': [this.email, [Validators.required, Validators.email]],
      'PhoneNumber': [this.phone, [Validators.required, Validators.minLength(10)]],
      'Role': [this.permissionId, [Validators.required]],
    })
  }


  EditSuperSubAdmin(form) {
    if (form.valid) {
      this.isProcessing = true;
      try {
        let params = {
          UserId: this.userId,
          Name: this.EditSuperSubAdminFrom.controls.Name.value,
          Phone: this.EditSuperSubAdminFrom.controls.PhoneNumber.value,
          Email: this.EditSuperSubAdminFrom.controls.Email.value,
          PermissionId: this.EditSuperSubAdminFrom.controls.Role.value
        }
        this.superSubAdminServiceService.updateSuperSubAdmin(params).subscribe(response => {
          if (response.success) {
            this.commonService.showSnakeBar(response.message);
            if(!response.message.includes("Email already exist")){
              this.closeDialog();
            }
            this.isProcessing = false;
          }
          else {
            this.commonService.commonSnakeBar();
            this.isProcessing = false;
          }
        }, (error) => {
          this.commonService.checkTokenValidity(error);
          this.isProcessing = false;
        })
      }
      catch{
        this.commonService.commonSnakeBar();
        this.isProcessing = false;
      }

    }
    else {
      this.commonService.showSnakeBar("Please do the correction");
    }
  }
  closeDialog() {
    this.dialogRef.close();
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  public numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getRoleTypeDropdown() {
    let param={
      SpOption:'Edit'
    }
    this.superSubAdminServiceService.getRoleTypeDropdown(param).subscribe(response => {
      if (response.success) {
        let count = [];
        response.data.forEach(element => {
          count.push({
            id: element.id,
            name: element.name
          })
        });
        this.allrolesTypes = count
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }
}
