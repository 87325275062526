import { Component, OnInit } from '@angular/core';
import { SuperSubAdminServiceService } from '../service/super-sub-admin-service.service';
import { CommonService } from '../../core/services/common.service';
import { AuthService } from '../../core/services/auth.service';
import { StatusMaster } from '../../core/constants/status';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppSettings } from '../../config/app-settings';
import { AddSuperSubAdminComponent } from '../add-super-sub-admin/add-super-sub-admin.component';
import { EditSuperSubAdminComponent } from '../edit-super-sub-admin/edit-super-sub-admin.component';
import { BlockModalComponent } from '../../shared/block-modal/block-modal.component';
import { ConfirmDeleteComponent } from '../../shared/confirm-delete/confirm-delete.component';
import { SharedService } from '../../shared/service/shared.service';
import { JwtService } from '../../core/services/jwt.service';

@Component({
  selector: 'app-super-sub-admin-list',
  templateUrl: './super-sub-admin-list.component.html',
  styleUrls: ['./super-sub-admin-list.component.scss']
})
export class SuperSubAdminListComponent implements OnInit {

  displayedColumns: string[] = ['userId', 'name', 'email', 'phone','permissionId','permissionName', 'Status', 'status'];
  dataSource = [];

  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  isLoadMore: boolean = false;

  statusMaster: any = StatusMaster

  public manualPaging: boolean = false

  fullControl: boolean = true;
  views: boolean = true;
  adds: boolean = true;
  edits: boolean = true;
  deletes: boolean = true;
  disableds: boolean = false;

  constructor(private superSubAdminServiceService: SuperSubAdminServiceService,
    private commonService: CommonService,
    private sharedService: SharedService,
    private jwtService: JwtService,
    private authService: AuthService,
    public dialog: MatDialog,
    private appSettings: AppSettings) { 
      this.getuserDetails();
    }

  ngOnInit() {
    this.superSubAdminListing(false)

    const role = this.jwtService.getUserRole();
    if (role == 'SuperSubAdmin') {
      this.getAddEditDeletePermission();
    }
  }



  noRecordFoundNotCon: boolean = false
  superSubAdminListing(isPaging: boolean) {
      try {
        let params
        if (this.manualPaging == true && isPaging != true) {
          this.pageNumber = 1
          params = {
            PageNumber: this.pageNumber,
            PageSize: this.pageSize
          }
        }
        else {
          params = {
            PageNumber: this.pageNumber,
            PageSize: this.pageSize
          }
        }
        this.superSubAdminServiceService.getSuperSubAdminList(params).subscribe(response => {
          if (response.success) {
            let len = response.data.length;
            if (len > 0) {
               this.noRecordFoundNotCon = true
             }
             else {
               this.noRecordFoundNotCon = false
             }

            if (response.data.length < this.pageSize) {
              this.isLoadMore = false;
            }
            else {
              this.isLoadMore = true;
            }

            if (isPaging) {
              this.appendsuperSubAdminListing(response.data);
            }
            else {
              this.dataSource = response.data;
            }
          }
          else {
            this.commonService.showSnakeBar(response.message);
          }
        }, (err) => {
          this.commonService.checkTokenValidity(err);
        })
      } catch{
        this.commonService.commonSnakeBar();
      }
  }


  loadMore() {
    this.pageNumber = this.pageNumber + 1
    this.pageSize = this.pageSize
    this.superSubAdminListing(true);
  }


  appendsuperSubAdminListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.dataSource.length > 0) {
        newState = this.dataSource.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.dataSource = newState;
    }
  }

  addSuperSubAdminModal(): void {
    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(AddSuperSubAdminComponent, {
        disableClose: true,
        panelClass: "full-width-popup"
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.superSubAdminListing(false);
        }
      })
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }
  }

  editSuperSubAdminModal(userDetail): void {

    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(EditSuperSubAdminComponent, {
        disableClose: true,
        panelClass: "full-width-popup",
        data: {
          userDetail
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.superSubAdminListing(false);
        }
      })
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }

 
  }

  onChange(enable: boolean, userDetail) {
    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(BlockModalComponent, {
        width: '600px',
        height: 'auto',
        data: {
          userDetail
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.superSubAdminListing(false);
        }
      })
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }
  
  }

  deleteSuperSubAdminConfirmModal(userId): void {
    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
        width: '600px',
        height: 'auto',
        data: {
          userId
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.superSubAdminListing(false);
        }
  
      })
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }
  
  }

  getAddEditDeletePermission() {
    let params = {
      MenuId: 20
    }
    this.sharedService.getAddEditDeletePermission(params).subscribe(response => {
      if (response.success) {

        if (response.data.length > 0) {
          this.adds = response.data[0].adds
          this.views = response.data[0].views
          this.fullControl = response.data[0].fullControle
          this.deletes = response.data[0].deletes
          this.edits=response.data[0].edit
        }


        if (this.fullControl == true || this.edits == true) {
          this.disableds = false
        }
        else {
          this.disableds = true
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }


  userDetails = []
  statusId: number
  getuserDetails(): number {
    try {
      let userId = this.jwtService.getCurrentUserId();
      let param = {
        UserId: userId
      }
      this.sharedService.getUserDetails(param).subscribe(response => {
        if (response.success) {
          this.userDetails = response.data;
          this.statusId = response.data[0].statusId;
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch {
      this.commonService.commonSnakeBar();
    }
    return this.statusId;
  }
  
}
