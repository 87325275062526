import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { CompanyService } from '../service/company.service';
import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { isNull, isNullOrUndefined } from 'util';

@Component({
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.scss']
})
export class EditCompanyComponent implements OnInit {
  editCompForm: FormGroup

  userData: any
  isProcessing: boolean = false
  companyId: any
  address: any
  city: any
  compName: any
  country: any
  countryId: any
  email: any
  phone: any
  state: any
  status: any
  zip: any
  countries = []

  pageNumber: any = 1
  pageSize: any = 20

  website: any
  aboutProfile: any
  twitterUrl: any
  facebookUrl: any
  linkedinUrl: any
  street: any
  ein: any
  companyType: any
  allcompanyType =[]
  public regWeb = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

  constructor(@Inject(MAT_DIALOG_DATA) public data: EditCompanyComponent,
    private companyService: CompanyService,
    private formbuilder: FormBuilder,
    private commonService: CommonService,
    private dialogRef: MatDialogRef<EditCompanyComponent>) {
    this.userData = data;
    this.companyId = this.userData.companyDetails.companyId;
    this.address = this.userData.companyDetails.address;
    this.city = this.userData.companyDetails.city;
    this.compName = this.userData.companyDetails.compName
    this.country = this.userData.companyDetails.country;
    this.countryId = this.userData.companyDetails.countryId;
    this.email = this.userData.companyDetails.email;
    this.phone = this.userData.companyDetails.phone;
    this.state = this.userData.companyDetails.state;
    this.status = this.userData.companyDetails.status
    this.zip = this.userData.companyDetails.zip;

    this.website = this.userData.companyDetails.website;
    this.aboutProfile = this.userData.companyDetails.aboutProfile;
    this.twitterUrl = this.userData.companyDetails.twitterUrl;
    this.facebookUrl = this.userData.companyDetails.facebookUrl;

    this.linkedinUrl = this.userData.companyDetails.linkedinUrl;
    this.street = this.userData.companyDetails.street;
    this.ein = this.userData.companyDetails.ein;
    this.companyType = this.userData.companyDetails.companyTypeId;
  }


  ngOnInit() {
    this.intiForm();
    this.getCountryDropdown();
    this.getCompanyTypeDropdown();
  }

  intiForm() {
    this.editCompForm = this.formbuilder.group({
      'CompName': [this.compName, [Validators.required, this.noWhitespaceValidator]],
      'Address': [this.address, [Validators.required, this.noWhitespaceValidator]],
      'City': [this.city, [Validators.required, this.noWhitespaceValidator]],
      'State': [this.state, [Validators.required, this.noWhitespaceValidator]],
      'Zip': [this.zip, [Validators.required, Validators.minLength(3)]],
      'Country': [Number(this.countryId), [Validators.required]],
      'CompanyType': [this.companyType, [Validators.required]],
      'Email': [this.email, [Validators.required, Validators.email]],
      'Phone': [this.phone, [Validators.required, this.noWhitespaceValidator]],
      'Website': [this.website, [Validators.pattern(this.regWeb)]],
      'AboutProfile': [this.aboutProfile],
      'TwitterUrl': [this.twitterUrl, [Validators.pattern(this.regWeb)]],
      'FacebookUrl': [this.facebookUrl, [Validators.pattern(this.regWeb)]],
      'LinkedinUrl': [this.linkedinUrl, [Validators.pattern(this.regWeb)]],
      'Street': [this.street],
      'Ein': [this.ein, [Validators.required]]
    })

  }

  editCompany(form) {
    if (form.valid) {
      try {
        this.isProcessing = true
        let params = {
          CompanyId: this.companyId,
          CompName: this.editCompForm.controls.CompName.value,
          Address: this.editCompForm.controls.Address.value,
          City: this.editCompForm.controls.City.value,
          State: this.editCompForm.controls.State.value,
          Zip: this.editCompForm.controls.Zip.value,
          CountryId: this.editCompForm.controls.Country.value,
          CompanyType: this.editCompForm.controls.CompanyType.value,
          Email: this.editCompForm.controls.Email.value,
          Phone: this.editCompForm.controls.Phone.value,
          Website: this.editCompForm.controls.Website.value,
          AboutProfile: this.editCompForm.controls.AboutProfile.value,
          TwitterUrl: this.editCompForm.controls.TwitterUrl.value,
          FacebookUrl: this.editCompForm.controls.FacebookUrl.value,
          LinkedinUrl: this.editCompForm.controls.LinkedinUrl.value,
          Street: this.editCompForm.controls.Street.value,
          Ein: this.editCompForm.controls.Ein.value
        }

        this.companyService.editCompany(params).subscribe(response => {

          if (response.success) {
            this.commonService.showSnakeBar(response.message);
            this.closeDialog();
            this.isProcessing = false;
          }
          else {
            this.commonService.showSnakeBar(response.message);
            this.isProcessing = false;
          }
        }, (err) => {
          this.commonService.checkTokenValidity(err);
          this.closeDialog();
          this.isProcessing = false;
        })
      }
      catch{
        this.commonService.commonSnakeBar();
        this.isProcessing = false;
      }
    }
    else {
      this.commonService.showSnakeBar("Please do the correction");
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  getCountryDropdown() {
    let params = {
      PageNumber: this.pageNumber,
      PageSize: this.pageSize
    }
    this.companyService.getCountryDropdown(params).subscribe(response => {
      if (response.success) {
        let count = [];

        response.data.forEach(element => {
          count.push({
            id: element.id,
            name: element.name
          })
        });
        this.countries = count
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  public numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getCompanyTypeDropdown() {
    this.companyService.getCompanyTypeDropdown().subscribe(response => {
      if (response.success) {
        let count = [];
        response.data.forEach(element => {
          count.push({
            id: element.id,
            name: element.name
          })
        });
        this.allcompanyType = count
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

}
