import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddBuyRequiredPackageComponent } from './add-buy-required-package/add-buy-required-package.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialComponentsModule } from '../material-components.module';
import { BuyRequiredPackageListComponent } from './buy-required-package-list/buy-required-package-list.component';
import { EditBuyRequiredPackageComponent } from './edit-buy-required-package/edit-buy-required-package.component';


@NgModule({
  imports: [
    CommonModule,
    MaterialComponentsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [AddBuyRequiredPackageComponent, BuyRequiredPackageListComponent, EditBuyRequiredPackageComponent],
  entryComponents:[AddBuyRequiredPackageComponent,EditBuyRequiredPackageComponent]
})
export class BuyRequiredPackageModule { }
