import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { ComponentPlanService } from '../service/component-plan.service'
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


@Component({
  selector: 'app-add-component-type',
  templateUrl: './add-component-type.component.html',
  styleUrls: ['./add-component-type.component.scss']
})
export class AddComponentTypeComponent implements OnInit {

  addCompTypeForm: FormGroup
  isProcessing: any = false

  constructor(private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private commonService: CommonService,
    private componentPlanService: ComponentPlanService,
    private datePipe: DatePipe,
    private dialogRef: MatDialogRef<AddComponentTypeComponent>) { }

  ngOnInit() {
    this.initForm()
  }

  initForm() {
    this.addCompTypeForm = this.formBuilder.group({
      'Name': [null, [Validators.required, this.noWhitespaceValidator]],
    });
  }

  addComponentType(form) {
    if (form.valid) {
      try {
        this.isProcessing = true;

        let params = {
          Name: this.addCompTypeForm.controls.Name.value.trim(),

        }
        this.componentPlanService.addComponentType(params).subscribe(resposne => {
          if (resposne.success) {
            this.isProcessing = false;
            this.commonService.showSnakeBar(resposne.message);
            this.closeDialog();
          }
          else {
            this.isProcessing = false;
            this.commonService.showSnakeBar(resposne.message);
          }

        }, (err) => {
          this.isProcessing = false;
          this.commonService.checkTokenValidity(err);
          this.closeDialog();
        })
      }
      catch
      {
        this.isProcessing = false;
        this.commonService.commonSnakeBar();
      }
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
}
