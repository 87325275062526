import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { MaterialComponentsModule } from '../material-components.module';
import { RouterModule } from '@angular/router';
import {  ReactiveFormsModule,FormsModule } from '@angular/forms';
import { UserPermissionListComponent } from './user-permission-list/user-permission-list.component';
import { AddUserPermissionComponent } from './add-user-permission/add-user-permission.component';
import { EditUserPermissionComponent } from './edit-user-permission/edit-user-permission.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    MaterialComponentsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [UserPermissionListComponent, AddUserPermissionComponent, EditUserPermissionComponent],
  entryComponents :[AddUserPermissionComponent,EditUserPermissionComponent]
})
export class UserPermissionModule { }
