import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NewSkillModalComponent } from '../new-skill-modal/new-skill-modal.component';
import { SkillService } from '../service/skill.service';
import { AppSettings } from '../../config/app-settings';
import { CommonService } from '../../core/services/common.service';
import { EditSkillComponent } from '../edit-skill/edit-skill.component';
import { DeleteSkillComponent } from '../delete-skill/delete-skill.component';
import { JwtService } from '../../core/services/jwt.service';
import { SharedService } from '../../shared/service/shared.service';

export interface PeriodicElement {
  name: string
  recruiter_no: string;
  ivr_extension: string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  // { user_name: '.Net Developer', recruiter_no: '4', ivr_extension: '4', },
];

@Component({
  selector: 'app-skill-list',
  templateUrl: './skill-list.component.html',
  styleUrls: ['./skill-list.component.scss']
})
export class SkillListComponent implements OnInit {
  displayedColumns: string[] = ['name', 'numOfRecruiter', 'ivrNumber', 'action'];
  dataSource = [];

  fullControl: boolean = true;
  views: boolean = true;
  adds: boolean = true;
  edits: boolean = true;
  deletes: boolean = true;
  disableds: boolean = false;

  spanOption: boolean = true;
  spanOptionToggle() {
    this.spanOption = !this.spanOption;
  }

  isLoadMore: boolean = false

  //NoSkillFound: boolean = false;
  public manualPaging: boolean = false
  constructor(
    public dialog: MatDialog,
    private skillService: SkillService,
    private appSettings: AppSettings,
    private jwtService: JwtService,
    private sharedService: SharedService,
    private commonService: CommonService
  ) {
    this.getuserDetails();
  }
  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE

  ngOnInit() {
    this.skillListng(false);

    const role = this.jwtService.getUserRole();
    if (role != 'CompanyAdmin') {
      this.getAddEditDeletePermission();
    }
  }

  AddSkillModal(): void {
     
    if (this.statusId == 10) {
	    const dialogRef = this.dialog.open(NewSkillModalComponent, {
        disableClose: true,
        width: '600px',
        height: 'auto',
        panelClass: 'modal-outer'
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.skillListng(false);
        }
      })
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }

  }

  EditSkillModal(skillDetails): void {

     
    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(EditSkillComponent, {
        width: '600px',
        height: 'auto',
        panelClass: 'modal-outer',
        data: skillDetails
      });
  
      dialogRef.afterClosed().subscribe(result => {
        // var index = this.dataSource.findIndex(ds => ds.id == result.Id);
        // if (index != -1) {
        //   this.dataSource[index] = {
        //     'name': result.Name,
        //     'numOfRecruiter': result.NumOfRec,
        //     'ivrNumber': result.IVRNumber
        //   };
        //   this.dataSource = this.dataSource.slice()
        // }
        if (result == undefined) {
          this.manualPaging = true
          this.skillListng(false);
        }
      })
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }
   
  }

  deleteSkill(skillDetails): void {

     
    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(DeleteSkillComponent, {
        width: '600px',
        height: 'auto',
        data: skillDetails
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.skillListng(false);
        }
  
      })
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }
  }
  noRecordFoundNotCon: boolean = false
  skillListng(isPaging: boolean) {
    try {
      let params
      if (this.manualPaging == true && isPaging != true) {
        this.pageNumber = 1
        params = {
          PageNumber: this.pageNumber,
          PageSize: this.pageSize
        }
      }
      else {
        params = {
          PageNumber: this.pageNumber,
          PageSize: this.pageSize
        }
      }
      this.skillService.getSkillList(params).subscribe(response => {
        if (response.success) {

          let len = response.data.length;
          if (len > 0) {
             this.noRecordFoundNotCon = true
           }
           else {
             this.noRecordFoundNotCon = false
           }

          if (response.data.length < this.pageSize) {
            this.isLoadMore = false;
          }
          else {
            this.isLoadMore = true;
          }

          // if (response.data.length <= 0) {
          //   this.NoSkillFound = true;
          // }
          // else {
          //   this.isLoadMore = false;
          // }

          if (isPaging) {
            this.appendSkillListing(response.data);
          }
          else {
            this.dataSource = response.data;
          }

        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch{
      this.commonService.commonSnakeBar();
    }
  }


  appendSkillListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.dataSource.length > 0) {
        newState = this.dataSource.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.dataSource = newState;
    }
  }

  loadMore() {
    this.pageNumber = this.pageNumber + 1
    this.pageSize = this.pageSize
    this.skillListng(true);
  }

  getAddEditDeletePermission() {
    let params = {
      MenuId: 5
    }
    this.sharedService.getAddEditDeletePermission(params).subscribe(response => {
      if (response.success) {

        if (response.data.length > 0) {
          this.adds = response.data[0].adds
          this.views = response.data[0].views
          this.fullControl = response.data[0].fullControle
          this.deletes = response.data[0].deletes
          this.edits=response.data[0].edit
        }


        if (this.fullControl == true || this.edits == true) {
          this.disableds = false
        }
        else {
          this.disableds = true
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  userDetails = []
  statusId: number
  getuserDetails(): number {
    try {
      let userId = this.jwtService.getCurrentUserId();
      let param = {
        UserId: userId
      }
      this.sharedService.getUserDetails(param).subscribe(response => {
        if (response.success) {
          this.userDetails = response.data;
          this.statusId = response.data[0].statusId;
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch {
      this.commonService.commonSnakeBar();
    }
    return this.statusId;
  }

}
