import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { ComponentPlanService } from '../service/component-plan.service';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { isNull, isNullOrUndefined } from 'util';
import { AddComponentTypeComponent } from '../add-component-type/add-component-type.component';

@Component({
  selector: 'app-edit-component-plans',
  templateUrl: './edit-component-plans.component.html',
  styleUrls: ['./edit-component-plans.component.scss']
})
export class EditComponentPlansComponent implements OnInit {
  editCompPlanForm: FormGroup
  userData: any

  planId: number
  mainComponent: any
  mainComponentId: number
  componentType: any
  name: any
  price: any
  status: any
  statusId: number
  isProcessing: boolean = false
  mainComp = [];
  componentTypeData = []
  componentTypeId: any

  DummyValue: any
  public numericNumberReg = '^-?[0-9]\\d*(\\.\\d{1,2})?$';

  constructor(@Inject(MAT_DIALOG_DATA) public data: EditComponentPlansComponent,
    private componentPlanService: ComponentPlanService,
    private formbuilder: FormBuilder,
    private commonService: CommonService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<EditComponentPlansComponent>) {
    this.userData = data;
    this.planId = this.userData.userDetail.planId;
    this.mainComponent = this.userData.userDetail.mainComponent;
    this.mainComponentId = this.userData.userDetail.mainComponentId;
    this.componentType = this.userData.userDetail.componentType;
    this.name = this.userData.userDetail.name;
    this.price = this.userData.userDetail.price;
    this.status = this.userData.userDetail.status;
    this.statusId = this.userData.userDetail.statusId;
    this.componentTypeId = this.userData.userDetail.componentTypeId;

  }

  ngOnInit() {
    this.intiForm();
    this.getMainComponentDropdown();
    this.getComponentTypeDropdown();
  }

  intiForm() {
    this.editCompPlanForm = this.formbuilder.group({
      'PlanId': [this.planId],
      'Name': [this.name, [Validators.required]],
      'Price': [this.price, [Validators.required, Validators.pattern(this.numericNumberReg)]],
      'Component': [Number(this.mainComponentId), [Validators.required]],
      'ComponentType': [Number(this.componentTypeId), [Validators.required]]
    })
  }


  editComponentPlan(form) {
    if (form.valid) {
      try {
        this.isProcessing = true

        let params = {
          PlanId: this.planId,
          Name: this.editCompPlanForm.controls.Name.value,
          Price: this.editCompPlanForm.controls.Price.value,
          Component: this.editCompPlanForm.controls.Component.value,
          componentType: this.componentType,
          ComponentTypeId: this.editCompPlanForm.controls.ComponentType.value
        }

        this.componentPlanService.editComponentPlan(params).subscribe(response => {

          if (response.success) {
            this.commonService.showSnakeBar(response.message);
            this.closeDialog();
            this.isProcessing = false;
          }
          else {
            this.commonService.showSnakeBar(response.message);
            this.isProcessing = false;
          }
        }, (err) => {
          this.commonService.checkTokenValidity(err);
          this.closeDialog();
          this.isProcessing = false;
        })
      }
      catch{
        this.commonService.commonSnakeBar();
        this.isProcessing = false;
      }
    }
    else {
      this.commonService.showSnakeBar("Please do the correction");
    }
  }


  closeDialog() {
    this.dialogRef.close();
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  getMainComponentDropdown() {
    let params = {
      SpAction: 'Edit'
    }
    this.componentPlanService.getMainComponentDropdown(params).subscribe(response => {
      if (response.success) {
        let count = [];
        response.data.forEach(element => {
          count.push({
            id: element.id,
            name: element.name
          })
        });
        this.mainComp = count
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  getComponentTypeDropdown() {
    let params =
    {
      SpAction: 'View'
    }
    this.componentPlanService.getComponentTypeDropdown(params).subscribe(response => {
      if (response.success) {
        let count = [];
        response.data.forEach(element => {
          count.push({
            id: element.id,
            name: element.name
          })
        });
        this.componentTypeData = count
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  AddComponentTypePlanModal(): void {
    const dialogRef = this.dialog.open(AddComponentTypeComponent, {
      width: '600px',
      height: 'auto',
      panelClass: "modal-outer"
    }
    )

    dialogRef.afterClosed().subscribe(result => {
      this.getComponentTypeDropdown();
      if (result == undefined) {

      }
    })
  };

}
