import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileModalComponent } from './profile-modal/profile-modal.component';
import { MaterialComponentsModule } from '../material-components.module';
import { RouterModule } from '@angular/router';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ConfirmDeleteComponent } from './confirm-delete/confirm-delete.component';
import { BlockModalComponent } from './block-modal/block-modal.component';
import { CreatePasswordComponent } from './create-password/create-password.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BlockPackageModelComponent } from './block-package-model/block-package-model.component';
import { ConfirmPackageDeleteComponent } from './confirm-package-delete/confirm-package-delete.component';
import { BlockCompanyModelComponent } from './block-company-model/block-company-model.component';
import { ConfirmCompanyDeleteComponent } from './confirm-company-delete/confirm-company-delete.component';
import { ConfirmComponentPlanDeleteComponent } from './confirm-component-plan-delete/confirm-component-plan-delete.component';
import { BlockComponentPlanModelComponent } from './block-component-plan-model/block-component-plan-model.component';
import { DeleteCustomizePackagesComponent } from './delete-customize-packages/delete-customize-packages.component';
import { BlockApprovedCustomizePackageComponent } from './block-approved-customize-package/block-approved-customize-package.component';
import { UiKitComponent } from './ui-kit/ui-kit.component';
import { BlockUserPermissionComponent } from './block-user-permission/block-user-permission.component';
import { DeleteUserPermissionComponent } from './delete-user-permission/delete-user-permission.component';
import { BlockUserPermissionCompanyAdminComponent } from './block-user-permission-company-admin/block-user-permission-company-admin.component';
import { DeleteUserPermissionCompanyAdminComponent } from './delete-user-permission-company-admin/delete-user-permission-company-admin.component';
import { ProfileBigScreenComponent } from './profile-big-screen/profile-big-screen.component';
import { StartCallingComponent } from './start-calling/start-calling.component';
import { CallingActiveModelComponent } from './calling-active-model/calling-active-model.component';
import { NextCallModelComponent } from './next-call-model/next-call-model.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmWebCallAcceptdialogComponent } from './confirm-web-call-acceptdialog/confirm-web-call-acceptdialog.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BlockDirectCallCandidateComponent } from './block-direct-call-candidate/block-direct-call-candidate.component';
import { ConfirmCandiateDirectCallDeleteComponent } from './confirm-candiate-direct-call-delete/confirm-candiate-direct-call-delete.component';
import { ConfirmSendSMSComponent } from './confirm-send-sms/confirm-send-sms.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ConfirmSendEmailComponent } from './confirm-send-email/confirm-send-email.component';


@NgModule({
  imports: [
    CommonModule,
    MaterialComponentsModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,

    OwlDateTimeModule,
    OwlNativeDateTimeModule

  ],
  declarations: [ProfileModalComponent, ChangePasswordComponent, ConfirmDeleteComponent, BlockModalComponent, CreatePasswordComponent, BlockPackageModelComponent, ConfirmPackageDeleteComponent, BlockCompanyModelComponent, ConfirmCompanyDeleteComponent, ConfirmComponentPlanDeleteComponent, BlockComponentPlanModelComponent, DeleteCustomizePackagesComponent, BlockApprovedCustomizePackageComponent, UiKitComponent, BlockUserPermissionComponent, DeleteUserPermissionComponent, BlockUserPermissionCompanyAdminComponent, DeleteUserPermissionCompanyAdminComponent, ProfileBigScreenComponent, StartCallingComponent, CallingActiveModelComponent, NextCallModelComponent, ConfirmDialogComponent, ConfirmWebCallAcceptdialogComponent, DashboardComponent, BlockDirectCallCandidateComponent, ConfirmCandiateDirectCallDeleteComponent, ConfirmSendSMSComponent, ConfirmSendEmailComponent],
  exports: [ProfileModalComponent, ChangePasswordComponent, ConfirmDeleteComponent, BlockModalComponent,],
  entryComponents: [ProfileModalComponent, ChangePasswordComponent, ConfirmDeleteComponent, BlockModalComponent, BlockCompanyModelComponent, ConfirmCompanyDeleteComponent, ConfirmComponentPlanDeleteComponent, BlockComponentPlanModelComponent, BlockApprovedCustomizePackageComponent, BlockUserPermissionComponent, DeleteUserPermissionComponent, BlockUserPermissionCompanyAdminComponent, DeleteUserPermissionCompanyAdminComponent, StartCallingComponent, CallingActiveModelComponent, NextCallModelComponent, ConfirmDialogComponent, ConfirmWebCallAcceptdialogComponent, BlockDirectCallCandidateComponent, ConfirmCandiateDirectCallDeleteComponent,ConfirmSendSMSComponent,ConfirmSendEmailComponent]
})
export class SharedModule { }
