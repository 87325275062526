import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CommonService } from '../../core/services/common.service';
import { StatusMaster } from '../../core/constants/status';
import { SmsCampaignServicesService } from '../service/sms-campaign-services.service';
@Component({
  selector: 'app-pause-campaign',
  templateUrl: './pause-campaign.component.html',
  styleUrls: ['./pause-campaign.component.scss']
})
export class PauseCampaignComponent implements OnInit {


  campData: any
  campaignId: number


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PauseCampaignComponent,
    private commomService: CommonService,
    private smsCampaignServicesService: SmsCampaignServicesService,
    private dialogRef: MatDialogRef<PauseCampaignComponent>
  ) {

    this.campData = data;

    this.campaignId = this.campData.campDetail.campaignId
  }

  ngOnInit() {
  }


  pause() {
    let params = {
      CampaignId: this.campaignId,
      StatusId: StatusMaster.Paused
    }
    this.smsCampaignServicesService.UpdateCampaignStatus(params).subscribe(resposne => {
      if (resposne.success) {
        this.commomService.showSnakeBar("Campaign paused");
        this.closeDialog();
      }
      else {
        this.commomService.showSnakeBar("Unable to pause campaign");
      }
    }, (err) => {
      this.commomService.checkTokenValidity(err);
      this.closeDialog();
    })
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
