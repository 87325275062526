import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialComponentsModule } from '../material-components.module';
import { AllocateMinutesToRecruiterListComponent } from './allocate-minutes-to-recruiter-list/allocate-minutes-to-recruiter-list.component';
import { AddAllocateMinutesToRecruiterComponent } from './add-allocate-minutes-to-recruiter/add-allocate-minutes-to-recruiter.component';
import { EditAllocateMinutesToRecruiterComponent } from './edit-allocate-minutes-to-recruiter/edit-allocate-minutes-to-recruiter.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialComponentsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [AllocateMinutesToRecruiterListComponent, AddAllocateMinutesToRecruiterComponent, EditAllocateMinutesToRecruiterComponent],
  entryComponents: [AddAllocateMinutesToRecruiterComponent, EditAllocateMinutesToRecruiterComponent]
})
export class AllocateMinutesToRecruiterModule { }
