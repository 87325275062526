import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { SharedService } from '../service/shared.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit {
  public CreatePasswordForm: FormGroup

  token: string;
  Email: string;
  hide = false;
  hideSec = false;
  constructor(
    private renderer: Renderer2,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private sharedService: SharedService,
    private activatedRoute: ActivatedRoute

  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params['token'];
      this.Email = params['Email'];
    });
    this.renderer.addClass(document.body, 'bg-theme-gradient');
  }

  ngOnInit() {
    this.initform();
  }

  initform() {
    this.CreatePasswordForm = this.formBuilder.group({
      'Password': [null, [Validators.required, Validators.pattern('^(?=.*[A-Z])(?=.*[@#$%^&+=]).*$')]],
      'ConfrimPassword': [null, [Validators.required, Validators.pattern('^(?=.*[A-Z])(?=.*[@#$%^&+=]).*$')]]
    })
  }

  Submit(form) {
    if (form.valid) {
      if (this.checkPasswords()) {
        try {

          let params = {
            Email: this.Email,
            Password: this.CreatePasswordForm.controls.Password.value,
            code: this.token
          };

          this.sharedService.createPassword(params).subscribe(response => {
            if (response.success) {
              this.commonService.showSnakeBar(response.message);
              this.commonService.navigate("/login");

            }
            else {
              this.commonService.showSnakeBar(response.message);
            }
          }, (err) => {
            // let response = err.json();
            // console.log(response)
            // this.commonService.showSnakeBar(response);
            this.commonService.commonSnakeBar();
          });
        }
        catch{
          this.commonService.commonSnakeBar();
        }

      }
      else {
        this.commonService.showSnakeBar("Password and Confrim password does not match.");

      }

    }
    else {
      this.commonService.showSnakeBar("Form not Valid");
    }
  }

  checkPasswords() { // here we have the 'passwords' group
    let pass = this.CreatePasswordForm.controls.Password.value;
    let confirmPass = this.CreatePasswordForm.controls.ConfrimPassword.value;

    return pass === confirmPass ? true : false
  }

}
