import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignsListComponent } from './campaigns-list/campaigns-list.component';
import { CoreModule } from '../core/core.module';
import { MaterialComponentsModule } from '../material-components.module';
import { CampaignsDetailComponent } from './campaigns-detail/campaigns-detail.component';
import { RouterModule } from '@angular/router';
import { CreateCampaignComponent } from './create-campaign/create-campaign.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StartCampaignComponent } from './start-campaign/start-campaign.component';
import { PauseCampaignComponent } from './pause-campaign/pause-campaign.component';
import { StopCampaignComponent } from './stop-campaign/stop-campaign.component';
import { EditCampaignComponent } from './edit-campaign/edit-campaign.component';
import { ConfirmBlacklistComponent } from './confirm-blacklist/confirm-blacklist.component';
import { CampaignDialListComponent } from './campaign-dial-list/campaign-dial-list.component';
import { CampaignAutoDialComponent } from './campaign-auto-dial/campaign-auto-dial.component';
import { ConfirmDialogComponent } from '../shared/confirm-dialog/confirm-dialog.component';
import { ResumeCampaignComponent } from './resume-campaign/resume-campaign.component';
import { ExcelService } from './campaigns-detail/excelservice';


@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    MaterialComponentsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [CampaignsListComponent, CampaignsDetailComponent, CreateCampaignComponent, StartCampaignComponent, PauseCampaignComponent, StopCampaignComponent, EditCampaignComponent, ConfirmBlacklistComponent, CampaignDialListComponent, CampaignAutoDialComponent, ResumeCampaignComponent],
  exports: [CreateCampaignComponent],
  entryComponents: [CreateCampaignComponent, StartCampaignComponent, PauseCampaignComponent, StopCampaignComponent, EditCampaignComponent, ConfirmBlacklistComponent,CampaignDialListComponent,CampaignAutoDialComponent,ConfirmDialogComponent,ResumeCampaignComponent],
  providers: [ExcelService]
})
export class CampaignsModule { }
