import { Component, OnInit, ViewChild} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material';
import { CallingActiveModelComponent } from '../calling-active-model/calling-active-model.component';

export interface PeriodicElement {
  campaignId: string;
  referenceId: string;
  candidateName: string;
  phone: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    campaignId: '1',
    referenceId: '22103679',
    candidateName: 'Chetan',
    phone: '7340953011',
  },
  {
    campaignId: '2',
    referenceId: '22103680',
    candidateName: 'Harjot',
    phone: '9501090026',
  },
];

@Component({
  selector: 'app-start-calling',
  templateUrl: './start-calling.component.html',
  styleUrls: ['./start-calling.component.css']
})
export class StartCallingComponent implements OnInit {

  constructor(public dialog: MatDialog) { }
  displayedColumns: string[] = ['campaignId', 'referenceId', 'candidateName', 'phone'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatSort) sort: MatSort;

  activeCalling(): void {
    const dialogRef = this.dialog.open(CallingActiveModelComponent, {
      disableClose: true,
      panelClass: ['modal-outer', 'modal-md'],
    });
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;
  }

}