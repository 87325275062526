import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { SharedService } from '../service/shared.service';
import { CommonService } from '../../core/services/common.service';
import { StatusMaster } from '../../core/constants/status';

@Component({
  selector: 'app-block-direct-call-candidate',
  templateUrl: './block-direct-call-candidate.component.html',
  styleUrls: ['./block-direct-call-candidate.component.scss']
})
export class BlockDirectCallCandidateComponent implements OnInit {

  id: null
  statusId: number
  userStatus: string
  userData: any
  userStatusId: number
  statusMstr: any = StatusMaster

  constructor(@Inject(MAT_DIALOG_DATA) public data: BlockDirectCallCandidateComponent,
  private sharedService: SharedService,
  private commomService: CommonService,
  private dialogRef: MatDialogRef<BlockDirectCallCandidateComponent>) {
    this.userData = data;
    this.id = this.userData.candidateDetails.id
    this.userStatus = this.userData.candidateDetails.status
    this.userStatusId = this.userData.candidateDetails.statusId

    if (this.userStatusId == this.statusMstr.InActive) {
      this.statusId = this.statusMstr.Active
    }
    else if (this.userStatusId == this.statusMstr.Active) {
      this.statusId = this.statusMstr.InActive
    }
   }

  ngOnInit() {
  }

  block() {
    try {
      let parms = {
        id: this.id,
        statusId: this.statusId
      }
      this.sharedService.blockDirectCallingCandidateStatus(parms).subscribe(response => {
        if (response.success) {
          this.commomService.showSnakeBar("Candidate status has been updated successfully");
          this.closeDialog();
        }
        else {
          this.commomService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commomService.checkTokenValidity(err);
      })
    }
    catch{
      this.commomService.commonSnakeBar();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
