import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { ResultModel } from '../../core/model/result.model';
import { Observable } from 'rxjs';
import { ApiUrls } from '../../config/api-urls';

@Injectable({
  providedIn: 'root'
})
export class SuperSubAdminServiceService {

  constructor(private apiService: ApiService,
    private apiUrl: ApiUrls) { }

    getSuperSubAdminList(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.SuperSubAdmin.SuperSubAdminListing, reqModel)
    }
    getRoleTypeDropdown(reqmodel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.SuperSubAdmin.GetRoleTypeDropdown,reqmodel)
    }
    
  
    addSuperSubAdmin(addModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.SuperSubAdmin.AddSuperSubAdmin, addModel);
    }
  
    updateSuperSubAdmin(updateModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.SuperSubAdmin.UpdateSuperSubAdmin, updateModel);
    }
}
