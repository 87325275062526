import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ApiUrls } from '../../config/api-urls';
import { Observable } from 'rxjs';
import { ResultModel } from '../model/result.model';

@Injectable({ providedIn: 'root' }

)
export class MenuDetails {

  constructor(
    private apiService: ApiService,
    private apiUrl: ApiUrls
  ) { }

  getMenuList(UserId): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Shared.GetMenuDetails, UserId);
  }

}
