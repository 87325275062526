import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { ApiUrls } from '../../config/api-urls';
import { Observable } from 'rxjs';
import { ResultModel } from '../../core/model/result.model';

@Injectable({
  providedIn: 'root'
})
export class CallForwardingService {

  constructor(
    private apiService: ApiService,
    private apiUrl: ApiUrls
  ) { }


  getDetails(userId): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.Shared.GetUserDetails, { UserId: userId });
  }

  ActivateCallForw(param): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.CallForwarding.CallForwarding, param);
  }
}
