import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialComponentsModule } from '../material-components.module';
import { PurchasedPackageManagementListComponent } from './purchased-package-management-list/purchased-package-management-list.component';
import { EditPurchasedPackageManagementComponent } from './edit-purchased-package-management/edit-purchased-package-management.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialComponentsModule
  ],
  declarations: [PurchasedPackageManagementListComponent, EditPurchasedPackageManagementComponent],
  entryComponents:[EditPurchasedPackageManagementComponent]
})
export class PurchasedPackageManagementModule { }
