import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { MenuComponent } from './menu/menu.component';
import { SearchComponent } from './search/search.component';
import { ProfileComponent } from './profile/profile.component';
import { MaterialComponentsModule } from '../material-components.module';
import { SharedModule } from '../shared/shared.module';
import { SearchResultComponent } from './search-result/search-result.component';
import { ApiService } from './services/api.service';
import { JwtService } from './services/jwt.service';
import { CommonService } from './services/common.service'
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { SpinnerComponent } from './spinner/spinner.component';
import { UnsubscribeFromSmsComponent } from './unsubscribe-from-sms/unsubscribe-from-sms.component';
import { SearchSmsCandidateComponent } from './search-sms-candidate/search-sms-candidate.component';
import { SearchEmailCandidateComponent } from './search-email-candidate/search-email-candidate.component';




@NgModule({
  declarations: [
    HeaderComponent,
    MenuComponent,
    SearchComponent,
    ProfileComponent,
    SearchResultComponent,
    SpinnerComponent,
    UnsubscribeFromSmsComponent,
    SearchSmsCandidateComponent,
    SearchEmailCandidateComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialComponentsModule,
    SharedModule,
    HttpClientModule
  ],
  exports: [
    HeaderComponent,
    SearchComponent,
    MenuComponent,
    ProfileComponent,
    SpinnerComponent,
    SearchSmsCandidateComponent,
    SearchEmailCandidateComponent
  ],
  providers: [
    ApiService,
    JwtService,
    CommonService,
    AuthService,
    DatePipe
  ]
})
export class CoreModule { }
