import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AppSettings } from '../../config/app-settings';
import { AllocateMinutesToSubscriberServiceService } from '../service/allocate-minutes-to-subscriber-service.service'

@Component({
  selector: 'app-edit-allocate-minutes-to-subscriber',
  templateUrl: './edit-allocate-minutes-to-subscriber.component.html',
  styleUrls: ['./edit-allocate-minutes-to-subscriber.component.scss']
})
export class EditAllocateMinutesToSubscriberComponent implements OnInit {

  removable = true;
  public isProcessing: boolean = false;
  userData: any
  packageId: any
  componentPlanData = [];
  componentInsertedPlanData = [];
  audioMins: any
  compName: any
  companyId: any
  endDate: any
  id: any
  packageName: any
  calCulatedPrice: any
  price: any
  priceUnit: any
  sms: any
  startDate: any
  status: any
  statusId: any
  videoMins: any

  totalPrice: number = 0
  description: any

  public smsPrice: any
  public audioPrice: any
  public videoPrice: any
  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE

  componentDataForDynamicInputs = [];

  insetedComponentsForStandardData = [];
  public editAllocateMintsToSubSpForm: FormGroup
  subscribers = [];
  public name: any
  public subscriberId: any
  public componentId: any
  public allocatedMainId: any

  constructor(private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: EditAllocateMinutesToSubscriberComponent,
    private commonService: CommonService,
    private datePipe: DatePipe,
    private appSettings: AppSettings,
    private allocateMinutesToSubscriberServiceService: AllocateMinutesToSubscriberServiceService,
    private dialogRef: MatDialogRef<EditAllocateMinutesToSubscriberComponent>) {
    this.userData = data;
    this.description = this.userData.userDetail.description
    this.name = this.userData.userDetail.name
    this.packageId = this.userData.userDetail.packageId
    this.subscriberId = this.userData.userDetail.subscriberId
    this.companyId = this.userData.userDetail.companyId
    this.allocatedMainId = this.userData.userDetail.id
  }

  ngOnInit() {
    this.intiForm()
    this.getSubscriberDropdown()
    this.getComponentDetails()
  }


  intiForm() {
    this.editAllocateMintsToSubSpForm = this.formBuilder.group({
      'Subscriber': [this.subscriberId, [Validators.required]],
      'Description': [this.description],
      'AllComponent': this.formBuilder.array([]),

    })
  }

  getSubscriberDropdown() {
    let params = {
      SpOption: ''
    }
    this.allocateMinutesToSubscriberServiceService.getSubscriberDropdown(params).subscribe(response => {
      if (response.success) {
        let subs = [];
        response.data.forEach(element => {
          subs.push({
            id: element.id,
            name: element.name
          })
        });
        this.subscribers = subs
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }
  getComponentDetails() {
    let allcomponent = this.editAllocateMintsToSubSpForm.get('AllComponent') as FormArray;
    let params = {
      PageNumber: this.pageNumber,
      PageSize: this.pageSize,
      AllocatedType: 'AllocatedMinutesToSubscriber',
      PackageId: this.packageId,
      AllocatedMainId: this.allocatedMainId,
      SpOption: 'Edit'
    }
    this.allocateMinutesToSubscriberServiceService.getComponentInsertedData(params).subscribe(response => {
      if (response.success) {
        let count = [];
        response.data.forEach(element => {
          allcomponent.push(this.formBuilder.control({
            ComponentId: element.componentId,
            ComponentType: element.componentType,
            ComponentTypeName: element.componentTypeName,
            InputParameter: element.inputParameter,
            Id: element.id,
            Name: element.name,
            AvailableUnit: element.availableUnit
          }));
          count.push({
            ComponentId: element.componentId,
            ComponentType: element.componentType,
            ComponentTypeName: element.componentTypeName,
            InputParameter: element.inputParameter,
            Id: element.id,
            Name: element.name,
            AvailableUnit: element.availableUnit
          })
        });

        if (count.length >= 0) {
          this.componentDataForDynamicInputs = count
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  AvailableUnitMain: any
  currentTargetMain: any

  public focusoutAllComponentHandler(event, AvailableUnit, id, ComponentId, name, ComponentType, ComponentTypeName, item) {
    if (AvailableUnit != undefined) {
      this.AvailableUnitMain = AvailableUnit
      this.currentTargetMain = event.target.value

      item.setValue({
        ComponentType: ComponentType,
        ComponentTypeName: ComponentTypeName,
        AvailableUnit: AvailableUnit,
        Id: id,
        ComponentId: ComponentId,
        Name: name,
        PackageId: '',
        // InputParameter: event.target.value
        InputParameter: this.getCurrentTargetValue()
      })

      let allcomponent = this.editAllocateMintsToSubSpForm.get('AllComponent') as FormArray;


      if (AvailableUnit >= event.currentTarget.value) {
        return true
      }
      else {
        event.currentTarget.value = ''
        this.commonService.allottedValueSnakeBar();
        return false
      }

    }
  }

  getCurrentTargetValue(): any {
    if (this.AvailableUnitMain >= this.currentTargetMain) {
      return this.currentTargetMain = this.currentTargetMain
    }
    else {
      return this.currentTargetMain = ''
    }
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  editAllocateMintsToSubscriber(form) {
    if (form.valid) {
      try {
        this.isProcessing = true
        let params = {
          Id: this.allocatedMainId,
          Description: this.editAllocateMintsToSubSpForm.controls.Description.value,
          SubscriberId: this.editAllocateMintsToSubSpForm.controls.Subscriber.value
        }
        this.allocateMinutesToSubscriberServiceService.editAllocateMintsToSubscriber(params).subscribe(response => {
          if (response.success) {

            let arrayAllComponent = this.editAllocateMintsToSubSpForm.controls.AllComponent.value

            for (let item of arrayAllComponent) {
              let inputparm
              if (item.InputParameter != '') {
                inputparm = item.InputParameter
              }
              else {
                inputparm = 0
              }

              let paramarr = {
                ComponentId: item.ComponentId,
                PackageId: this.packageId,
                Name: item.Name,
                ComponentType: item.ComponentType,
                InputParameter: inputparm,
                LastPackageInsertedId: this.allocatedMainId,
                ArrayInsertedId: item.Id,
                AllocattedType: 'AllocatedMinutesToSubscriber',
                SpOptions: 'Update'
              };

              try {

                this.allocateMinutesToSubscriberServiceService.editAllocateMintsArrayToSubscriber(paramarr).subscribe(resposne => {
                  if (resposne.success) {
                    this.isProcessing = false;
                    const msg = 'Component is allocated Successfully'
                    this.commonService.showSnakeMinuteAllocatedBar(msg);
                    this.closeDialog();
                  }
                  else {
                    this.isProcessing = false;
                    this.commonService.showSnakeBar(resposne.message);
                  }

                }, (err) => {
                  this.isProcessing = false;
                  this.commonService.checkTokenValidity(err);
                  this.closeDialog();
                })
              }
              catch
              {
                this.isProcessing = false;
                this.commonService.commonSnakeBar();
              }
            }

            //   this.commonService.showSnakeBar(response.message);
            this.closeDialog();
            this.isProcessing = false;
          }
          else {
            this.commonService.showSnakeBar(response.message);
            this.isProcessing = false;
          }
        }, (err) => {
          this.commonService.checkTokenValidity(err);
          this.closeDialog();
          this.isProcessing = false;
        })
      }
      catch{
        this.commonService.commonSnakeBar();
        this.isProcessing = false;
      }
    }
    else {
      this.isProcessing = false;
      this.commonService.requiredValidationSnakeBar();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  clearFields() {
    // this.editAllocateMintsToSubSpForm.get('txtParameter').patchValue([]);
  }

}
