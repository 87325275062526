import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscribeListComponent } from './subscribe-list/subscribe-list.component';
import { CoreModule } from '../core/core.module';
import { MaterialComponentsModule } from '../material-components.module';
import { RouterModule } from '@angular/router';
import { AddSubscribeComponent } from './add-subscribe/add-subscribe.component';
import { EditSubscribeComponent } from './edit-subscribe/edit-subscribe.component';
import {  ReactiveFormsModule,FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    MaterialComponentsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [SubscribeListComponent, AddSubscribeComponent, EditSubscribeComponent],
  exports: [AddSubscribeComponent, EditSubscribeComponent],
  entryComponents: [AddSubscribeComponent, EditSubscribeComponent]
})
export class SubscribeModule { }
