import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { CampaignService } from '../service/campaign.service';
import { CommonService } from '../../core/services/common.service';
import { AppSettings } from '../../config/app-settings';
import { StatusMaster } from '../../core/constants/status';
import { SharedService } from '../../shared/service/shared.service';
import { JwtService } from '../../core/services/jwt.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TwilioService, TwilioTokenType } from '../../core/services/twilio.service';
import { SignalRService } from '../../core/services/signalr.service';


//declare const Twilion: any;
@Component({
  selector: 'app-campaign-dial-list',
  templateUrl: './campaign-dial-list.component.html',
  styleUrls: ['./campaign-dial-list.component.scss']
})

export class CampaignDialListComponent implements OnInit {

  displayedColumns: string[] = ['Id', 'CampaignId', 'ReferenceId', 'CandidateName', 'Phone', 'Skill', 'StatusId', 'Status'];
  ListingData = []
  userdata: any
  campaignId: any
  candidateName: any
  private token: any;
  device: any;
  userId: any
  campaignStatusId: any
  CandidateEndMutedataSource = []
  selected: any;
  public campaignDialListSpForm: FormGroup;
  showhide: boolean = false;
  userName: any
  callInProgress: boolean = false;
  consentDiv: boolean = false;
  updatedId: any
  conferenceName: any
  conferenceSid: any
  frontEndNum: any
  frontEndName: any
  CandidatedataSource = []
  candidateAutoGenerateId: any
  showCallDuration: boolean = false
  mergeEmpNumbers = []
  empDropdownButtonMerge: boolean = false;
  dropDownName: any
  dropDownNumber: any
  dropDownId: any
  muteUnmuteData = []
  disconnectedUser = []
  stopTimers: boolean = true;
  callingFeatureAvailability: boolean = false;
  IsShowRecordingOption: boolean = false;
  IsRecording: boolean = true;
  durationStart: boolean = true;
  timerIntervalId: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: CampaignDialListComponent,
    private campaignService: CampaignService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private jwtService: JwtService,
    public snackBar: MatSnackBar,
    private readonly twilioService: TwilioService,
    private readonly signalrService: SignalRService
  ) {
    this.userdata = data;
    this.userId = this.jwtService.getCurrentUserId();
    if (this.userdata.campDetail.length > 0) {
      this.campaignId = this.userdata.campDetail[0].campaignId;
      this.candidateName = this.userdata.campDetail[0].candidateName;
      this.campaignStatusId = this.userdata.campDetail[0].campaignStatusId;
    }
  }

  intiForm() {
    this.campaignDialListSpForm = this.formBuilder.group({
      'Employee': [[], [Validators.required]],
      'Description': [null],
      'dialerNum': [{ value: '', disabled: true }]
    })

  }

  ngOnInit() {
    this.initSignalR();
    this.intiForm()
    this.getCandidateDetailsForFrontEnd()
    ////this.generateTokenForCall()
    this.getEmployeeDropDownForCallMerge()
    this.getCallingAvailability()
    //setInterval(() => { this.checkedValueForCallDuration(); }, 10000);

  }
  /**
   * init singnalr
   */
  initSignalR() {
    this.signalrService.startConnection((type, data) => {
      console.log(type, data);
      if (type === 'respConf') {
        if (data.sequenceNumber === "1") {
          this.addNumbers();
        }
        if (data.statusCallbackEvent === "conference-end") {
          if (data.reasonConferenceEnded === "last-participant-left" || data.reasonConferenceEnded ===  "participant-with-end-conference-on-exit-left") {
            this.hangUpCall('event');
          }
        }
      }
      if (type === 'callConf') {
        if (data.to && (data.status === "no-answer" || data.status === 'busy' || data.status === 'canceled' || data.status === 'failed' || data.status === 'completed')) {
          let phone = data.to.substr(4);
          this.CandidateEndMutedataSource = this.CandidateEndMutedataSource.filter(item => !item.phone.includes(phone));
          if (this.CandidateEndMutedataSource.length === 0) {
            this.hangUpCall();
          }
        }
        if (data.status === "in-progress") {
          console.log('in-progress');
          if (data.to) {
            let phone = data.to.substr(4);
            if (this.frontEndNum.includes(phone)) {
              if (!this.timerIntervalId) {
                this.showCallDuration = true
                this.startTimer()
              }
            }
          }
        }
      }
    });
  }
  /**
   * create a conference and join the candiate to call
   */
  public async addNumbersSleep() {
    console.log('addNumbersSleep');
    // For Checking the user has data for calling or not
    if (this.callingFeatureAvailability) {
      this.twilioGenerateConference()
    }
    else {
      this.commonService.callingDataNotAvailable();
    }
    // this.twilioGenerateConference()
    // await this.delay(10000);
    // this.addNumbers();
  }
  /**
   * delay (sleep)
   * @param ms 
   */
  public delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  /**
   * dialer toggle
   */
  dialertoggle() {
    this.showhide = !this.showhide;
  }

  /**
   * This method is used to get the all candidate details for the calling
   */
  getCandidateDetailsForFrontEnd() {
    let params = {
      CampaignId: this.campaignId
    }
    this.campaignService.getCandidateDetailsForFrontEnd(params).subscribe(response => {
      if (response.success) {
        this.ListingData = response.data;
        this.CandidatedataSource = this.ListingData
        //  this.CandidateEndMutedataSource = this.ListingData;
      }
      else {
        this.commonService.commonSnakeBar();
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  /**
   * update campaign status and start
   */
  start() {
    let params = {
      CampaignId: this.campaignId,
      StatusId: StatusMaster.CallInProgress
    }
    this.campaignService.UpdateCampaignStatus(params).subscribe(resposne => {
      if (resposne.success) {
        this.commonService.showSnakeBar("Campaign started");
      }
      else {
        this.commonService.showSnakeBar("Unable to start campaign");
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }
  /**
   * This method is used to generate the token for the creating the conference
   * Depricated
   */
  // generateTokenForCall() {

  //   this.campaignService.generateTokenForCall().subscribe(response => {
  //     if (response.success) {
  //       let data = response.data;
  //       this.token = data.content;
  //       localStorage.setItem('localtoken', this.token);

  //       if (this.token) {
  //         try {
  //           // Setup Twilio.Device
  //           this.device = null;
  //           // new Twilion.Device(this.token, {
  //           //   codecPreferences: ['opus', 'pcmu'],
  //           //   fakeLocalDTMF: true,
  //           //   enableRingingState: true,
  //           //   debug: true 
  //           // });

  //           this.device.on('ready', function (device) {
  //             console.log('On ready-----');
  //           });

  //           this.device.on('error', function (error) {
  //             if (error.code = 31201) {
  //               alert("Kindly use microphone for the calls");
  //             }
  //             console.log('Twilio.Device Error: ' + error.message);
  //           });

  //           this.device.on('connect', function (conn) {
  //             console.log('Successfully established call!------');

  //           });
  //           this.device.on('disconnect', function (conn) {
  //             console.log('Call ended.');
  //           });
  //         } catch (error) {
  //           console.log(error);
  //         }
  //       }
  //     }
  //     else {
  //       this.commonService.commonSnakeBar();
  //     }
  //   }, (err) => {
  //     this.commonService.checkTokenValidity(err);
  //   })
  // }


  /**
   * This method is used to create and start the conference 
   * @param cb 
   */
  async twilioGenerateConference() {
    this.CandidatedataSource = this.ListingData
    let server = this.CandidatedataSource.find(x => x.statusId === 13);
    this.updatedId = server.id
    this.conferenceName = 'Conference'.concat(this.campaignId)
    this.conferenceName = this.conferenceName.concat('_').concat(this.userId).concat('-').concat(this.updatedId)
    let conId = this.signalrService.connectionId;
    this.frontEndNum = server.phone
    this.frontEndName = server.candidateName
    var params = {
      token: '',
      confName: this.conferenceName,
      conId
    };
    this.twilioService.connect(params, TwilioTokenType.Conference, () => {});
  }

  /**
   * This method is used to start the call with the conference
   * add a number to a conference once created
   */
  addNumbers() {
    // this.CandidatedataSource.find(item => item.id == this.updatedId).statusId = 3;
    let newdata = this.ListingData.filter(item => item.phone == this.frontEndNum)
    if (newdata.length > 0) {
      this.candidateAutoGenerateId = newdata[0].id;
    }
    let params = {
      To: this.frontEndNum,
      ConferenceSid: this.conferenceName,
      Description: this.campaignDialListSpForm.controls.Description.value,
      CandidateAutoGenerateId: this.candidateAutoGenerateId
    }
    this.campaignService.addNumbers(params).subscribe(response => {
      if (response.success) {
        this.conferenceSid = response.data;
        this.IsShowRecordingOption = true;
        this.CandidatedataSource.find(item => item.id == this.updatedId).statusId = 3;
        this.callInProgress = true;
        this.CandidateEndMutedataSource.push({
          candidateName: this.frontEndName,
          phone: this.frontEndNum
        })
        this.stopTimers = true;
      }
      else {
        this.commonService.commonSnakeBar();
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  /**
   * This method is used to end the conference and their all running calls
   * @param action 
   */
  hangUpCall(action?: string) {
    if (action === 'event') {
      this.resetDialer();
      return;
    }

    let params = {
      ConferenceSid: this.conferenceName,
      To: ''
    }
    this.campaignService.hangUpCall(params).subscribe(response => {
      if (response.success) {
        this.conferenceSid = response.data;
        this.resetDialer();
      }
      else {
        ////this.commonService.conferenceNotCreatedYet(response.message);
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }
  /**
   * reset dialer popup 
   */
  resetDialer() {
    //console.log('[resetDialer]');
    clearInterval(this.timerIntervalId)
    this.timerIntervalId = null;
    let minutesLabel = document.getElementById("minutes");
    let secondsLabel = document.getElementById("seconds");
    let hoursLabel = document.getElementById("hours");
    this.frontEndName = "";
    this.frontEndNum = "";
    this.empDropdownButtonMerge = false;
    this.CandidatedataSource.find(item => item.id == this.updatedId).statusId = 2;
    this.CandidateEndMutedataSource = []
    this.callInProgress = false;
    this.consentDiv = false;
    this.campaignDialListSpForm.get('Description').patchValue('');
    this.IsShowRecordingOption = false;

    if (minutesLabel != null) {
      minutesLabel.innerHTML = '00';
    }
    if (secondsLabel != null) {
      secondsLabel.innerHTML = '00';
    }
    if (hoursLabel != null) {
      hoursLabel.innerHTML = '00';
    }
    this.stopTimers = false;
    this.showCallDuration = false;
    this.updateCampaignStatusWithCampaign();
  }

  /**
   * get employees list 
   */
  getEmployeeDropDownForCallMerge() {
    this.campaignService.getEmployeeDropDownForCallMerge().subscribe(response => {
      if (response.success) {
        this.mergeEmpNumbers = response.data;
      }
      else {
        this.commonService.commonSnakeBar();
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }
  /**
   * This method is used to dial the number through dropdown with the conference call
   */
  mergeDropDownNumbers() {

    let params = {
      To: this.dropDownNumber,
      ConferenceSid: this.conferenceName
    }
    this.campaignService.addNumbers(params).subscribe(response => {
      if (response.success) {
        this.conferenceSid = response.data;

        this.CandidateEndMutedataSource.push({
          candidateName: this.dropDownName,
          phone: this.dropDownNumber
        })
      }
      else {
        this.commonService.commonSnakeBar();
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  /**
   * This method is used to merge the call of dialer from the dialer with the conference call
   */
  mergeDialerNumbers() {
    this.dialertoggle();

    let data = this.CandidateEndMutedataSource.find(item => item.phone == this.campaignDialListSpForm.controls.dialerNum.value);
    if (data == undefined) {
      if (this.conferenceName != undefined) {
        let params = {
          To: this.campaignDialListSpForm.controls.dialerNum.value,
          ConferenceSid: this.conferenceName
        }
        this.campaignService.addNumbers(params).subscribe(response => {
          if (response.success) {
            this.conferenceSid = response.data;

            this.CandidateEndMutedataSource.push({
              candidateName: this.campaignDialListSpForm.controls.dialerNum.value,
              phone: this.campaignDialListSpForm.controls.dialerNum.value
            })
          }
          else {
            this.commonService.commonSnakeBar();
          }
        }, (err) => {
          this.commonService.checkTokenValidity(err);
        })
      }
      else {
        this.commonService.conferenceNotCreatedClientSideYet();
      }
    }
    else {
      this.commonService.userisAlreadyinCall();
    }


  }

  /**
   * select employee
   * @param event 
   */
  employeeselect(event) {
    if (this.conferenceName != undefined) {
      this.empDropdownButtonMerge = true;
    }
    this.dropDownName = event.value.name;
    this.dropDownNumber = event.value.phone;
    this.dropDownId = event.value.id;
  }
  /**
   * This method is used to dial the number through dialer by dialing dialer pad
   * @param num 
   */
  DialerNumbers(num) {
    let dummyNum
    if (this.campaignDialListSpForm.controls.dialerNum.value != '') {
      dummyNum = this.campaignDialListSpForm.controls.dialerNum.value
      let concatedValue = dummyNum.concat(num)

      this.campaignDialListSpForm.controls.dialerNum.setValue(concatedValue)
    }
    else {
      this.campaignDialListSpForm.controls.dialerNum.setValue(num)
    }
  }

  /**
   * This method is used to remove the numbers from the dialer after dialing the numbers from the diler 
   */
  backSpaceNumbers() {
    let dummyNum
    dummyNum = this.campaignDialListSpForm.controls.dialerNum.value;
    if (dummyNum != '') {
      var sliced = dummyNum.slice(0, -1);
      this.campaignDialListSpForm.controls.dialerNum.setValue(sliced)
    }
  }

  /**
   * This method is used to mute/unmute to the candidates,dialed numbers the call during calling
   * @param el 
   * @param phone 
   */
  muteAndUnmute(el, phone) {
    let muteUnmuteValue: boolean = true

    if (this.muteUnmuteData.length == 0) {

      let muteUnmuteData = this.muteUnmuteData;
      muteUnmuteValue = true;
      el.innerHTML = '<i class="material-icons">mic_off</i>';

      this.muteUnmuteData.push({
        phone: phone,
        muteUnmute: true
      })
    }
    else {
      this.muteUnmuteData = this.muteUnmuteData.filter(item => item.phone = phone);

      if (this.muteUnmuteData.length == 0) {
        let muteUnmuteData = this.muteUnmuteData;
        muteUnmuteValue = true;
        el.innerHTML = '<i class="material-icons">mic_off</i>';

        this.muteUnmuteData.push({
          phone: phone,
          muteUnmute: true
        })
      }
      else {
        if (this.muteUnmuteData[0].muteUnmute == true) {
          this.muteUnmuteData.find(item => item.phone == phone).muteUnmute = false;
          muteUnmuteValue = false;
          el.innerHTML = '<i class="material-icons">mic</i>';
        }
        else {
          this.muteUnmuteData.find(item => item.phone == phone).muteUnmute = true;
          muteUnmuteValue = true;
          el.innerHTML = '<i class="material-icons">mic_off</i>';
        }
      }
    }

    let params = {
      Mute: muteUnmuteValue,
      ConferenceSid: this.conferenceName,
      To: phone
    }
    this.campaignService.muteAndUnmute(params).subscribe(response => {
      if (response.success) {
        this.conferenceSid = response.data;
      }
      else {
        this.commonService.commonSnakeBar();
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  /**
   * This method is used to remove the participant from the conference call
   * @param phone 
   */
  removeParticipantsUsers(phone) {

    let minutesLabel = document.getElementById("minutes");
    let secondsLabel = document.getElementById("seconds");
    let hoursLabel = document.getElementById("hours");
    let params = {
      Mute: true,
      ConferenceSid: this.conferenceName,
      To: phone
    }
    this.campaignService.removeParticipantsUsers(params).subscribe(response => {
      if (response.success) {
        this.conferenceSid = response.data;
        this.CandidatedataSource.find(item => item.id == this.updatedId).statusId = 2;
        this.CandidateEndMutedataSource = this.CandidateEndMutedataSource.filter(item => item.phone != phone);
        if (this.CandidateEndMutedataSource.length == 0) {
          this.hangUpCall();
        }
      }
      else {
        this.CandidateEndMutedataSource = this.CandidateEndMutedataSource.filter(item => item.phone != phone);
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  /**
   * This method is used to check that the call is initiated or not
   */
  checkedValueForCallDuration() {
    if (this.durationStart == true) {
      let params = {
        ConferenceSid: this.conferenceName,
        UserId: this.userId
      }
      this.campaignService.checkedValueForCallDuration(params).subscribe(response => {
        if (response.success) {
          this.showCallDuration = true
          this.durationStart = false;
          this.startTimer()
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    }
  }

  /**
   * This method is used for start the recording of the call
   */
  createRecording() {
    let params = {
      ConferenceSid: this.conferenceName,
      To: this.frontEndNum
    }
    this.campaignService.createRecording(params).subscribe(response => {
      if (response.success) {
        this.conferenceSid = response.data;
        this.IsRecording = false;
        this.consentDiv = true;
      }
      else {
        this.commonService.commonSnakeBar();
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  /**
   * This method is used for stop/delete the recording
   */
  deleteRecording() {
    let params = {
      ConferenceSid: this.conferenceName,
      To: this.frontEndNum
    }
    this.campaignService.deleteRecording(params).subscribe(response => {
      if (response.success) {
        this.conferenceSid = response.data;
        this.IsRecording = true;
        this.IsShowRecordingOption = false;
        this.consentDiv = false;
      }
      else {
        this.commonService.commonSnakeBar();
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  /**
   * This method checks whether the user have calling seconds or not
   */
  getCallingAvailability() {
    let params = {
      ComponentId: 5
    }
    this.campaignService.getCallingAvailability(params).subscribe(response => {
      if (response.success && response.data.length > 0) {
        this.callingFeatureAvailability = true;
      }
      else {
        this.callingFeatureAvailability = false;
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }
  /**
   * stop timer
   */
  stopTimer() {
    //  clearInterval(this.interval)
    let minutesLabel = document.getElementById("minutes");
    let secondsLabel = document.getElementById("seconds");
    let hoursLabel = document.getElementById("hours");
    minutesLabel.innerHTML = '00';
    secondsLabel.innerHTML = '00';
    hoursLabel.innerHTML = '00';
  }

  /**
   * This method is used for start the calling duration
   */
  startTimer() {
    var _this = this
    let totalSeconds = 0;
    //if (this.stopTimers == true) {
    this.timerIntervalId = setInterval(function () {
      var newVal = _this.stopTimers
      if (newVal == true) {
        ////_this.disconnectedCall();
        let minutesLabel = document.getElementById("minutes");
        let secondsLabel = document.getElementById("seconds");
        let hoursLabel = document.getElementById("hours");
        // console.log(newVal);
        ++totalSeconds;
        let valStringSec = Number(totalSeconds % 60).toFixed() + "";
        if (valStringSec.length < 2) {
          valStringSec = "0" + valStringSec;
        }
        secondsLabel.innerHTML = valStringSec;
        let valStringMin = totalSeconds / 60 + "";
        if (valStringMin.length < 2) {
          valStringMin = "0" + valStringMin;
        }
        minutesLabel.innerHTML = String(Math.floor(Number(valStringMin)));
        let valStringHour = totalSeconds / 3600 + "";
        if (valStringHour.length < 2) {
          valStringHour = "0" + valStringHour;
        }
        hoursLabel.innerHTML = String(Math.floor(Number(valStringHour)));
      }
      else {
        totalSeconds = 0
      }
    }, 1000);
  }

  /**
   * add an employee/staff from dropdown list to the conference
   */
  startClientCall() {
    let data = this.CandidateEndMutedataSource.find(item => item.phone == "client:" + this.dropDownId);
    if (!data) {
      let params = {
        To: this.dropDownId,
        ConferenceName: this.conferenceName,
        UserId: this.userId
      }
      console.log("Callling to " + this.dropDownId);
      this.twilioService.connect(params, TwilioTokenType.Capability, () => {});
      this.CandidateEndMutedataSource.push({
        candidateName: this.dropDownName,
        phone: "client:" + this.dropDownId
      })
      return;
    }
    else {
      this.commonService.userisAlreadyinCall();
    }
  }
  /**
   * This method is used to update the status of the campaign
   */
  updateCampaignStatusWithCampaign() {
    let params = {
      CampaignId: this.campaignId
    }
    this.campaignService.updateCampaignStatusWithCampaign(params).subscribe(response => {
      if (response.success) {
      }

    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  /**
   * close consent msg
   */
  dismissConsent() {
    this.consentDiv = false;
  }

  /**
   * diconnect call
   * depricated
   */
  disconnectedCall() {

    let params = {
      ConferenceSid: this.conferenceName,
      To: ''
    }
    this.campaignService.disconnectedCall(params).subscribe(response => {
      if (response.success) {
        this.disconnectedUser = response.data;

        for (let i = 0; i < this.disconnectedUser.length; i++) {
          let incrementVal = i;
          this.CandidateEndMutedataSource = this.CandidateEndMutedataSource.filter(({ phone }) => phone !== this.disconnectedUser[incrementVal].toNum);
        }
        if (this.CandidateEndMutedataSource.length == 0) {
          this.hangUpCall();
        }
      }
      else {

      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

}




