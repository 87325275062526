import { Component, OnInit } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SharedService } from '../../shared/service/shared.service'
import { JwtService } from '../../core/services/jwt.service'
import { CommonService } from '../../core/services/common.service';


@Component({
  selector: 'app-package-management-list',
  templateUrl: './package-management-list.component.html',
  styleUrls: ['./package-management-list.component.scss']
})
export class PackageManagementListComponent implements OnInit {

  standfullControl: boolean = true;
  standviews: boolean = true;
  standadds: boolean = true;
  standedits: boolean = true;
  standdeletes: boolean = true;
  standdisableds: boolean = false;

  constructor(private sharedService: SharedService,
    private commonService: CommonService,
    private jwtService: JwtService) {
  }

  ngOnInit() {
    const role = this.jwtService.getUserRole();
    if (role == 'SuperSubAdmin') {
      this.getAddEditDeletePermission();
    }

  }


  getAddEditDeletePermission() {
    let params = {
      MenuId: 19,
      Type: 'packageManagement'
    }
    this.sharedService.getAddEditDeletePermission(params).subscribe(response => {
      if (response.success) {
        if (response.data.length > 0) {
          this.standadds = response.data[0].adds
          this.standviews = response.data[0].views
          this.standfullControl = response.data[0].fullControle
          this.standdeletes = response.data[0].deletes
          this.standedits = response.data[0].edit
        }

        if (this.standfullControl == true || this.standedits == true) {
          this.standdisableds = false
        }
        else {
          this.standdisableds = true
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

}
