import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomizePackageSuperAdminListComponent } from './customize-package-super-admin-list/customize-package-super-admin-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialComponentsModule } from '../material-components.module';
import { AddCustomizePackageSuperAdminComponent } from './add-customize-package-super-admin/add-customize-package-super-admin.component';
import { AddCustomizePackageSuperAdminCommentComponentComponent } from './add-customize-package-super-admin-comment-component/add-customize-package-super-admin-comment-component.component';
import { CustomizePackageSuperAdmincommentListComponent } from './customize-package-super-admincomment-list/customize-package-super-admincomment-list.component';
import { EditNewCustomizePackageSuperAdminComponent } from './edit-new-customize-package-super-admin/edit-new-customize-package-super-admin.component';


@NgModule({
  imports: [
    CommonModule,
    MaterialComponentsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [CustomizePackageSuperAdminListComponent, AddCustomizePackageSuperAdminComponent, AddCustomizePackageSuperAdminCommentComponentComponent, CustomizePackageSuperAdmincommentListComponent, EditNewCustomizePackageSuperAdminComponent],
  exports: [CustomizePackageSuperAdminListComponent],
  entryComponents: [AddCustomizePackageSuperAdminComponent, AddCustomizePackageSuperAdminCommentComponentComponent, CustomizePackageSuperAdmincommentListComponent, EditNewCustomizePackageSuperAdminComponent]
})
export class CustomizePackageSuperAdminModule { }
