import { Component, OnInit } from '@angular/core';
import { CampaignService } from '../service/campaign.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { MatDialogRef } from '@angular/material';
import { element } from '@angular/core/src/render3/instructions';
import { AuthService } from '../../core/services/auth.service';

import { saveAs } from 'file-saver';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-create-campaign',
  templateUrl: './create-campaign.component.html',
  styleUrls: ['./create-campaign.component.scss']
})
export class CreateCampaignComponent implements OnInit {
  createCampForm: FormGroup

  recruiters = [];

  subscribers = [];

  skillList = [];

  subsCriberId: number

  selectedFile: File
  roleManagers = []

  IsVoiceMailSMS: boolean = false;
  isProcessing: boolean = false;
  userRoll: any
  fileUrl: any
  constructor(
    private campaignService: CampaignService,
    private authService: AuthService,
    private formbuilder: FormBuilder,
    private commonService: CommonService,
    private sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<CreateCampaignComponent>
  ) { }

  ngOnInit() {
    this.intiForm();
    // this.getRecruiterDropdown();
    this.getSkillDropDown();
    this.getRoleManagerDropdown()

    const data = 'Name,Phone,Skill';
    const blob = new Blob([data], { type: 'application/octet-stream' });

    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
  }

  intiForm() {
    this.createCampForm = this.formbuilder.group({
      'Name': [null, [Validators.required, this.noWhitespaceValidator]],
      'SMS': [null, [Validators.required, this.noWhitespaceValidator]],
      'VoiceMail': [false],
      'VoiceMailSMS': [null],
      'CampaignSkill': [[], [Validators.required]],
      'RoleManager': [null, [Validators.required]],
      'Staff': [null]
    })
    this.formControlValueChanged();
  }

  formControlValueChanged() {
    const VoiceMailSMS = this.createCampForm.get('VoiceMailSMS');
    this.createCampForm.get('VoiceMail').valueChanges.subscribe(
      (mode: boolean) => {
        if (mode) {
          VoiceMailSMS.setValidators([Validators.required, this.noWhitespaceValidator])
        }
        else {
          VoiceMailSMS.clearValidators();
          VoiceMailSMS.updateValueAndValidity();
        }
      }
    );
  }

  createCampaign(form: any) {
    if (form.valid) {
      this.isProcessing = true;
      const formData = new FormData();
      if (this.selectedFile == null || this.selectedFile == undefined) {
        this.commonService.showSnakeBar("Please upload file");
        this.isProcessing = false;
        return;
      }
      else if (this.selectedFile.name.split('.').pop().toLocaleLowerCase() != "csv") {
        this.commonService.showSnakeBar("Only .csv extension allowed");
        this.isProcessing = false;
        return;
      }
      let staffId = (form.value && form.value.Staff) ? form.value.Staff : 0;
      formData.append(this.selectedFile.name, this.selectedFile);
      formData.append("Name", this.createCampForm.controls.Name.value);
      formData.append("SMS", this.createCampForm.controls.SMS.value);
      formData.append("VoiceMail", this.createCampForm.controls.VoiceMail.value);
      formData.append("VoiceMailSMS", this.createCampForm.controls.VoiceMailSMS.value);
      formData.append("CampaignSkill", this.createCampForm.controls.CampaignSkill.value);
      formData.append("RoleManager", this.createCampForm.controls.RoleManager.value);
      formData.append("Staff", staffId);
      console.log("staff", staffId);
      this.campaignService.createCamapignFormRequest(formData).subscribe(response => {
        if (response.success) {
          this.commonService.showSnakeBar(response.message);
          this.closeDialog();
          this.isProcessing = false;
        }
        else {
          this.commonService.showSnakeBar(response.message);
          this.isProcessing = false;
        }
      }, (err) => {
        this.isProcessing = false;
        this.commonService.checkTokenValidity(err);
        this.closeDialog();
      })
    }
    else {
      this.commonService.showSnakeBar("Please do the correction");
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onFileChanged(event) {
    if (event.target.value != "") {
      this.selectedFile = event.target.files[0]
    }
  }

  showOptions(event) {
    if (event.checked) {
      this.IsVoiceMailSMS = true
    }
    else {
      this.IsVoiceMailSMS = false
    }
  }


  getSkillDropDown() {
    let params = {
      SpOption: 'Add'
    }
    this.campaignService.getSkillDropDown(params).subscribe(response => {
      if (response.success) {
        let skill = [];

        response.data.forEach(element => {
          skill.push({
            id: element.id,
            name: element.name
          })
        });
        this.skillList = skill
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }


  getRoleManagerDropdown() {
    let params = {
      SpOption: 'FirstLevel'
    }
    this.campaignService.getRoleManagerDropdown(params).subscribe(response => {
      if (response.success) {
        let roles = [];
        response.data.forEach(element => {
          roles.push({
            id: element.id,
            name: element.name,
            permissionId: element.permissionId
          })
        });
        this.roleManagers = roles
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }


  staffUsers = []
  roleManagerDiv: boolean = false
  roleChange(event) {
    let index = this.roleManagers.map(item => {
      return item.id;
    }).indexOf(event.value);

    let params = {
      SpOption: 'SecondLevel',
      RoleId: parseInt(this.roleManagers[index].permissionId)
    }
    this.campaignService.getRoleManagerDropdown(params).subscribe(response => {
      if (response.success) {
        let count = [];
        if (response.data.length > 0) {
          response.data.forEach(element => {
            count.push({
              id: element.id,
              name: element.name
            })
          });
        }
        else {
          this.createCampForm.get('Staff').patchValue([]);
        }
        this.staffUsers = count

        if (this.staffUsers.length > 0) {
          this.roleManagerDiv = true
        }
        else {
          this.roleManagerDiv = false
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }



  // public downloadFile() {
  //   this.http.get('my-api-url', { responseType: 'blob' }).subscribe(blob => {
  //      saveAs(blob, 'SomeFileDownloadName.someExtensions', {
  //         type: 'text/plain;charset=windows-1252' // --> or whatever you need here
  //      });
  //   });


}
