import { Component, OnInit, Inject } from '@angular/core';
import { CampaignService } from '../service/campaign.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { isNull, isNullOrUndefined } from 'util';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-edit-campaign',
  templateUrl: './edit-campaign.component.html',
  styleUrls: ['./edit-campaign.component.scss']
})
export class EditCampaignComponent implements OnInit {
  editCampForm: FormGroup

  recruiters = []
  skillList = []
  subscribers = []
  isProcessing: boolean = false;
  subsCriberId: number
  IsVoiceMailSMS: boolean
  selectedFile: File
  campData: any
  campaignId: string
  campaignName: string
  sms: string
  voiceMail: boolean
  voiceMailSMS: string
  campaignSkill: number
  recruiterId: number
  subscriberId: number
  subscriberType: string

  mangerId: any
  staffId: any

  fileUrl: any
  constructor
    (
      @Inject(MAT_DIALOG_DATA) public data: EditCampaignComponent,
      private campaignService: CampaignService,
      private formbuilder: FormBuilder,
      private sanitizer: DomSanitizer,
      private commonService: CommonService,
      private dialogRef: MatDialogRef<EditCampaignComponent>
    ) {
    
    this.campData = data;
    this.campaignName = this.campData.campDetail.name
    this.subscriberId = this.campData.campDetail.subscriberId
    this.recruiterId = this.campData.campDetail.recruiterId
    this.sms = this.campData.campDetail.sms
    this.voiceMail = this.campData.campDetail.voiceMail
    this.voiceMailSMS = this.campData.campDetail.voiceMailSMS
    this.IsVoiceMailSMS = this.campData.campDetail.voiceMail
    this.campaignId = this.campData.campDetail.campaignId
    this.campaignSkill = this.campData.campDetail.campaignSkill
    this.mangerId = this.campData.campDetail.managerId
    this.staffId = this.campData.campDetail.staffId
  }

  ngOnInit() {
    this.intiForm();
    this.getSkillDropDown();
    this.getRoleManagerDropdown()
    this.geStaffForEditDropdown()
    const data = 'Name,Phone,Skill';
    const blob = new Blob([data], { type: 'application/octet-stream' });
    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
  }

  intiForm() {
    if (this.voiceMailSMS == "null") {
      this.voiceMailSMS = ""
    }
    
    this.editCampForm = this.formbuilder.group({
      'Name': [this.campaignName, [Validators.required, this.noWhitespaceValidator]],
      'SMS': [this.sms, [Validators.required, this.noWhitespaceValidator]],
      'VoiceMail': ([this.voiceMail]),
      'VoiceMailSMS': [this.voiceMailSMS],
      'CampaignSkill': [this.campaignSkill, [Validators.required]],
      'RoleManager': [this.mangerId, [Validators.required]],
      'Staff': [this.staffId]
    })
    this.formControlValueChanged();
    const VoiceMailSMS = this.editCampForm.get('VoiceMailSMS');
    if (this.voiceMail) {
      VoiceMailSMS.setValidators([Validators.required, this.noWhitespaceValidator])
    }
    else {
      VoiceMailSMS.clearValidators();
      VoiceMailSMS.updateValueAndValidity();
    }
  }

  editCampaign(form) {
    if (form.valid) {
      try {
        this.isProcessing = true;

        const formData = new FormData();

        if (this.selectedFile != null || this.selectedFile != undefined) {
          if (this.selectedFile.name.split('.').pop().toLocaleLowerCase() != "csv") {
            this.commonService.showSnakeBar("Only .csv extension allowed");
            this.isProcessing = false;
            return;
          }
          else {
            formData.append(this.selectedFile.name, this.selectedFile);
          }
        }
        // else if (this.selectedFile.name.split('.').pop() != "csv") {
        //   this.commonService.showSnakeBar("Only .csv extension allowed");
        //   this.isProcessing = false;
        //   return;
        // }
        formData.append("CampaignId", this.campaignId);
        formData.append("Name", this.editCampForm.controls.Name.value);
        formData.append("SMS", this.editCampForm.controls.SMS.value);
        formData.append("VoiceMail", this.editCampForm.controls.VoiceMail.value);
        formData.append("VoiceMailSMS", this.editCampForm.controls.VoiceMailSMS.value == null ? null : this.editCampForm.controls.VoiceMailSMS.value);
        formData.append("CampaignSkill", this.editCampForm.controls.CampaignSkill.value);
        formData.append("RoleManager", this.editCampForm.controls.RoleManager.value);
        formData.append("Staff", this.editCampForm.controls.Staff.value);

        this.campaignService.UpdateCamapignFormRequest(formData).subscribe(response => {
          if (response.success) {
            this.commonService.showSnakeBar(response.message);
            this.closeDialog();
            this.isProcessing = false;
          }
          else {
            this.commonService.showSnakeBar(response.message);
            this.isProcessing = false;
          }
        }, (err) => {
          this.isProcessing = false;
          this.commonService.checkTokenValidity(err);
          this.closeDialog();
        })
      }
      catch {
        this.isProcessing = false;
        this.commonService.commonSnakeBar();
      }
    }
    else {
      this.commonService.showSnakeBar("Please do the correction");
    }
  }


  getSkillDropDown() {
    let params = {
      SpOption: 'Edit'
    }
    this.campaignService.getSkillDropDown(params).subscribe(response => {
      if (response.success) {
        let skill = [];

        response.data.forEach(element => {
          skill.push({
            id: element.id,
            name: element.name
          })
        });
        this.skillList = skill
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }


  formControlValueChanged() {
    const VoiceMailSMS = this.editCampForm.get('VoiceMailSMS');
    this.editCampForm.get('VoiceMail').valueChanges.subscribe(
      (mode: boolean) => {
        if (mode) {
          VoiceMailSMS.setValidators([Validators.required, this.noWhitespaceValidator])
        }
        else {
          VoiceMailSMS.clearValidators();
          VoiceMailSMS.updateValueAndValidity();
        }
      }
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onFileChanged(event) {
    this.selectedFile = event.target.files[0]

  }

  showOptions(event) {
    if (event.checked) {
      this.IsVoiceMailSMS = true
      this.editCampForm.get('VoiceMailSMS').patchValue('')
    }
    else {
      this.IsVoiceMailSMS = false
    }
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  roleManagers = []
  getRoleManagerDropdown() {
    let params = {
      SpOption: 'FirstLevel'
    }
    this.campaignService.getRoleManagerDropdown(params).subscribe(response => {
      if (response.success) {
        let roles = [];
        response.data.forEach(element => {
          roles.push({
            id: element.id,
            name: element.name,
            permissionId: element.permissionId
          })
        });
        
        this.roleManagers = roles
        if (this.roleManagers && this.roleManagers.length > 0) {
          this.roleChange({ value: this.roleManagers[0].id })
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  staffUsers = []
  roleManagerDiv: boolean = false
  roleChange(event) {
    let index = this.roleManagers.map(item => {
      return item.id;
    }).indexOf(event.value);

    let params = {
      SpOption: 'SecondLevel',
      RoleId: parseInt(this.roleManagers[index].permissionId)
    }
    this.campaignService.getRoleManagerDropdown(params).subscribe(response => {
      if (response.success) {
        let count = [];
        if (response.data.length > 0) {
          response.data.forEach(element => {
            count.push({
              id: element.id,
              name: element.name
            })
          });
        }
        else {
          this.editCampForm.get('Staff').patchValue([]);
        }
        
        this.staffUsers = count

        if (this.staffUsers.length > 0) {
          this.roleManagerDiv = true
        }
        else {
          this.roleManagerDiv = false
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  geStaffForEditDropdown() {
    
    let params = {
      SpOption: 'EditSecondLevel',
      StaffId: this.staffId
    }
    this.campaignService.getRoleManagerDropdown(params).subscribe(response => {
      if (response.success) {
        this.roleManagerDiv = true;
        let roles = [];
        response.data.forEach(element => {
          roles.push({
            id: element.id,
            name: element.name
          })
        });
        this.staffUsers = roles
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

}
