import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { MaterialComponentsModule } from '../material-components.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AddSuperSubAdminComponent } from './add-super-sub-admin/add-super-sub-admin.component';
import { SuperSubAdminListComponent } from './super-sub-admin-list/super-sub-admin-list.component';
import { EditSuperSubAdminComponent } from './edit-super-sub-admin/edit-super-sub-admin.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    MaterialComponentsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [AddSuperSubAdminComponent,SuperSubAdminListComponent, EditSuperSubAdminComponent],
  entryComponents: [AddSuperSubAdminComponent,EditSuperSubAdminComponent]
})
export class SuperSubAdminModule { }
