import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CommonService } from '../../core/services/common.service';
import { SharedService } from '../service/shared.service';
import { StatusMaster } from '../../core/constants/status';


@Component({
  selector: 'app-confirm-component-plan-delete',
  templateUrl: './confirm-component-plan-delete.component.html',
  styleUrls: ['./confirm-component-plan-delete.component.scss']
})
export class ConfirmComponentPlanDeleteComponent implements OnInit {
  userData: any

planId :number
componentType: any
mainComponentId: number
statusMstr: any = StatusMaster

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmComponentPlanDeleteComponent,
    private commonService: CommonService,
    private sharedService: SharedService,
    private dialogRef: MatDialogRef<ConfirmComponentPlanDeleteComponent>) {
    this.userData = this.data
    this.planId = this.userData.userId.planId
    this.mainComponentId = this.userData.userId.mainComponentId
    this.componentType = this.userData.userId.componentType
  }

  ngOnInit() {
  }

  delete() {
    let param = {
      planId: this.planId,
      componentType: this.componentType,
      mainComponentId: this.mainComponentId,
      statusId:this.statusMstr.Deleted
    }
    try {
      this.sharedService.deleteComponentPlan(param).subscribe(response => {
        if (response.success) {
          this.commonService.showSnakeBar("Component plan deleted successfully.");
          this.closeDialog();
        }
        else {
          this.commonService.showSnakeBar(response.message);
          this.closeDialog();
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
        this.closeDialog();
      })
    }
    catch{
      this.commonService.commonSnakeBar();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
