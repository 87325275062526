import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { isNull, isNullOrUndefined } from 'util';
import { DirectCallingServicesService } from '../services/direct-calling-services.service';
import { DISABLED } from '@angular/forms/src/model';
import { DirectDialListComponent } from '../direct-dial-list/direct-dial-list.component';

@Component({
  selector: 'app-edit-direct-calling-candidate',
  templateUrl: './edit-direct-calling-candidate.component.html',
  styleUrls: ['./edit-direct-calling-candidate.component.scss']
})
export class EditDirectCallingCandidateComponent implements OnInit {

  editCandForm: FormGroup
  skillList = [];
  voiceMail: boolean
  userData: any

  IsVoiceMailSMS: boolean = false;
  isProcessing: boolean = false;

  constructor(private directCallingServicesService: DirectCallingServicesService,
    @Inject(MAT_DIALOG_DATA) public data: EditDirectCallingCandidateComponent,
    private formbuilder: FormBuilder,
    private commonService: CommonService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<EditDirectCallingCandidateComponent>) {
    this.userData = data;
    
    this.IsVoiceMailSMS = this.userData.candidateDetails.voiceMail


  }

  ngOnInit() {
    this.intiForm()
    this.getSkillDropDown();
  }

  intiForm() {
    this.editCandForm = this.formbuilder.group({
      'Name': [this.userData.candidateDetails.name, [Validators.required, this.noWhitespaceValidator]],
      'Email': [this.userData.candidateDetails.email, [Validators.email]],
      'Phone': [this.userData.candidateDetails.phone, [Validators.required, this.noWhitespaceValidator]],
      'SMS': [this.userData.candidateDetails.sms],
      'CandidateSkill': [{ value: this.userData.candidateDetails.skill, disabled: true }, [Validators.required, Validators.email]],
      'VoiceMail': ([this.userData.candidateDetails.voiceMail]),
      'VoiceMailSMS': [this.userData.candidateDetails.voiceMailSMS],
      'Description': [this.userData.candidateDetails.description],
      'CallDuration': [this.userData.candidateDetails.callDuration],
      'CallTime': [this.userData.candidateDetails.callTime]
    })

    this.formControlValueChanged();

    const VoiceMailSMS = this.editCandForm.get('VoiceMailSMS');
    if (this.userData.candidateDetails.voiceMail) {
      VoiceMailSMS.setValidators([Validators.required, this.noWhitespaceValidator])
    }
    else {
      VoiceMailSMS.clearValidators();
      VoiceMailSMS.updateValueAndValidity();
    }

  }

  formControlValueChanged() {
    const VoiceMailSMS = this.editCandForm.get('VoiceMailSMS');
    this.editCandForm.get('VoiceMail').valueChanges.subscribe(
      (mode: boolean) => {
        if (mode) {
          VoiceMailSMS.setValidators([Validators.required, this.noWhitespaceValidator])
        }
        else {
          VoiceMailSMS.clearValidators();
          VoiceMailSMS.updateValueAndValidity();
        }
      }
    );
  }


  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  getSkillDropDown() {
    let params = {
      SpOption: 'Edit'
    }
    this.directCallingServicesService.getSkillDropDown(params).subscribe(response => {
      if (response.success) {
        let skill = [];
        response.data.forEach(element => {
          skill.push({
            id: element.id,
            name: element.name
          })
        });
        this.skillList = skill
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  showOptions(event) {
    if (event.checked) {
      this.IsVoiceMailSMS = true
      this.editCandForm.get('VoiceMailSMS').patchValue('')
    }
    else {
      this.IsVoiceMailSMS = false
    }
  }

  public numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  candidateId: any
  editCandidate(form) {
    if (form.valid) {
      try {
        this.isProcessing = true;

        const formData = new FormData();

        formData.append("Id", this.userData.candidateDetails.id);
        formData.append("Name", this.editCandForm.controls.Name.value);
        formData.append("SMS", this.editCandForm.controls.SMS.value);
        formData.append("VoiceMail", this.editCandForm.controls.VoiceMail.value);
        formData.append("VoiceMailSMS", this.editCandForm.controls.VoiceMailSMS.value == null ? null : this.editCandForm.controls.VoiceMailSMS.value);
        formData.append("CandidateSkill", this.editCandForm.controls.CandidateSkill.value);
        formData.append("Phone", this.editCandForm.controls.Phone.value);
        formData.append("Email", this.editCandForm.controls.Email.value);

        this.directCallingServicesService.updateCandidateDirectCallFormRequest(formData).subscribe(response => {
          if (response.success) {
            //  this.commonService.showSnakeBar(response.message);
            this.closeDialog();
            this.isProcessing = false;
            this.candidateId = response.data;
            this.StartSecondFrontEndCallModal();

          }
          else {
            this.commonService.showSnakeBar(response.message);
            this.isProcessing = false;
          }
        }, (err) => {
          this.isProcessing = false;
          this.commonService.checkTokenValidity(err);
          this.closeDialog();
        })
      }
      catch{
        this.isProcessing = false;
        this.commonService.commonSnakeBar();
      }
    }
    else {
      this.commonService.showSnakeBar("Please do the correction");
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  StartSecondFrontEndCallModal(): void {
    let candidateDetail = {
      Id: this.candidateId,
      Name: this.editCandForm.controls.Name.value,
      Phone: this.editCandForm.controls.Phone.value,
      StatusId: 13,
      Status: 'Pending'
    }
    const dialogRef = this.dialog.open(DirectDialListComponent, {
      disableClose: true,
      panelClass: ['modal-outer', 'modal-md'],
      data: {
        candidateDetail
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.getCandidateDetailsForFrontEnd();
      location.reload();
    })
  }


}
