import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from '../../core/services/auth.service';
import { CommonService } from '../../core/services/common.service';
import { AppSettings } from '../../config/app-settings';
import { StatusMaster } from '../../core/constants/status';
import { AutofillMonitor } from '@angular/cdk/text-field';
import { CustomizePackageSuperAdminserviceService } from '../service/customize-package-super-adminservice.service'
import { AddCustomizePackageSuperAdminComponent } from '../add-customize-package-super-admin/add-customize-package-super-admin.component'
import { AddCustomizePackageSuperAdminCommentComponentComponent } from '../add-customize-package-super-admin-comment-component/add-customize-package-super-admin-comment-component.component'
import { CustomizePackageSuperAdmincommentListComponent } from '../customize-package-super-admincomment-list/customize-package-super-admincomment-list.component'
import { EditCustomizePackageComponent } from '../../cutomize-packages/edit-customize-package/edit-customize-package.component'
import { Router } from '@angular/router';
import { SharedService } from '../../shared/service/shared.service';
import { JwtService } from '../../core/services/jwt.service';
import { EditNewCustomizePackageSuperAdminComponent } from '../edit-new-customize-package-super-admin/edit-new-customize-package-super-admin.component';

@Component({
  selector: 'app-customize-package-super-admin-list',
  templateUrl: './customize-package-super-admin-list.component.html',
  styleUrls: ['./customize-package-super-admin-list.component.scss']
})
export class CustomizePackageSuperAdminListComponent implements OnInit {

  displayedColumns: string[] = ['CustomizePackageId', 'CustomizePackageRefId', 'Name', 'Description',
    'AudioMinutes', 'VideoMinutes', 'SMS', 'Price', 'CompanyId', 'CompName', 'PackageTenure', 'Status'];

  dataSource = [];

  // spanOption: boolean = true;
  // spanOptionToggle() {
  //   this.spanOption = !this.spanOption;
  // }
  public ListingData = [];
  public AllStandardPackages = [];


  constructor(public dialog: MatDialog,
    private authService: AuthService,
    private commonService: CommonService,
    private router: Router,
    private appSettings: AppSettings,
    private sharedService: SharedService,
    private jwtService: JwtService,
    private customizePackageSuperAdminserviceService: CustomizePackageSuperAdminserviceService) {
    this.getuserDetails();
  }

  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  isLoadMore: boolean = false
  statusMstr: any = StatusMaster;
  NoRecFound: boolean = false;

  fullControl: boolean = true;
  views: boolean = true;
  adds: boolean = true;
  edits: boolean = true;
  deletes: boolean = true;
  disableds: boolean = false;

  ngOnInit() {
    this.getCustomizePackageSuperAdminListing(false)

    const role = this.jwtService.getUserRole();
    if (role == 'SuperSubAdmin') {
      this.getAddEditDeletePermission();
    }

  }
  noRecordFoundNotCon: boolean = false
  getCustomizePackageSuperAdminListing(isPaging: boolean) {
    try {
      let params = {
        PageNumber: this.pageNumber,
        PageSize: this.pageSize
      }
      this.customizePackageSuperAdminserviceService.getCustomizePackageSuperAdminListing(params).subscribe(response => {
        if (response.success) {
          let len = response.data.length;
          if (len > 0) {
             this.noRecordFoundNotCon = true
           }
           else {
             this.noRecordFoundNotCon = false
           }
          //this.dataSource = response.data;
          if (response.data.length < this.pageSize) {
            this.isLoadMore = false;
          }
          else {
            this.isLoadMore = true;
          }
          if (response.data.length > 0) {
            this.NoRecFound = false;
          }
          else {
            this.NoRecFound = true;
          }

          if (isPaging) {
            this.appendCustomizePackageSuperAdminListing(response.data);
          }
          else {
            this.dataSource = response.data;
          }
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch {
      this.commonService.commonSnakeBar();
    }
  }

  appendCustomizePackageSuperAdminListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.dataSource.length > 0) {
        newState = this.dataSource.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.dataSource = newState;
    }
  }



  AddCustomizePlanBySuperAdminModal(): void {
    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(AddCustomizePackageSuperAdminComponent, {
        width: '800px',
        height: 'auto',
        panelClass: "modal-outer",
      }
      )

      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.pageNumber = 1
          this.getCustomizePackageSuperAdminListing(false);
        }
      })
    }
    else {
      this.commonService.userDeActivatedOrDeleted();
    }
  };

  AddCustomizedPlanCommentModal(planDetail): void {

    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(AddCustomizePackageSuperAdminCommentComponentComponent, {
        width: '600px',
        panelClass: "modal-outer",
        height: 'auto',
        data: {
          planDetail
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.getCustomizePackageSuperAdminListing(false);
        }
      })
    }
    else {
      this.commonService.userDeActivatedOrDeleted();
    }

  }


  ViewCustomizedPlanCommentModal(planDetail): void {
    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(CustomizePackageSuperAdmincommentListComponent, {
        disableClose: true,
        panelClass: "full-width-popup",
        data: {
          planDetail
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.getCustomizePackageSuperAdminListing(false);
        }
      })
    }
    else {
      this.commonService.userDeActivatedOrDeleted();
    }


  }



  // EditCustomizedPlanCommentModal(planDetail, type): void {
  //   
  //   this.router.navigate(['/editcustomizePackage', planDetail, type]);
  // }


  loadMore() {
    this.pageNumber = this.pageNumber + 1
    this.pageSize = this.pageSize
    this.getCustomizePackageSuperAdminListing(true);
  }

  getAddEditDeletePermission() {
    let params = {
      MenuId: 19
    }
    this.sharedService.getAddEditDeletePermission(params).subscribe(response => {
      if (response.success) {
        if (response.data.length > 0) {
          this.adds = response.data[0].adds
          this.views = response.data[0].views
          this.fullControl = response.data[0].fullControle
          this.deletes = response.data[0].deletes
          this.edits = response.data[0].edit
        }


        if (this.fullControl == true || this.edits == true) {
          this.disableds = false
        }
        else {
          this.disableds = true
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }


  public manualPaging: boolean = false
  EditCustomizedPlanCommentModal(planDetails): void {

    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(EditNewCustomizePackageSuperAdminComponent, {
        disableClose: true,
        panelClass: "full-width-popup",
        data: {
          planDetails
        }

      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.getCustomizePackageSuperAdminListing(false);
        }
      })
    }
    else {
      this.commonService.userDeActivatedOrDeleted();
    }
  }

  userDetails = []
  statusId: number
  getuserDetails(): number {
    try {
      let userId = this.jwtService.getCurrentUserId();
      let param = {
        UserId: userId
      }
      this.sharedService.getUserDetails(param).subscribe(response => {
        if (response.success) {
          this.userDetails = response.data;
          this.statusId = response.data[0].statusId;
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch {
      this.commonService.commonSnakeBar();
    }
    return this.statusId;
  }

}
