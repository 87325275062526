import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { MatDialogRef } from '@angular/material';
import { AppSettings } from '../../config/app-settings';
import { UserPermissionCompanyAdminServiceService } from '../service/user-permission-company-admin-service.service';

@Component({
  selector: 'app-add-user-permission-company-admin',
  templateUrl: './add-user-permission-company-admin.component.html',
  styleUrls: ['./add-user-permission-company-admin.component.scss']
})
export class AddUserPermissionCompanyAdminComponent implements OnInit {

  menuId: number

  public addPermissionForm: FormGroup;
  public isProcessing: boolean = false;
  public lastInsertedStandardPackageId: any
  displayedColumns: string[] = ['menuId', 'id', 'menuName', 'menuLink', 'fullControle', 'views'
    , 'adds', 'edits', 'deletes'];
  dataSource = [];
  allroles = [];

  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  isLoadMore: boolean = false;

  constructor(private userPermissionCompanyAdminServiceService: UserPermissionCompanyAdminServiceService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private appSettings: AppSettings,
    private dialogRef: MatDialogRef<AddUserPermissionCompanyAdminComponent>) { }

  ngOnInit() {
    this.initForm()
    this.getmainListingForCompanyAdminAddition(false)
    this.getRoleDropdown()
  }

  initForm() {
    this.addPermissionForm = this.formBuilder.group({
      'PermissionName': [null, [Validators.required, this.noWhitespaceValidator]],
      'RoleLevel': [null, [Validators.required]]
    });
  }

  addMainPermissionByCompanyAdmin(form) {
    if (form.valid) {
      try {
        this.isProcessing = true;
        let params = {
          PermissionName: this.addPermissionForm.controls.PermissionName.value.trim(),
          RoleLevelId: this.addPermissionForm.controls.RoleLevel.value
        }
        this.userPermissionCompanyAdminServiceService.addMainPermissionByCompanyAdmin(params).subscribe(resposne => {
          if (resposne.success) {
            this.isProcessing = false;
            this.addPermissionForm.get('PermissionName').patchValue('');
            this.lastInsertedStandardPackageId = resposne.data;
            let arrayAllComponent = this.dataSource;
            for (let item of arrayAllComponent) {

              let paramarr = {
                PermissionId: this.lastInsertedStandardPackageId,
                MenuId: item.menuId,
                FullControle: item.fullControle,
                Views: item.views,
                Adds: item.adds,
                Edit: item.edits,
                Deletes: item.deletes
              };

              try {
                this.userPermissionCompanyAdminServiceService.addChildPermissionByCompanyAdmin(paramarr).subscribe(resposne => {
                  if (resposne.success) {
                    this.isProcessing = false;
                    this.closeDialog();
                  }
                  else {
                    this.isProcessing = false;
                    this.closeDialog();
                    this.commonService.showSnakeBar(resposne.message);
                  }

                }, (err) => {
                  this.isProcessing = false;
                  this.closeDialog();
                  this.commonService.checkTokenValidity(err);
                })
              }
              catch
              {
                this.isProcessing = false;
                this.closeDialog();
                this.commonService.commonSnakeBar();
              }
            }
            this.commonService.showSnakeBar(resposne.message);
            this.closeDialog();
          }
          else {
            this.addPermissionForm.get('PermissionName').patchValue('')
            this.isProcessing = false;
            this.closeDialog();
            this.commonService.showSnakeBar(resposne.message);
          }

        }, (err) => {
          this.isProcessing = false;
          this.closeDialog();
          this.commonService.checkTokenValidity(err);
        })
      }
      catch{
        this.isProcessing = false;
        this.closeDialog();
        this.commonService.commonSnakeBar();
      }

    }
    else {
      this.commonService.showSnakeBar("Please do the correction");
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getmainListingForCompanyAdminAddition(isPaging: boolean) {
    try {
      let params = {
        PageNumber: this.pageNumber,
        PageSize: this.pageSize
      }
      this.userPermissionCompanyAdminServiceService.getmainListingForCompanyAdminAddition(params).subscribe(response => {
        if (response.success) {
          this.dataSource = response.data
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch{
      this.commonService.commonSnakeBar();
    }
  }


  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }


  public checkall(event: any) {
    var data = this.dataSource

    var newdata = []

    // for (let i = 1; i < this.dataSource.length; i++) {
    //   this.dataSource[i].fullControle = event.checked
    //   this.dataSource[i].views = event.checked
    //   this.dataSource[i].adds = event.checked
    //   this.dataSource[i].edits = event.checked
    //   this.dataSource[i].deletes = event.checked
    // }

  }

  public checkValue(event: any, value) {
    let checkedType = value
    this.menuId = this.menuId

    switch (checkedType) {
      case "fullcontrol":
        this.dataSource.find(item => item.menuId == this.menuId).fullControle = event.checked;
        this.dataSource.find(item => item.menuId == this.menuId).views = event.checked;
        this.dataSource.find(item => item.menuId == this.menuId).adds = event.checked;
        this.dataSource.find(item => item.menuId == this.menuId).edits = event.checked;
        this.dataSource.find(item => item.menuId == this.menuId).deletes = event.checked;
        break;
      case "view":
        this.dataSource.find(item => item.menuId == this.menuId).views = event.checked;

        if (this.dataSource.find(item => item.menuId == this.menuId).views == true &&
          this.dataSource.find(item => item.menuId == this.menuId).adds == true &&
          this.dataSource.find(item => item.menuId == this.menuId).edits == true &&
          this.dataSource.find(item => item.menuId == this.menuId).deletes == true) {
          this.dataSource.find(item => item.menuId == this.menuId).fullControle = true
        }
        else {
          this.dataSource.find(item => item.menuId == this.menuId).fullControle = false
        }

        if (this.dataSource.find(item => item.menuId == this.menuId).views == false) {
          this.dataSource.find(item => item.menuId == this.menuId).fullControle = false;
          this.dataSource.find(item => item.menuId == this.menuId).adds = false;
          this.dataSource.find(item => item.menuId == this.menuId).edits = false;
          this.dataSource.find(item => item.menuId == this.menuId).deletes = false;
        }
        break;
      case "add":
        this.dataSource.find(item => item.menuId == this.menuId).adds = event.checked;

        if (this.dataSource.find(item => item.menuId == this.menuId).edits == false &&
          this.dataSource.find(item => item.menuId == this.menuId).deletes == false) {
          this.dataSource.find(item => item.menuId == this.menuId).views = event.checked;
        }

        if (this.dataSource.find(item => item.menuId == this.menuId).views == true &&
          this.dataSource.find(item => item.menuId == this.menuId).adds == true &&
          this.dataSource.find(item => item.menuId == this.menuId).edits == true &&
          this.dataSource.find(item => item.menuId == this.menuId).deletes == true) {
          this.dataSource.find(item => item.menuId == this.menuId).fullControle = true
        }
        else {
          this.dataSource.find(item => item.menuId == this.menuId).fullControle = false
        }
        break;
      case "edit":
        this.dataSource.find(item => item.menuId == this.menuId).edits = event.checked;

        if (this.dataSource.find(item => item.menuId == this.menuId).adds == false &&
          this.dataSource.find(item => item.menuId == this.menuId).deletes == false) {
          this.dataSource.find(item => item.menuId == this.menuId).views = event.checked;
        }

        if (this.dataSource.find(item => item.menuId == this.menuId).views == true &&
          this.dataSource.find(item => item.menuId == this.menuId).adds == true &&
          this.dataSource.find(item => item.menuId == this.menuId).edits == true &&
          this.dataSource.find(item => item.menuId == this.menuId).deletes == true) {
          this.dataSource.find(item => item.menuId == this.menuId).fullControle = true
        }
        else {
          this.dataSource.find(item => item.menuId == this.menuId).fullControle = false
        }
        break;
      case "delete":
        this.dataSource.find(item => item.menuId == this.menuId).deletes = event.checked;

        if (this.dataSource.find(item => item.menuId == this.menuId).adds == false &&
          this.dataSource.find(item => item.menuId == this.menuId).edits == false) {
          this.dataSource.find(item => item.menuId == this.menuId).views = event.checked;
        }

        if (this.dataSource.find(item => item.menuId == this.menuId).views == true &&
          this.dataSource.find(item => item.menuId == this.menuId).adds == true &&
          this.dataSource.find(item => item.menuId == this.menuId).edits == true &&
          this.dataSource.find(item => item.menuId == this.menuId).deletes == true) {
          this.dataSource.find(item => item.menuId == this.menuId).fullControle = true
        }
        else {
          this.dataSource.find(item => item.menuId == this.menuId).fullControle = false
        }
        break;

    }
  }


  onRowClicked(row) {
    this.menuId = row.menuId
  }


  getRoleDropdown() {
    let params = {
      PageNumber: this.pageNumber,
      PageSize: this.pageSize
    }
    this.userPermissionCompanyAdminServiceService.getRoleDropdown(params).subscribe(response => {
      if (response.success) {
        let count = [];
        response.data.forEach(element => {
          count.push({
            id: element.id,
            name: element.name
          })
        });
        this.allroles = count
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }
}
