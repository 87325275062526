import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CommonService } from '../../core/services/common.service';
import { SmsCampaignServicesService } from '../service/sms-campaign-services.service';
import { isNullOrUndefined } from 'util';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-edit-sms-campaign',
  templateUrl: './edit-sms-campaign.component.html',
  styleUrls: ['./edit-sms-campaign.component.scss']
})
export class EditSmsCampaignComponent implements OnInit {

  editSMSCampForm: FormGroup

  recruiters = []
  skillList = []
  subscribers = []
  isProcessing: boolean = false;
  subsCriberId: number
  IsVoiceMailSMS: boolean
  selectedFile: File
  campData: any
  campaignId: string
  campaignName: string
  sms: string
  voiceMail: boolean
  voiceMailSMS: string
  campaignSkill: number
  recruiterId: number
  subscriberId: number
  subscriberType: string
  description: any

  startDate: any
  startTime: any
  endTime: any
  gstZone: any
  selected: any;
  fileUrl: any
  constructor(@Inject(MAT_DIALOG_DATA) public data: EditSmsCampaignComponent,
    private smsCampaignServicesService: SmsCampaignServicesService,
    private formbuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private commonService: CommonService,
    private dialogRef: MatDialogRef<EditSmsCampaignComponent>) {

    this.campData = data;
    this.campaignName = this.campData.campDetail.name
    this.sms = this.campData.campDetail.sms
    this.campaignId = this.campData.campDetail.campaignId
    this.description = this.campData.campDetail.description
    // this.startTime = this.campData.campDetail.startTime
    this.gstZone = this.campData.campDetail.gstTime

    this.startDate = this.campData.campDetail.startTime
    this.startDate = this.startDate.substring(0, this.startDate.indexOf(' '));

    this.startTime = this.campData.campDetail.startTime;
    this.startTime = this.startTime.substring(this.startTime.indexOf(" ")).trim();

    this.endTime = this.campData.campDetail.endTime;
    // this.endTime = this.endTime.substring(this.endTime.indexOf(" ")).trim();


  }

  ngOnInit() {
    this.intiForm();


    const data = 'Name,Phone,Skill';
    const blob = new Blob([data], { type: 'application/octet-stream' });

    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
  }

  intiForm() {
    this.editSMSCampForm = this.formbuilder.group({
      'Name': [this.campaignName, [Validators.required, this.noWhitespaceValidator]],
      'SMS': [this.sms, [Validators.required, this.noWhitespaceValidator]],
      'Description': [this.description],
      'StartDate': [this.startDate, [Validators.required]],
      'StartTime': [this.startTime, [Validators.required]],
      // 'EndTime': [this.endTime],
      'GstZone': [this.gstZone, [Validators.required]]
    })
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }


  closeDialog() {
    this.dialogRef.close();
  }

  onFileChanged(event) {
    this.selectedFile = event.target.files[0]

  }


  editSMSCampaign(form) {
    if (form.valid) {
      try {
        this.isProcessing = true;

        const formData = new FormData();

        if (this.selectedFile != null || this.selectedFile != undefined) {
          if (this.selectedFile.name.split('.').pop().toLocaleLowerCase() != "csv") {
            this.commonService.showSnakeBar("Only .csv extension allowed");
            this.isProcessing = false;
            return;
          }
          else {
            formData.append(this.selectedFile.name, this.selectedFile);
          }
        }

        let startTimeVal = this.editSMSCampForm.controls.StartTime.value;

        var str = new String(startTimeVal);

        if (str.charAt(2) != ':' || str.charAt(5) != ':'
          || str.charAt(0) == ':' || str.charAt(1) == ':'
          || str.charAt(3) == ':' || str.charAt(4) == ':'
          || str.charAt(6) == ':' || str.charAt(7) == ':') {
          this.commonService.showSnakeBar("Please proper fill the start time in correct format");
          this.isProcessing = false;
          return;
        }

        // let endTimeVal = this.editSMSCampForm.controls.EndTime.value;
        // var strendTime = new String(endTimeVal);

        // if (strendTime.charAt(2) != ':' || strendTime.charAt(5) != ':'
        //   || strendTime.charAt(0) == ':' || strendTime.charAt(1) == ':'
        //   || strendTime.charAt(3) == ':' || strendTime.charAt(4) == ':'
        //   || strendTime.charAt(6) == ':' || strendTime.charAt(7) == ':') {
        //   this.commonService.showSnakeBar("Please proper fill the end time in correct format");
        //   this.isProcessing = false;
        //   return;
        // }


        let startDates: any

        if (this.startDate != this.editSMSCampForm.controls.StartDate.value) {
          startDates = this.editSMSCampForm.controls.StartDate.value.toLocaleDateString()
        }
        else {
          startDates = this.editSMSCampForm.controls.StartDate.value;
        }

        formData.append("CampaignId", this.campaignId);
        formData.append("Name", this.editSMSCampForm.controls.Name.value);
        formData.append("SMS", this.editSMSCampForm.controls.SMS.value);
        formData.append("Description", this.editSMSCampForm.controls.Description.value);
        formData.append("GstZone", this.editSMSCampForm.controls.GstZone.value);
        formData.append("StartDate", startDates);
        formData.append("StartTime", this.editSMSCampForm.controls.StartTime.value);
        //  formData.append("EndTime", this.editSMSCampForm.controls.EndTime.value);
        formData.append("EndTime", "12:00:00");

        this.smsCampaignServicesService.UpdateSMSCamapignFormRequest(formData).subscribe(response => {
          if (response.success) {
            this.commonService.showSnakeBar(response.message);
            this.closeDialog();
            this.isProcessing = false;
          }
          else {
            this.commonService.showSnakeBar(response.message);
            this.isProcessing = false;
          }
        }, (err) => {
          this.isProcessing = false;
          this.commonService.checkTokenValidity(err);
          this.closeDialog();
        })
      }
      catch{
        this.isProcessing = false;
        this.commonService.commonSnakeBar();
      }
    }
    else {
      this.commonService.showSnakeBar("Please do the correction");
    }
  }

  public onTimeStartTimekeypress(event) {
    var val = event.target.value;
    var textlength = this.editSMSCampForm.controls.StartTime.value.length;

    if (textlength == 2) {
      if (this.editSMSCampForm.controls.StartTime.value < 24) {

        if (this.editSMSCampForm.controls.StartTime.value > 8 &&
          this.editSMSCampForm.controls.StartTime.value <= 19) {
          this.editSMSCampForm.get('StartTime').setValue(val + ':');
        }
        else {
          this.editSMSCampForm.get('StartTime').patchValue('');
          this.commonService.showSnakeBar("Start time cannot be below 8 AM and greater than 9 PM");
        }
      }
      else {
        this.editSMSCampForm.get('StartTime').patchValue('');
      }
    }
    if (textlength == 5) {
      const str = this.editSMSCampForm.controls.StartTime.value;
      const slug = str.split(':').pop();
      if (slug < 60) {
        this.editSMSCampForm.get('StartTime').setValue(val + ':');
      }
      else {
        this.editSMSCampForm.get('StartTime').patchValue('');
      }
    }
    if (textlength == 8) {
      var member = this.editSMSCampForm.controls.StartTime.value;

      var last2 = member.slice(-2);

      if (last2 < 60) {

      }
      else {
        this.editSMSCampForm.get('StartTime').patchValue('');
      }
    }
    var first2 = this.editSMSCampForm.controls.StartTime.value.substring(0, 2);


    if (textlength >= 2) {
      if (first2 > 8 &&
        first2 <= 19) {
      }
      else {
        this.editSMSCampForm.get('StartTime').patchValue('');
        this.commonService.showSnakeBar("Start time cannot be below 8 AM and greater than 9 PM");
      }

    }

  }


  public onTimeEndTimekeypress(event) {
    var val = event.target.value;
    var textlength = this.editSMSCampForm.controls.EndTime.value.length;

    if (textlength == 2) {
      if (this.editSMSCampForm.controls.EndTime.value < 24) {
        this.editSMSCampForm.get('EndTime').setValue(val + ':');
      }
      else {
        this.editSMSCampForm.get('EndTime').patchValue('');
      }

    }
    if (textlength == 5) {
      const str = this.editSMSCampForm.controls.EndTime.value;
      const slug = str.split(':').pop();
      if (slug < 60) {
        this.editSMSCampForm.get('EndTime').setValue(val + ':');
      }
      else {
        this.editSMSCampForm.get('EndTime').patchValue('');
      }

    }
    if (textlength == 8) {
      var member = this.editSMSCampForm.controls.EndTime.value;

      var last2 = member.slice(-2);

      if (last2 < 60) {

      }
      else {
        this.editSMSCampForm.get('EndTime').patchValue('');
      }
    }

    var member = this.editSMSCampForm.controls.EndTime.value;

    var last2 = member.slice(-2);

    if (last2 > 59) {
      this.editSMSCampForm.get('EndTime').patchValue('');
    }
  }

  SmsPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

}
