import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { ComponentPlanService } from '../service/component-plan.service'
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AddComponentTypeComponent } from '../../component-plan/add-component-type/add-component-type.component'



@Component({
  selector: 'app-add-component-plan',
  templateUrl: './add-component-plan.component.html',
  styleUrls: ['./add-component-plan.component.scss']
})
export class AddComponentPlanComponent implements OnInit {

  public addCompPlanForm: FormGroup
  public isProcessing: boolean = false
  componentPlanData = [];
  componentTypeData = []
  public numericNumberReg = '^-?[0-9]\\d*(\\.\\d{1,2})?$';

  constructor(private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private commonService: CommonService,
    private componentPlanService: ComponentPlanService,
    private datePipe: DatePipe,
    private dialogRef: MatDialogRef<AddComponentPlanComponent>) { }

  ngOnInit() {
    this.initForm();
    this.getMainComponentDropdown();
    this.getComponentTypeDropdown();
  }

  initForm() {
    this.addCompPlanForm = this.formBuilder.group({
      'Name': [null, [Validators.required, this.noWhitespaceValidator]],
      'Price': [null, [Validators.required, this.noWhitespaceValidator, Validators.pattern(this.numericNumberReg)]],
      'Component': [null, [Validators.required]],
      'ComponentType': [null, [Validators.required]]
    });
  }
  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  addComponentPlan(form) {
    if (form.valid) {
      try {
        this.isProcessing = true;

        let params = {
          Name: this.addCompPlanForm.controls.Name.value.trim(),
          Price: this.addCompPlanForm.controls.Price.value,
          Component: this.addCompPlanForm.controls.Component.value,
          ComponentTypeId: this.addCompPlanForm.controls.ComponentType.value
        }
        this.componentPlanService.addComponentPlan(params).subscribe(resposne => {
          if (resposne.success) {
            this.isProcessing = false;
            this.commonService.showSnakeBar(resposne.message);
            this.closeDialog();
          }
          else {
            this.isProcessing = false;
            this.commonService.showSnakeBar(resposne.message);
          }

        }, (err) => {
          this.isProcessing = false;
          this.commonService.checkTokenValidity(err);
          this.closeDialog();
        })
      }
      catch
      {
        this.isProcessing = false;
        this.commonService.commonSnakeBar();
      }
    }
  }

  getMainComponentDropdown() {
    let params =
    {
      SpAction: 'Add'
    }
    this.componentPlanService.getMainComponentDropdown(params).subscribe(response => {
      if (response.success) {
        let count = [];
        response.data.forEach(element => {
          count.push({
            id: element.id,
            name: element.name
          })
        });
        this.componentPlanData = count
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }


  getComponentTypeDropdown() {
    let params =
    {
      SpAction: 'View'
    }
    this.componentPlanService.getComponentTypeDropdown(params).subscribe(response => {
      if (response.success) {
        let count = [];
        response.data.forEach(element => {
          count.push({
            id: element.id,
            name: element.name
          })
        });
        this.componentTypeData = count
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  closeDialog() {
    this.dialogRef.close();
  }

  numberWithDotOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode == 190 || charCode == 110)) {
      return false;
    }
    return true;
  }

  AddComponentTypePlanModal(): void {
    const dialogRef = this.dialog.open(AddComponentTypeComponent, {
      width: '600px',
      height: 'auto',
      panelClass: "modal-outer"
    }
    )

    dialogRef.afterClosed().subscribe(result => {
      this.getComponentTypeDropdown();
      if (result == undefined) {

      }
    })
  };

}
