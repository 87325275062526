import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AppSettings } from '../../config/app-settings';
import { CustomizepackageserviceService } from '../service/customizepackageservice.service'
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-customize-package',
  templateUrl: './edit-customize-package.component.html',
  styleUrls: ['./edit-customize-package.component.scss']
})
export class EditCustomizePackageComponent implements OnInit {

  removable = true;
  public editSpForm: FormGroup;
  public isProcessing: boolean = false;
  userData: any
  packageId: any
  componentPlanData = [];
  componentInsertedPlanData = [];
  audioMins: any
  compName: any
  companyId: any
  endDate: any
  id: any
  packageName: any
  price: any
  calculatedPrice: any
  priceUnit: any
  sms: any
  startDate: any
  status: any
  statusId: any
  videoMins: any
  description: any
  dateFormat: any;
  totalPrice: number = 0

  public smsPrice: any
  public audioPrice: any
  public videoPrice: any
  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE

  componentDataForDynamicInputs = [];

  insetedComponentsForStandardData = [];
  customizePackageRefId: any
  packageTenure: any
  PackageTenure: any
  createdDate: any
  userType: any

  constructor(private formBuilder: FormBuilder,
    private commonService: CommonService,
    private appSettings: AppSettings,
    private route: ActivatedRoute,
    private customizepackageserviceService: CustomizepackageserviceService) {
    this.route.params.subscribe((packageId) => {
      this.packageId = packageId.id;
      this.userType = packageId.type

    });

    this.dateFormat = this.appSettings.CAMPAIGN_DATE
  }

  ngOnInit() {
    this.initForm()
    this.getRequestedBasicDetails()
    this.getComponentDetails()
  }

  initForm() {
    this.editSpForm = this.formBuilder.group({
      'PackageName': [this.packageName, [Validators.required, this.noWhitespaceValidator]],
      'AllComponent': this.formBuilder.array([]),
      'CalculatedPrice': [this.calculatedPrice, [Validators.required, Validators.minLength(1)]],
      'Price': [this.price],
      'CustomizePackageRefId': [this.customizePackageRefId],
      'Id': [this.id],
      'Description': [this.description],
      'PackageTenure': [String(this.packageTenure), [Validators.required]]
    });
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }


  getRequestedBasicDetails() {
    let allcomponent = this.editSpForm.get('AllComponent') as FormArray;
    let params = {
      PageNumber: this.pageNumber,
      PageSize: this.pageSize,
      PackageId: this.packageId
    }
    this.customizepackageserviceService.getRequestedBasicDetails(params).subscribe(response => {
      if (response.success) {
        this.packageName = response.data[0].name
        this.calculatedPrice = response.data[0].calculatedPrice
        this.price = response.data[0].price
        this.customizePackageRefId = response.data[0].customizePackageRefId
        this.description = response.data[0].description
        this.packageTenure = response.data[0].packageTenure
        this.createdDate = response.data[0].createdDate



      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }


  getComponentDetails() {
    let allcomponent = this.editSpForm.get('AllComponent') as FormArray;
    let params = {
      PageNumber: this.pageNumber,
      PageSize: this.pageSize,
      PackageType: 'CustomizePackageByCompanyAdmin',
      PackageId: this.packageId,
      SpOption: 'Get',
      PurchasedOption: 'No'
    }
    this.customizepackageserviceService.getComponentInsertedData(params).subscribe(response => {
      if (response.success) {
        this.insetedComponentsForStandardData = response.data
        let count = [];
        response.data.forEach(element => {
          allcomponent.push(this.formBuilder.control({
            ComponentId: element.componentId,
            ComponentType: element.componentType,
            InputParameter: element.inputParameter,
            Id: element.id,
            Name: element.name,
            Price: element.price,
            CalculatedValue: element.calculatedValue
          }));
          count.push({
            ComponentId: element.componentId,
            ComponentType: element.componentType,
            InputParameter: element.inputParameter,
            Id: element.id,
            Name: element.name,
            Price: element.price,
            CalculatedValue: element.calculatedValue
          })
        });

        if (count.length >= 0) {
          this.componentDataForDynamicInputs = count
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }
}
