import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResultModel } from '../../core/model/result.model';
import { ApiService } from '../../core/services/api.service';
import { ApiUrls } from '../../config/api-urls';

@Injectable({
  providedIn: 'root'
})
export class CustomizepackageserviceService {

  constructor(private apiService: ApiService,
    private apiUrl: ApiUrls) { }

  getCustomizePackageListing(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.CustomizePackageListing, reqModel);
  }



  getCustomizePackageCommentsListing(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.CustomizePackageCommentsListing, reqModel);
  }

  addCustomizePackage(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.AddCustomizePackage, reqModel);
  }

  addCustPackageComment(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.AddCustPackageComment, reqModel);
  }

  viewAndMailSentCustomized(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.ViewAndMailSentCustomized, reqModel);
  }

  superAdminEmailId(): Observable<ResultModel> {
    return this.apiService.get<ResultModel>(this.apiUrl.CompanyAdmin.SuperAdminEmailId);
  }

  getComponentDetails(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.GetComponentDetails, reqModel);
  }

  addPackageArrayDetails(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.AddPackageArrayDetails, reqModel);
  }

  getComponentInsertedData(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.GetComponentInsertedData, reqModel);
  }

  getRequestedBasicDetails(reqModel):Observable<ResultModel>{
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.GetRequestedBasicDetails, reqModel);
  }

}
