import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from '../../core/services/auth.service';
import { CommonService } from '../../core/services/common.service';
import { AppSettings } from '../../config/app-settings';
import { StatusMaster } from '../../core/constants/status';
import { AutofillMonitor } from '@angular/cdk/text-field';
import { PurchasedPackageServiceService } from '../service/purchased-package-service.service'
import { EditPurchasedPackagesComponent } from '../edit-purchased-packages/edit-purchased-packages.component'
import { SharedService } from '../../shared/service/shared.service';
import { JwtService } from '../../core/services/jwt.service';
import { AddAllocateMinutesToSubscriberComponent } from '../../allocate-minutes-to-subscriber/add-allocate-minutes-to-subscriber/add-allocate-minutes-to-subscriber.component';
import { DeallocateMinutesToSubscriberComponent } from '../../allocate-minutes-to-subscriber/deallocate-minutes-to-subscriber/deallocate-minutes-to-subscriber.component';
import { EditAllocateMinutesToSubscriberComponent } from '../../allocate-minutes-to-subscriber/edit-allocate-minutes-to-subscriber/edit-allocate-minutes-to-subscriber.component';
import { EditDeallocateMinutesToSubscriberComponent } from '../../allocate-minutes-to-subscriber/edit-deallocate-minutes-to-subscriber/edit-deallocate-minutes-to-subscriber.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-purchased-packages-list',
  templateUrl: './purchased-packages-list.component.html',
  styleUrls: ['./purchased-packages-list.component.scss']
})
export class PurchasedPackagesListComponent implements OnInit {

  // displayedColumns: string[] = ['Id', 'PackageId', 'Name', 'Description', 'PackageType', 'AudioMinutes',
  //   'VideoMinutes', 'SMS', 'Price', 'CompanyId', 'FromDate', 'CreatedBy', 'PackageTenure', 'Status', 'Action'];

  dataSource = [];
  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  isLoadMore: boolean = false
  statusMstr: any = StatusMaster;

  fullControl: boolean = true;
  views: boolean = true;
  adds: boolean = true;
  edits: boolean = true;
  deletes: boolean = true;
  disableds: boolean = false;

  public ListingData = [];
  public AllStandardPackages = [];

  public purchasedComponentsPackages = [];

  displayedColumns: string[] = ['Id', 'PackageId',
    'Name', 'Description', 'AudioMinutes', 'VideoMinutes',
    'SMS', 'CompanyId', 'CompName', 'SubscriberId',
    'SubscriberName', 'CreatedDate', 'Status', 'Action'];

  displayedColumnsdeallocated: string[] = ['Id', 'PackageId',
    'Name', 'Description', 'AudioMinutes', 'VideoMinutes',
    'SMS', 'CompanyId', 'CompName', 'SubscriberId',
    'SubscriberName', 'CreatedDate', 'Status', 'Action'];

  constructor(public dialog: MatDialog,
    private authService: AuthService,
    private commonService: CommonService,
    private sharedService: SharedService,
    private jwtService: JwtService,
    private _location: Location,
    private purchasedPackageServiceService: PurchasedPackageServiceService,
    private appSettings: AppSettings) { }

  ngOnInit() {

    //  this.getAllocateManagerMinsListing(false)
    this.getPurchasePackageListing(false)


    const role = this.jwtService.getUserRole();
    if (role != 'CompanyAdmin') {
      this.getAddEditDeletePermission();
    }
    this.getSubcriberAllocatedMinutesListing(false)  // For allocation list
    this.getSubcriberDeAllocatedMinutesListing(false) // // For  deallocation list
  }

  price: any
  name: any
  id: any
  packageType: any
  packageId: any

  backClicked() {
    this._location.back();
  }

  getPurchasePackageListing(isPaging: boolean) {

    try {
      let params = {
        PageNumber: this.pageNumber,
        PageSize: this.pageSize
      }
      this.purchasedPackageServiceService.purchasedPackageListing(params).subscribe(response => {
        if (response.success) {
          if (response.data[0].id > 0) {
            this.price = response.data[0].price;
            this.name = response.data[0].name;
            this.id = response.data[0].id;
            this.packageType = response.data[0].packageType;
            this.packageId = response.data[0].packageId;

          }


          this.getComponentDetails();
          if (response.data.length < this.pageSize) {
            this.isLoadMore = false;
          }
          else {
            this.isLoadMore = true;
          }

          if (isPaging) {
            this.appendStandardPackageListing(response.data);
          }
          else {
            this.dataSource = response.data;
          }
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch {
      this.commonService.commonSnakeBar();
    }
  }

  appendStandardPackageListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.dataSource.length > 0) {
        newState = this.dataSource.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.dataSource = newState;
    }
  }

  loadMore() {
    this.pageNumber = this.pageNumber + 1
    this.pageSize = this.pageSize
    this.getPurchasePackageListing(true);
  }

  ViewPurchasedPackageModal(userDetail): void {
    const dialogRef = this.dialog.open(EditPurchasedPackagesComponent, {
      disableClose: true,
      panelClass: 'full-width-popup',
      data: {
        userDetail
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == undefined) {
        this.getPurchasePackageListing(false);
      }
    })
  }



  AddAllocateMinutesToSubscriberModal(): void {
    const dialogRef = this.dialog.open(AddAllocateMinutesToSubscriberComponent, {
      disableClose: true,
      panelClass: 'full-width-popup'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == undefined) {
        this.getPurchasePackageListing(false);
      }
    })
  }

  AddDeAllocateMinutesToSubscriberModal(): void {
    const dialogRef = this.dialog.open(DeallocateMinutesToSubscriberComponent, {
      disableClose: true,
      panelClass: "full-width-popup"
    }
    )

    dialogRef.afterClosed().subscribe(result => {
      if (result == undefined) {
        this.manualPaging = true
        this.getSubcriberAllocatedMinutesListing(false);
      }
    })
  };

  getAddEditDeletePermission() {
    let params = {
      MenuId: 14
    }
    this.sharedService.getAddEditDeletePermission(params).subscribe(response => {
      if (response.success) {
        if (response.data.length > 0) {
          this.adds = response.data[0].adds
          this.views = response.data[0].views
          this.fullControl = response.data[0].fullControle
          this.deletes = response.data[0].deletes
          this.edits = response.data[0].edit
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  EditSubscriberAllocatedMinsModal(userDetail): void {
    const dialogRef = this.dialog.open(EditAllocateMinutesToSubscriberComponent, {
      disableClose: true,
      panelClass: "full-width-popup",
      data: {
        userDetail
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == undefined) {
        this.manualPaging = true
        this.getSubcriberAllocatedMinutesListing(false);
      }
    })
  }




  EditSubscriberDeAllocatedMinsModal(userDetail): void {
    const dialogRef = this.dialog.open(EditDeallocateMinutesToSubscriberComponent, {
      disableClose: true,
      panelClass: "full-width-popup",
      data: {
        userDetail
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == undefined) {
        this.manualPaging = true
        this.getSubcriberDeAllocatedMinutesListing(false);
      }
    })
  }

  isPackAvail = false;
  getComponentDetails() {
    let params = {
      PageNumber: this.pageNumber,
      PageSize: this.pageSize,
      PackageType: this.packageType,
      PackageId: this.packageId,
      MainId: this.id,
      SpOption: 'Edit',
      PurchasedOption: 'Yes'
    }
    this.purchasedPackageServiceService.getComponentInsertedData(params).subscribe(response => {
      if (response.success) {
        this.purchasedComponentsPackages = response.data;
        this.purchasedComponentsPackages.some(e => {
          if (e.inputParameter > 0) {
            this.isPackAvail = true;
            return true;
          }
        })
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  allocatedManagersList = []
  getAllocateManagerMinsListing(isPaging: boolean) {
    try {
      let params = {
        SpOption: 'AllocatedManagers'
      }
      this.purchasedPackageServiceService.getAllocateManagerMinsListing(params).subscribe(response => {
        if (response.success) {
          this.allocatedManagersList = response.data;
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch {
      this.commonService.commonSnakeBar();
    }
  }

  getPurchasePackageAndAllocateandUsedListing() {
    try {
      let params = {
        PageNumber: this.pageNumber,
        PageSize: this.pageSize
      }
      this.purchasedPackageServiceService.getPurchasePackageAndAllocateandUsedListing(params).subscribe(response => {
        if (response.success) {

          var data = response.data;

          let commonArray = [];
          let managerData = [];
          managerData = this.allocatedManagersList
          // // let resourceArray = data.map((item)=>{ return {'AvailableUnit':item.availableUnit,'Used':item.usedUnits,'Name':item.name} ; });

          for (let i = 0; i < data.length; i++) {
            commonArray[data[i].managerId] = data.map((item) => {
              if (data[i].managerId == item.managerId) {
                return { 'AvailableUnit': item.availableUnit, 'Used': item.usedUnits, 'Name': item.name }
              }
            });
          }
          console.log(commonArray);

        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch {
      this.commonService.commonSnakeBar();
    }
  }

  public manualPaging: boolean = false
  allocateddataSource = []

  getSubcriberAllocatedMinutesListing(isPaging: boolean) {
    try {
      let params
      if (this.manualPaging == true && isPaging != true) {
        this.pageNumber = 1
        params = {
          PageNumber: this.pageNumber,
          PageSize: this.pageSize
        }
      }
      else {
        params = {
          PageNumber: this.pageNumber,
          PageSize: this.pageSize
        }
      }
      this.purchasedPackageServiceService.getSubcriberAllocatedMinutesListing(params).subscribe(response => {
        if (response.success) {
          if (response.data.length < this.pageSize) {
            this.isLoadMore = false;
          }
          else {
            this.isLoadMore = true;
          }

          if (isPaging) {
            this.appendCompanyListing(response.data);
          }
          else {
            this.allocateddataSource = response.data;
          }
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch {
      this.commonService.commonSnakeBar();
    }
  }

  appendCompanyListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.allocateddataSource.length > 0) {
        newState = this.allocateddataSource.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.allocateddataSource = newState;
    }
  }


  appendDeCompanyListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.dataSource.length > 0) {
        newState = this.dataSource.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.dataSource = newState;
    }
  }
  DeallocatedataSource = []

  getSubcriberDeAllocatedMinutesListing(isPaging: boolean) {
    try {
      let params
      if (this.manualPaging == true && isPaging != true) {
        this.pageNumber = 1
        params = {
          PageNumber: this.pageNumber,
          PageSize: this.pageSize,
          SpOption: ''
        }
      }
      else {
        params = {
          PageNumber: this.pageNumber,
          PageSize: this.pageSize,
          SpOption: ''
        }
      }
      this.purchasedPackageServiceService.getSubcriberDeAllocatedMinutesListing(params).subscribe(response => {
        if (response.success) {
          this.DeallocatedataSource = response.data;
          if (response.data.length < this.pageSize) {
            this.isLoadMore = false;
          }
          else {
            this.isLoadMore = true;
          }

          if (isPaging) {
            this.appendDeCompanyListing(response.data);
          }
          else {
            this.DeallocatedataSource = response.data;
          }
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch {
      this.commonService.commonSnakeBar();
    }
  }

}
