import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResultModel } from '../../core/model/result.model';
import { ApiService } from '../../core/services/api.service';
import { ApiUrls } from '../../config/api-urls';

@Injectable({
  providedIn: 'root'
})
export class AllocateMinutesToSubscriberServiceService {

  constructor(private apiService: ApiService,
    private apiUrl: ApiUrls) { 
  }

  getSubcriberAllocatedMinutesListing(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.SubcriberAllocatedMinutesListing, reqModel);
  }

  getSubscriberDropdown(reqModel): Observable<ResultModel>{
    return this.apiService.post<ResultModel>(this.apiUrl.Subscriber.SubscriberDropdown,reqModel);
  }

  getComponentDetailsForMintsAllocationForSubscriber(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.getComponentDetailsForMintsAllocationForSubscriber, reqModel);
  }

  addAllocateMintsToSubscriber(reqModel):Observable<ResultModel>{
    return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.addAllocateMintsToSubscriber, reqModel);
  }

  addAllocateMinsSubscriberArrayDetails(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.addAllocateMinsSubscriberArrayDetails, reqModel);
  }

  getComponentInsertedData(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.GetComponentInsertedAllocatedUnitsData, reqModel);
  }

  editAllocateMintsToSubscriber(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.EditAllocateMintsToSubscriber, reqModel);
  }


  editAllocateMintsArrayToSubscriber(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.addAllocateMinsSubscriberArrayDetails, reqModel);
  }


  getSubscriberDealloactionDropdown(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.GetSubscriberDealloactionDropdown, reqModel);
  }

  getComponentDetailsForMintsDeAllocationForSubscriber(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.GetComponentDetailsForMintsDeAllocationForSubscriber, reqModel);
  }

  addDeAllocateMintsToSubscriber(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.AddDeAllocateMintsToSubscriber, reqModel);
  }

  addDeAllocateMinsSubscriberArrayDetails(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.AddDeAllocateMinsSubscriberArrayDetails, reqModel);
  }

  getComponentDeallocateInsertedData(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.GetComponentDeallocateInsertedData, reqModel);
  }
  

}
