import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResultModel } from '../../core/model/result.model';
import { ApiService } from '../../core/services/api.service';
import { ApiUrls } from '../../config/api-urls';

@Injectable({
  providedIn: 'root'
})
export class CustomizePackageSuperAdminserviceService {

  constructor(private apiService: ApiService,
    private apiUrl: ApiUrls) { }

    getCustomizePackageSuperAdminListing(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.CustomizePackageListing, reqModel);
    }


    getCompanyDropDown(): Observable<ResultModel> {
      return this.apiService.get<ResultModel>(this.apiUrl.CompanyAdmin.CompanyDropDown);
    }
    

    getCustomizePackageCommentsListing(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.CustomizePackageCommentsListing, reqModel);
    }

    addCustomizePackage(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.AddCustomizePackage, reqModel);
    }

    addCustPackageComment(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.AddCustPackageComment, reqModel);
    }

    getComponentInsertedData(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.GetComponentInsertedData, reqModel);
    }

    addCustomizeApprovedPackage(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.AddCustomizeApprovedPackage, reqModel);
    }

    addPackageArrayDetails(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.AddPackageArrayDetails, reqModel);
    }
}
