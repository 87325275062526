import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentPlansListComponent } from './component-plans-list/component-plans-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialComponentsModule } from '../material-components.module';
import { AddComponentPlanComponent } from './add-component-plan/add-component-plan.component';
import { EditComponentPlansComponent } from './edit-component-plans/edit-component-plans.component';
import { AddComponentTypeComponent } from './add-component-type/add-component-type.component';


@NgModule({
  imports: [
    CommonModule,
    MaterialComponentsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [ComponentPlansListComponent, AddComponentPlanComponent, EditComponentPlansComponent, AddComponentTypeComponent],
  exports: [ComponentPlansListComponent],
  entryComponents: [AddComponentPlanComponent, EditComponentPlansComponent, AddComponentTypeComponent]
})
export class ComponentPlanModule { }
