import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { SkillService } from '../service/skill.service';
import { CommonService } from '../../core/services/common.service';

@Component({
  selector: 'app-edit-skill',
  templateUrl: './edit-skill.component.html',
  styleUrls: ['./edit-skill.component.scss']
})
export class EditSkillComponent implements OnInit {
  editSkillForm: FormGroup
  isProcessing: boolean = false

  skillId: number
  name: string
  numOfRec: number
  IVRExtension: number

  skillData: any


  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditSkillComponent,
    private formBuilder: FormBuilder,
    private skillServce: SkillService,
    private commonService: CommonService,
    private dialogRef: MatDialogRef<EditSkillComponent>
  ) {


    this.skillData = data

    this.name = this.skillData.name
    this.numOfRec = this.skillData.numOfRecruiter
    this.IVRExtension = this.skillData.ivrNumber
    this.skillId = this.skillData.id

  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.editSkillForm = this.formBuilder.group({
      'Name': [this.name, [Validators.required, this.noWhitespaceValidator]],
      // 'IVRNumber': [this.IVRExtension, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.min(0), Validators.max(9)]]
      'IVRNumber': [this.IVRExtension]
    })
  }

  editSkill(form) {
    if (form.valid) {
      try {
        this.isProcessing = true
        let params = {
          Id: this.skillId,
          Name: this.editSkillForm.controls.Name.value,
          IVRNumber: 0
        }
        this.skillServce.editSkill(params).subscribe(response => {
          if (response.success) {
            this.isProcessing = false;
            this.commonService.showSnakeBar(response.message);

            // let UpdatedValues = {
            //   Id: this.skillId,
            //   Name: this.editSkillForm.controls.Name.value,
            //   IVRNumber: this.editSkillForm.controls.IVRNumber.value,
            //   NumOfRec: this.numOfRec
            // }
            this.closeDialog();
          }
          else {
            this.isProcessing = false;
            this.commonService.showSnakeBar(response.message);
          }

        }, (err) => {
          this.isProcessing = false;
          this.commonService.checkTokenValidity(err);
          this.closeDialog();
        })
      }
      catch{
        this.isProcessing = false;
        this.commonService.commonSnakeBar();
      }
    }
    else {
      this.commonService.showSnakeBar("Please do the correction");
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

}
