import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialComponentsModule } from '../material-components.module';
import { AppRoutingModule } from '../app-routing.module';
import { CutomizePackagesModule } from '../cutomize-packages/cutomize-packages.module';
import { MyPackagesModule } from '../my-packages/my-packages.module';
import { PlanmanagementCompanyAdminListComponent } from './planmanagement-company-admin-list/planmanagement-company-admin-list.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialComponentsModule,
    AppRoutingModule,
    CutomizePackagesModule,
    MyPackagesModule
  ],
  declarations: [PlanmanagementCompanyAdminListComponent]
})
export class PlanmanagementCompanyAdminModule { }
