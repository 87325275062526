import { Injectable } from '@angular/core';
import { JwtService } from './jwt.service';

@Injectable()
export class AuthService {

  constructor(private jwtService: JwtService) { }

  isCompanyAdmin(): boolean {
    const role = this.jwtService.getUserRole();
    if (role && role == "CompanyAdmin") {
      return true;
    }
    else {
      return false;
    }

  }

  isSubscriber(): boolean {
    const role = this.jwtService.getUserRole();
    if (role && role == "Subscriber") {
      return true;
    }
    else {
      return false;
    }

  }

  isRecruiter(): boolean {
    const role = this.jwtService.getUserRole();
    if (role && role == "Recruiter") {
      return true;
    }
    else {
      return false;
    }
  }

  isSuperAdmin(): boolean {
    const role = this.jwtService.getUserRole();
    if (role && role == "SuperAdmin") {
      return true;
    }
    else {
      return false;
    }
  }
}
