import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResultModel } from '../../core/model/result.model';
import { ApiService } from '../../core/services/api.service';
import { ApiUrls } from '../../config/api-urls';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(
    private apiService: ApiService,
    private apiUrl: ApiUrls
  ) { }

  companyListing(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.CompanyListing, reqModel);
  }

  getCompanyDetailsById(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.GetCompanyDetailsById, reqModel);
  }

  getPurchasedPlanBasicDetails(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.GetPurchasedPlanBasicDetails, reqModel);
  }

  getPurchasdPlanComponentForCompanyDetailsDetails(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.GetPurchasdPlanComponentForCompanyDetailsDetails, reqModel);
  }
  
  addCompany(reqModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.AddCompany, reqModel);
  }

  editStandardPackage(editModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.EditStandardPackage, editModel);
  }

  editCompany(editModel): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.EditCompany, editModel)
  }

  getCountryDropdown(model): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.CountryDropdown, model);
  }

  getCompanyTypeDropdown(): Observable<ResultModel> {
    return this.apiService.post<ResultModel>(this.apiUrl.SuperAdmin.CompanyTypeDropdown);
  }

  
}
