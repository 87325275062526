import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, RequiredValidator } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { MatDialogRef } from '@angular/material';
import {CustomizePackageSuperAdminserviceService} from '../service/customize-package-super-adminservice.service'
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-add-customize-package-super-admin',
  templateUrl: './add-customize-package-super-admin.component.html',
  styleUrls: ['./add-customize-package-super-admin.component.scss']
})
export class AddCustomizePackageSuperAdminComponent implements OnInit {
  public addCustPackForm: FormGroup
  public isProcessing :boolean =false
  companyList = [];

  constructor(private formBuilder: FormBuilder,
    private commonService: CommonService,
    private customizePackageSuperAdminserviceService: CustomizePackageSuperAdminserviceService,
    private datePipe: DatePipe,
    private dialogRef: MatDialogRef<AddCustomizePackageSuperAdminComponent>) { }

  ngOnInit() {
    this.initForm()
    this.getCompanyDropDown();
  }

  initForm() {
    this.addCustPackForm = this.formBuilder.group({
      'Name': [null,[Validators.required]],
      'Description': [null],
      'AudioMinutes': [null],
      'VideoMinutes': [null],
      'SMS': [null],
      'Price': [null],
      'Company':[null,[Validators.required]]
    });
  }

  public addCustPackage(form) {
    if (form.valid) {
      try {
        this.isProcessing = true;
        let description: any
        let audioMinutes: any
        let videoMinutes: any
        let sMS: any
        let price: any
        let comment: any
        let company: any

        if(this.addCustPackForm.controls.AudioMinutes.value !=null
          || this.addCustPackForm.controls.VideoMinutes.value !=null
          || this.addCustPackForm.controls.SMS.value !=null)
        {
          if (this.addCustPackForm.controls.Description.value != null) {
            description = this.addCustPackForm.controls.Description.value;
          }
          else {
            description = '';
          }
  
          if (this.addCustPackForm.controls.AudioMinutes.value != null) {
            audioMinutes = this.addCustPackForm.controls.AudioMinutes.value;
          }
          else {
            audioMinutes = '';
          }

          if (this.addCustPackForm.controls.VideoMinutes.value != null) {
            videoMinutes = this.addCustPackForm.controls.VideoMinutes.value;
          }
          else {
            videoMinutes = '';
          }

          if (this.addCustPackForm.controls.SMS.value != null) {
            sMS = this.addCustPackForm.controls.SMS.value;
          }
          else {
            sMS = '';
          }

          if (this.addCustPackForm.controls.Price.value != null) {
            price = this.addCustPackForm.controls.Price.value;
          }
          else {
            price = '';
          }

          if (this.addCustPackForm.controls.Company.value != null) {
            company = this.addCustPackForm.controls.Company.value;
          }
          else {
            company = '';
          }

          let params = {
            Name: this.addCustPackForm.controls.Name.value.trim(),
            Description: description.trim(),
            AudioMinutes: audioMinutes.trim(),
            VideoMinutes: videoMinutes.trim(),
            SMS: sMS.trim(),
            Price: price.trim(),
            Compan:company.value
          }
          this.customizePackageSuperAdminserviceService.addCustomizePackage(params).subscribe(resposne => {
            if (resposne.success) {
              this.isProcessing = false;
              this.commonService.showSnakeBar(resposne.message);
              this.closeDialog();
            }
            else {
              this.isProcessing = false;
              this.commonService.showSnakeBar(resposne.message);
            }
          }, (err) => {
            this.isProcessing = false;
            this.commonService.checkTokenValidity(err);
            this.closeDialog();
          })
        }       
        else{
          this.isProcessing = false;
          this.commonService.requiredValidationSnakeBar();
        }
      }
      catch
      {
        this.isProcessing = false;
        this.commonService.commonSnakeBar();
      }
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  numberWithDotOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode == 190 || charCode == 110)) {
      return false;
    }
    return true;
  }

  getCompanyDropDown() {
    this.customizePackageSuperAdminserviceService.getCompanyDropDown().subscribe(response => {
      if (response.success) {
        let company = [];

        response.data.forEach(element => {
          company.push({
            id: element.id,
            name: element.name
          })
        });
        this.companyList = company
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }
}
