import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { ApiUrls } from "../../config/api-urls";
import { Observable } from "rxjs";
import { ResultModel } from "../model/result.model";
declare const Twilio: any;
export enum TwilioTokenType {
    Conference,
    Capability
}

@Injectable({
    providedIn: 'root'
})
export class TwilioService {
    device: any;
    token: any;
    constructor(private apiService: ApiService,
        private apiUrl: ApiUrls) {
        console.log('twilio constructed..')
    }
    /**
     * get token for twilio
     * @param type 
     */
    private getToken(type: TwilioTokenType): Observable<ResultModel> {
        if (type === TwilioTokenType.Conference) {
            return this.apiService.post<ResultModel>(this.apiUrl.Campaign.GenerateTokenForCall, {})
        }
        else {
            return this.apiService.post<ResultModel>(this.apiUrl.Campaign.GenerateTokenForClientCall, {})
        }
    }
    /**
     * on ready event
     * @param device 
     * @param cb 
     */
    private onReady(device: any, cb: () => void) {
        device.on('ready', function (device) {
            console.log('Twilio.Device Ready!');
            cb();
        });
    }
    /**
     * event
     * @param device 
     * @param cb 
     */
    private onConnect(device: any, cb: () => void) {
        device.on('connect', function (conn) {
            console.log('Successfully established call!');
            cb();
        });
    }
    /**
     * event
     * @param device 
     * @param cb 
     */
    private onOffline(device: any, cb: () => void) {
        device.on('offline', function (device) {
            console.log('offline! refresh token', device);
            cb();
        });
    }
    /**
     * event
     * @param device 
     * @param cb 
     */
    private onCancel(device: any, cb: (conn: any) => void) {
        device.on('cancel', function (conn) {
            console.log('cancel', conn);
            cb(conn);
        });
    }
    /**
     * event
     * @param device 
     * @param cb 
     */
    private onDisconnect(device: any, cb: (conn: any) => void) {
        device.on('disconnect', function (conn: any) {
            cb(conn);
        });
    }
    /**
     * event
     * @param device 
     * @param cb 
     */
    private onIncoming(device: any, cb: (conn: any) => void) {
        device.on('incoming', function (conn: any) {
            cb(conn);
        });
    }
    /**
     * get twilio device
     * @param type 
     * @param cb 
     */
    private async getDevice(type: TwilioTokenType, cb: (device: any, token: string) => void) {
        //console.log('twilio token');
        let token = await (await this.getToken(type).toPromise()).data.content;
        //console.log('[twilio]', token);
        let device = new Twilio.Device(token, {
            codecPreferences: ['opus', 'pcmu'],
            fakeLocalDTMF: true,
            enableRingingState: true,
            debug: true
        });
        this.onReady(device, () => {
            cb(device, token);
        })

    }

    /**
     * Listener for call events like incoming call
     * @param type 
     * @param cb 
     */
    listner(type: TwilioTokenType, cb: (type: string, conn: any) => void) {
        //console.log('listner');
        this.getDevice(type, (device, token) => {
            this.onIncoming(device, (conn) => {
                cb('incoming', conn);
            })

            this.onConnect(device, () => {
                console.log('listner onConnect');
            });

            this.onOffline(device, () => {
                console.log('listner onOffline')
                this.listner(type,cb);
            })

            this.onDisconnect(device, (conn) => {
                cb('disconnect', conn);
            })

            this.onCancel(device, (conn) => {
                cb('cancel', conn);
            })
        });
    }
    /**
     * connect for make call
     * @param option 
     * @param type 
     * @param cb 
     */
    connect(option: any, type: TwilioTokenType, cb: () => void) {
        console.log('connect option', option)
        this.getDevice(type, (device, token) => {
            console.log('connect device', device);
            option.token = token
            let res = device.connect(option);
            console.log('connect res', res);

            this.onConnect(device, () => {
                cb();
            });

            this.onOffline(device, () => {
                console.log('onOffline')
            })

            this.onDisconnect(device, () => {
                console.log('onDisconnect')
            })

            this.onCancel(device, () => {
                console.log('onCancel')
            })

            this.onIncoming(device, () => {
                console.log('onIncoming')
            })

        })
    }
}

