import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProfileModalComponent } from '../../shared/profile-modal/profile-modal.component';
import { ChangePasswordComponent } from '../../shared/change-password/change-password.component';
import { Detail } from '../../users/user-auth/detail';
import { JwtService } from '../services/jwt.service';
import { CommonService } from '../services/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  @Input() userInfo: Detail;

  callForwardingTab :boolean =true;

  constructor(public dialog: MatDialog,
    private jwtService: JwtService,
    private router: Router,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    const role = this.jwtService.getUserRole();
    if(role =='SuperAdmin' || role =='SuperSubAdmin')
    {
      this.callForwardingTab =false
    }

  }

  ViewProfileModal(): void {
    const dialogRef = this.dialog.open(ProfileModalComponent, {
      width: '600px',
      height: 'auto',
      panelClass: 'modal-outer',
      data: {
        userInfo: this.userInfo
      }
    });

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });
  }
  ViewChangePasswordModal(): void {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '500px',
      height: 'auto',
      panelClass: 'modal-outer',
      data: {
        userInfo: this.userInfo
      }
    });
  }

  logout() {
    this.jwtService.deleteToken();
    this.commonService.navigate('/login');
  }

  CallForwardingModal() {
    this.commonService.navigate('/call-forwarding');
  }

}
