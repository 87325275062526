import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { ResultModel } from '../../core/model/result.model';
import { Observable } from 'rxjs';
import { ApiUrls } from '../../config/api-urls';

@Injectable({
  providedIn: 'root'
})
export class ViewMinutesStatusServiceService {

  constructor(private apiService: ApiService,
    private apiUrl: ApiUrls) { }

    getSubcriberAllocatedMinutesListing(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.SubcriberAllocatedMinutesListing, reqModel);
    }

    getSubscriberDropdown(reqModel): Observable<ResultModel>{
      return this.apiService.post<ResultModel>(this.apiUrl.Subscriber.SubscriberDropdown,reqModel);
    }

    getComponentInsertedData(reqModel): Observable<ResultModel> {
      return this.apiService.post<ResultModel>(this.apiUrl.CompanyAdmin.GetComponentInsertedAllocatedUnitsData, reqModel);
    }
}
