import {CustomizePackageSuperAdminserviceService} from '../service/customize-package-super-adminservice.service'
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-add-customize-package-super-admin-comment-component',
  templateUrl: './add-customize-package-super-admin-comment-component.component.html',
  styleUrls: ['./add-customize-package-super-admin-comment-component.component.scss']
})
export class AddCustomizePackageSuperAdminCommentComponentComponent implements OnInit {
  public addCustPackCommentForm: FormGroup
  public isProcessing :boolean =false
  userData: any
  customizePackageId:number

  constructor(private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: AddCustomizePackageSuperAdminCommentComponentComponent,
    private commonService: CommonService,
    private customizePackageSuperAdminserviceService: CustomizePackageSuperAdminserviceService,
    private datePipe: DatePipe,
    private dialogRef: MatDialogRef<AddCustomizePackageSuperAdminCommentComponentComponent>) { 
      this.userData=data;
      this.customizePackageId=this.userData.planDetail.customizePackageId
    }

  ngOnInit() {
    this.initForm()
  }

  initForm() {
    this.addCustPackCommentForm = this.formBuilder.group({
      'Comment':[null,[Validators.required]]
    });
  }

  public addCustPackageComment(form) {
    if (form.valid) {
      try {
        this.isProcessing = true;   
          let params = {
            Comment: this.addCustPackCommentForm.controls.Comment.value.trim(),
            CustomizePackageId:this.customizePackageId
          }
          this.customizePackageSuperAdminserviceService.addCustPackageComment(params).subscribe(resposne => {
            if (resposne.success) {
              this.isProcessing = false;
              this.commonService.showSnakeBar(resposne.message);
              this.closeDialog();
            }
            else {
              this.isProcessing = false;
              this.commonService.showSnakeBar(resposne.message);
            }
  
          }, (err) => {
            this.isProcessing = false;
            this.commonService.checkTokenValidity(err);
            this.closeDialog();
          })
      }
      catch
      {
        this.isProcessing = false;
        this.commonService.commonSnakeBar();
      }
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

}
