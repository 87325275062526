import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { MaterialComponentsModule } from '../material-components.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SmsCampaignListComponent } from './sms-campaign-list/sms-campaign-list.component';
import { CreateSMSCampaignComponent } from './create-smscampaign/create-smscampaign.component';
import { SmsCampaignDetailComponent } from './sms-campaign-detail/sms-campaign-detail.component';
import { EditSmsCampaignComponent } from './edit-sms-campaign/edit-sms-campaign.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { PauseCampaignComponent } from './pause-campaign/pause-campaign.component';
import { ResumeCampaignComponent } from './resume-campaign/resume-campaign.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    MaterialComponentsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  declarations: [SmsCampaignListComponent, CreateSMSCampaignComponent, SmsCampaignDetailComponent, EditSmsCampaignComponent,PauseCampaignComponent,ResumeCampaignComponent],
  entryComponents :[CreateSMSCampaignComponent,EditSmsCampaignComponent,PauseCampaignComponent,ResumeCampaignComponent]
})
export class SmsCampaignsModule { }
