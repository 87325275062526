import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from '../../core/services/auth.service';
import { CommonService } from '../../core/services/common.service';
import { AppSettings } from '../../config/app-settings';
import { StatusMaster } from '../../core/constants/status';
import { AutofillMonitor } from '@angular/cdk/text-field';
import { CustomizepackageserviceService } from '../service/customizepackageservice.service'
import { AddCustomizePackageComponent } from '../add-customize-package/add-customize-package.component'
import { AddCustomizePackageCommentComponent } from '../add-customize-package-comment/add-customize-package-comment.component'
import { DeleteCustomizePackagesComponent } from '../../shared/delete-customize-packages/delete-customize-packages.component'
import { CustomizePackageCommentListComponent } from '../customize-package-comment-list/customize-package-comment-list.component'
import { EditCustomizePackageComponent } from '../edit-customize-package/edit-customize-package.component'
import { Router } from '@angular/router';
import { SharedService } from '../../shared/service/shared.service';
import { JwtService } from '../../core/services/jwt.service';

@Component({
  selector: 'app-cutomize-packages-list',
  templateUrl: './cutomize-packages-list.component.html',
  styleUrls: ['./cutomize-packages-list.component.scss']
})
export class CutomizePackagesListComponent implements OnInit {

  displayedColumns: string[] = ['CustomizePackageId', 'CustomizePackageRefId', 'Name', 'Description',
    'AudioMinutes', 'VideoMinutes', 'SMS', 'Price', 'CompanyId', 'CompName', 'PackageTenure', 'Status'];

  dataSource = [];

  public ListingData = [];
  public AllStandardPackages = [];
  public manualPaging: boolean = false
  constructor(public dialog: MatDialog,
    private authService: AuthService,
    private commonService: CommonService,
    private sharedService: SharedService,
    private jwtService: JwtService,
    private appSettings: AppSettings,
    private router: Router,
    private customizepackageserviceService: CustomizepackageserviceService) { 
      this.getuserDetails();
    }

  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  isLoadMore: boolean = false
  statusMstr: any = StatusMaster;

  standfullControl: boolean = true;
  standviews: boolean = true;
  standadds: boolean = true;
  standedits: boolean = true;
  standdeletes: boolean = true;
 
  ngOnInit() {
    this.getCustomizePackageListing(false)

    const role = this.jwtService.getUserRole();
    if (role != 'CompanyAdmin') {
      this.getAddEditDeletePermission();
    }
  }

  noRecordFoundNotCon: boolean = false
  getCustomizePackageListing(isPaging: boolean) {
    try {
      let params
      if (this.manualPaging == true && isPaging != true) {
        this.pageNumber = 1
        params = {
          PageNumber: this.pageNumber,
          PageSize: this.pageSize
        }
      }
      else {
        params = {
          PageNumber: this.pageNumber,
          PageSize: this.pageSize
        }
      }
      this.customizepackageserviceService.getCustomizePackageListing(params).subscribe(response => {
        if (response.success) {

          let len = response.data.length;
          if (len > 0) {
             this.noRecordFoundNotCon = true
           }
           else {
             this.noRecordFoundNotCon = false
           }
       
          if (response.data.length < this.pageSize) {
            this.isLoadMore = false;
          }
          else {
            this.isLoadMore = true;
          }

          if (isPaging) {
            this.appendCustomizePackageListing(response.data);
          }
          else {
            this.dataSource = response.data;
          }
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch{
      this.commonService.commonSnakeBar();
    }
  }

  appendCustomizePackageListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.dataSource.length > 0) {
        newState = this.dataSource.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.dataSource = newState;
    }
  }

  loadMore() {
    this.pageNumber = this.pageNumber + 1
    this.pageSize = this.pageSize
    this.getCustomizePackageListing(true);
  }


  AddCustomizePlanModal(): void {

    if (this.statusId == 10) {
	    const dialogRef = this.dialog.open(AddCustomizePackageComponent, {
        disableClose: true,
        panelClass: "full-width-popup"
      }
      )
  
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.getCustomizePackageListing(false);
        }
      })
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }

  };

  AddCustomizedPlanCommentModal(planDetail): void {

    if (this.statusId == 10) {
	    const dialogRef = this.dialog.open(AddCustomizePackageCommentComponent, {
        width: '600px',
        height: 'auto',
        panelClass: "modal-outer",
        data: {
          planDetail
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.getCustomizePackageListing(false);
        }
      })
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }

  }

  EditCustomizedPlanCommentModal(planDetail, type): void {
    if (this.statusId == 10) {
	    this.router.navigate(['/editcustomizePackage', planDetail, type]);
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }
  }



  deleteCustomizedPlanModal(planDetail): void {

    if (this.statusId == 10) {
	    const dialogRef = this.dialog.open(DeleteCustomizePackagesComponent, {
        width: '600px',
        height: 'auto',
        data: {
          planDetail
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.getCustomizePackageListing(false);
        }
      })
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }
  }

  ViewCustomizedPlanCommentModal(planDetail): void {

    if (this.statusId == 10) {
      const dialogRef = this.dialog.open(CustomizePackageCommentListComponent, {
        disableClose: true,
        panelClass: "full-width-popup",
        data: {
          planDetail
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.getCustomizePackageListing(false);
        }
      })
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }
  
  }

  getAddEditDeletePermission() {
    let params = {
      MenuId: 23
    }
    this.sharedService.getAddEditDeletePermission(params).subscribe(response => {
      if (response.success) {
        if (response.data.length >0) {
          this.standadds = response.data[0].adds
          this.standviews = response.data[0].views
          this.standfullControl = response.data[0].fullControle
          this.standdeletes = response.data[0].deletes
          this.standedits = response.data[0].edit
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  userDetails = []
  statusId: number
  getuserDetails(): number {
    try {
      let userId = this.jwtService.getCurrentUserId();
      let param = {
        UserId: userId
      }
      this.sharedService.getUserDetails(param).subscribe(response => {
        if (response.success) {
          this.userDetails = response.data;
          this.statusId = response.data[0].statusId;
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch {
      this.commonService.commonSnakeBar();
    }
    return this.statusId;
  }

}
