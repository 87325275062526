import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { MyPackageServiceService } from '../service/my-package-service.service';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppSettings } from '../../config/app-settings';
import { AddPaymentComponent } from '../../stripe-payment/add-payment/add-payment.component';


@Component({
  selector: 'app-edit-my-packages-standard-customize',
  templateUrl: './edit-my-packages-standard-customize.component.html',
  styleUrls: ['./edit-my-packages-standard-customize.component.scss']
})
export class EditMyPackagesStandardCustomizeComponent implements OnInit {
  public editMyPacStandCustSpForm: FormGroup;
  public isProcessing: boolean = false;
  userData: any
  packageId: any
  componentPlanData = [];
  componentInsertedPlanData = [];
  audioMins: any
  compName: any
  companyId: any
  endDate: any
  id: any
  packageName: any
  calCulatedPrice: any
  price: any
  priceUnit: any
  sms: any
  startDate: any
  status: any
  statusId: any
  videoMins: any
  packageType: any
  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  componentDataForDynamicInputs = [];
  packageTenure: any
  PackageTenure: any
  description: any

  constructor(private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: EditMyPackagesStandardCustomizeComponent,
    private commonService: CommonService,
    private myPackageServiceService: MyPackageServiceService,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private appSettings: AppSettings,
    private dialogRef: MatDialogRef<EditMyPackagesStandardCustomizeComponent>) {
    this.userData = data;
    this.id = this.userData.purchaseDetails.id;
    this.compName = this.userData.purchaseDetails.compName;
    this.companyId = this.userData.purchaseDetails.companyId;
    this.endDate = this.userData.purchaseDetails.endDate;
    this.packageName = this.userData.purchaseDetails.packageName;
    if (this.packageName == undefined) {
      this.packageName = this.userData.purchaseDetails.name;
    }

    this.calCulatedPrice = this.userData.purchaseDetails.calCulatedPrice;
    this.priceUnit = this.userData.purchaseDetails.priceUnit;
    this.startDate = this.userData.purchaseDetails.startDate;
    this.status = this.userData.purchaseDetails.status;
    this.statusId = this.userData.purchaseDetails.statusId;
    this.price = this.userData.purchaseDetails.price;
    this.packageType = this.userData.purchaseDetails.packageType
    this.packageTenure = this.userData.purchaseDetails.packageTenure
    this.description = this.userData.purchaseDetails.description
  }

  ngOnInit() {
    this.initForm()
    this.getComponentDetails()
    this.noofDays()
  }

  noofDays() {
    var date = new Date();
    var time = new Date(date.getTime());
    time.setMonth(date.getMonth() + 1);
    time.setDate(0);
    var days = time.getDate() > date.getDate() ? time.getDate() - date.getDate() : 0;
    this.price = ((this.price / 30) * days).toFixed();
  }

  initForm() {
    this.editMyPacStandCustSpForm = this.formBuilder.group({
      'PackageName': [this.packageName, [Validators.required, this.noWhitespaceValidator]],
      'Description': [this.description],
      'AllComponent': this.formBuilder.array([]),
      'CalCulatedPrice': [this.calCulatedPrice],
      'Price': [this.price],
      'StartDate': [this.startDate],
      'EndDate': [this.endDate],
      'Id': [this.id],
      'PackageTenure': [String(this.packageTenure), [Validators.required, this.noWhitespaceValidator]]
    });
  }

  getComponentDetails() {
    let allcomponent = this.editMyPacStandCustSpForm.get('AllComponent') as FormArray;
    let params = {
      PageNumber: this.pageNumber,
      PageSize: this.pageSize,
      PackageType: this.packageType,
      PackageId: this.id,
      SpOption: 'Get',
      PurchasedOption: 'No',
      PurchasePackage: 'PurchasePage'
    }
    this.myPackageServiceService.getComponentInsertedData(params).subscribe(response => {
      if (response.success) {
        this.componentInsertedPlanData = response.data
        let count = [];
        response.data.forEach(element => {
          allcomponent.push(this.formBuilder.control({
            ComponentId: element.componentId,
            ComponentType: element.componentType,
            InputParameter: element.inputParameter,
            Id: element.id,
            Name: element.name,
            Price: element.price,
            CalculatedValue: element.calculatedValue
          }));
          count.push({
            ComponentId: element.componentId,
            ComponentType: element.componentType,
            InputParameter: element.inputParameter,
            Id: element.id,
            Name: element.name,
            Price: element.price,
            CalculatedValue: element.calculatedValue
          })
        });

        if (count.length >= 0) {
          this.componentDataForDynamicInputs = count
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }


  PurchaseCompanyModal(): void {
    const dialogRef = this.dialog.open(AddPaymentComponent, {
      disableClose: true,
      width: '600px',
      height: 'auto',
      panelClass: 'modal-outer',
      data: {
        PackageId: this.id,
        PackageType: this.packageType,
        Amount: this.price
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    })
  }

}
