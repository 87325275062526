import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../core/services/common.service';
import { AuthService } from '../../core/services/auth.service';
import { StatusMaster } from '../../core/constants/status';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmDeleteComponent } from '../../shared/confirm-delete/confirm-delete.component';
import { BlockModalComponent } from '../../shared/block-modal/block-modal.component';
import { AppSettings } from '../../config/app-settings';
import { UserPermissionCompanyAdminServiceService } from '../service/user-permission-company-admin-service.service';
import { AddUserPermissionCompanyAdminComponent } from '../add-user-permission-company-admin/add-user-permission-company-admin.component';
import { EditUserPermissionCompanyAdminComponent } from '../edit-user-permission-company-admin/edit-user-permission-company-admin.component';
import { BlockUserPermissionCompanyAdminComponent } from '../../shared/block-user-permission-company-admin/block-user-permission-company-admin.component';
import { DeleteUserPermissionCompanyAdminComponent } from '../../shared/delete-user-permission-company-admin/delete-user-permission-company-admin.component';
import { SharedService } from '../../shared/service/shared.service';
import { JwtService } from '../../core/services/jwt.service';

@Component({
  selector: 'app-user-permission-company-admin-list',
  templateUrl: './user-permission-company-admin-list.component.html',
  styleUrls: ['./user-permission-company-admin-list.component.scss']
})
export class UserPermissionCompanyAdminListComponent implements OnInit {

  displayedColumns: string[] = ['permissionId', 'permissionName', 'RoleId', 'Status', 'status'];
  dataSource = [];

  pageNumber: number = 1
  pageSize: number = this.appSettings.PAGINATOR_SIZE
  isLoadMore: boolean = false;

  statusMaster: any = StatusMaster

  public manualPaging: boolean = false

  fullControl: boolean = true;
  views: boolean = true;
  adds: boolean = true;
  edits: boolean = true;
  deletes: boolean = true;
  disableds: boolean = false;

  constructor(private userPermissionCompanyAdminServiceService: UserPermissionCompanyAdminServiceService,
    private commonService: CommonService,
    private sharedService: SharedService,
    private authService: AuthService,
    private jwtService: JwtService,
    public dialog: MatDialog,
    private appSettings: AppSettings) { 
      this.getuserDetails(); 
    }

  ngOnInit() {
    this.getmainPermissonListingForCompAdmin(false)
    const role = this.jwtService.getUserRole();
    if (role != 'CompanyAdmin') {
      this.getAddEditDeletePermission();
    }
  }
  noRecordFoundNotCon: boolean = false
  getmainPermissonListingForCompAdmin(isPaging: boolean) {
    try {
      let params
      if (this.manualPaging == true && isPaging != true) {
        this.pageNumber = 1
        params = {
          PageNumber: this.pageNumber,
          PageSize: this.pageSize
        }
      }
      else {
        params = {
          PageNumber: this.pageNumber,
          PageSize: this.pageSize
        }
      }
      this.userPermissionCompanyAdminServiceService.getmainPermissonListingForCompAdmin(params).subscribe(response => {
        if (response.success) {
          let len = response.data.length;
          if (len > 0) {
             this.noRecordFoundNotCon = true
           }
           else {
             this.noRecordFoundNotCon = false
           }

          if (response.data.length < this.pageSize) {
            this.isLoadMore = false;
          }
          else {
            this.isLoadMore = true;
          }

          if (isPaging) {
            this.appendmainPermissonListing(response.data);
          }
          else {
            this.dataSource = response.data;
          }
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch{
      this.commonService.commonSnakeBar();
    }
  }


  loadMore() {
    this.pageNumber = this.pageNumber + 1
    this.pageSize = this.pageSize
    this.getmainPermissonListingForCompAdmin(true);
  }


  appendmainPermissonListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.dataSource.length > 0) {
        newState = this.dataSource.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.dataSource = newState;
    }
  }


  addPermissonModal(): void {

    if (this.statusId == 10) {
	    const dialogRef = this.dialog.open(AddUserPermissionCompanyAdminComponent, {
        disableClose: true,
        panelClass: "full-width-popup"
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.getmainPermissonListingForCompAdmin(false);
        }
      })
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }

  }

  editPermissionModal(userDetail): void {

    if (this.statusId == 10) {
	    const dialogRef = this.dialog.open(EditUserPermissionCompanyAdminComponent, {
        disableClose: true,
        panelClass: "full-width-popup",
        data: {
          userDetail
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.getmainPermissonListingForCompAdmin(false);
        }
      })
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }

  }


  onChange(enable: boolean, permissionDetails) {

    if (this.statusId == 10) {
	    const dialogRef = this.dialog.open(BlockUserPermissionCompanyAdminComponent, {
        width: '600px',
        height: 'auto',
        data: {
          permissionDetails
        }
      })
  
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.getmainPermissonListingForCompAdmin(false);
        }
      })
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }

  }

  deleteConfirmModal(permissionDetails): void {

    if (this.statusId == 10) {
	    const dialogRef = this.dialog.open(DeleteUserPermissionCompanyAdminComponent, {
        width: '600px',
        height: 'auto',
        data: {
          permissionDetails
        }
      })
  
      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) {
          this.manualPaging = true
          this.getmainPermissonListingForCompAdmin(false);
  
        }
      })
    }
      else {
        this.commonService.userDeActivatedOrDeleted();
      }

  };

  getAddEditDeletePermission() {
    let params = {
      MenuId: 22
    }
    this.sharedService.getAddEditDeletePermission(params).subscribe(response => {
      if (response.success) {

        if (response.data.length > 0) {
          this.adds = response.data[0].adds
          this.views = response.data[0].views
          this.fullControl = response.data[0].fullControle
          this.deletes = response.data[0].deletes
          this.edits = response.data[0].edit
        }


        if (this.fullControl == true || this.edits == true) {
          this.disableds = false
        }
        else {
          this.disableds = true
        }
      }
    }, (err) => {
      this.commonService.checkTokenValidity(err);
    })
  }


  userDetails = []
  statusId: number
  getuserDetails(): number {
    try {
      let userId = this.jwtService.getCurrentUserId();
      let param = {
        UserId: userId
      }
      this.sharedService.getUserDetails(param).subscribe(response => {
        if (response.success) {
          this.userDetails = response.data;
          this.statusId = response.data[0].statusId;
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      }, (err) => {
        this.commonService.checkTokenValidity(err);
      })
    } catch {
      this.commonService.commonSnakeBar();
    }
    return this.statusId;
  }

}
