import { CustomizepackageserviceService } from '../service/customizepackageservice.service';
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';



@Component({
  selector: 'app-add-customize-package-comment',
  templateUrl: './add-customize-package-comment.component.html',
  styleUrls: ['./add-customize-package-comment.component.scss']
})
export class AddCustomizePackageCommentComponent implements OnInit {
  public addCustPackCommentForm: FormGroup
  public isProcessing :boolean =false
  userData: any
  customizePackageId:number

  
  constructor(private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: AddCustomizePackageCommentComponent,
    private commonService: CommonService,
    private customizepackageserviceService: CustomizepackageserviceService,
    private datePipe: DatePipe,
    private dialogRef: MatDialogRef<AddCustomizePackageCommentComponent>) {
      this.userData=data;
      this.customizePackageId=this.userData.planDetail.customizePackageId
     }

  ngOnInit() {
    this.initForm();
  }


  initForm() {
    this.addCustPackCommentForm = this.formBuilder.group({
      'Comment':[null,[Validators.required,this.noWhitespaceValidator]]
    });
  }

  public addCustPackageComment(form) {
    if (form.valid) {
      try {
        this.isProcessing = true;   
          let params = {
            Comment: this.addCustPackCommentForm.controls.Comment.value.trim(),
            CustomizePackageId:this.customizePackageId
          }
          this.customizepackageserviceService.addCustPackageComment(params).subscribe(resposne => {
            if (resposne.success) {
              this.isProcessing = false;
              this.commonService.showSnakeBar(resposne.message);
              this.closeDialog();
            }
            else {
              this.isProcessing = false;
              this.commonService.showSnakeBar(resposne.message);
            }
  
          }, (err) => {
            this.isProcessing = false;
            this.commonService.checkTokenValidity(err);
            this.closeDialog();
          })
      }
      catch
      {
        this.isProcessing = false;
        this.commonService.commonSnakeBar();
      }
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

}
